import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import { hydrate, render } from 'react-dom'
import './index.css'
import smoothscroll from 'smoothscroll-polyfill'
import App from './App'

import { initialize as initializeSentry } from './modules/sentry/initialize'
import * as serviceWorker from './serviceWorker'
import 'moment/locale/ja'

initializeSentry()
smoothscroll.polyfill()

const rootElement = document.getElementById('root')

if (rootElement?.hasChildNodes()) {
  hydrate(<App />, rootElement)
} else {
  render(<App />, rootElement)
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
