import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43 41.653c-11.467 0-20.812-9.346-20.812-20.841C22.188 9.345 31.533 0 43 0s20.812 9.345 20.812 20.84c0 11.467-9.345 20.813-20.812 20.813zm0-37.066c-3.21 0-6.335.946-9.03 2.752a16.091 16.091 0 00-5.963 7.281 16.154 16.154 0 00-.917 9.374 16.273 16.273 0 004.443 8.313 16.273 16.273 0 008.314 4.444c3.153.63 6.421.315 9.374-.918a16.336 16.336 0 007.281-5.991 16.3 16.3 0 002.723-9.03c0-4.3-1.72-8.428-4.758-11.467A16.233 16.233 0 0043 4.587zM80.84 86H5.16V58.996c0-3.985 1.577-7.797 4.415-10.635a15.018 15.018 0 0110.606-4.415h.344l.344.115c.115.028 11.094 3.468 21.644 3.468 11.094 0 22.503-3.44 22.618-3.468l.315-.086h.344c3.985 0 7.797 1.576 10.607 4.414 2.809 2.81 4.386 6.651 4.414 10.636V86h.029zM9.747 81.413h66.506V58.996a10.483 10.483 0 00-10.12-10.463c-2.092.602-12.813 3.554-23.62 3.554-10.32 0-20.583-2.924-22.676-3.554a10.427 10.427 0 00-7.166 3.182 10.45 10.45 0 00-2.953 7.281v22.417h.029zM67.768 65.16l-5.963 5.963-5.962-5.963c-.774-.802-1.233-1.863-1.233-2.981a4.28 4.28 0 011.204-2.981 4.244 4.244 0 012.953-1.29 4.197 4.197 0 013.01 1.175c.802-.774 1.892-1.175 3.01-1.175a4.244 4.244 0 012.952 1.29c.774.802 1.233 1.863 1.204 2.981.058 1.118-.401 2.179-1.175 2.981z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgPatient = _style(SvgIcon)

export default SvgPatient
