import isPropValid from '@emotion/is-prop-valid'
import css from '@styled-system/css'
import React, { FC, useContext } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'
import styled, { ThemeContext } from 'styled-components'
import {
  borderRadius,
  BorderRadiusProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  flexbox,
  FlexboxProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
  shadow,
  ShadowProps,
} from 'styled-system'

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  BorderRadiusProps &
  PositionProps &
  ShadowProps &
  FlexboxProps

const styledSystemProps = [
  typography,
  space,
  layout,
  color,
  borderRadius,
  position,
  shadow,
  flexbox,
]

type ColorStyle =
  | 'green'
  | 'green-outlined'
  | 'green-flat'
  | 'white'
  | 'primary' // primary will be removed
  | 'primary-outlined' // primary-outline will be removed
type Shape = 'square' | 'round'
type Size = 'regular' | 'small'

export type ButtonProps = {
  colorStyle?: ColorStyle
  shape?: Shape
  sizeStyle?: Size
} & StyledSystemProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>

export const Button: FC<ButtonProps> = props => {
  return <ButtonRoot {...props}>{props.children}</ButtonRoot>
}

Button.defaultProps = {
  colorStyle: 'green',
  shape: 'round',
  sizeStyle: 'regular',
}

export type LinkButtonProps = {
  colorStyle?: ColorStyle
  shape?: Shape
  sizeStyle?: Size
} & StyledSystemProps &
  RouterLinkProps

export const LinkButton: FC<LinkButtonProps> = props => {
  const boxShadow = useThemedBoxShadow(props.colorStyle)

  return (
    <LinkButtonRoot {...props} boxShadow={boxShadow}>
      {props.children}
    </LinkButtonRoot>
  )
}

LinkButton.defaultProps = {
  colorStyle: 'green',
  shape: 'round',
  sizeStyle: 'regular',
}

export type TextButtonProps = {
  colorStyle?: 'green'
} & StyledSystemProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>

export const TextButton: FC<TextButtonProps> = props => {
  return <TextButtonRoot {...props}>{props.children}</TextButtonRoot>
}

const useThemedBoxShadow = (colorStyle: ColorStyle | undefined) => {
  const themeContext = useContext(ThemeContext)
  const boxShadowValue = colorStyle2BoxShadow[colorStyle || 'no-style']

  const boxShadowValueArray = boxShadowValue.split(' ')
  if (boxShadowValueArray.length > 0) {
    const shadowColor = boxShadowValueArray[boxShadowValueArray.length - 1]

    boxShadowValueArray[boxShadowValueArray.length - 1] =
      themeContext.colors[shadowColor] || shadowColor
  }

  return boxShadowValueArray.join(' ')
}

const colorStyle2BoxShadow = {
  primary: '0px 2px 15px greenShadow',
  green: '0px 2px 15px greenShadow',
  'primary-outlined': '0px 2px 4px rgba(49,53,65,0.3)',
  'green-outlined': '0px 2px 4px rgba(49,53,65,0.3)',
  'green-flat': '',
  white: '0px 2px 7px rgba(111,111,111,0.15)',
  'no-style': '',
}

TextButton.defaultProps = {
  colorStyle: 'green',
}

const baseCss = css({
  display: 'inline-flex',
  fontFamily: 'inherit',
  fontWeight: 'bold',
  backgroundColor: 'transparent',
  border: 'none',
  appearance: 'none',
  cursor: 'pointer',
  outline: 'none',
  textDecoration: 'none',
  alignItems: 'center',
  justifyContent: 'center',
})

const colorVariant = variant({
  prop: 'colorStyle',
  variants: {
    primary: {
      // WARNING: `primary` will be removed after the refactor
      color: 'white',
      bg: 'green',
      '&:disabled': {
        border: '1px solid',
        borderColor: 'transparent',
        color: 'shuttleGray',
        bg: 'slateGray',
        boxShadow: 'none',
        '&:hover': {
          bg: 'slateGray',
        },
      },
      '&:hover': {
        bg: 'seaGreen',
      },
    },
    green: {
      color: 'white',
      bg: 'green',
      '&:disabled': {
        border: '1px solid',
        borderColor: 'transparent',
        color: 'shuttleGray',
        bg: 'slateGray',
        boxShadow: 'none',
        '&:hover': {
          bg: 'slateGray',
        },
      },
      '&:hover': {
        bg: 'seaGreen',
      },
    },
    'primary-outlined': {
      // WARNING: `primary-outline` will be removed after the refactor
      border: '1px solid',
      borderColor: 'green',
      color: 'green',
      background: 'white',
      '&:disabled': {
        border: '1px solid',
        borderColor: 'shuttleGray',
        color: 'shuttleGray',
        bg: 'lightGray',
        boxShadow: 'none',
        '&:hover': {
          bg: 'lightGray',
        },
      },
      '&:hover': {
        bg: 'pistachioGreen',
      },
    },
    'green-outlined': {
      border: '1px solid',
      borderColor: 'green',
      color: 'green',
      background: 'white',
      '&:disabled': {
        border: '1px solid',
        borderColor: 'shuttleGray',
        color: 'shuttleGray',
        bg: 'lightGray',
        boxShadow: 'none',
        '&:hover': {
          bg: 'lightGray',
        },
      },
      '&:hover': {
        bg: 'pistachioGreen',
      },
    },
    'green-flat': {
      border: '1px solid',
      borderColor: 'green',
      color: 'green',
      background: 'white',
      '&:disabled': {
        border: '1px solid',
        borderColor: 'shuttleGray',
        color: 'shuttleGray',
        bg: 'lightGray',
        '&:hover': {
          bg: 'lightGray',
        },
      },
      '&:hover': {
        bg: 'pistachioGreen',
      },
    },
    white: {
      color: 'green',
      bg: 'white',
      '&:disabled': {
        border: '1px solid',
        borderColor: 'shuttleGray',
        color: 'shuttleGray',
        bg: 'lightGray',
        boxShadow: 'none',
        '&:hover': {
          bg: 'lightGray',
        },
      },
      '&:hover': {
        bg: 'whiteGray',
      },
    },
  },
})

const shapeVariant = variant({
  prop: 'shape',
  variants: {
    square: {
      borderRadius: '5px',
    },
    round: {
      borderRadius: '22px',
    },
  },
})

const sizeVariant = variant({
  prop: 'sizeStyle',
  variants: {
    regular: {
      width: '100%',
      minHeight: '44px',
      fontSize: 'large',
      lineHeight: '120%',
      py: '12px',
    },
    small: {
      width: 'max-content',
      minHeight: '32px',
      fontSize: 'normal',
      lineHeight: '120%',
      px: '16px',
    },
  },
})

const ButtonRoot = styled('button')<StyledSystemProps>(
  baseCss,
  colorVariant,
  shapeVariant,
  sizeVariant,
  styledSystemProps,
)

// https://github.com/DefinitelyTyped/DefinitelyTyped/pull/43718
// const LinkButtonRoot = styled(RouterLink).withConfig({
//   shouldForwardProp: isPropValid,
// })<StyledSystemProps & RouterLinkProps>(
//   baseCss,
//   colorVarient,
//   shapeVarient,
//   styledSystemProps,
// )

const LinkButtonRoot = (styled(RouterLink) as any).withConfig({
  shouldForwardProp: isPropValid,
})(baseCss, colorVariant, shapeVariant, sizeVariant, styledSystemProps)

const textButtonCss = css({
  display: 'inline-block',
  width: 'max-content',
  fontFamily: 'inherit',
  fontSize: 'normal',
  lineHeight: '120%',
  fontWeight: 'bold',
  textDecoration: 'none',
  textAlign: 'center',
  verticalAlign: 'middle',
  border: 'none',
  bg: 'transparent',
  appearance: 'none',
  cursor: 'pointer',
  outline: 'none',
})

const textColorVariant = variant({
  prop: 'colorStyle',
  variants: {
    green: {
      color: 'green',
      '&:disabled': {
        color: 'shuttleGray',
        '&:hover': {
          color: 'shuttleGray',
        },
      },
      '&:hover': {
        color: 'seaGreen',
      },
    },
  },
})

const TextButtonRoot = styled('button')<StyledSystemProps>(
  textButtonCss,
  textColorVariant,
  styledSystemProps,
)
