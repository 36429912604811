import React, { FC } from 'react'
import styled from 'styled-components'
import { Border } from '../lib/border'
import { Heading } from '../lib/heading'
import { Text } from '../lib/text'
import { View } from '../lib/view'

type Props = {
  searchTitle: string
}

const TitleText = styled.h1`
  font-size: 18px;
  color: 'darkGray';
  font-weight: 600;
`

export const SearchTitleHeader: FC<Props> = props => {
  const { searchTitle } = props

  return (
    <View fontSize="18px" fontWeight="bold" color="darkGray" my="20px">
      {searchTitle === '薬局検索' ? (
        <View textAlign="center">
          <Heading pb="8px">薬局検索</Heading>
          <Border
            borderColor="seaFormGreen"
            borderStyle="solid"
            borderWidth="4px"
            width="50px"
            margin="0 auto"
          />
        </View>
      ) : (
        <View textAlign="left">
          <TitleText>{searchTitle}</TitleText>
        </View>
      )}
    </View>
  )
}
