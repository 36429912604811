import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M81.299 86c-.316 0-.602-.057-.889-.172l-17.372-6.565a41.97 41.97 0 01-19.98 5.074H43C19.78 84.337.86 65.417.86 42.17.86 18.92 19.751 0 43 0c23.249 0 42.14 18.92 42.14 42.169a42.33 42.33 0 01-4.988 19.923l3.64 20.927c.144.888-.171 1.834-.888 2.408a2.56 2.56 0 01-1.605.573zm-18.49-12.097c.315 0 .602.057.888.172l14.276 5.418-3.038-17.401c-.115-.573 0-1.175.286-1.691a37.13 37.13 0 004.788-18.204c0-20.439-16.627-37.066-37.038-37.066-20.41 0-37.037 16.627-37.037 37.066 0 20.44 16.627 37.066 37.037 37.066h.058c6.478 0 12.871-1.72 18.49-4.988.401-.258.86-.372 1.29-.372zM43.4 52.46c1.29 0 2.322.373 3.096 1.147.774.745 1.176 1.72 1.176 2.895a3.546 3.546 0 01-.287 1.548c-.2.487-.516.946-.889 1.319-.774.745-1.806 1.146-3.096 1.146-1.261 0-2.293-.372-3.067-1.146a4.117 4.117 0 01-.889-1.319 4.118 4.118 0 01-.286-1.548c-.029-.545.086-1.06.286-1.577.201-.487.516-.946.889-1.318.774-.774 1.806-1.147 3.067-1.147zm3.154-4.185h-6.336l-.888-26.603h8.112l-.888 26.603z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgAlertBubble = _style(SvgIcon)

export default SvgAlertBubble
