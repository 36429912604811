import React, { FC } from 'react'
import styled, { keyframes, ThemeContext } from 'styled-components'

type SpinnerProps = {
  spinnerSize?: string
  color?: string
} & React.HTMLAttributes<HTMLDivElement>

export const Spinner: FC<SpinnerProps> = ({ spinnerSize, ...props }) => {
  const themeContext = React.useContext(ThemeContext)

  const color = props.color || 'seaGreen'
  if (color != null) {
    props = { ...props, color: themeContext.colors[color] || color }
  }

  return (
    <SpinnerRoot {...props}>
      <svg
        className="spinner"
        width={spinnerSize || '40px'}
        height={spinnerSize || '40px'}
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="path"
          fill="none"
          stroke={props.color}
          strokeWidth="6"
          strokeLinecap="round"
          cx="33"
          cy="33"
          r="30"
        ></circle>
      </svg>
    </SpinnerRoot>
  )
}

const offset = 187
const duration = 1.4

const RotatorKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
`

const DashKeyframes = keyframes`
  0% {
    stroke-dashoffset: ${offset};
  }
  50% {
    stroke-dashoffset: ${offset / 4};
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: ${offset};
    transform: rotate(450deg);
  }
`

const SpinnerRoot = styled.div`
  .spinner {
    animation: ${RotatorKeyframes} ${duration}s linear infinite;
  }

  @keyframes rotator {
  }

  .path {
    stroke-dasharray: ${offset};
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: ${DashKeyframes} ${duration}s ease-in-out infinite;
  }

  @keyframes dash {
  }
`
