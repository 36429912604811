import { DayOfWeek } from 'src/modules/entities/enums/day-of-week'
import {
  RepeatType,
  specificWeekNumberRepeatTypes,
} from 'src/modules/entities/enums/repeat-type'
import { RepeatCycle } from '../../repeat-cycle/entity'
import { BusinessHourInfo } from '../entity'

type ReturnProps = {
  [key: string]: {
    key: RepeatType
    repeatCycle: RepeatCycle | null
  }
}

// 第一金曜日 や 第二金曜日　なとの　RepeatCycles
export function getDissimilarCycles(
  businessHoursInfo: BusinessHourInfo,
  dayOfWeek: DayOfWeek,
): ReturnProps {
  const cycles: RepeatCycle[] =
    businessHoursInfo.repeatCycles.filter(r => r.dayOfWeek === dayOfWeek) || []

  return specificWeekNumberRepeatTypes.reduce((mapping: ReturnProps, d) => {
    mapping[d] = {
      key: d,
      repeatCycle: cycles.find(c => c.repeatType === d) || null,
    }
    return mapping
  }, {})
}
