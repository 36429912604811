import React, { FC } from 'react'
import styled from 'styled-components'
import { Link } from 'src/modules/components/lib/link'
import { View } from 'src/modules/components/lib/view'
import { routes } from 'src/modules/routes'
import { Spacer } from 'src/modules/screens/top/spacer'

export const Float: FC = () => (
  <LinkWrap>
    <Spacer size={23} />
    <View>
      <LinkButton
        to={routes.search.path}
        rel="noopener noreferrer"
        target="_blank"
      >
        <LinkText textAlign="center" color="#fff" fontWeight="700">
          対応する薬局を探す
        </LinkText>
      </LinkButton>
    </View>
    <Spacer size={23} />
  </LinkWrap>
)

const LinkWrap = styled(View)`
  background: #fff;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 10;
`

const LinkButton = styled(Link)`
  display: block;
  max-width: 335px;
  margin: 0 auto;
  padding: 13px 0 11px;
  text-align: center;
  background-color: #2ba168;
  border-radius: 8px;
  cursor: pointer;
`

const LinkText = styled(View)`
  font-size: 16px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
  color: #fff;
  border-radius: 8px;
`
