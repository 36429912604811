import React, { FC } from 'react'
import styled from 'styled-components'
import { ReactComponent as CheckCircle } from 'src/assets/top/check_circle.svg'
import { useAuthorizationRefresh } from 'src/modules/auth/use-authorization-refresh'
import { Border } from 'src/modules/components/lib/border'
import { LinkButton } from 'src/modules/components/lib/button'
import { Link } from 'src/modules/components/lib/link'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox, View } from 'src/modules/components/lib/view'
import { routes } from 'src/modules/routes'
import { Footer } from 'src/modules/screens/top/footer'
import { Header } from 'src/modules/screens/top/header'

export const CompletedEntryCampaign: FC = () => {
  const { authorized, fetched } = useAuthorizationRefresh()

  return (
    <>
      <Header fetched={fetched} authorized={authorized} />
      <View maxWidth="430px" mx="auto" p="20px" fontFamily="Noto Sans JP">
        <View mt="50px" mx="auto" textAlign="center">
          <CheckCircle />
        </View>
        <Text p="10px" textAlign="center" fontSize="16px" fontWeight="500">
          エントリーの受付が完了しました
        </Text>
        <View paddingBottom="31px">
          <Text>
            本キャンペーンへのエントリーが完了しました。
            ご登録のメールアドレス宛にエントリー受付完了メールを送信しておりますため、ご確認をお願いいたします。
          </Text>
        </View>
        <Border borderColor="lightGray" />
        <View my="28px" textAlign="center">
          <Flexbox justifyContent="center">
            <Text
              fontSize="16px"
              fontWeight="700"
              color="#2BA168"
              borderBottom="1px solid #FFD801"
            >
              NEXT STEP
            </Text>
          </Flexbox>
          <Text fontSize="16px" fontWeight="500">
            次はお好きな薬局を検索し、
            <br />
            クロン処方箋ネット受付を利用しましょう
          </Text>
        </View>

        <Flexbox justifyContent="center">
          <SearchPharmacyButton
            to={routes.telePharmacySearchPharmacy.build()}
            shape="square"
            maxWidth="300px"
            backgroundColor="seaGreen"
            fontWeight="500"
            onClick={() => null}
          >
            対応する薬局を探す
          </SearchPharmacyButton>
        </Flexbox>

        <Flexbox mt="28px" mb="74px" justifyContent="center">
          <Link to={routes.campaign202210.build()}>
            <Text borderBottom="1px solid green">キャンペーンページに戻る</Text>
          </Link>
        </Flexbox>
      </View>
      <Footer />
    </>
  )
}

const SearchPharmacyButton = styled(LinkButton)`
  &:hover {
    background-color: green;
  }
`
