import { AreaTag } from 'src/modules/entities/area-tag/entity'
import { Chain } from 'src/modules/entities/chain/entity'
import { BusinessHourInfo } from '../business-hour-info/entity'
import { Active } from '../enums/active'
import { PlanType } from '../enums/plan-type'

export type VideoCallProvider = 'skyway' | 'sora' | 'agora'

export type Pharmacy = {
  uid: string
  name: string
  nameJ: string
  postal: string
  url: string
  prefecture: string
  city: string
  district: string
  building: string | null
  phone: string
  email: string
  fax: string
  systemUser: boolean
  planType: PlanType
  pharmacyCode: string
  isFollowUpAvailable: boolean
  lat: number
  lng: number
  netReceptionAvailable: boolean
  businessHourInfo: BusinessHourInfo | null
  active: Active
  prescriptionSuspended: boolean
  readyForNetReception: boolean
  isStripeEnabled: boolean
  videoCallProvider: VideoCallProvider
  thumbnailUrl: string | null
  access: string[]
  services: PharmacyService[]
  nearbyPharmacies: NearbyPharmacy[]
  chain?: Chain
  areaTag?: AreaTag
  nearbyStations?: NearbyStation[]
  // nearbyArea: NearbyArea[]
}

export type PharmacyService = {
  uid: string
  name: string
  serviceCategory: ServiceCategory
}

export type NearbyPharmacy = {
  uid: string
  name: string
  address: string
  thumbnailUrl: string | null
  services: PharmacyService[]
  access: string[]
}

export type NearbyStation = {
  name: string
  code: string
  line: string
  distance: number
}

export type NearbyArea = {
  uid: string
  name: string
}

export type Area = {
  name: string
  slug: string
  prefecture: string
  type: string
  count: number
}

export type Station = {
  name: string
  code: string
  line: string
  prefecture: string
  count: number
}

export type ExternalPharmacyBrandLogo = {
  imageUrl: string
}

type ServiceCategory = 'service' | 'prescription' | 'facility'

export const toAddress = (pharmacy: Pharmacy) => {
  return nullableStringsJoin([
    pharmacy.prefecture,
    pharmacy.city,
    pharmacy.district,
    pharmacy.building,
  ])
}

const nullableStringsJoin = (
  strings: (string | undefined | null)[],
): string => {
  return strings.filter(s => !!s).join(' ')
}

export const reservationAvailable = (pharmacy: Pharmacy) => {
  return pharmacy.planType === PlanType.Beta
}
