import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import styled, { keyframes } from 'styled-components'
import { Text } from './text'
import { ViewProps } from './view'

export const useToast = () => {
  return useContext(ToastContext)
}

export const ToastContainer: PropsWithChildren<FC> = ({ children }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [error, setError] = useState<boolean>(false)
  const timer = useRef<number | null>(null)

  const showToast = useCallback(
    (message: string, error?: boolean) => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
      setVisible(true)
      setMessage(message)
      setError(!!error)
      // NOTE: 型が NodeJS.Timeout の方を参照してしまうので、windowオブジェクトから呼ぶ
      timer.current = window.setTimeout(() => setVisible(false), 4000)
    },
    [setVisible, setMessage],
  )
  const hideToast = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current)
      timer.current = null
    }
    setVisible(false)
  }, [setVisible])

  return (
    <ToastContext.Provider
      value={{ visible, message, error, showToast, hideToast }}
    >
      {children}
      <Toast visible={visible} message={message} error={error} />
    </ToastContext.Provider>
  )
}

type ToastContextValue = {
  visible: boolean
  message: string
  error: boolean
  showToast: (message: string, error?: boolean) => void
  hideToast: () => void
}

const ToastContext = React.createContext<ToastContextValue>({
  visible: false,
  message: '',
  error: false,
  showToast: () => null,
  hideToast: () => null,
})

type ToastProps = {
  visible: boolean
  error: boolean
  message: string
} & ViewProps

const Toast: FC<ToastProps> = ({ visible, message, error, ...props }) => {
  const [initialRender, setInitialRender] = useState(true)

  useEffect(() => {
    if (visible) {
      setInitialRender(false)
    }
  }, [visible])

  const visibleClass = initialRender ? '' : visible ? 'visible' : 'invisible'
  const errorClass = error ? 'error' : ''

  return (
    <ToastRoot className={`${visibleClass} ${errorClass}`} {...props}>
      <Text style={{ whiteSpace: 'pre-wrap' }}>{message}</Text>
    </ToastRoot>
  )
}

const ToVisibleKeyFrames = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`

const ToInvisibleKeyFrames = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 1;
  }
`

const ToastRoot = styled.div`
  position: fixed;
  z-index: 99999999;
  min-height: 64px;
  width: 100%;
  top: 0;
  left: 0;
  background: #f2c94c;
  text-align: center;
  opacity: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 5px;

  &.visible {
    transform: translateY(0);
    opacity: 1;

    animation: ${ToVisibleKeyFrames} 350ms ease-out 0s;
  }

  &.invisible {
    transform: translateY(-100%);
    opacity: 0;

    animation: ${ToInvisibleKeyFrames} 350ms ease-out 0s;
  }

  &.error {
    background: #fe446d;
    color: white;
  }
`
