import { replace } from 'connected-react-router'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useSubmit as useSubmitAuthByToken } from 'src/modules/apis/auth/by_token/use-submit'
import { useFetch as useFetchPharmacyVisit } from 'src/modules/apis/pharmacy-visit/single/use-fetch'
import { Loader } from 'src/modules/components/lib/loader'
import {
  ApplicationType,
  PharmacyVisit,
} from 'src/modules/entities/pharmacy-visit/entity'
import { getQueryParams } from 'src/modules/helpers/get-query-params'
import { routes } from 'src/modules/routes'
import { routes as newPatientRoutes } from 'src/modules/routes/pharmacy-patient'

export const RedirectScreen = () => {
  const { token } = useParams<{
    token: string
  }>()

  const { pharmacy_visit_uid: pharmacyVisitUid, redirect_url: redirectUrl } =
    getQueryParams(['pharmacy_visit_uid', 'redirect_url'])

  const dispatch = useDispatch()

  const { doFetch } = useFetchPharmacyVisit(pharmacyVisitUid!, {
    immediate: false,
    onComplete: useCallback((pharmacyVisit: PharmacyVisit) => {
      // NOTE: OL診療経由の場合は、患者登録画面に遷移しない
      window.location.replace(
        pharmacyVisit.applicationType === ApplicationType.Curon
          ? newPatientRoutes.onlineMedicationGuidanceDeliveryMethod.build(
              pharmacyVisit.uid,
            )
          : newPatientRoutes.onlineMedicationGuidancePatient.build(
              pharmacyVisit.uid,
            ),
      )
    }, []),
    onFailure: useCallback(() => {
      dispatch(replace(routes.notFound.build()))
    }, [dispatch]),
  })

  const { doSubmit } = useSubmitAuthByToken({
    onComplete: useCallback(() => {
      if (!!redirectUrl) {
        dispatch(replace(redirectUrl))
        return
      }
      doFetch()
    }, [dispatch, doFetch, redirectUrl]),
    onFailure: useCallback(() => {
      dispatch(replace(routes.notFound.build()))
    }, [dispatch]),
  })

  useEffect(() => {
    doSubmit({ token_uid: token })
  }, [doSubmit, token])

  return <Loader />
}
