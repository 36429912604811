import React, { FC } from 'react'
import Reload from 'src/modules/components/lib/reload'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox, ViewProps } from 'src/modules/components/lib/view'

type LoaderProps = ViewProps

export const Loader: FC<LoaderProps> = props => {
  return (
    <Flexbox
      py="32px"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <Reload size="32px" />
      <Text color="shuttleGray" fontSize="12px">
        読み込み中...
      </Text>
    </Flexbox>
  )
}
