import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43 81.49a4.21 4.21 0 01-1.643-.32c-.594-.228-1.142-.638-1.552-1.095L7.578 47.894l-.274-.274v-.046l-.137-.137A25.28 25.28 0 010 29.772C0 15.712 11.412 4.3 25.471 4.3c6.528 0 13.01 3.333 17.575 8.765C47.61 7.724 54.138 4.3 60.529 4.3 74.589 4.3 86 15.712 86 29.772c0 6.664-2.556 12.918-7.167 17.71-.045.047-.091.092-.137.138l-.045.046c-.092.09-.183.182-.274.273L46.15 80.121c-.411.456-.913.822-1.507 1.05a3.83 3.83 0 01-1.187.32H43zM13.42 41.64l.183.183L43 71.174l29.351-29.306.229-.228.045-.046a16.87 16.87 0 004.793-11.822c0-9.313-7.577-16.936-16.935-16.936-5.25 0-11.001 4.154-13.649 9.906-.684 1.506-2.19 2.465-3.88 2.465-1.689 0-3.195-.959-3.88-2.51-2.556-5.707-8.307-9.86-13.603-9.86-9.312 0-16.935 7.577-16.935 16.935a16.87 16.87 0 004.793 11.822c.046 0 .046 0 .091.046z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgHeart = _style(SvgIcon)

export default SvgHeart
