import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#warning_svg__clip0)">
        <path
          d="M83.535 75.25H2.465c-.458 0-.888-.115-1.261-.344a2.555 2.555 0 01-.889-.946c-.2-.401-.315-.831-.286-1.29.028-.459.172-.889.43-1.233L40.965 11.81c.229-.344.516-.601.888-.802.344-.2.746-.287 1.147-.287s.803.086 1.147.287c.344.2.659.459.888.803L85.57 71.409c.258.372.401.802.43 1.232.029.459-.086.889-.287 1.29-.2.401-.516.717-.888.946-.373.23-.832.373-1.29.373zM7.08 70.348h71.838L43 17.573 7.08 70.347zm33.224-9.317c0-.344.058-.716.172-1.06.144-.344.344-.631.574-.89.516-.515 1.204-.773 2.064-.773.86 0 1.576.258 2.092.774.258.258.459.544.574.889.143.344.2.688.172 1.032 0 .343-.058.688-.172 1.032-.115.315-.316.63-.574.86-.487.515-1.204.745-2.092.745-.918 0-1.606-.258-2.093-.745a2.823 2.823 0 01-.573-.86 2.374 2.374 0 01-.144-1.004zm4.902-6.45h-4.3l-.458-21.127h5.274l-.516 21.127z"
          fill={props.color || '#000'}
        />
      </g>
      <defs>
        <clipPath id="warning_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgWarning = _style(SvgIcon)

export default SvgWarning
