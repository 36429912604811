import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#voicememo_svg__clip0)" fill={props.color || '#000'}>
        <path d="M28.1 0C18.624 0 10.91 7.54 10.91 16.799V45.35c0 9.259 7.714 16.798 17.188 16.798 9.475 0 17.189-7.568 17.189-16.913V16.799C45.288 7.539 37.574 0 28.1 0zm11.204 45.236c0 6.106-5.045 11.065-11.234 11.065s-11.234-4.93-11.234-10.979V16.799c0-6.049 5.045-10.98 11.234-10.98 6.19 0 11.234 4.931 11.234 10.98v28.437z" />
        <path d="M53.207 30.989c-1.643 0-2.962 1.318-2.962 2.895v11.467c0 11.954-9.944 21.643-22.146 21.643S5.954 57.276 5.954 45.351V33.884c0-1.605-1.35-2.895-2.963-2.895-1.642 0-2.962 1.318-2.962 2.895v11.467c0 14.161 11.029 25.857 25.137 27.32v7.538h-9.357c-1.642 0-2.962 1.32-2.962 2.896 0 1.605 1.35 2.895 2.962 2.895h24.639c1.642 0 2.962-1.319 2.962-2.895 0-1.606-1.349-2.896-2.962-2.896H31.09V72.67c14.108-1.462 25.137-13.158 25.137-27.32V33.885c-.03-1.605-1.35-2.895-3.021-2.895z" />
        <path d="M84.973 49.364l-8.653-7.482a3.116 3.116 0 00-2.229-.716 2.935 2.935 0 00-2.053 1.06L45.992 73.674c-.499.602-.734 1.347-.646 2.092l.704 7.482c.147 1.49 1.408 2.638 2.963 2.638l7.949.028c.909 0 1.76-.401 2.317-1.09l26.046-31.446c.998-1.233.85-2.982-.352-4.014zm-10.266-1.118l4.195 3.64-2.376 2.868-4.195-3.641 2.376-2.867zM55.554 80.095H51.74l-.352-3.67 17.217-20.783 4.195 3.64-17.247 20.813z" />
      </g>
      <defs>
        <clipPath id="voicememo_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgVoicememo = _style(SvgIcon)

export default SvgVoicememo
