import { BusinessHourInfo } from 'src/modules/entities/business-hour-info/entity'
import {
  IrregularDayResponse,
  parse as parseIrregularDayResponse,
} from '../irregular-day/response'
import {
  RepeatCycleResponse,
  parse as parseRepeatCycleResponse,
} from '../repeat-cycle/response'
import {
  TargetDateOpeningHourResponse,
  parse as parseTargetDateOpeningHourResponse,
} from '../target-date-opening-hour/response'

export type BusinessHoursInfoResponse = {
  uid: string
  comment?: string
  repeat_cycles: RepeatCycleResponse[]
  irregular_days: IrregularDayResponse[]
  target_date_opening_hour: TargetDateOpeningHourResponse
}

export const parse = (resp: BusinessHoursInfoResponse): BusinessHourInfo => {
  const res: BusinessHourInfo = {
    uid: resp.uid,
    comment: resp.comment || null,
    repeatCycles: resp.repeat_cycles
      ? resp.repeat_cycles.map(parseRepeatCycleResponse)
      : [],
    irregularDays: resp.irregular_days
      ? resp.irregular_days.map(parseIrregularDayResponse)
      : [],
    targetDateOpeningHour: resp.target_date_opening_hour
      ? parseTargetDateOpeningHourResponse(resp.target_date_opening_hour)
      : null,
  }

  return res
}
