import { AreaResponse } from 'src/modules/apis/pharmacy/areas/response'
import { disableArea } from 'src/modules/constants/disable-area'

export const filterArea = ({ areaData }: { areaData?: AreaResponse[] }) => {
  const filteredList = areaData?.filter(area => {
    return !disableArea.some(disableArea => {
      return area.slug === disableArea.slug
    })
  })

  //  disableAreaのnameが含まれるものは上位に表示する
  if (filteredList) {
    const sortedList = filteredList.sort((a, b) => {
      const aName = a.name
      const bName = b.name
      const aDisableArea = disableArea.find(disableArea => {
        return aName.startsWith(disableArea.name)
      })
      const bDisableArea = disableArea.find(disableArea => {
        return bName.startsWith(disableArea.name)
      })
      if (aDisableArea && bDisableArea) {
        return 0
      } else if (aDisableArea) {
        return -1
      } else if (bDisableArea) {
        return 1
      } else {
        return 0
      }
    })
    return sortedList
  }

  return []
}
