import React, { FC } from 'react'
import styled from 'styled-components'
import CheckWithCircle from 'src/modules/components/lib/curon-icon/CheckWithCircle'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox, View } from 'src/modules/components/lib/view'

export const Recommended: FC = () => {
  return (
    <ViewContainer>
      <SectionHeaderText>こんな方におすすめ</SectionHeaderText>

      <ViewInner
        flexDirection="row"
        flexWrap="wrap"
        alignItems="baseline"
        justifyContent="space-between"
      >
        <RecommendationItemContainer>
          <ResponsiveCheckWithCircle
            width={undefined}
            height={undefined}
            viewBox="0 0 39 39"
          />

          <RecommendedPeopleFrame>
            <RecommendedPeopleText>
              忙しくて待ち時間を減らしたい方
            </RecommendedPeopleText>
          </RecommendedPeopleFrame>
        </RecommendationItemContainer>

        <RecommendationItemContainer>
          <ResponsiveCheckWithCircle
            width={undefined}
            height={undefined}
            viewBox="0 0 39 39"
          />

          <RecommendedPeopleFrame>
            <RecommendedPeopleText>
              処方薬の調剤に時間がかかる方
            </RecommendedPeopleText>
          </RecommendedPeopleFrame>
        </RecommendationItemContainer>

        <RecommendationItemContainer>
          <ResponsiveCheckWithCircle
            width={undefined}
            height={undefined}
            viewBox="0 0 39 39"
          />

          <RecommendedPeopleFrame>
            <RecommendedPeopleText>
              お子様との待ち時間にお困りの方
            </RecommendedPeopleText>
          </RecommendedPeopleFrame>
        </RecommendationItemContainer>
      </ViewInner>
    </ViewContainer>
  )
}

const ViewContainer = styled(View)`
  padding: 70px 3px 40px;
  background-color: ${({ theme }) => theme.colors.pistachioGreen};
  align-items: center;
  font-family: 'HiraginoSans', sans-serif;

  @media (max-width: 560px) {
    padding: 45px 60px 35px;
  }
  @media (max-width: 320px) {
    padding-right: 30px;
    padding-left: 30px;
  }
`
const ViewInner = styled(Flexbox)`
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: baseline;
  max-width: 1100px;
  margin: 0 auto;
`

const SectionHeaderText = styled(Text)`
  font-weight: 600;
  font-size: 32px;
  text-align: center;
  width: 100%;
  margin-bottom: 35px;

  @media (max-width: 560px) {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 15px;
  }
`

const RecommendedPeopleFrame = styled(View)`
  position: relative;
  padding: 10px;
  margin-top: 20px;

  @media (max-width: 560px) {
    padding: 0;
    margin-top: 0;
  }
`

const RecommendedPeopleText = styled(Text)`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin: 0 auto;

  @media (max-width: 560px) {
    font-size: 14px;
    line-height: 21px;
  }
`

const RecommendationItemContainer = styled(Flexbox)`
  flex-flow: column wrap;
  align-items: center;
  justify-content: space-between;
  margin: 24px 0;
  postion: relative;

  @media (max-width: 560px) {
    flex-flow: row nowrap;
    justify-content: flex-start;
    width: 100%;
    margin: 10px 0;
  }
`

const ResponsiveCheckWithCircle = styled(CheckWithCircle)`
  width: 100%;
  max-width: 39px;
  max-height: 39px;

  @media (max-width: 560px) {
    max-width: 25px;
    max-height: 25px;
    margin-right: 10px;
  }
`
