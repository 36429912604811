import React, { FC } from 'react'
import styled from 'styled-components'
import { Image } from 'src/modules/components/lib/image'
import { Text } from 'src/modules/components/lib/text'
import { View } from 'src/modules/components/lib/view'
import { Spacer } from './spacer'

export const About: FC = () => {
  return (
    <ViewContainer>
      <AboutBox>
        <AboutTitle>クロン処方箋ネット受付とは？</AboutTitle>
        <ImageWrap>
          <Image
            src="/top/about_img.png"
            width="100%"
            alt="クロン処方箋ネット受付とは"
          />
        </ImageWrap>
        <AboutText>
          スマホで撮影した処方箋画像を、事前に全国の薬局に送信・予約できるサービスです。
          <Text fontWeight="500" display="inline">
            好きな時間に好きな場所で処方薬を受け取ることができます。
          </Text>
        </AboutText>
        <Spacer size={4} />
        <Text fontSize="12px">
          ※システム利用料は無料です
          <br />
          ※薬局の営業時間内での受け取りとなります
        </Text>
      </AboutBox>
    </ViewContainer>
  )
}

const ViewContainer = styled(View)`
  padding: 60px 21px 80px;
  background-color: ${({ theme }) => theme.colors.pistachioGreen};
  font-family: 'HiraginoSans', sans-serif;

  @media (max-width: 560px) {
    padding-top: 20px;
    padding-bottom: 60px;
  }
`

const AboutBox = styled(View)`
  max-width: 700px;
  margin: 0 auto;
  padding: 45px 73px 40px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 6px 20px rgba(176, 186, 197, 0.3);
  border-radius: 6px;

  @media (max-width: 560px) {
    padding: 30px 25px;
  }
`

const AboutTitle = styled(Text)`
  margin: 0 0 20px;
  font-size: 24px;
  font-weight: 700;
  color: #2ba168;
  text-align: center;
  line-height: 36px;

  @media (max-width: 560px) {
    font-size: 20px;
    line-height: 30px;
  }
`

const AboutText = styled(Text)`
  font-size: 16px;
  line-height: 28px;

  @media (max-width: 560px) {
    font-size: 14px;
    line-height: 24px;
  }
`

const ImageWrap = styled(View)`
  max-width: 183px;
  margin: 0 auto 30px;

  @media (max-width: 560px) {
    margin-bottom: 20px;
  }
`
