import React, { FC } from 'react'
import Marquee from 'react-fast-marquee'
import styled from 'styled-components'
import { Image } from 'src/modules/components/lib/image'
import { Text } from 'src/modules/components/lib/text'
import { View } from 'src/modules/components/lib/view'

export const Pharmacy: FC = () => {
  return (
    <ViewContainer>
      <SectionHeaderText>ご利用可能な薬局チェーン</SectionHeaderText>
      <LogoWrap speed={60} gradient={false}>
        <LogoImage src="/top/pharmacy_logo_01.png" alt="薬樹" />
        <LogoImage src="/top/pharmacy_logo_02.png" alt="ココカラファイン" />
        <LogoImage src="/top/pharmacy_logo_03.png" alt="クオール" />
        <LogoImage src="/top/pharmacy_logo_04.png" alt="アイランド薬局" />
        <LogoImage src="/top/pharmacy_logo_06.png" alt="フラワー薬局" />
        <LogoImage src="/top/pharmacy_logo_07.png" alt="apoch" />
        <LogoImage src="/top/pharmacy_logo_09.png" alt="ユニスマイル" />
        <LogoImage src="/top/pharmacy_logo_10.png" alt="merhalsa" />
        <LogoImage src="/top/pharmacy_logo_11.png" alt="Tomods" />
      </LogoWrap>
    </ViewContainer>
  )
}

const ViewContainer = styled(View)`
  padding: 80px 0 80px;
  font-family: 'HiraginoSans', sans-serif;
  overflow: hidden;

  @media (max-width: 560px) {
    padding: 35px 0 40px;
  }
`

const SectionHeaderText = styled(Text)`
  margin: 0 0 35px;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  line-height: 48px;

  @media (max-width: 560px) {
    font-size: 20px;
    line-height: 30px;
  }
`

const LogoWrap = styled(Marquee)`
  & > .marquee {
    justify-content: space-evenly;
  }
`

const LogoImage = styled(Image)`
  max-width: 120px;
  image-rendering: -webkit-optimize-contrast;

  @media (max-width: 560px) {
    max-width: 160px;
  }
`
