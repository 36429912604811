import React, { FC, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'src/modules/reducer'

const useGaSetTag = () => {
  const authorizedAccount = useSelector(
    (state: RootState) => state.auth.account,
  )

  useEffect(() => {
    if (!!window.dataLayer) {
      window.dataLayer.push({
        logged_in: !!authorizedAccount,
        user_id: authorizedAccount ? authorizedAccount.mainUserUid : null,
        line_login_associated: authorizedAccount?.lineLoginAssociated || false,
        apple_login_associated:
          authorizedAccount?.appleLoginAssociated || false,
        email_login_associated:
          authorizedAccount?.emailLoginAssociated || false,
      })
    }
  }, [authorizedAccount])
}

export const GaProvider: FC = ({ children }) => {
  useGaSetTag()

  return <>{children}</>
}
