import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useFetch as useUnreadAnnouncementCount } from 'src/modules/apis/announcements/unread-count/use-fetch'
import { useErrorToastOnFailure } from 'src/modules/components/error/use-error-toast'
import { RootState } from 'src/modules/reducer'
import { routes } from 'src/modules/routes'
import { HamburgerHeaderContext, NavPage } from './hamburger-header'

// HamburgerHeader の実装を提供します
export const HamburgerHeaderProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const currentPage = useCurrentPage()
  const account = useSelector((state: RootState) => state.auth.account)

  const { data: announcementData } = useUnreadAnnouncementCount({
    onFailure: useErrorToastOnFailure(),
  })

  return (
    <HamburgerHeaderContext.Provider
      value={{
        account,
        currentPage,
        unreadAnnouncementCount: announcementData?.unreadCount ?? 0,
      }}
    >
      {children}
    </HamburgerHeaderContext.Provider>
  )
}

const useCurrentPage = () => {
  const location = useLocation()
  let currentPage: NavPage = NavPage.Other

  switch (location.pathname) {
    case routes.followUp.build():
      currentPage = NavPage.Top
      break
  }

  return currentPage
}
