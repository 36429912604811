import React from 'react'
import { FavoritePharmacy } from 'src/modules/entities/favorite-pharmacy/entity'

type FavoritePharmacyContextValue = {
  doFetch: () => void
  fetched: boolean
  fetching: boolean
  add: (pharmacyUid: string) => void
  remove: (pharmacyUid: string) => void
  isFavorite: (pharmacyUid: string) => boolean
  canAdd: boolean
  favorites: FavoritePharmacy[] | null
  activeFavorites: FavoritePharmacy[] | null
}

export const FavoritePharmacyContext =
  React.createContext<FavoritePharmacyContextValue>({
    doFetch: () => {},
    fetched: false,
    fetching: false,
    add: () => {},
    remove: () => {},
    isFavorite: () => false,
    canAdd: false,
    favorites: null,
    activeFavorites: null,
  })
