import { chain } from 'lodash-es'
import React, { FC } from 'react'
import { BreadCrumbs } from 'src/modules/components/lib/breadcrumbs'
import {
  FixedZIndex,
  HEADER_HEIGHT,
  MUTUAL_INDUCTION_HEIGHT,
} from 'src/modules/const'
import { Chain } from 'src/modules/entities/chain/entity'
import { Area, Station, Pharmacy } from 'src/modules/entities/pharmacy/entity'
import { PageType } from 'src/modules/screens/net-application/search/helper'
import { Prefecture } from '../../constants/prefecture-list'
import { View } from '../lib/view'
import { useSearchNavBreadCrumbs } from './useSearchNavBreadCrumbs'

const headerHeight = HEADER_HEIGHT

export type Props = {
  pageType: PageType
  keyword: string | null
  prefecture: Prefecture | null
  area: Area | null
  station: Station | null
  chain: Chain | null
  displayMutualInduction?: boolean
}
export const SearchNavigation: FC<Props> = props => {
  const {
    pageType,
    keyword,
    prefecture,
    area,
    station,
    chain,
    displayMutualInduction,
  } = props

  const breadcrumbs = useSearchNavBreadCrumbs({
    pageType,
    keyword,
    prefecture,
    area,
    station,
    chain,
  })

  const top = displayMutualInduction
    ? headerHeight + MUTUAL_INDUCTION_HEIGHT
    : headerHeight

  return (
    <View
      backgroundColor="#F0FAF5"
      position="fixed"
      top={top}
      right="0"
      left="0"
      zIndex={FixedZIndex.header}
    >
      <BreadCrumbs breadcrumbs={breadcrumbs} />
    </View>
  )
}
