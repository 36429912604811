import React, { FC } from 'react'

import { Image } from 'src/modules/components/lib/image'
import { Text } from 'src/modules/components/lib/text'
import { View, Flexbox } from 'src/modules/components/lib/view'
import { Spacer } from 'src/modules/screens/top/spacer'
import { LinkButton } from '../../lib/button'

export const Hero: FC = () => {
  return (
    <Flexbox
      width="100%"
      height="210px"
      backgroundColor="dsGreen.100"
      backgroundImage="url(/search/town.png)"
      backgroundRepeat="no-repeat"
      backgroundPosition="bottom"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Flexbox alignItems="center">
        <View>
          <View
            padding="6px 8px"
            backgroundColor="white"
            borderRadius="4px"
            width="fit-content"
          >
            <Text color="dsGreen.800" fontWeight="bold" lineHeight="14px">
              処方箋ネット受付
            </Text>
          </View>
          <Spacer size={12} />
          <Text
            color="dsGreen.800"
            fontSize="30px"
            fontWeight="bold"
            lineHeight="36px"
          >
            待たずに受取り
          </Text>
        </View>
        <Spacer size={12} horizontal />
        <Image
          src="/search/send-prescription.png"
          alt="処方箋をスマホで送信"
          width="100px"
        ></Image>
      </Flexbox>
      <Spacer size={25} />
      <View>
        <Text color="dsYellow.600" fontWeight="bold">
          ＼ 処方箋を撮影して送信するだけ！ ／
        </Text>
      </View>
      <Spacer size={10} />
      <LinkButton
        to="/#how-to-use"
        colorStyle="green-outlined"
        shape="square"
        width="120px"
        fontSize="12px"
        fontWeight="bold"
        minHeight="32px"
        maxHeight="32px"
        style={{
          boxShadow: 'none',
        }}
      >
        利用方法はこちら
      </LinkButton>
    </Flexbox>
  )
}
