import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M82.675.64A3.601 3.601 0 0078.849.47L3.594 43.47a3.583 3.583 0 00.645 6.511l24.612 8.203 1.612 24.468a3.577 3.577 0 002.602 3.21 3.586 3.586 0 003.899-1.376l13.524-19.092 18.255 6.085c.372.125.752.186 1.132.186.645 0 1.283-.176 1.85-.516a3.567 3.567 0 001.687-2.48l10.75-64.503A3.578 3.578 0 0082.675.64zM30.998 51.348l-16.89-5.63 47.654-27.23-30.764 32.86zm5.955 20.76l-.756-11.475 7.189 2.398-6.433 9.077zm30.08-8.748l-28.55-9.517L75.147 14.68l-8.113 48.678z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgSend = _style(SvgIcon)

export default SvgSend
