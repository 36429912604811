import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'src/modules/components/lib/link'
import { routes } from 'src/modules/routes'

type buttonLocation = 'main' | 'banner'
type gtmId = 'gtm-phanet-anc-btn01'
type UsageLinkButtonProps = {
  buttonLocation?: buttonLocation
  gtmId?: gtmId
}

export const NeareSearchLinkButton: FC<UsageLinkButtonProps> = ({
  buttonLocation,
  gtmId,
}) => {
  // ここから遷移するときはパラメーターにtopからの遷移だとわかるようにする
  const path = routes.searchNear.path + '?from=top'
  return (
    <StyledAnchorLink buttonLocation={buttonLocation} id={gtmId} to={path}>
      現在地から探す
    </StyledAnchorLink>
  )
}

NeareSearchLinkButton.defaultProps = {
  buttonLocation: 'main',
}

const StyledAnchorLink = styled(Link)<{ buttonLocation?: string }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  margin: 15px 9px 9px;
  flex: 0 0 200px;
  background: #ffffff;
  border: 2px solid #04a14d;
  box-sizing: border-box;
  border-radius: 1000px;
  height: 50px;
  max-width: 270px;
  transition: 0.3s;
  font-size: 16px;
  font-family: 'Hiragino Sans';
  font-weight: 600;
  color: #04a14d;
  ::hover {
    box-shadow: 0 2px 16px rgba(0, 117, 57, 0.55);
  }
  ::before {
    content: '';
    display: block;
    width: 20px;
    height: 19px;
    margin: 0 6px 0 5px;
    background: url(/top/nearMe.svg) no-repeat 0 0 / contain;
  }

  @media (max-width: 560px) {
    font-size: 14px;
    ${({ buttonLocation }) =>
      buttonLocation === 'banner' &&
      css`
        font-size: 16px;
      `}
    height: 46px;
  }

  :hover {
    box-shadow: 0px 2px 16px rgba(63, 63, 63, 0.45);
  }
  ${({ buttonLocation }) =>
    buttonLocation === 'banner' &&
    css`
      font-size: 17px;
      width: 190px;
    `}
`
