import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M71.603 5.543h-5.927v-1.21A4.337 4.337 0 0061.344 0H24.656a4.337 4.337 0 00-4.332 4.332v1.211h-5.927a4.829 4.829 0 00-4.823 4.824v70.81A4.828 4.828 0 0014.397 86h57.206a4.828 4.828 0 004.823-4.822V10.367a4.829 4.829 0 00-4.823-4.824zm-46.24-.504h35.274v5.879H25.363V5.039zm46.024 75.922H14.613V10.582h5.711v1.043a4.337 4.337 0 004.332 4.332h36.688a4.337 4.337 0 004.332-4.332v-1.043h5.71v70.379z"
        fill={props.color || '#000'}
      />
      <path
        d="M28.219 51.062h-5.375a2.52 2.52 0 000 5.039h5.375a2.52 2.52 0 000-5.04zM28.219 37.625h-5.375a2.52 2.52 0 000 5.04h5.375a2.52 2.52 0 000-5.04zM28.219 24.189h-5.375a2.52 2.52 0 000 5.038h5.375a2.52 2.52 0 100-5.038zM28.219 64.5h-5.375a2.52 2.52 0 000 5.04h5.375a2.52 2.52 0 000-5.04zM63.156 51.062H40.313a2.52 2.52 0 000 5.039h22.843a2.52 2.52 0 000-5.04zM63.156 37.625H40.313a2.52 2.52 0 000 5.04h22.843a2.52 2.52 0 000-5.04zM63.156 24.189H40.313a2.52 2.52 0 000 5.038h22.843a2.52 2.52 0 000-5.038zM63.156 64.5H40.313a2.52 2.52 0 000 5.04h22.843a2.52 2.52 0 000-5.04z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgMedicalHistory = _style(SvgIcon)

export default SvgMedicalHistory
