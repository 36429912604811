import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useFetch } from 'src/modules/apis/auth/ping/use-fetch'
import { actions as authActions } from 'src/modules/auth/redux'
import { PatientAccount } from 'src/modules/entities/patient-account/entity'
import { useAccount } from '../hooks/use-account'

export const useAuthorizationRefresh = () => {
  const dispatch = useDispatch()
  const { authorized, authorizedAccount } = useAccount()

  const { fetched } = useFetch({
    onComplete: useCallback(
      (account: PatientAccount | null) => {
        if (account) {
          dispatch(authActions.authorize({ account }))
        }
      },
      [dispatch],
    ),
  })

  return { authorized, fetched, authorizedAccount }
}
