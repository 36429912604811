import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      fill="none"
    >
      <g clip-path="url(#clip0_6851_18212)">
        <path
          d="M3.90686 2.7052L3.25839 2.0543L2.80389 1.59455H3.80378C3.88464 1.59455 3.9643 1.58243 4.0408 1.55864C4.09016 1.5432 4.13837 1.52292 4.18396 1.49815C4.25132 1.46145 4.3132 1.41532 4.36752 1.36095C4.42188 1.30652 4.46798 1.24474 4.50473 1.17729C4.56775 1.06146 4.60112 0.929953 4.60103 0.797203C4.60103 0.689695 4.57996 0.585328 4.53836 0.486844C4.51837 0.439641 4.49359 0.394055 4.46484 0.351375C4.40713 0.266297 4.33488 0.193828 4.2497 0.136242C4.11773 0.0471094 3.96355 0 3.80376 0H0.863969C0.653078 0 0.454773 0.0822891 0.305547 0.231422C0.15632 0.380555 0.0742188 0.578859 0.0742188 0.78975V3.72961C0.0742188 4.16909 0.431781 4.52674 0.871445 4.52684C0.952211 4.52684 1.03206 4.51472 1.10838 4.49093C1.15783 4.47556 1.20604 4.45521 1.25153 4.43044C1.31898 4.39381 1.38077 4.34761 1.43519 4.29323C1.48954 4.23891 1.53566 4.17713 1.57239 4.10974C1.63541 3.99373 1.6687 3.86234 1.6687 3.72959V3.13554L1.67034 2.89263L1.67404 2.72714L4.35203 5.40525C4.66286 5.71598 5.16864 5.71598 5.47942 5.40525C5.79032 5.09435 5.79032 4.58848 5.47942 4.27777L3.90686 2.7052Z"
          fill="white"
        />
        <path
          d="M11.1301 7.47338C11.0494 7.47338 10.9696 7.4854 10.8932 7.50928C10.8438 7.52463 10.7955 7.54491 10.75 7.56968C10.6826 7.60631 10.6208 7.65241 10.5664 7.70688C10.512 7.76121 10.4659 7.82309 10.4292 7.89045C10.3662 8.00637 10.3329 8.13788 10.333 8.27063L10.3329 8.86177L10.3312 9.10629L10.3275 9.27293L7.64956 6.59494C7.33882 6.28411 6.83295 6.28411 6.52217 6.59494C6.21136 6.90574 6.21136 7.41152 6.52217 7.72233L8.10216 9.30244L8.74803 9.95055L9.19781 10.4057H8.19783C8.11697 10.4057 8.03731 10.4178 7.9609 10.4416C7.91154 10.4569 7.86324 10.4773 7.81767 10.502C7.75022 10.5386 7.68853 10.5847 7.63409 10.6392C7.57974 10.6935 7.53354 10.7553 7.49691 10.8228C7.43386 10.9388 7.40058 11.0702 7.40058 11.2029C7.40058 11.3105 7.42174 11.4149 7.46335 11.5133C7.48324 11.5606 7.50802 11.6061 7.53678 11.6489C7.59438 11.7339 7.66683 11.8063 7.75191 11.8639C7.88386 11.953 8.03813 12.0001 8.19783 12.0001H11.1376C11.3485 12.0001 11.5468 11.918 11.696 11.7688C11.8453 11.6197 11.9274 11.4214 11.9274 11.2104V8.27063C11.9273 7.83094 11.5698 7.47338 11.1301 7.47338Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6851_18212">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  any,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgExpansion = _style(SvgIcon)

export default SvgExpansion
