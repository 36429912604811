import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43 16.125C25.083 16.125 9.783 27.269 3.583 43c6.2 15.73 21.5 26.875 39.417 26.875S76.218 58.731 82.417 43c-6.2-15.73-21.5-26.875-39.417-26.875zm0 44.792c-9.89 0-17.917-8.027-17.917-17.917 0-9.89 8.027-17.917 17.917-17.917 9.89 0 17.917 8.027 17.917 17.917 0 9.89-8.027 17.917-17.917 17.917z"
        fill={props.color || '#000'}
      />
      <path
        d="M43 32.25c-5.931 0-10.75 4.819-10.75 10.75S37.069 53.75 43 53.75 53.75 48.931 53.75 43 48.931 32.25 43 32.25zm-.816 7.562a2.468 2.468 0 00-2.446 2.447h-3.559c.074-3.337 2.743-6.006 6.005-6.006v3.56z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgVisibilityOn = _style(SvgIcon)

export default SvgVisibilityOn
