import useSWR from 'swr'
import { SWRFetchOption } from 'src/modules/apis/hooks/use-fetch'
import { fetch, Params } from './request'

export const useSWRFetch = ({
  params,
  options,
  skip,
}: {
  params: Params
  options?: SWRFetchOption<ResolvedType<ReturnType<typeof fetch>>>
  skip?: boolean
}) => {
  const key = skip
    ? null
    : `/patients/net_receptions/external_pharmacies/${params.uid}/pharmacies/nearby.json`
  const { data, error } = useSWR(key, () => fetch(params), {
    onSuccess: data => {
      options?.onComplete && options.onComplete(data)
    },
    onError: error => {
      options?.onFailure && options.onFailure(error)
    },
  })

  return {
    data,
    error,
    loading: !data && !error,
  }
}
