import { PharmacyResponse, parse } from 'src/modules/apis/pharmacy/response'
import { Pharmacy } from 'src/modules/entities/pharmacy/entity'
import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'

export type Params = {
  pharmacyCode?: string
  keyword?: string | null
  prefecture?: string | null
  area?: string | null
  station?: string | null
  // conditions?: string[]
  lat?: number | null
  lng?: number | null
  currentLocation?: boolean | null
  page?: number | null
  chain?: string | null
  selectedDay?: string | null
}

export type FetchResponse = {
  current_page: number
  pharmacies: PharmacyResponse[]
  count: number
  total_page: number
}

export type PharmaciesSearchResponse = {
  currentPage: number
  pharmacies: Pharmacy[]
  count: number
  totalPage: number
}

export const fetch: (
  params: Params,
) => Promise<PharmaciesSearchResponse> = async params => {
  const resp = await requestGet<FetchResponse>({
    url: `${PHARMACY_API_URL}/patients/pharmacies/search.json`,
    params: {
      pharmacy_code: params.pharmacyCode,
      keyword: params.keyword,
      prefecture: params.prefecture,
      area: params.area,
      station: params.station,
      // conditions: (params.conditions || []).join(','),
      lat: params.lat,
      lng: params.lng,
      current_location: params.currentLocation,
      page: params.page,
      chain: params.chain,
      selected_day: params.selectedDay,
      // pager はフロントからAPIに互換性を見るために送っています（pager の機能がリリースされて時間が経ったら削除します）
      pager: true,
    },
  })

  return {
    currentPage: resp.data.current_page,
    pharmacies: resp.data.pharmacies.map(parse),
    count: resp.data.count,
    totalPage: resp.data.total_page,
  }
}
