import React, { FC } from 'react'
import styled from 'styled-components'
import { Text } from 'src/modules/components/lib/text'
import { View } from 'src/modules/components/lib/view'
import { Spacer } from 'src/modules/screens/top/spacer'

export const Merit: FC = () => (
  <View background="#f0faf5 ">
    <MeritContainer>
      <Spacer size={20} />
      <MeritBox>
        <MeritTitle>
          ネット受付を使えば
          <br />
          こんなに<span>便利</span>！
        </MeritTitle>
        <Spacer size={22} />

        <MeritListText>調剤に時間がかかるお薬ももう待たない</MeritListText>
        <Spacer size={18} />
        <MeritListText>忙しい時も調剤時間を効率的に過ごせる</MeritListText>
        <Spacer size={18} />
        <MeritListText>お子さん連れでの待ち時間も解消</MeritListText>
      </MeritBox>
    </MeritContainer>
  </View>
)

const MeritContainer = styled(View)`
  max-width: 375px;
  margin: 0 auto;
  padding: 10px 20px 128px;
  text-align: center;
  color: #272c33;
  position: relative;
  font-family: Hiragino Sans, sans-serif;
  background: url(/cp/app/merit_bg.png) no-repeat bottom right / contain;
  ::after {
    content: '';
    display: block;
    width: 323px;
    height: 166px;
    background: url(/cp/app/merit_illustration.png) no-repeat bottom center /
      contain;
    position: absolute;
    bottom: 0;
  }
  @media (max-width: 322px) {
    ::after {
      width: 300px;
      height: 154px;
    }
  }
`

const MeritBox = styled(View)`
  padding: 40px 20px 55px;
  border: 2px solid #2ba168;
  border-radius: 6px;
  background: #fff;
  position: relative;
`

const MeritTitle = styled(Text)`
  max-width: 210px;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5em;
  color: #1e8f58;
  position: relative;
  background: url(/cp/app/braket_left.svg) no-repeat top left / 10px 20px,
    url(/cp/app/braket_right.svg) no-repeat bottom right / 10px 20px;
  span {
    display: inline-block;
    border-bottom: 3px solid #ffd500;
  }
`

const MeritListText = styled(Text)`
  padding: 0 0 0 30px;
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  color: #1e8f58;
  background: url(/cp/app/checkbox.svg) no-repeat center left / 21px 20px;
`
