import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#call_off_svg__clip0)">
        <path
          d="M5.017 60.086c.544.286 2.064 0 4.5-.803.689-.172 1.663-.459 2.925-.831 1.26-.373 2.408-.688 3.44-1.004a92.063 92.063 0 012.952-.774c.172-.028.66-.114 1.49-.258.832-.143 1.491-.315 1.979-.487.487-.172.917-.43 1.232-.745.46-.459.717-1.376.746-2.724a24.267 24.267 0 00-.258-4.07 27.264 27.264 0 01-.316-3.985c0-1.319.201-2.179.602-2.58.201-.2.545-.401.947-.602.43-.2.773-.344 1.003-.43.258-.086.688-.2 1.319-.344.63-.143 1.003-.23 1.06-.258 4.96-1.405 9.718-2.121 14.276-2.121s9.317.716 14.276 2.121c.058.029.43.115 1.06.258.632.143 1.09.258 1.32.344.258.086.573.23 1.003.43.43.2.745.401.946.602.43.43.63 1.29.602 2.58a26.97 26.97 0 01-.316 3.985 24.267 24.267 0 00-.258 4.07c.03 1.348.287 2.265.746 2.724.315.315.745.573 1.233.745.487.172 1.175.344 1.977.487.832.144 1.32.23 1.491.258.918.23 1.92.488 2.953.774 1.032.287 2.178.631 3.44 1.004 1.261.372 2.236.659 2.924.831 2.436.803 3.956 1.09 4.5.803.23-.086.516-.316.832-.631.63-.63 1.347-1.548 2.121-2.81.774-1.232 1.348-2.264 1.663-3.095.688-1.634.487-4.272-.545-7.912-1.003-3.354-2.58-6.106-4.701-8.228a17.49 17.49 0 00-1.95-1.691 24.138 24.138 0 00-2.436-1.577c-.946-.544-1.663-.917-2.179-1.146-.487-.23-1.376-.631-2.666-1.204a63.45 63.45 0 01-2.35-1.09c-3.096-1.462-6.106-2.522-9.002-3.21C54.954 26.374 49.364 25.8 43 25.8c-6.364 0-11.954.574-16.77 1.692-2.924.659-5.905 1.748-9.001 3.21-.287.144-1.06.488-2.35 1.09-1.29.573-2.18.974-2.667 1.204-.487.229-1.204.602-2.178 1.146-.947.545-1.778 1.061-2.437 1.577-.688.516-1.319 1.09-1.95 1.691-2.15 2.122-3.726 4.874-4.7 8.228C-.116 49.278-.288 51.916.4 53.55c.316.831.89 1.863 1.663 3.096.774 1.232 1.49 2.178 2.121 2.809.316.344.602.545.832.63z"
          fill={props.color || '#000'}
        />
      </g>
      <defs>
        <clipPath id="call_off_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgCallOff = _style(SvgIcon)

export default SvgCallOff
