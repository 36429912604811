import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M48.189 22.847H23.994v4.042h24.195v-4.042zm0 6.909H23.994v4.042h24.195v-4.042zm0 6.88H23.994v4.042h24.195v-4.042zm33.368 9.747a3.473 3.473 0 00-2.437-1.004h-5.303V0H24.223v4.042h45.58v41.337h-2.838V6.91H19.837v4.042h43.058v34.457h-2.896v-31.62H12.183V45.38H6.88c-.917 0-1.777.373-2.437 1.004a3.473 3.473 0 00-1.003 2.436V82.56c0 .917.373 1.777 1.003 2.437.631.63 1.52 1.003 2.437 1.003h72.24c.917 0 1.777-.373 2.437-1.003a3.473 3.473 0 001.003-2.437V48.82c0-.918-.373-1.778-1.003-2.437zM16.225 17.83h39.761v27.548h-39.76V17.831zm62.293 64.127H7.482V49.421h71.036v32.537zm-22.99-17.257H30.472a4.861 4.861 0 01-3.44-1.434 4.861 4.861 0 01-1.434-3.44v-.487c0-1.29.516-2.523 1.434-3.44a4.861 4.861 0 013.44-1.433h25.054c1.29 0 2.523.516 3.44 1.433a4.861 4.861 0 011.434 3.44v.487c0 1.29-.516 2.523-1.434 3.44a4.861 4.861 0 01-3.44 1.434zm-25.055-6.164c-.201 0-.43.086-.574.23a.8.8 0 00-.229.573v.487c0 .201.086.43.23.574a.8.8 0 00.573.229h25.054c.201 0 .43-.086.574-.23a.8.8 0 00.229-.573v-.487c0-.2-.086-.43-.23-.573a.8.8 0 00-.573-.23H30.473z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgFileBox = _style(SvgIcon)

export default SvgFileBox
