import { debounce } from 'lodash-es'
import { useCallback, useLayoutEffect, useState } from 'react'

const getActualVh = () =>
  typeof window !== 'undefined'
    ? Number((window.innerHeight * 0.01).toFixed(2))
    : 0

// NOTE: アドレスバーを除外した高さを計算して取得する
export const useViewportHeight = (): number => {
  const [vh, setVh] = useState(getActualVh())

  const updateVh = useCallback(() => {
    const newVh = getActualVh()
    setVh(newVh)
  }, [setVh])

  useLayoutEffect(() => {
    updateVh()

    const calculate = debounce(updateVh, 200)
    window.addEventListener('resize', calculate)

    return () => {
      window.removeEventListener('resize', calculate)
    }
  }, [updateVh])

  return vh
}
