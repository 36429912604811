import { ID_PLATFORM_URL } from '../const'

export const routes = {
  accountMenu: {
    path: `${ID_PLATFORM_URL}/settings`,
  },
  signUp: {
    path: `${ID_PLATFORM_URL}`,
  },
  signIn: {
    path: `${ID_PLATFORM_URL}/signin`,
  },
  signOut: {
    path: `${ID_PLATFORM_URL}/signout`,
  },
  resetPassword: {
    path: `${ID_PLATFORM_URL}/recovery/email`,
  },
  users: {
    path: `${ID_PLATFORM_URL}/users`,
  },
}
