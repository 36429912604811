import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43 41.653c-11.495 0-20.84-9.346-20.84-20.841C22.16 9.317 31.504 0 43 0s20.84 9.345 20.84 20.84c0 11.496-9.345 20.813-20.84 20.813zm0-37.066c-8.944 0-16.254 7.281-16.254 16.254 0 8.972 7.281 16.254 16.254 16.254 8.973 0 16.254-7.282 16.254-16.254 0-8.973-7.31-16.254-16.254-16.254zm22.847 39.359h-.344l-.315.086c-.115.029-11.553 3.469-22.647 3.469-10.578 0-21.557-3.44-21.672-3.469l-.344-.115h-.344c-8.284 0-15.021 6.737-15.021 15.022V86h75.737V58.996c-.028-8.285-6.765-15.05-15.05-15.05zM23.908 62.952c1.118 0 2.035.917 2.035 2.035a2.043 2.043 0 01-2.035 2.036 2.043 2.043 0 01-2.035-2.036c0-1.118.917-2.035 2.035-2.035zm52.374 18.461H9.718V58.996c0-5.647 4.5-10.263 10.12-10.435.43.115 1.203.344 2.235.631a2.226 2.226 0 00-.057.459v9.832a5.864 5.864 0 00-3.927 5.533c0 3.24 2.637 5.848 5.848 5.848 3.239 0 5.848-2.637 5.848-5.848 0-2.551-1.663-4.73-3.928-5.533V50.11c4.386.975 10.55 2.007 16.713 2.007 6.192 0 12.327-.975 16.856-1.892v6.163c-1.49.373-2.838 1.376-3.899 2.953-.917 1.376-1.662 3.21-2.293 5.59a15.769 15.769 0 00-.115 7.224v.029c.316 1.404.832 3.497 2.552 4.472.43.229 1.032.458 1.834.458.459 0 1.004-.086 1.606-.286a1.923 1.923 0 00-1.233-3.641c-.2.057-.315.086-.373.086-.286-.315-.573-1.52-.659-1.95a12.476 12.476 0 01.086-5.446c.487-1.92 1.09-3.411 1.749-4.415.774-1.146 1.433-1.404 2.006-1.404.201.057.402.086.631.057.23.029.43 0 .63-.057.574 0 1.233.286 2.007 1.404.688 1.004 1.262 2.494 1.749 4.415.459 1.777.487 3.67.086 5.447-.258 1.089-.487 1.748-.66 1.949-.057 0-.171-.029-.372-.086a1.923 1.923 0 00-1.233 3.64c.602.201 1.147.287 1.606.287.802 0 1.404-.229 1.834-.458 1.749-.975 2.236-3.068 2.552-4.472v-.029c.516-2.38.458-4.873-.115-7.224-.602-2.38-1.347-4.214-2.293-5.59-1.061-1.577-2.408-2.58-3.899-2.953v-6.163c0-.287-.057-.573-.172-.831 1.52-.373 2.609-.688 3.153-.832 5.619.172 10.12 4.788 10.12 10.435v22.417h-.029z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgDoctor = _style(SvgIcon)

export default SvgDoctor
