import { Active } from 'src/modules/entities/enums/active'
import { PlanType } from 'src/modules/entities/enums/plan-type'
import {
  NearbyPharmacy,
  Pharmacy,
  PharmacyService,
  VideoCallProvider,
} from 'src/modules/entities/pharmacy/entity'
import {
  BusinessHoursInfoResponse,
  parse as parseBusinessHoursInfoResponse,
} from '../business-hours-info/response'

export type PharmacyResponse = {
  uid: string
  name: string
  name_j: string
  postal: string
  url: string
  prefecture: string
  city: string
  district: string
  building: string
  phone: string
  email: string
  fax: string
  fax_verified: boolean
  system_user: boolean
  plan_type: string
  pharmacy_code: string
  is_follow_up_available: boolean
  lat: number
  lng: number
  net_reception_available: boolean
  business_hour_info?: BusinessHoursInfoResponse
  active: number
  prescription_suspended: boolean
  ready_for_net_reception: boolean
  is_stripe_enabled: boolean
  video_call_provider: VideoCallProvider
  thumbnail_url: string
  access: string[]
  services: PharmacyServiceResponse[]
  nearby_pharmacies: NearbyPharmacyResponse[]
  chain?: ChainResponse
  area_tag?: AreaTagResponse
  nearby_stations?: NearbyStationResponse[]
  // nearby_area: NearbyAreaResponse[]
}

export type PharmacyServiceResponse = {
  uid: string
  name: string
  service_category: ServiceCategory
}

export type NearbyPharmacyResponse = {
  uid: string
  name: string
  address: string
  thumbnail_url: string | null
  services: PharmacyServiceResponse[]
  access: string[]
}

export type NearbyAreaResponse = {
  uid: string
  code: string
  name: string
}

export type NearbyStationResponse = {
  name: string
  code: string
  line: string
  distance: number
}

export type ChainResponse = {
  code: string
  name: string
}

export type AreaTagResponse = {
  slug: string
  name: string
}

type ServiceCategory = 'service' | 'prescription' | 'facility'

export const parseService = (
  resp: PharmacyServiceResponse,
): PharmacyService => {
  const res = {
    uid: resp.uid,
    name: resp.name,
    serviceCategory: resp.service_category,
  }
  return res
}

export const parseNearbyPharmacy = (
  resp: NearbyPharmacyResponse,
): NearbyPharmacy => {
  const res = {
    uid: resp.uid,
    name: resp.name,
    address: resp.address,
    thumbnailUrl: resp.thumbnail_url,
    services: resp.services.map(parseService),
    access: resp.access,
  }
  return res
}

export const parse = (resp: PharmacyResponse): Pharmacy => {
  const res = {
    uid: resp.uid,
    name: resp.name,
    nameJ: resp.name_j,
    postal: resp.postal,
    url: resp.url,
    prefecture: resp.prefecture,
    city: resp.city,
    district: resp.district,
    building: resp.building,
    phone: resp.phone,
    email: resp.email,
    fax: resp.fax,
    faxVerified: resp.fax_verified,
    systemUser: resp.system_user,
    planType: resp.plan_type as PlanType,
    pharmacyCode: resp.pharmacy_code,
    isFollowUpAvailable: resp.is_follow_up_available,
    lat: resp.lat,
    lng: resp.lng,
    netReceptionAvailable: resp.net_reception_available,
    businessHourInfo: resp.business_hour_info
      ? parseBusinessHoursInfoResponse(resp.business_hour_info)
      : null,
    active: resp.active as Active,
    prescriptionSuspended: resp.prescription_suspended,
    readyForNetReception: resp.ready_for_net_reception,
    isStripeEnabled: resp.is_stripe_enabled,
    videoCallProvider: resp.video_call_provider,
    thumbnailUrl: resp.thumbnail_url,
    access: resp.access,
    services: resp.services.map(parseService),
    nearbyPharmacies: resp.nearby_pharmacies
      ? resp.nearby_pharmacies.map(parseNearbyPharmacy)
      : [],
    chain: resp.chain,
    areaTag: resp.area_tag,
    nearbyStations: resp.nearby_stations,
    // nearbyArea: resp.nearby_area as NearbyArea[],
  }

  return res
}
