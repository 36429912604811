import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.10041 11.6629C7.46653 11.2968 7.46653 10.7032 7.10041 10.3371L2.76333 6L7.10041 1.66291C7.46653 1.2968 7.46653 0.703203 7.10041 0.337088C6.7343 -0.0290289 6.1407 -0.0290289 5.77459 0.337088L0.774587 5.33709C0.408471 5.7032 0.408471 6.2968 0.774587 6.66291L5.77459 11.6629C6.1407 12.029 6.7343 12.029 7.10041 11.6629Z"
        fill={props.color || '#2BA168'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  any,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgArrowLeft2 = _style(SvgIcon)

export default SvgArrowLeft2
