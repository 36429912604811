import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M85.197 42.513l-7.912-7.081a2.335 2.335 0 00-1.003-.889L43.889 5.59c-.918-.832-2.294-.803-3.211.028L.774 42.541c-.975.889-1.032 2.408-.143 3.354.888.975 2.408 1.032 3.354.143l4.328-4.013v36.579c0 1.319 1.061 2.38 2.38 2.38h25.14c1.319 0 2.38-1.061 2.38-2.38V58.709h9.546v19.895c0 1.319 1.06 2.38 2.379 2.38H75.25c1.319 0 2.38-1.061 2.38-2.38V42.169l4.357 3.898a2.415 2.415 0 001.576.602c.66 0 1.319-.258 1.778-.802.917-.975.831-2.466-.144-3.354zM72.9 76.225H52.546V56.33c0-1.319-1.06-2.38-2.38-2.38H35.834a2.374 2.374 0 00-2.379 2.38v19.895H13.101V39.1h59.798v37.124zM16.684 34.314l25.657-23.707 26.545 23.707H16.684z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgHome = _style(SvgIcon)

export default SvgHome
