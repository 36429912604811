import React, { FC } from 'react'
import { Text, TextProps, InlineText } from '../lib/text'
import Heart from './Heart'

export const FavoriteTag: FC<TextProps> = ({ ...props }) => {
  return (
    <Text
      lineHeight="100%"
      display="inline-flex"
      alignItems="center"
      fontSize="11px"
      bg="#E8F5FF"
      py="1"
      px="2"
      borderRadius="10px"
      {...props}
    >
      <Heart color="#376EA1" size="10px" />
      <InlineText ml={1}>お気に入り薬局</InlineText>
    </Text>
  )
}
