import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#help_tip_svg__clip0)">
        <path
          d="M82.732 26.574A42.916 42.916 0 0070.291 9.775 42.86 42.86 0 0051.399.831 42.899 42.899 0 0030.53 1.863a42.577 42.577 0 00-17.917 10.75A42.975 42.975 0 00.86 34.63a43.124 43.124 0 002.437 24.853A42.84 42.84 0 0019.12 78.776 43.063 43.063 0 0043 86c7.052 0 14.018-1.749 20.267-5.045a43.02 43.02 0 0015.48-14.018c3.928-5.905 6.364-12.642 7.052-19.694a43.225 43.225 0 00-3.067-20.669zM45.092 62.81c-.687.717-1.662 1.06-2.923 1.06-1.262 0-2.236-.372-2.924-1.06-.66-.688-.975-1.548-1.004-2.609 0-1.118.344-2.035 1.061-2.723.717-.688 1.663-1.032 2.867-1.032 1.232 0 2.178.344 2.895 1.06.717.717 1.06 1.606 1.06 2.695 0 1.06-.343 1.92-1.031 2.609zm5.62-21.27l-3.527 3.468c-1.261 1.433-1.92 3.526-1.949 6.278l-6.479.029c0-2.552.316-4.559.946-6.078.631-1.52 1.892-3.182 3.756-4.988 1.863-1.806 3.038-3.096 3.526-3.87a6.829 6.829 0 001.146-3.784c0-1.806-.458-3.153-1.347-4.1-.889-.945-2.179-1.404-3.899-1.404-1.634 0-2.981.459-3.956 1.376-1.003.918-1.519 2.179-1.519 3.755h-6.966c.029-3.382 1.204-6.077 3.469-8.026 2.264-1.978 5.246-2.953 8.972-2.953 3.842 0 6.823.975 8.973 2.924 2.15 1.95 3.24 4.673 3.21 8.17 0 3.125-1.433 6.192-4.357 9.202z"
          fill={props.color || '#000'}
        />
      </g>
      <defs>
        <clipPath id="help_tip_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgHelpTip = _style(SvgIcon)

export default SvgHelpTip
