import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestPost } from 'src/modules/server/request'
import { parse, FavoritePharmacyResponse } from '../response'

export type SubmitParams = {
  pharmacyUid: string
}

export const submit = async (params: SubmitParams) => {
  const resp = await requestPost<FavoritePharmacyResponse>({
    url: `${PHARMACY_API_URL}/patients/favorite_pharmacies.json`,
    params: {
      pharmacy_uid: params.pharmacyUid,
    },
  })

  return parse(resp.data)
}
