import moment, { Moment } from 'moment'

export enum DayOfWeek {
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
  Sunday = 'sunday',
  Holiday = 'holiday',
}

export const dayOfWeekStrings: { [key: string]: string } = {
  [DayOfWeek.Monday]: '月曜',
  [DayOfWeek.Tuesday]: '火曜',
  [DayOfWeek.Wednesday]: '水曜',
  [DayOfWeek.Thursday]: '木曜',
  [DayOfWeek.Friday]: '金曜',
  [DayOfWeek.Saturday]: '土曜',
  [DayOfWeek.Sunday]: '日曜',
  [DayOfWeek.Holiday]: '祝日',
}

export const dayOfWeekToString = (dayOfWeek: DayOfWeek): string => {
  return dayOfWeekStrings[dayOfWeek]
}

export const getDayOfWeek = (mnt: Moment): DayOfWeek | null => {
  const weekdayStr = mnt.locale('en').format('dddd').toLowerCase()

  const entiresOfDayOfWeek = Object.entries(DayOfWeek)
  const foundEntry: [string, DayOfWeek] | undefined = entiresOfDayOfWeek.find(
    ([_, value]) => value === weekdayStr,
  )
  const value: DayOfWeek | null = foundEntry?.[1] || null

  return value
}

export const getDayOfWeekToday = (): DayOfWeek | null => {
  return getDayOfWeek(moment())
}
