import { useCallback } from 'react'
import { SubmitOption, useSubmit as _useSubmit } from '../hooks/use-submit'
import { submit } from './request'

export const useSubmit = (
  options?: SubmitOption<ResolvedType<ReturnType<typeof submit>>>,
) => {
  const request = useCallback((params: { campaignCode: string }) => {
    return submit(params.campaignCode)
  }, [])

  return _useSubmit(request, options)
}
