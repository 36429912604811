import { useCallback } from 'react'
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import {
  SWRFetchOption,
  FetchOption,
  useFetch as _useFetch,
} from 'src/modules/apis/hooks/use-fetch'
import { fetch, Params } from './request'

export const useFetch = (
  params: Params,
  options?: FetchOption<ResolvedType<ReturnType<typeof fetch>>>,
) => {
  const {
    pharmacyCode,
    keyword,
    lat,
    lng,
    prefecture,
    area,
    station,
    // conditions,
    currentLocation,
    page,
    chain,
    selectedDay,
  } = params

  const request = useCallback(() => {
    return fetch({
      pharmacyCode,
      keyword,
      lat,
      lng,
      prefecture,
      area,
      station,
      // conditions,
      currentLocation,
      page,
      chain,
      selectedDay,
    })
  }, [
    pharmacyCode,
    keyword,
    lat,
    lng,
    prefecture,
    area,
    station,
    currentLocation,
    page,
    chain,
    selectedDay,
  ])

  return _useFetch(request, options)
}

export const useSWRFetch = ({
  params,
  options,
  skip,
}: {
  params: Params
  options?: SWRFetchOption<ResolvedType<ReturnType<typeof fetch>>>
  skip?: boolean
}) => {
  const key = skip
    ? null
    : `patients/pharmacies/search/pharmacy_${params.pharmacyCode}_keyword_${params.keyword}_prefecture_${params.prefecture}_area_${params.area}_station_${params.station}_lat_${params.lat}_lng_${params.lng}_current_location_${params.currentLocation}_page_${params.page}_chain_${params.chain}_selected_day_${params.selectedDay}`
  const { data, error } = useSWR(key, () => fetch(params), {
    onSuccess: data => {
      options?.onComplete && options.onComplete(data)
    },
    onError: error => {
      options?.onFailure && options.onFailure(error)
    },
  })

  const {
    trigger,
    data: mutationData,
    isMutating,
  } = useSWRMutation(
    String(Math.random()),
    (url, { arg }: { arg: Params }) => {
      return fetch(arg)
    },
    {
      onSuccess: data => {
        options?.onComplete && options.onComplete(data)
      },
      onError: error => {
        options?.onFailure && options.onFailure(error)
      },
    },
  )

  return {
    data: mutationData || data,
    error,
    loading: (!data && !error) || isMutating,
    trigger,
  }
}
