import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { useAuthorizationRefresh } from 'src/modules/auth/use-authorization-refresh'
import { PublicPageHeader } from 'src/modules/components/header/public-page-header'
import { routes } from 'src/modules/routes'
import { Float } from './float'
import { Flow } from './flow'
import { Main } from './main'
import { Merit } from './merit'

export const AppTop: FC = () => {
  const { authorized, fetched } = useAuthorizationRefresh()
  const pageMeta = [
    {
      property: 'description',
      content:
        '処方箋を事前にスマホで薬局に送信。薬局で調剤の準備完了の連絡が来たら取りに行きます。処方薬の受け取りを薬局で待つ必要がありません。',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:site_name',
      content: '処方箋ネット受付curon(クロン)TOP',
    },
    {
      property: 'og:title',
      content: '一般向け | 処方箋ネット受付curon(クロン)',
    },
    {
      property: 'og:description',
      content:
        '処方箋を事前にスマホで薬局に送信。薬局で調剤の準備完了の連絡が来たら取りに行きます。処方薬の受け取りを薬局で待つ必要がありません。',
    },
    {
      property: 'og:url',
      content: `${window.location.origin}${routes.index.path}`,
    },
    {
      name: 'robots',
      content: 'noindex',
    },
  ]

  return (
    <>
      <Helmet
        meta={pageMeta}
        title={'一般向け | 処方箋ネット受付curon(クロン)'}
      />
      <PublicPageHeader fetched={fetched} authorized={authorized} />
      <Main />
      <Merit />
      <Flow />
      <Float />
    </>
  )
}
