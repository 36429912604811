import React, { FC } from 'react'
import styled from 'styled-components'
import { Image } from 'src/modules/components/lib/image'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox, View } from 'src/modules/components/lib/view'
import { Spacer } from './spacer'

export const Example: FC = () => {
  return (
    <ViewContainer>
      <SectionHeaderText>処方箋調剤中の「スキマ時間」活用例</SectionHeaderText>
      <ExampleWrap>
        <ExampleBox>
          <PcImage
            src="/top/example01_pc.svg"
            alt="ご自宅や会社近くで受け取り"
          />
          <SpImage
            src="/top/example01_sp.svg"
            alt="ご自宅や会社近くで受け取り"
          />
          <TextWrap>
            <BoxText>
              通勤などの移動時間を活用して
              <br />
              処方箋を送信！ご自宅や会社近くで受け取り
            </BoxText>
          </TextWrap>
        </ExampleBox>

        <Spacer horizontal size={20} />

        <ExampleBox>
          <PcImage
            src="/top/example02_pc.svg"
            alt="用事を済ませてから受け取り"
          />
          <SpImage
            src="/top/example02_sp.svg"
            alt="用事を済ませてから受け取り"
          />
          <TextWrap>
            <BoxText>
              お買い物の時間を活用して処方箋送信
              <br />
              用事を済ませてから受け取り
            </BoxText>
          </TextWrap>
        </ExampleBox>
      </ExampleWrap>
    </ViewContainer>
  )
}

const ViewContainer = styled(View)`
  padding: 75px 21px 80px;
  background-color: ${({ theme }) => theme.colors.pistachioGreen};
  font-family: 'HiraginoSans', sans-serif;

  @media (max-width: 560px) {
    padding-top: 50px;
    padding-bottom: 70px;
  }
`

const SectionHeaderText = styled(Text)`
  margin: 0 0 60px;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  line-height: 48px;

  @media (max-width: 560px) {
    margin-bottom: 25px;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.04em;
  }
`

const ExampleWrap = styled(Flexbox)`
  max-width: 890px;
  margin: 0 auto;
  justify-content: space-between;

  @media (max-width: 560px) {
    flex-direction: column;
  }
`

const ExampleBox = styled(View)`
  max-width: 410px;
  margin: 0 auto;

  @media (max-width: 560px) {
    max-width: 335px;
    &:first-child {
      margin-bottom: 40px;
    }
  }
`

const PcImage = styled(Image)`
  width: 100%;

  @media (max-width: 560px) {
    display: none;
  }
`

const SpImage = styled(Image)`
  display: none;
  width: 100%;

  @media (max-width: 560px) {
    display: block;
  }
`

const TextWrap = styled(View)`
  padding: 25px 20px 20px;
  background: ${({ theme }) => theme.colors.white};
`

const BoxText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  text-align: center;

  @media (max-width: 560px) {
    font-size: 14px;
  }
`
