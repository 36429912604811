import React, { FC } from 'react'

import styled from 'styled-components'
import { ReactComponent as AreaIcon } from 'src/assets/search/area.svg'
import { ReactComponent as TrainIcon } from 'src/assets/search/train.svg'
import { Clickable } from 'src/modules/components/lib/clickable'
import { Text } from 'src/modules/components/lib/text'
import { View, Flexbox } from 'src/modules/components/lib/view'
import { Spacer } from 'src/modules/screens/top/spacer'

type Props = {
  openAreaModal: () => void
  openStationModal: () => void
}

export const Area: FC<Props> = ({ openAreaModal, openStationModal }) => {
  return (
    <View width="100%">
      <Text fontSize="16px" fontWeight="bold">
        エリアや路線・駅から探す
      </Text>
      <Spacer size={14} />
      <Flexbox height="60px">
        <IconTextButton onClick={openAreaModal}>
          <Flexbox alignItems="center" width="100%" paddingX="8px">
            <AreaIcon />

            <Text
              textAlign="center"
              color="green"
              style={{
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '130%',
                margin: '0 auto',
              }}
            >
              エリアから
              <br />
              探す
            </Text>
          </Flexbox>
        </IconTextButton>

        <Spacer horizontal size={12} />
        <IconTextButton onClick={openStationModal}>
          <Flexbox alignItems="center" width="100%" paddingX="8px">
            <TrainIcon />

            <Text
              textAlign="center"
              color="green"
              style={{
                fontWeight: 600,
                fontSize: '14px',
                lineHeight: '130%',
                margin: '0 auto ',
              }}
            >
              路線・駅から
              <br />
              探す
            </Text>
          </Flexbox>
        </IconTextButton>
      </Flexbox>
    </View>
  )
}

const IconTextButton = styled(Clickable)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: 0px 2px 7px rgba(111, 111, 111, 0.15);
`
