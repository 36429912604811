import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M85.513 4.988a6.727 6.727 0 00-1.434-2.15 6.76 6.76 0 00-2.121-1.434c-.803-.344-1.634-.487-2.523-.487h-5.59c-1.49 0-2.981.516-4.156 1.462a6.47 6.47 0 00-2.15 3.21c-5.906 2.896-26.087 12.012-52.432 15.48a6.645 6.645 0 00-2.522.89 7.29 7.29 0 00-1.606 1.347H6.565a6.55 6.55 0 00-4.644 1.92C.688 26.516 0 28.15 0 29.928v11.208a6.55 6.55 0 001.92 4.644 6.7 6.7 0 004.645 1.95h4.357a6.003 6.003 0 001.605 1.347 6.61 6.61 0 002.552.889h.086l8.399 30.272c.373 1.376 1.233 2.608 2.35 3.468a6.676 6.676 0 003.985 1.348h12.126c.746-.029 1.434-.344 1.978-.86a2.848 2.848 0 00.803-1.978c0-.746-.287-1.462-.803-1.978-.458-.459-1.032-.774-1.691-.832l-7.08-25.513c16.511 4.214 28.58 9.775 32.25 11.553.372 1.261 1.146 2.379 2.15 3.21a6.619 6.619 0 004.156 1.462h5.619a6.55 6.55 0 004.644-1.92 6.55 6.55 0 001.92-4.644V7.51a6.35 6.35 0 00-.458-2.522zM10.435 42.57h-3.87c-.201 0-.373-.029-.545-.115-.172-.086-.344-.172-.459-.315a1.64 1.64 0 01-.315-.459 1.242 1.242 0 01-.115-.545V29.928c0-.201.029-.373.115-.545.086-.172.172-.344.315-.459.144-.143.287-.229.459-.315.172-.086.373-.115.545-.115H10.406V42.57h.029zM37.18 79.922h-7.196c-.315 0-.63-.114-.86-.286-.258-.201-.43-.459-.516-.746L20.64 50.252c3.01.516 5.934 1.061 8.772 1.692l7.769 27.978zm30.616-20.066c-7.568-3.526-27.205-11.754-52.002-15.022-.344-.028-.659-.2-.917-.487a1.453 1.453 0 01-.373-.946V27.634c0-.344.144-.688.373-.946.23-.258.545-.43.917-.487 24.797-3.268 44.434-11.467 52.002-14.993v48.648zM80.869 7.51v56.015c0 .2-.029.373-.115.545-.086.172-.172.344-.315.458-.144.144-.287.23-.459.316a1.242 1.242 0 01-.545.114h-5.59c-.2 0-.372-.028-.544-.114-.172-.086-.344-.172-.459-.316a1.638 1.638 0 01-.315-.458 1.242 1.242 0 01-.115-.545V7.51c0-.2.029-.372.115-.544.086-.172.172-.344.315-.459.143-.143.287-.23.459-.315.172-.086.372-.115.544-.115h5.59c.201 0 .373.029.545.115.172.086.344.172.459.315.143.143.229.287.315.459.057.172.115.372.115.544z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgMegaphone = _style(SvgIcon)

export default SvgMegaphone
