import {
  ReservationCandidate,
  TimeRange,
} from 'src/modules/entities/reservation-candidate/entity'

/**
 * @deprecated リクエスト予約廃止に伴いdeprecated.
 */
export type ReservationCandidateResponse = {
  uid: string
  day: string
  time_range: string
  priority_rank: number
}
/**
 * @deprecated リクエスト予約廃止に伴いdeprecated.
 */
export const parse = (
  resp: ReservationCandidateResponse,
): ReservationCandidate => {
  return {
    uid: resp.uid,
    day: resp.day,
    timeRange: resp.time_range as TimeRange,
    priorityRank: resp.priority_rank,
  }
}
