import moment from 'moment'
import { TimeRange } from '../entity'

export function timeRangeCompetitor(a: TimeRange, b: TimeRange): number {
  // NOTE: 登録されている時間帯に依って、何故か1日ズレてDateが登録されているので日付を揃える
  const aHour = moment(a.openedAt).date(1)
  const bHour = moment(b.openedAt).date(1)

  return aHour.isBefore(bHour) ? -1 : 1
}
