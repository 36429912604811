import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M79.263 86H6.737A2.435 2.435 0 014.3 83.563V2.437A2.435 2.435 0 016.737 0h72.526A2.435 2.435 0 0181.7 2.437v81.098A2.44 2.44 0 0179.263 86zm-70.09-4.902h67.654V4.902H9.173v76.196z"
        fill={props.color || '#000'}
      />
      <path
        d="M25.8 36.407c-.63 0-1.233-.23-1.691-.66l-6.594-6.22a2.448 2.448 0 01-.086-3.47 2.448 2.448 0 013.469-.085l4.873 4.586 10.406-10.405a2.476 2.476 0 013.469.085 2.48 2.48 0 010 3.383L27.549 35.72c-.459.43-1.09.688-1.749.688zM25.8 63.525c-.63 0-1.233-.229-1.691-.659l-6.966-6.593a2.444 2.444 0 01-.201-3.44 2.443 2.443 0 013.44-.2.397.397 0 00.115.085l5.217 4.96 9.69-9.661a2.475 2.475 0 013.468.086 2.48 2.48 0 010 3.382L27.52 62.78a2.557 2.557 0 01-1.72.745zM65.733 36.034H39.359a2.435 2.435 0 01-2.436-2.437 2.435 2.435 0 012.436-2.436h26.374a2.435 2.435 0 012.436 2.436c.029 1.348-1.06 2.437-2.436 2.437zM65.733 63.153H39.359a2.435 2.435 0 01-2.436-2.437 2.435 2.435 0 012.436-2.437h26.374a2.435 2.435 0 012.436 2.437 2.417 2.417 0 01-2.436 2.437z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgTasklist = _style(SvgIcon)

export default SvgTasklist
