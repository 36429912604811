import { NearbyStation } from 'src/modules/entities/pharmacy/entity'

// 徒歩1分＝80mをしてdistanceをもとに徒歩分数を計算する
export const getWalkingTime = (distance: number) => {
  const walkingTime = Math.round(distance / 80)
  if (walkingTime < 1) {
    return 1
  }
  return walkingTime
}

export const getStationText = (stations: NearbyStation[]) => {
  const uniqueNames = new Set<string>()
  // distanceが近い順にソート
  stations.sort((a, b) => a.distance - b.distance)

  // 同じ名前の駅は表示しない
  const stationText = stations
    .filter(s => {
      if (uniqueNames.has(s.name)) {
        return false
      } else {
        uniqueNames.add(s.name)
        return true
      }
    })
    .map(s => `${s.name}駅 徒歩${getWalkingTime(s.distance)}分`)
    .join('｜')

  return stationText
}
