import { push } from 'connected-react-router'
import moment from 'moment'

import React, { FC, Fragment, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { FavoriteTag } from 'src/modules/components/favorite/favorite-tag'
import { Button } from 'src/modules/components/lib/button'
import { ArrowRight, Clock, Train } from 'src/modules/components/lib/curon-icon'
import { Link } from 'src/modules/components/lib/link'
import { ServiceTag } from 'src/modules/components/lib/serviceTag'
import { InlineText, Text } from 'src/modules/components/lib/text'
import { View } from 'src/modules/components/lib/view'
import { BusinessHourInfo } from 'src/modules/entities/business-hour-info/entity'
import { PharmacySchedule } from 'src/modules/entities/enums/pharmacy-schedule'
import { PharmacyOpenedStatus } from 'src/modules/entities/enums/pharmacy-status'
import { Pharmacy, toAddress } from 'src/modules/entities/pharmacy/entity'
import { formatTimeRange } from 'src/modules/entities/time-range/helpers/format-time-ranges'
import { timeRangeCompetitor } from 'src/modules/entities/time-range/helpers/time-range-competitor'
import { useFavoritePharmacy } from 'src/modules/favorite/use-favorite-pharmacy'
import { getQueryParams } from 'src/modules/helpers/get-query-params'
import { getStationText } from 'src/modules/helpers/get-station-text'
import { useIdPlatform } from 'src/modules/hooks/useIdPlatform'
import { routes } from 'src/modules/routes'
import { Spacer } from 'src/modules/screens/top/spacer'

const TodayBusinessHour: FC<{
  businessHourInfo: BusinessHourInfo | null
}> = ({ businessHourInfo }) => {
  const getSelectedDayParam = () => {
    return getQueryParams(['selected_day']).selected_day
  }
  const selectedDayMoment = useCallback(() => {
    return moment().add(getSelectedDayParam(), 'days')
  }, [getSelectedDayParam])

  if (!businessHourInfo || businessHourInfo.repeatCycles.length === 0) {
    return <Text>店舗にお問い合わせください</Text>
  }

  const getSelectedDay = () => {
    if (getSelectedDayParam() === '0' || !getSelectedDayParam()) {
      return '本日'
    }
    return `${selectedDayMoment().format('M/D')}${selectedDayMoment().format(
      'ddd',
    )}`
  }

  return (
    <>
      <Text flexShrink={'0'}>{getSelectedDay()}</Text>
      {businessHourInfo.targetDateOpeningHour?.scheduleType ===
        PharmacySchedule.Irregular && (
        <>
          {businessHourInfo.targetDateOpeningHour.status ===
          PharmacyOpenedStatus.Opened ? (
            <>
              <InlineText color="red" fontWeight={'bold'} flexShrink={'0'}>
                特別営業
              </InlineText>
              <Content>
                {businessHourInfo.targetDateOpeningHour.timeRanges
                  .sort((a, b) => timeRangeCompetitor(a, b))
                  .map((time, index, arr) => (
                    <Fragment key={index}>
                      <TimeRange>{formatTimeRange(time)}</TimeRange>
                      {index !== arr.length - 1 && (
                        <TimeRangeDivider>|</TimeRangeDivider>
                      )}
                    </Fragment>
                  ))}
              </Content>
            </>
          ) : (
            <InlineText color={'red'}>特別休業</InlineText>
          )}
        </>
      )}

      {businessHourInfo.targetDateOpeningHour?.scheduleType ===
        PharmacySchedule.Regular && (
        <>
          {businessHourInfo.targetDateOpeningHour.status ===
          PharmacyOpenedStatus.Opened ? (
            <Content>
              {businessHourInfo.targetDateOpeningHour.timeRanges.map(
                (time, index, arr) => (
                  <Fragment key={index}>
                    <TimeRange>{formatTimeRange(time)}</TimeRange>
                    {index !== arr.length - 1 && (
                      <TimeRangeDivider>|</TimeRangeDivider>
                    )}
                  </Fragment>
                ),
              )}
            </Content>
          ) : (
            <InlineText>休業</InlineText>
          )}
        </>
      )}
    </>
  )
}

type CardProps = {
  authorized: boolean
  pharmacy: Pharmacy
  externalPharmacyUid?: string
}

export const PharmacyCard: FC<CardProps> = ({
  authorized,
  pharmacy,
  externalPharmacyUid,
}) => {
  const address = toAddress(pharmacy)
  const { isFavorite } = useFavoritePharmacy()

  const dispatch = useDispatch()
  const { redirectToLogin } = useIdPlatform()
  const onNavigateToNetApplication = useCallback(() => {
    const url = externalPharmacyUid
      ? `${routes.netApplicationPatient.build(
          pharmacy.uid,
        )}?external_pharmacy_uid=${externalPharmacyUid}`
      : routes.netApplicationPatient.build(pharmacy.uid)

    if (!authorized) return redirectToLogin(url)

    dispatch(
      push({
        pathname: routes.netApplicationPatient.build(pharmacy.uid),
        search: externalPharmacyUid
          ? `external_pharmacy_uid=${externalPharmacyUid}`
          : undefined,
      }),
    )
  }, [authorized, dispatch, externalPharmacyUid, pharmacy.uid, redirectToLogin])

  return (
    <Card>
      <OverLink
        to={{
          pathname: routes.pharmaciesDetail.build(pharmacy.uid),
          search: externalPharmacyUid
            ? `external_pharmacy_uid=${externalPharmacyUid}`
            : undefined,
        }}
      />

      <SummaryBox>
        {/* <Thumbnail src={pharmacy.thumbnailUrl || emptyThumbnail} /> */}
        <InfoBox>
          <NameLink>{pharmacy.name}</NameLink>
          <Address>{address}</Address>
          <br />
          <Object>
            <Anchor
              href={`https://www.google.com/maps/search/?api=1&query=${pharmacy.lat},${pharmacy.lng}`}
              target="_blank"
            >
              地図アプリで見る
            </Anchor>
          </Object>
          {/* 右矢印 */}
        </InfoBox>
        <ArrowRight
          color="#4DB27F"
          size="18px"
          style={{ width: '18px', height: '18px' }}
        />
      </SummaryBox>
      {isFavorite(pharmacy.uid) && (
        <View mb={2}>
          <FavoriteTag />
        </View>
      )}
      <ServiceBox>
        {pharmacy.services.map(service => (
          <ServiceTag key={service.name} service={service} />
        ))}
      </ServiceBox>
      <AdditionalInfoBox>
        {pharmacy.nearbyStations && pharmacy.nearbyStations.length > 0 && (
          <AdditionalInfoRow>
            <IconBox>
              <Train />
            </IconBox>
            <Text>{getStationText(pharmacy.nearbyStations)}</Text>
          </AdditionalInfoRow>
        )}
        <AdditionalInfoRow>
          <IconBox>
            <Clock />
          </IconBox>
          <TodayBusinessHour businessHourInfo={pharmacy.businessHourInfo} />
        </AdditionalInfoRow>
        {pharmacy.businessHourInfo && pharmacy.businessHourInfo.comment && (
          <PharmacyDescriptionBox>
            <PharmacyDescriptionTitle>薬局紹介</PharmacyDescriptionTitle>
            <PharmacyDescriptionText>
              {pharmacy.businessHourInfo.comment}
            </PharmacyDescriptionText>
          </PharmacyDescriptionBox>
        )}
      </AdditionalInfoBox>
      <Object>
        <ButtonBox>
          <Spacer horizontal size={12} />
          <Button
            padding="0 16px"
            shape="square"
            onClick={onNavigateToNetApplication}
            style={{
              fontSize: '14px',
              width: 'auto',
            }}
          >
            処方箋ネット受付
          </Button>
        </ButtonBox>
      </Object>
    </Card>
  )
}

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 12px 0;
  padding: 20px 16px;
  background: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
`

const Object = styled.object`
  position: relative;
  z-index: 1;
`

const SummaryBox = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`

const InfoBox = styled.div`
  width: 100%;
`

const OverLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

const NameLink = styled.div`
  margin: 0 0 8px;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.15px;
  color: #272c33;
`

const Address = styled.div`
  display: inline-block;
  font-size: 14px;
  color: #272c33;
`

const ServiceBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 4px;
  row-gap: 4px;
  margin: 14px 0 0;
`

const AdditionalInfoBox = styled.div`
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid #ecf0f5;
`

const AdditionalInfoRow = styled.div`
  display: flex;
  align-items: start;
  column-gap: 12px;
  margin-bottom: 8px;
`

const IconBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  margin-top: 2px;
`

const PharmacyDescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0;
  padding: 12px 16px;
  background: #f4f7fa;
  border-radius: 4px;
`

const PharmacyDescriptionTitle = styled.div`
  margin-bottom: 4px;
  font-weight: bold;
  color: #3a424c;
`

const PharmacyDescriptionText = styled.div``

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
`

const Content = styled(Text)`
  display: inline-flex;
  column-gap: 6px;
  font-weight: bold;
  flex-wrap: wrap;
`

const TimeRange = styled(Text)`
  white-space: nowrap;
  font-variant-numeric: tabular-nums;
`

const TimeRangeDivider = styled(Text)`
  font-weight: normal;
  user-select: none;
`
const Anchor = styled.a`
  color: #2ba168;
  text-decoration: underline;
`
