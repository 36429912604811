import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M46.268 47.902H41.28l-.545-24.653h6.135l-.602 24.653zm-5.733 7.54c0-.918.286-1.663.888-2.265.602-.602 1.405-.918 2.437-.918 1.032 0 1.835.316 2.437.918.602.602.888 1.347.888 2.264 0 .889-.286 1.606-.86 2.208-.573.602-1.404.888-2.436.888-1.061 0-1.864-.286-2.437-.888-.63-.602-.917-1.348-.917-2.208zm37.41-.574c-8.027-10.291-8.027-18.031-8.027-22.188v-.172c0-14.075-10.836-25.685-24.625-26.89V2.036A2.043 2.043 0 0043.258 0a2.043 2.043 0 00-2.035 2.035v3.526c-14.076.889-25.256 12.642-25.256 26.947 0 5.59-.917 13.158-7.97 22.331a7.982 7.982 0 00-1.576 4.157c-.172 2.523.746 5.074 2.494 6.937 1.749 1.864 4.157 2.896 6.737 2.896h54.696c2.523 0 4.873-.975 6.622-2.781a9.257 9.257 0 002.637-6.68 7.76 7.76 0 00-1.662-4.5zm-3.928 8.342a5.096 5.096 0 01-3.67 1.548H15.653a5.135 5.135 0 01-3.755-1.605c-.975-1.061-1.491-2.466-1.405-3.87a3.672 3.672 0 01.717-1.921c8.8-11.438 8.8-21.185 8.8-24.825 0-12.614 10.263-22.905 22.905-22.905 12.613 0 22.905 10.263 22.905 22.905v.172c0 4.644 0 13.301 8.886 24.71a3.69 3.69 0 01.774 2.093c.058 1.347-.487 2.695-1.462 3.698zm-18.29 13.043a2.04 2.04 0 00-1.72-2.322 2.04 2.04 0 00-2.321 1.72c-.545 3.555-3.67 6.25-7.281 6.25-3.612 0-6.737-2.695-7.282-6.25a2.04 2.04 0 00-2.322-1.72 2.04 2.04 0 00-1.72 2.322c.86 5.533 5.705 9.718 11.324 9.718 5.59.029 10.463-4.156 11.323-9.718z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgAlertBell = _style(SvgIcon)

export default SvgAlertBell
