import { useContext } from 'react'
import { FavoritePharmacyContext } from './context'

export const useFavoritePharmacy = () => {
  const {
    favorites,
    activeFavorites,
    doFetch,
    add,
    remove,
    isFavorite,
    canAdd,
    fetching,
    fetched,
  } = useContext(FavoritePharmacyContext)

  return {
    favorites,
    activeFavorites,
    doFetch,
    fetching,
    fetched,
    add,
    remove,
    isFavorite,
    canAdd,
  }
}
