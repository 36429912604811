import React, { FC } from 'react'
import styled from 'styled-components'
import { Image } from 'src/modules/components/lib/image'
import { Link } from 'src/modules/components/lib/link'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox, View } from 'src/modules/components/lib/view'
import { routes } from 'src/modules/routes'
import { SearchPharmacyLink } from './search-pharmacy'

export const Usage: FC = () => {
  return (
    <ViewContainer id="how-to-use">
      <SectionHeaderText>
        処方箋ネット受付
        <br />
        ご利用の流れ
      </SectionHeaderText>

      <UsageContainer>
        <StepWrap>
          <StepNumber>STEP 01</StepNumber>
          <Flexbox justifyContent="space-between" position="relative">
            <TextWrap>
              <StepTitle>処方箋を受け取り後、薬局を検索</StepTitle>
              <UsageText>
                医療機関で処方箋をお受け取り後、ご希望の薬局を検索し「処方箋ネット受付」を選択してください。
                <br />
                <SearchLink
                  to={routes.search.path}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  検索画面はこちら
                </SearchLink>
              </UsageText>
            </TextWrap>
            <ImageWrap>
              <Image
                src="/top/step1.png"
                alt="STEP1"
                width="100%"
                height="auto"
              />
            </ImageWrap>
          </Flexbox>
        </StepWrap>

        <StepWrap>
          <StepNumber>STEP 02</StepNumber>
          <Flexbox justifyContent="space-between" position="relative">
            <TextWrap>
              <StepTitle>スマホで処方箋を撮影して事前送信</StepTitle>
              <UsageText>
                処方箋をご自身のスマホで撮影し、アップロードを行い、質問票にご回答ください。「回答を送信」を選択して調剤予約が完了します。
              </UsageText>
            </TextWrap>
            <ImageWrap>
              <Image
                src="/top/step2.png"
                alt="STEP2"
                width="100%"
                height="auto"
              />
            </ImageWrap>
          </Flexbox>
        </StepWrap>

        <StepWrap>
          <StepNumber>STEP 03</StepNumber>
          <Flexbox justifyContent="space-between" position="relative">
            <TextWrap>
              <StepTitle>通知が届いたら、処方薬受け取り</StepTitle>
              <UsageText>
                調剤完了後はご登録いただいたご連絡先に通知が届きます。店頭でお薬の代金をお支払いいただき処方薬をお受け取りください。
              </UsageText>
              <Desc>
                ※お受け取りの際は、処方箋原本（紙）と保険証が必要です
                <br />
                ※処方箋有効期限内に薬局にお越しください
              </Desc>
            </TextWrap>
            <ImageWrap>
              <Image
                src="/top/step3.png"
                alt="STEP3"
                width="100%"
                height="auto"
              />
            </ImageWrap>
          </Flexbox>
        </StepWrap>
      </UsageContainer>

      <BtnWrap>
        <SearchPharmacyLink gtmId="gtm-phanet-link-btn01" />
      </BtnWrap>
    </ViewContainer>
  )
}

const ViewContainer = styled(View)`
  padding: 69px 22px 70px;
  font-family: 'HiraginoSans', sans-serif;

  @media (max-width: 560px) {
    padding-top: 48px;
    padding-bottom: 60px;
  }
`

const SectionHeaderText = styled(Text)`
  margin: 0 0 55px;
  font-weight: 600;
  font-size: 32px;
  text-align: center;

  @media (max-width: 560px) {
    margin-bottom: 92px;
    font-size: 20px;
  }
`

const UsageContainer = styled(View)`
  max-width: 600px;
  margin: 0 auto;
`

const TextWrap = styled(View)`
  width: 65%;

  @media (max-width: 560px) {
    width: 100%;
  }
`

const StepWrap = styled(View)`
  margin: 110px 0 0;
  &:first-child {
    margin-top: 0;
  }

  @media (max-width: 560px) {
    margin-top: 100px;
  }
`

const StepNumber = styled(Text)`
  font-size: 22px;
  font-weight: 600;
  color: #2ba168;
  font-family: 'Roboto';

  @media (max-width: 560px) {
    font-size: 14px;
  }
`

const StepTitle = styled(Text)`
  font-size: 24px;
  font-weight: 600;
  color: #2ba168;

  @media (max-width: 560px) {
    font-size: 16px;
  }
`

const UsageText = styled(Text)`
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 560px) {
    font-size: 14px;
  }
`

const Desc = styled(Text)`
  font-size: 12px;

  @media (max-width: 560px) {
  }
`

const ImageWrap = styled(View)`
  width: 160px;

  @media (max-width: 560px) {
    width: 96px;
    position: absolute;
    top: -70px;
    right: 17px;
  }
`

const BtnWrap = styled(View)`
  max-width: 288px;
  margin: 100px auto 0;

  @media (max-width: 560px) {
    margin-top: 65px;
  }
`

const SearchLink = styled(Link)`
  &:hover {
    text-decoration-line: underline;
  }
`
