import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#help_svg__clip0)">
        <path
          d="M43 86a43.063 43.063 0 01-23.88-7.224A42.84 42.84 0 013.298 59.484 43.124 43.124 0 01.86 34.629a42.975 42.975 0 0111.753-22.015A42.577 42.577 0 0130.53 1.864 42.899 42.899 0 0151.4.83a42.86 42.86 0 0118.89 8.944 42.916 42.916 0 0112.442 16.8 43.225 43.225 0 013.067 20.668c-.688 7.052-3.124 13.788-7.052 19.694a43.02 43.02 0 01-15.48 14.018C57.018 84.25 50.052 86 43 86zm0-80.18a37.137 37.137 0 00-20.669 6.277c-6.106 4.1-10.893 9.89-13.702 16.684a37.284 37.284 0 00-2.122 21.5 37.158 37.158 0 0010.177 19.035 36.54 36.54 0 0012.069 8.055A37.234 37.234 0 0042.97 80.21c4.874 0 9.718-.975 14.219-2.838a37.6 37.6 0 0012.069-8.056 37.597 37.597 0 008.055-12.069A37.268 37.268 0 0080.152 43c0-4.902-.975-9.718-2.838-14.247a37.288 37.288 0 00-8.055-12.069A36.62 36.62 0 0057.19 8.6C52.718 6.765 47.873 5.79 43 5.82zm-4.243 45.493c0-2.55.316-4.558.946-6.077.631-1.52 1.892-3.182 3.756-4.988 1.863-1.806 3.038-3.096 3.526-3.87a6.829 6.829 0 001.146-3.784c0-1.806-.458-3.153-1.347-4.1-.889-.945-2.179-1.404-3.899-1.404-1.634 0-2.981.459-3.956 1.376-1.003.918-1.519 2.179-1.519 3.755h-6.966c.029-3.382 1.204-6.077 3.469-8.026 2.264-1.978 5.246-2.953 8.972-2.953 3.842 0 6.823.975 8.973 2.924 2.15 1.95 3.24 4.673 3.21 8.17 0 3.125-1.433 6.192-4.357 9.202l-3.526 3.469c-1.261 1.433-1.92 3.526-1.949 6.278l-6.479.029zm-.516 8.887c0-1.118.344-2.035 1.061-2.723.717-.688 1.663-1.032 2.867-1.032 1.232 0 2.178.344 2.895 1.06.717.717 1.06 1.606 1.06 2.695 0 1.06-.343 1.92-1.031 2.609-.688.717-1.663 1.06-2.924 1.06-1.262 0-2.236-.372-2.924-1.06-.66-.688-.975-1.548-1.004-2.609z"
          fill={props.color || '#000'}
        />
      </g>
      <defs>
        <clipPath id="help_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgHelp = _style(SvgIcon)

export default SvgHelp
