import React, { FC } from 'react'
import { MAX_PAGE_WIDTH } from 'src/modules/const'
import { View, ViewProps, Flexbox } from './view'

export const Screen: FC<ViewProps> = props => {
  return (
    <View
      width="100%"
      minHeight="100vh"
      style={{
        minHeight: '-webkit-fill-available',
      }}
      bg="appBackground"
      overflowY="auto"
    >
      <View maxWidth={MAX_PAGE_WIDTH} width="100%" margin="auto" {...props}>
        {props.children}
      </View>
    </View>
  )
}

export const FlexboxScreen: FC<ViewProps> = props => {
  return (
    <View
      width="100%"
      minHeight="100vh"
      style={{
        minHeight: '-webkit-fill-available',
      }}
      bg="appBackground"
      overflowY="auto"
      display="flex"
      flexDirection="column"
    >
      <Flexbox
        width="100%"
        maxWidth={MAX_PAGE_WIDTH}
        margin="auto"
        flexDirection="column"
        flexGrow={1}
        {...props}
      >
        {props.children}
      </Flexbox>
    </View>
  )
}
