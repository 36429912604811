import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#video_off_svg__clip0)" fill={props.color || '#000'}>
        <path d="M84.672 25.361a2.887 2.887 0 00-2.786-.134l-18.785 9.4v-6.832a8.618 8.618 0 00-.554-3.022l-5.187 5.188v32.23a2.87 2.87 0 01-2.87 2.87H22.26l-5.725 5.725H54.49a8.59 8.59 0 008.595-8.595V55.36l18.767 9.4a2.87 2.87 0 003.844-1.275c.202-.403.303-.84.303-1.293V27.795c.033-.99-.487-1.914-1.327-2.434zm-4.381 32.18l-17.19-8.595v-7.923l17.19-8.595v25.113zM11.533 59.472V27.795a2.87 2.87 0 012.87-2.87H46.08l5.724-5.725H14.403a8.59 8.59 0 00-8.595 8.595v34.38c0 .906.151 1.796.403 2.618l5.322-5.321zM69.665 6.593c.739 0 1.494.286 2.065.856a2.898 2.898 0 010 4.113L4.986 78.306c-.571.571-1.31.856-2.065.856a2.925 2.925 0 01-2.065-.856 2.898 2.898 0 010-4.112L67.6 7.448a2.925 2.925 0 012.065-.856z" />
      </g>
      <defs>
        <clipPath id="video_off_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgVideoOff = _style(SvgIcon)

export default SvgVideoOff
