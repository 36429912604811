import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.825383 14.364C0.53249 14.6569 0.53249 15.1317 0.825383 15.4246C1.11828 15.7175 1.59315 15.7175 1.88604 15.4246L8.25 9.06066L14.614 15.4246C14.9069 15.7175 15.3817 15.7175 15.6746 15.4246C15.9675 15.1317 15.9675 14.6569 15.6746 14.364L9.31066 8L15.6746 1.63604C15.9675 1.34315 15.9675 0.868276 15.6746 0.575383C15.3817 0.28249 14.9069 0.28249 14.614 0.575383L8.25 6.93934L1.88604 0.575383C1.59315 0.282489 1.11828 0.28249 0.825383 0.575383C0.53249 0.868276 0.53249 1.34315 0.825383 1.63604L7.18934 8L0.825383 14.364Z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  any,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgClose2 = _style(SvgIcon)

export default SvgClose2
