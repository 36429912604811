export const SERVER_URL =
  process.env.REACT_APP_SERVER_URL || 'http://localhost:3000'

export const PHARMACY_API_URL = `${SERVER_URL}/pharmacy_api`
export const API_URL = `${SERVER_URL}/api`

export const RTC_CONNECTION_URL =
  process.env.REACT_APP_RTC_CONNECTION_URL || 'ws://localhost:8444/apc'
export const RTC_CONNECTION_AVAILABLE =
  !!process.env.REACT_APP_RTC_CONNECTION_AVAILABLE

export const GOOGLE_MAPS_API_KEY =
  process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
  'AIzaSyAXg6zfjakOVmgF_nva9MahcDBqO6k3HJ4'

export const VIDEO_CALL_SERVER_HOST =
  process.env.REACT_APP_VIDEO_CALL_SERVER_HOST || 'http://localhost:8082'
