import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#delivery_svg__clip0)">
        <path
          d="M85.312 58.365c-.459-.43-1.06-.688-1.663-.688h-.602V43a2.336 2.336 0 00-.688-1.663L64.93 23.909a2.291 2.291 0 00-.774-.516 2.482 2.482 0 00-.917-.172h-6.307V12.843c0-.63-.258-1.233-.688-1.663a2.365 2.365 0 00-1.663-.688H2.38c-.63 0-1.232.258-1.662.688A2.276 2.276 0 000 12.843v47.214c0 .63.258 1.232.688 1.662.43.43 1.032.688 1.663.688h5.246a11.298 11.298 0 00.745 6.078 11.34 11.34 0 004.214 5.131 11.446 11.446 0 006.364 1.92c3.039 0 5.934-1.203 8.084-3.353a11.412 11.412 0 003.354-8.084c0-.574-.057-1.147-.115-1.692h26.173a11.299 11.299 0 00.745 6.078 11.34 11.34 0 004.214 5.131 11.447 11.447 0 006.364 1.92c3.039 0 5.934-1.203 8.084-3.353a11.412 11.412 0 003.354-8.084c0-.574-.057-1.147-.114-1.692h4.615c.63 0 1.233-.257 1.663-.688.43-.43.688-1.032.688-1.662a2.536 2.536 0 00-.717-1.692zM62.264 27.95l12.67 12.7H56.933v-12.7h5.332zM4.73 15.194h47.472v42.455H28.925c-.172 0-.344.029-.516.057a11.462 11.462 0 00-5.132-4.185 11.617 11.617 0 00-6.622-.66 11.237 11.237 0 00-5.848 3.125 10.217 10.217 0 00-1.404 1.72c-.172-.029-.316-.057-.488-.057H4.73V15.193zm19.78 52.632a6.847 6.847 0 01-3.01 2.465 6.639 6.639 0 01-3.87.373 6.733 6.733 0 01-3.44-1.835 6.622 6.622 0 01-1.835-3.44c-.258-1.29-.114-2.637.373-3.87a6.848 6.848 0 012.465-3.01c1.09-.745 2.408-1.118 3.727-1.118 1.777 0 3.469.717 4.73 1.978a6.685 6.685 0 011.978 4.73 6.685 6.685 0 01-1.118 3.727zm48.79 0a6.849 6.849 0 01-3.01 2.465 6.639 6.639 0 01-3.87.373 6.733 6.733 0 01-3.44-1.835 6.622 6.622 0 01-1.834-3.44c-.258-1.29-.115-2.637.373-3.87a6.848 6.848 0 012.465-3.01c1.09-.745 2.408-1.118 3.727-1.118 1.777 0 3.468.717 4.73 1.978a6.685 6.685 0 011.978 4.73 6.921 6.921 0 01-1.118 3.727zm3.928-10.091a11.339 11.339 0 00-5.131-4.214 11.617 11.617 0 00-6.622-.66 11.236 11.236 0 00-5.848 3.125 10.87 10.87 0 00-1.376 1.663h-1.319V45.35h21.357v12.298h-.316c-.258 0-.487.057-.716.114 0 0-.029 0-.029-.028z"
          fill={props.color || '#000'}
        />
      </g>
      <defs>
        <clipPath id="delivery_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgDelivery = _style(SvgIcon)

export default SvgDelivery
