import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#download_svg__clip0)" fill={props.color || '#000'}>
        <path d="M85.283 64.844a2.406 2.406 0 00-1.72-.717c-.659 0-1.261.258-1.72.717a2.406 2.406 0 00-.716 1.72v12.757H4.845V66.564c0-.63-.258-1.261-.717-1.72a2.406 2.406 0 00-1.72-.717c-.63 0-1.261.258-1.72.717a2.406 2.406 0 00-.717 1.72V81.73c0 .63.258 1.261.717 1.72.459.458 1.06.716 1.72.716h81.155c.631 0 1.262-.258 1.72-.716.459-.459.717-1.06.717-1.72V66.564c0-.66-.258-1.261-.717-1.72z" />
        <path d="M41.165 70.406c.23.229.488.401.774.516.287.114.602.172.918.172h.286c.316 0 .631-.058.918-.172.286-.115.573-.287.774-.516L70.09 45.179c.459-.459.717-1.06.717-1.72 0-.63-.258-1.261-.717-1.72a2.406 2.406 0 00-1.72-.717c-.63 0-1.261.258-1.72.717L45.38 62.981V4.271c0-.63-.259-1.26-.717-1.72a2.406 2.406 0 00-1.72-.716c-.63 0-1.262.258-1.72.717a2.406 2.406 0 00-.717 1.72V63.01l-21.27-21.242a2.406 2.406 0 00-1.72-.717c-.631 0-1.262.258-1.72.717a2.406 2.406 0 00-.717 1.72c0 .63.258 1.261.716 1.72l25.37 25.198z" />
      </g>
      <defs>
        <clipPath id="download_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgDownload = _style(SvgIcon)

export default SvgDownload
