import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M78.441 5.543H7.56C3.39 5.543 0 8.933 0 13.102v59.796c0 4.168 3.39 7.559 7.559 7.559H78.44c4.168 0 7.559-3.39 7.559-7.559V13.102c0-4.168-3.39-7.559-7.559-7.559zm2.52 67.355c0 1.39-1.13 2.52-2.52 2.52H7.56c-1.39 0-2.52-1.13-2.52-2.52V13.102c0-1.39 1.13-2.52 2.52-2.52H78.44c1.39 0 2.52 1.13 2.52 2.52v59.796z"
        fill={props.color || '#000'}
      />
      <path
        d="M37.625 16.293H13.437a2.52 2.52 0 00-2.519 2.52v18.812a2.52 2.52 0 002.52 2.52h24.187a2.52 2.52 0 002.52-2.52V18.812a2.52 2.52 0 00-2.52-2.519zm-2.52 18.813H15.957v-4.368h2.855a2.52 2.52 0 000-5.039h-2.855v-4.367h19.148v13.773zM37.625 45.855H13.437a2.52 2.52 0 00-2.519 2.52v18.813a2.52 2.52 0 002.52 2.519h24.187a2.52 2.52 0 002.52-2.52V48.376a2.52 2.52 0 00-2.52-2.52zm-2.52 18.813H15.957v-4.367h2.855a2.52 2.52 0 000-5.04h-2.855v-4.367h19.148v13.774zM72.563 16.293H48.374a2.52 2.52 0 00-2.52 2.52v18.812a2.52 2.52 0 002.52 2.52h24.188a2.52 2.52 0 002.519-2.52V18.812a2.52 2.52 0 00-2.52-2.519zm-2.52 18.813H50.894v-4.368h2.856a2.52 2.52 0 000-5.039h-2.855v-4.367h19.148v13.773zM72.563 45.855H48.374a2.52 2.52 0 00-2.52 2.52v18.813a2.52 2.52 0 002.52 2.519h24.188a2.52 2.52 0 002.519-2.52V48.376a2.52 2.52 0 00-2.52-2.52zm-2.52 18.813H50.894v-4.367h2.856a2.52 2.52 0 000-5.04h-2.855v-4.367h19.148v13.774z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgLocker = _style(SvgIcon)

export default SvgLocker
