import React, { FC, useState, useCallback } from 'react'
import { PublicPageHeader } from 'src/modules/components/header/public-page-header'

import { Screen } from 'src/modules/components/lib/screen'

import { Text } from 'src/modules/components/lib/text'
import { View } from 'src/modules/components/lib/view'
import { MutualInduction } from 'src/modules/components/mutualInduction/mutualInduction'
import { Near } from 'src/modules/components/net-application/chainTop/near'
import { AreaModal } from 'src/modules/components/net-application/modal-area/modal-area'
import { StationModal } from 'src/modules/components/net-application/modal-station'
import { SearchNavigation } from 'src/modules/components/net-application/search-navigation'
import { Area as AreaComponent } from 'src/modules/components/net-application/top/area'
import { NewPharmacy } from 'src/modules/components/net-application/top/new-pharmacy'
import { Chain } from 'src/modules/entities/chain/entity'
import { Pharmacy } from 'src/modules/entities/pharmacy/entity'
import { Spacer } from 'src/modules/screens/top/spacer'
import { LocationSearch } from '../../../../components/net-application/chainTop/location-search'
import { Footer } from '../../../top/footer'

type Props = {
  chain: Chain
  latestPharmacies: Pharmacy[] | []
  nearPharmacies: Pharmacy[] | []
  prefectureCount?: { [key: string]: number }
  fetched: boolean
  authorized: boolean
  loading: boolean
  nearPharmaciesLoading: boolean
  currentPosition: GeolocationPosition | null
  isGeoApproved: boolean
}

export const NetApplicationChainSearchTopScreen: FC<Props> = ({
  chain,
  latestPharmacies,
  nearPharmacies,
  prefectureCount,
  fetched,
  authorized,
  loading,
  nearPharmaciesLoading,
  currentPosition,
  isGeoApproved,
}) => {
  const [showAreaModal, setShowAreaModal] = useState(false)
  const [showStationModal, setShowStationModal] = useState(false)

  const openAreaModal = useCallback(() => {
    setShowAreaModal(true)
  }, [])

  const closeAreaModal = useCallback(() => {
    setShowAreaModal(false)
  }, [])

  const openStationModal = useCallback(() => {
    setShowStationModal(true)
  }, [])

  const closeStationModal = useCallback(() => {
    setShowStationModal(false)
  }, [])

  return (
    <View minHeight="100%">
      <PublicPageHeader
        fetched={fetched}
        authorized={authorized}
        displayMutualInduction
      />
      <MutualInduction />
      <Screen>
        <SearchNavigation
          pageType="search_chain"
          prefecture={null}
          area={null}
          station={null}
          chain={chain}
          keyword={''}
          displayMutualInduction
        />
        <View
          minHeight="90px"
          width="100%"
          position="relative"
          marginTop="32px"
          style={{
            background:
              'radial-gradient(50% 50.00% at 50% 50.00%, #EEF6F2 0%, #DAEDE3 100%)',
          }}
        >
          <Text
            style={{
              fontSize: '16px',
              fontWeight: 'bold',
              letterSpacing: '0.15px',
              lineHeight: '150%',
              color: '#157A49',
              position: 'absolute',
              left: ' 50%',
              top: '50%',
              transform: 'translateX(-50%) translateY(-50%)',
            }}
          >
            {chain.name}を探す
          </Text>
        </View>
        <View backgroundColor="appBackground" px="16px" height="100%">
          <Spacer size={22} />
          <Near
            nearPharmacies={nearPharmacies}
            nearPharmaciesLoading={nearPharmaciesLoading}
            currentPosition={currentPosition}
            isGeoApproved={isGeoApproved}
            chain={chain}
          />
          <Spacer size={22} />
          <AreaComponent
            openAreaModal={openAreaModal}
            openStationModal={openStationModal}
          />
          <Spacer size={22} />
          <LocationSearch
            prefectureCount={prefectureCount}
            chainCode={chain.code}
          />
          <Spacer size={22} />
          <NewPharmacy
            authorized={authorized}
            pharmacies={latestPharmacies}
            fetching={loading}
          />
        </View>
        <AreaModal
          defaultMode="all"
          prefecture={null}
          visible={showAreaModal}
          onCloseClick={closeAreaModal}
        />

        <StationModal
          visible={showStationModal}
          onCloseClick={closeStationModal}
        />

        <Footer />
      </Screen>
    </View>
  )
}
