import React, { FC, useContext, useEffect, useState, useMemo } from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'
import { useSWRFetch as useAreaSearchByKeywordFetch } from 'src/modules/apis/pharmacy/areas/search_by_keyword/use-fetch'
import { useSWRFetch as useAreaSearchByPrefectureFetch } from 'src/modules/apis/pharmacy/areas/search_by_prefecture/use-fetch'
import { useSWRFetch as useStationSearchByKeywordFetch } from 'src/modules/apis/pharmacy/stations/search_by_keyword/use-fetch'
import { useSWRFetch as useStationSearchByPrefectureFetch } from 'src/modules/apis/pharmacy/stations/search_by_prefecture/use-fetch'
import { Input } from 'src/modules/components/lib/input'
import { Link } from 'src/modules/components/lib/link'
import { View } from 'src/modules/components/lib/view'
import { Area, Station } from 'src/modules/entities/pharmacy/entity'
import { getSearchPagePath } from 'src/modules/helpers/get-serch-page-path'
import { useSearchQuery } from 'src/modules/hooks/use-search-query'
import { useViewportHeight } from 'src/modules/hooks/useViewportHeight'
import { Prefecture, PREFECTURE_LIST } from '../../../constants/prefecture-list'

import { Border } from '../../lib/border'
import { Clickable } from '../../lib/clickable'
import {
  ArrowLeft2,
  ArrowRight2,
  Close2,
  Nearby,
  SearchLeft,
} from '../../lib/curon-icon'
import { Heading } from '../../lib/heading'
import { ModalContext, useModalStackDepth } from '../../lib/modal'
import { Text } from '../../lib/text'
import { filterArea } from './filter-area-list'

const ToVisibleKeyFrames = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`

const ToInvisibleKeyFrames = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
`
type AreaContainerRootProps = {
  containerRootHeight: any
}

const AreaContainerRoot = styled.div<AreaContainerRootProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.containerRootHeight};
  background: white;
  visibility: hidden;
  &.visible {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    animation: ${ToVisibleKeyFrames} 200ms ease-out 0s;
  }
  &.invisible {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    animation: ${ToInvisibleKeyFrames} 200ms ease-out 0s;
    animation-fill-mode: forwards;
  }
`

const AreaElementRoot = styled.div`
  &:hover {
    background-color: ${props => props.theme.colors.pistachioGreen};
    outline: none;
  }
`

const CustomInputRoot = styled.div`
  position: relative;
  .icon {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }
  input {
    padding-left: 30px;
  }
`

const CategoryHeader = styled.div`
  padding: 4px 16px;
  width: 100%;
  background: #f4f7fa;
  border-bottom: 1px solid #ecf0f5;
  font-weight: 600;
  font-size: 14px;
  color: #667587;
`

const BackRow = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  background: #f4f7fa;
  border-bottom: 1px solid #ecf0f5;
  font-weight: 600;
`
const CurrentLocationRow = styled(Link)`
  display: flex;
  align-items: center;
  padding: 16px;
`

const OptionLinkRow = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 16px 16px;
  color: #313541;
`

const OptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 16px 16px;
`

const AreaContainer: FC<
  React.HTMLAttributes<HTMLDivElement> & {
    visible: boolean
  }
> = ({ visible, ...props }) => {
  const context = useContext(ModalContext)
  const stackDepth = useModalStackDepth(visible)
  const vh = useViewportHeight()

  if (!context.root) {
    return null
  }

  return ReactDOM.createPortal(
    <AreaContainerRoot
      style={{ zIndex: stackDepth }}
      className={visible ? 'visible' : 'invisible'}
      containerRootHeight={`calc(${vh * 100}px)`}
      {...props}
    >
      {props.children}
    </AreaContainerRoot>,
    context.root,
  )
}

type AreaListProps = {
  areaList: Array<Area>
  params: URLSearchParams
}

const AreaList: FC<AreaListProps> = props => {
  const { keyword, station: stationCode, chain } = useSearchQuery()

  return (
    <>
      {props.areaList.map(area => {
        const prefecture = PREFECTURE_LIST.find(p => p.name === area.prefecture)
        const prefValue = prefecture ? prefecture.value : ''
        const path = getSearchPagePath({
          chainCode: chain,
          keyword: keyword || undefined,
          prefecture: prefValue,
          areaSlug: area.slug,
          stationCode,
        })
        return (
          <AreaElementRoot key={area.slug}>
            {area.count > 0 ? (
              <>
                <OptionLinkRow to={path}>
                  <Text>
                    {area.name}（{area.prefecture}）
                  </Text>
                  <Text>{area.count}件</Text>
                </OptionLinkRow>
                <Border borderColor="#ECF0F5" />
              </>
            ) : (
              <>
                <OptionRow>
                  <Text color="#93A3B5">
                    {area.name}（{area.prefecture}）
                  </Text>
                  <Text color="#93A3B5">0件</Text>
                </OptionRow>
                <Border borderColor="#ECF0F5" />
              </>
            )}
          </AreaElementRoot>
        )
      })}
    </>
  )
}

type StationListProps = {
  stationList: Array<Station>
  params: URLSearchParams
}

const StationList: FC<StationListProps> = props => {
  const { keyword, chain } = useSearchQuery()

  return (
    <>
      {props.stationList.map(station => {
        const prefecture = PREFECTURE_LIST.find(
          p => p.name === station.prefecture,
        )
        const prefValue = prefecture ? prefecture.value : ''

        const path = getSearchPagePath({
          chainCode: chain,
          keyword: keyword || undefined,
          prefecture: prefValue,
          areaSlug: undefined,
          stationCode: station.code,
        })

        return (
          <AreaElementRoot key={station.code}>
            {station.count > 0 ? (
              <>
                <OptionLinkRow to={path}>
                  <Text>
                    {station.line} {station.name}駅（{station.prefecture}）
                  </Text>
                  <Text>{station.count}件</Text>
                </OptionLinkRow>
                <Border borderColor="#ECF0F5" />
              </>
            ) : (
              <>
                <OptionRow>
                  <Text color="#93A3B5">
                    {station.line} {station.name}駅（{station.prefecture}）
                  </Text>
                  <Text color="#93A3B5">{station.count}件</Text>
                </OptionRow>
                <Border borderColor="#ECF0F5" />
              </>
            )}
          </AreaElementRoot>
        )
      })}
    </>
  )
}

type Props = {
  visible: boolean
  defaultMode: 'all' | 'prefecture' | 'area' | 'station'
  prefecture: Prefecture | null
  onCloseClick: () => void
}

export const AreaModal: FC<Props> = props => {
  const { defaultMode, prefecture, visible, onCloseClick } = props

  const {
    keyword: keywordQuery,
    area,
    currentPage,
    station,
    chain,
  } = useSearchQuery()
  const params = new URLSearchParams()

  if (keywordQuery) {
    params.append('keyword', encodeURI(keywordQuery))
  }

  const [mode, setMode] = useState(defaultMode)

  const [currentPrefecture, setCurrentPrefecture] = useState<Prefecture | null>(
    prefecture ? prefecture : null,
  )
  const [keyword, setKeyword] = useState('')
  const [inputValue, setInputValue] = useState('')

  const { data: areaPrefectureData } = useAreaSearchByPrefectureFetch({
    prefecture: currentPrefecture ? currentPrefecture.name : null,
    keywordForSearchPharmacy: keywordQuery ? keywordQuery : null,
    chainCode: chain || null,
  })

  const { data: areaKeywordData } = useAreaSearchByKeywordFetch({
    keywordForSearchArea: keyword ? keyword : null,
    keywordForSearchPharmacy: keywordQuery ? keywordQuery : null,
    chainCode: chain || null,
  })

  const areaList = useMemo(() => {
    const list = keyword ? areaKeywordData : areaPrefectureData

    return list ? filterArea({ areaData: list }) : []
  }, [areaPrefectureData, areaKeywordData, keyword])

  const { data: stationPrefectureData } = useStationSearchByPrefectureFetch({
    prefecture: currentPrefecture ? currentPrefecture.name : null,
    keywordForSearchPharmacy: keywordQuery ? keywordQuery : null,
    chainCode: chain || null,
  })

  const { data: stationKeywordData } = useStationSearchByKeywordFetch({
    keywordForSearchStation: keyword ? keyword : null,
    keywordForSearchPharmacy: keywordQuery ? keywordQuery : null,
    chainCode: chain || null,
  })

  const stationList = useMemo(() => {
    if (keyword) return stationKeywordData || []
    if (currentPrefecture) return stationPrefectureData || []

    return []
  }, [currentPrefecture, keyword, stationPrefectureData, stationKeywordData])

  const WAIT_SEARCH_PER_SECONDS_FROM_INPUT_VALUE = 700 // 0.7秒
  useEffect(() => {
    const timer = setTimeout(() => {
      setKeyword(inputValue)
    }, WAIT_SEARCH_PER_SECONDS_FROM_INPUT_VALUE)

    return () => clearTimeout(timer)
  }, [keyword, inputValue])

  const prefectureSearchPath = getSearchPagePath({
    chainCode: chain,
    keyword: keyword || undefined,
    prefecture: currentPrefecture ? currentPrefecture.value : undefined,
  })

  const nearLocationSearchPath = getSearchPagePath({
    near: true,
  })
  const allPrefectureSearchPath = getSearchPagePath({
    keyword: keyword || undefined,
    chainCode: chain,
    prefecture: 'all',
  })

  return (
    <AreaContainer visible={visible}>
      {/* モーダル上部：テキスト（エリアから探す or 検索窓（エリア・駅名で探す） */}
      <View position="relative" m={4} pr="30px">
        {['prefecture', 'area', 'station'].includes(mode) ? (
          <CustomInputRoot>
            <SearchLeft color="shuttleGray" className="icon" />
            <Input
              type="search"
              width="100%"
              placeholder="エリア・駅名で探す"
              value={inputValue}
              onChange={e => {
                setInputValue(e.target.value)
              }}
            />
          </CustomInputRoot>
        ) : (
          <Heading fontSize="16px" textAlign="center" pl="30px">
            エリアから探す
          </Heading>
        )}
        <Clickable
          onClick={onCloseClick}
          position="absolute"
          right="0"
          top="12px"
        >
          <Close2 size="16px" color="shuttleGray" />
        </Clickable>
      </View>
      <Border borderColor="#ecf0f5" />

      {/* エリアページへのリンク一覧 */}
      <View overflowY="scroll" overflowX="hidden" flexGrow={1}>
        <View width="100vw">
          {mode !== 'all' && (
            <BackRow
              onClick={() => {
                setMode('all')
                setInputValue('')
              }}
            >
              <ArrowLeft2 color="#2BA168" size="12px" mr={3} />
              全国に戻る
            </BackRow>
          )}

          <AreaElementRoot>
            <CurrentLocationRow to={nearLocationSearchPath}>
              <Nearby color="#27AE57" mr="8px" />
              現在地から探す
            </CurrentLocationRow>
          </AreaElementRoot>
          <Border borderColor="#ecf0f5" />

          {mode === 'all' && (
            <>
              <AreaElementRoot>
                <OptionLinkRow to={allPrefectureSearchPath}>
                  全国から探す
                </OptionLinkRow>
              </AreaElementRoot>
              <Border borderColor="#ecf0f5" />
              <CategoryHeader>都道府県</CategoryHeader>
              {PREFECTURE_LIST.map(prefecture => (
                <AreaElementRoot key={prefecture.id}>
                  <OptionRow
                    onClick={() => {
                      setCurrentPrefecture(prefecture)
                      setMode('prefecture')
                    }}
                  >
                    {prefecture.name}
                    <ArrowRight2 color="#2BA168" size="12px" mr={3} />
                  </OptionRow>
                  <Border borderColor="#ecf0f5" />
                </AreaElementRoot>
              ))}
            </>
          )}
          {mode !== 'all' && (
            <AreaElementRoot>
              <OptionLinkRow to={currentPrefecture ? prefectureSearchPath : ''}>
                {currentPrefecture?.name}全てで探す
              </OptionLinkRow>
            </AreaElementRoot>
          )}
          {(mode === 'prefecture' || mode === 'area') && (
            <>
              {areaList.some(a => a.type === 'tokyo_wards') && (
                <>
                  <CategoryHeader>東京23区</CategoryHeader>
                  <AreaList
                    areaList={areaList.filter(a => a.type === 'tokyo_wards')}
                    params={params}
                  />
                </>
              )}
              {areaList.some(a => a.type !== 'tokyo_wards') && (
                <>
                  <CategoryHeader>エリア</CategoryHeader>
                  <AreaList
                    areaList={areaList.filter(a => a.type !== 'tokyo_wards')}
                    params={params}
                  />
                </>
              )}
              {stationList.length > 0 && (
                <>
                  <CategoryHeader>駅名</CategoryHeader>
                  <StationList stationList={stationList} params={params} />
                </>
              )}
            </>
          )}
          {mode === 'station' && (
            <>
              {stationList.length > 0 && (
                <>
                  <CategoryHeader>駅名</CategoryHeader>
                  <StationList stationList={stationList} params={params} />
                </>
              )}
              {areaList.some(a => a.type === 'tokyo_wards') && (
                <>
                  <CategoryHeader>東京23区</CategoryHeader>
                  <AreaList
                    areaList={areaList.filter(a => a.type === 'tokyo_wards')}
                    params={params}
                  />
                </>
              )}
              {areaList.some(a => a.type !== 'tokyo_wards') && (
                <>
                  <CategoryHeader>エリア</CategoryHeader>
                  <AreaList
                    areaList={areaList.filter(a => a.type !== 'tokyo_wards')}
                    params={params}
                  />
                </>
              )}
            </>
          )}
        </View>
      </View>
    </AreaContainer>
  )
}
