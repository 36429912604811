import * as Sentry from '@sentry/react'
import { Integrations as TracingIntegrations } from '@sentry/tracing'
import { matchPath } from 'react-router-dom'
import { history } from 'src/modules/store'
import { routes } from '../routes'

// NOTE: Sentryに渡すpathの配列を降順にして、matchPathの優先度を調整する
// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/#parameterized-transaction-names
const routesForSentry = Object.values(routes)
  .map(route => ({
    path: route.path,
  }))
  .sort((a, b) => b.path.length - a.path.length)

export const initialize = () => {
  if (!!process.env.REACT_APP_SENTRY_ENABLED) {
    Sentry.init({
      dsn: 'https://c60c3907408f46b3b829234eea84dd13@o112361.ingest.sentry.io/5244932',
      integrations: [
        new TracingIntegrations.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV5Instrumentation(
            history,
            routesForSentry,
            matchPath,
          ),
        }),
      ],
      environment: process.env.REACT_APP_ENV,
      tracesSampleRate: 0.2,
    })
  }
}
