import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.05396 1.74792C2.3184 1.74792 2.57413 1.78525 2.81616 1.85492L2.81616 0H1.31616V1.84802C1.55095 1.78279 1.79838 1.74792 2.05396 1.74792Z"
        fill={props.color || '#2ba168'}
      />
      <path
        d="M1.31616 16V7.14783C1.55095 7.21306 1.79838 7.24792 2.05396 7.24792C2.3184 7.24792 2.57413 7.2106 2.81616 7.14093L2.81616 16H1.31616Z"
        fill={props.color || '#2ba168'}
      />
      <path
        d="M8.25 5.25C8.51445 5.25 8.77018 5.28733 9.01221 5.357V0H7.51221V5.35009C7.747 5.28486 7.99443 5.25 8.25 5.25Z"
        fill={props.color || '#2ba168'}
      />
      <path
        d="M7.51221 16V10.6499C7.747 10.7151 7.99443 10.75 8.25 10.75C8.51445 10.75 8.77018 10.7127 9.01221 10.643V16H7.51221Z"
        fill={props.color || '#2ba168'}
      />
      <path
        d="M15.2622 8.90659C15.0202 8.83692 14.7644 8.79959 14.5 8.79959C14.2444 8.79959 13.997 8.83446 13.7622 8.89969V0H15.2622V8.90659Z"
        fill={props.color || '#2ba168'}
      />
      <path
        d="M13.7622 14.1995V16H15.2622V14.1926C15.0202 14.2623 14.7644 14.2996 14.5 14.2996C14.2444 14.2996 13.997 14.2647 13.7622 14.1995Z"
        fill={props.color || '#2ba168'}
      />
      <path
        d="M2.06616 6.16907C3.03266 6.16907 3.81616 5.38557 3.81616 4.41907C3.81616 3.45257 3.03266 2.66907 2.06616 2.66907C1.09966 2.66907 0.316162 3.45257 0.316162 4.41907C0.316162 5.38557 1.09966 6.16907 2.06616 6.16907Z"
        fill={props.color || '#2ba168'}
      />
      <path
        d="M10 8C10 8.9665 9.2165 9.75 8.25 9.75C7.2835 9.75 6.5 8.9665 6.5 8C6.5 7.0335 7.2835 6.25 8.25 6.25C9.2165 6.25 10 7.0335 10 8Z"
        fill={props.color || '#2ba168'}
      />
      <path
        d="M14.5 13.25C15.4665 13.25 16.25 12.4665 16.25 11.5C16.25 10.5335 15.4665 9.75 14.5 9.75C13.5335 9.75 12.75 10.5335 12.75 11.5C12.75 12.4665 13.5335 13.25 14.5 13.25Z"
        fill={props.color || '#2ba168'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  any,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgControl = _style(SvgIcon)

export default SvgControl
