import { Patient } from 'src/modules/entities/patient/entity'
import { UserResponse, parse as parseUserResponse } from '../user/response'

export type PatientResponse = {
  user_code: string
} & UserResponse

export const parse = (resp: PatientResponse): Patient => {
  const res = {
    ...parseUserResponse(resp),
    userCode: resp.user_code,
  }

  return res
}
