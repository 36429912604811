import IconButton from '@bit/mui-org.material-ui.icon-button'
import GoogleMapReact, { ChangeEventValue } from 'google-map-react'
import React, {
  FC,
  useContext,
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react'
import ReactDOM from 'react-dom'
import {
  SnapList,
  SnapItem,
  useScroll,
  useVisibleElements,
} from 'react-snaplist-carousel'
import styled, { keyframes } from 'styled-components'
import { ReactComponent as ListImage } from 'src/assets/search/list.svg'
import { ReactComponent as MapNearArrow } from 'src/assets/search/map_near_arrow.svg'
import { ReactComponent as CurrentPositionPin } from 'src/assets/search/now_place.svg'
import { Params as PharmacySearchParams } from 'src/modules/apis/pharmacy/search/request'
import { Button } from 'src/modules/components/lib/button'
import { Clickable } from 'src/modules/components/lib/clickable'
import { ArrowLeft, SearchLeft } from 'src/modules/components/lib/curon-icon'
import { Input } from 'src/modules/components/lib/input'
import Reload from 'src/modules/components/lib/reload'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox, View } from 'src/modules/components/lib/view'
import { KeywordModal } from 'src/modules/components/net-application/modal-keyword'
import { TOKYO_STATION_POSITION } from 'src/modules/constants/tokyo_station_position'
import { Chain } from 'src/modules/entities/chain/entity'
import { Pharmacy } from 'src/modules/entities/pharmacy/entity'
import { useViewportHeight } from 'src/modules/hooks/useViewportHeight'
import { Spacer } from 'src/modules/screens/top/spacer'
import { GOOGLE_MAPS_API_KEY } from 'src/modules/server/const'
import { ModalContext, useModalStackDepth } from '../../lib/modal'
import { PharmacyCard } from './pharmacy-card'
import { PharmacyPin } from './pharmacy-pin'

type SearchMapContainerRootProps = {
  containerRootHeight: string
}

const SearchMapContainer: FC<
  React.HTMLAttributes<HTMLDivElement> & {
    visible: boolean
  }
> = ({ visible, ...props }) => {
  const context = useContext(ModalContext)
  const stackDepth = useModalStackDepth(visible)
  const vh = useViewportHeight()

  if (!context.root) {
    return null
  }

  return ReactDOM.createPortal(
    <SearchMapContainerRoot
      style={{ zIndex: stackDepth }}
      className={visible ? 'visible' : 'invisible'}
      containerRootHeight={`calc(${vh * 100}px)`}
      {...props}
    >
      {props.children}
    </SearchMapContainerRoot>,
    context.root,
  )
}

type Props = {
  visible: boolean
  onCloseClick: () => void
  pharmacySearchTrigger: (params: PharmacySearchParams) => void
  pharmacySearchData: Pharmacy[] | undefined
  pharmacySearchLoading: boolean
  setKeyword: (keyword: string) => void
  setChain: (chain: Chain | null) => void
  keyword: string | null
  chain: Chain | null
  currentPosition?: GeolocationPosition | null
  isGeoApproved: boolean
}

export const SearchMap: FC<Props> = ({
  onCloseClick,
  visible,
  pharmacySearchTrigger,
  pharmacySearchLoading,
  pharmacySearchData,
  setKeyword,
  setChain,
  keyword,
  chain,
  currentPosition,
  isGeoApproved,
}) => {
  const centerLocation = useRef<{ lat: number; lng: number }>()

  const PharmacyList = useMemo(
    () => pharmacySearchData || [],
    [pharmacySearchData],
  )

  const [map, setMap] = useState<any | null>(null)
  const [refetchButtonVisible, setRefetchButtonVisible] = useState(false)
  const [selectPharmacy, setSelectPharmacy] = useState<Pharmacy | null>(null)
  const [keywordModalVisible, setKeywordModalVisible] = useState(false)
  const snapList = useRef(null)
  const goToSnapItem = useScroll({ ref: snapList })
  const snapVisible = useVisibleElements(
    { debounce: 25, ref: snapList },
    ([element]) => element,
  )

  useEffect(() => {
    // 初回中心座標を現在地にする
    if (!map || !currentPosition || !isGeoApproved) return
    map.panTo({
      lat: currentPosition.coords.latitude,
      lng: currentPosition.coords.longitude,
    })
  }, [currentPosition, isGeoApproved, map])

  useEffect(() => {
    // snaplistが動いたときにmapの中心を選択したpharmacyにする
    if (!PharmacyList[snapVisible] || !map || !selectPharmacy) return
    map.panTo({
      lat: PharmacyList[snapVisible].lat,
      lng: PharmacyList[snapVisible].lng,
    })
    setSelectPharmacy(PharmacyList[snapVisible])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [snapVisible])

  const onMoveCenterPosition = useCallback((mapValue: ChangeEventValue) => {
    centerLocation.current = {
      lat: mapValue.center.lat,
      lng: mapValue.center.lng,
    }
  }, [])

  const onSearch = useCallback(
    ({ chain, keyword }: { keyword?: string | null; chain?: Chain | null }) => {
      setKeywordModalVisible(false)
      pharmacySearchTrigger({
        keyword: keyword || null,
        prefecture: null,
        area: null,
        station: null,
        currentLocation: true,
        lat: centerLocation.current
          ? centerLocation.current.lat
          : TOKYO_STATION_POSITION.lat,
        lng: centerLocation.current
          ? centerLocation.current.lng
          : TOKYO_STATION_POSITION.lng,
        chain: chain ? chain.code : null,
      })
    },
    [pharmacySearchTrigger, setKeywordModalVisible],
  )

  const onClickMarker = useCallback(
    async ({ pharmacy, index }: { pharmacy: Pharmacy; index: number }) => {
      if (!map) return
      setSelectPharmacy(pharmacy)
      await map.panTo({ lat: pharmacy.lat, lng: pharmacy.lng })
      // アニメーションはピンとピンが遠い場合他のピンにフォーカスする場合があるのでfalse
      goToSnapItem(index, { animationEnabled: false })
    },
    [goToSnapItem, map],
  )

  const onDragEndForMap = useCallback(() => {
    setSelectPharmacy(null)
    setRefetchButtonVisible(true)
  }, [])

  const onClickInputClear = useCallback(() => {
    setKeyword('')
    setChain(null)
    onSearch({ chain: null, keyword: null })
  }, [onSearch, setChain, setKeyword])

  const onClickCurrentPlace = useCallback(() => {
    if (!map || !currentPosition) return
    map.panTo({
      lat: currentPosition.coords.latitude,
      lng: currentPosition.coords.longitude,
    })
  }, [currentPosition, map])

  return (
    <>
      <SearchMapContainer visible={visible}>
        <Flexbox backgroundColor="#fff" padding="10px 16px" alignItems="center">
          <Clickable onClick={onCloseClick}>
            <Flexbox alignItems="center">
              <ArrowLeft color="green" width="16px" height="16px" />
            </Flexbox>
          </Clickable>
          <Spacer horizontal size={16} />
          <CustomInputRoot>
            <SearchLeft color="shuttleGray" className="icon" />
            <Input
              type="search"
              width="100%"
              placeholder="店舗・キーワードで探す"
              value={chain?.name || keyword || ''}
              readOnly
              onClick={() => setKeywordModalVisible(true)}
            />
            {(!!keyword || chain?.name) && (
              <ClearButton onClick={onClickInputClear} />
            )}
          </CustomInputRoot>
        </Flexbox>

        {refetchButtonVisible && (
          <Button
            shape="round"
            onClick={() => {
              onSearch({ chain, keyword })
            }}
            colorStyle="white"
            style={{
              width: '150px',
              position: 'absolute',
              top: '65px',
              zIndex: '99',
              left: '50%',
              transform: 'translateX(-50%)',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              fontSize: '14px',
            }}
          >
            {pharmacySearchLoading ? (
              <Flexbox alignItems="center">
                <Reload />
                <Spacer horizontal size={6} />
                <Text>読み込み中</Text>
              </Flexbox>
            ) : (
              'このエリアで再検索'
            )}
          </Button>
        )}

        {refetchButtonVisible && currentPosition && (
          <IconButton
            onClick={onClickCurrentPlace}
            style={{
              width: '44px',
              height: '44px',
              backgroundColor: '#fff',
              borderRadius: '50%',
              position: 'absolute',
              top: '70px',
              left: '20px',
              zIndex: '99',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <MapNearArrow />
          </IconButton>
        )}
        <View width="100%" height="100%">
          <GoogleMapReact
            defaultZoom={14}
            options={maps => ({
              gestureHandling: 'greedy',
              zoomControl: true,
              zoomControlOptions: {
                position: maps.ControlPosition.TOP_RIGHT,
              },
              fullscreenControl: false,
            })}
            onGoogleApiLoaded={({ map }) => {
              setMap(map)
            }}
            defaultCenter={{
              lat: TOKYO_STATION_POSITION.lat,
              lng: TOKYO_STATION_POSITION.lng,
            }}
            onChange={onMoveCenterPosition}
            onDragEnd={onDragEndForMap}
            onZoomAnimationStart={() => {
              setSelectPharmacy(null)
            }}
            bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
            yesIWantToUseGoogleMapApiInternals
          >
            {PharmacyList.map((pharmacy, index) => (
              <View
                key={pharmacy.uid}
                {...{ lat: pharmacy.lat, lng: pharmacy.lng }}
              >
                <PharmacyPin
                  pharmacy={pharmacy}
                  onClick={onClickMarker}
                  index={index}
                  selected={pharmacy.uid === selectPharmacy?.uid}
                />
              </View>
            ))}
            {currentPosition && (
              <View
                {...{
                  lat: currentPosition.coords.latitude,
                  lng: currentPosition.coords.longitude,
                }}
              >
                <CurrentPositionPin width="35px" height="35px" />
              </View>
            )}
          </GoogleMapReact>
          <Flexbox
            style={{
              position: 'sticky',
              bottom: selectPharmacy ? '180px' : '20px',
            }}
          >
            <Button
              style={{
                boxShadow:
                  '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)',
              }}
              margin="0 auto"
              backgroundColor="#F0FAF5"
              width="130px"
              height="32px"
              colorStyle="green-outlined"
              onClick={onCloseClick}
              zIndex={100}
            >
              <Flexbox alignItems="center">
                <ListImage />
                <Spacer size={4} horizontal />
                <Text fontSize="12px">一覧から探す</Text>
              </Flexbox>
            </Button>
          </Flexbox>
          <Flexbox
            overflow="scroll"
            style={{
              position: 'sticky',
              bottom: '15px',
              maxWidth: '1000px', //1000px以上のサイズにするとSnapListが機能しなくなってしまう
              margin: '0 auto',
              visibility: selectPharmacy ? 'visible' : 'hidden',
            }}
          >
            <SnapList ref={snapList} direction="horizontal">
              {PharmacyList.map((pharmacy, index) => (
                <SnapItem
                  margin={{
                    left: index === 0 ? '40vw' : '5px',
                    right: PharmacyList.length - 1 === index ? '40vw' : '5px',
                  }}
                  snapAlign="center"
                  key={pharmacy.uid}
                >
                  <PharmacyCard pharmacy={pharmacy} />
                </SnapItem>
              ))}
            </SnapList>
          </Flexbox>
        </View>
      </SearchMapContainer>
      <KeywordModal
        visible={keywordModalVisible}
        keyword={keyword || ''}
        setKeyword={setKeyword}
        setChain={setChain}
        onCloseClick={() => setKeywordModalVisible(false)}
        onSearch={onSearch}
      />
    </>
  )
}

const ToInvisibleKeyFrames = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
`

const ToVisibleKeyFrames = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`

const SearchMapContainerRoot = styled.div<SearchMapContainerRootProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.containerRootHeight};
  background: white;
  visibility: hidden;

  &.visible {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    animation: ${ToVisibleKeyFrames} 200ms ease-out 0s;
  }
  &.invisible {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    animation: ${ToInvisibleKeyFrames} 200ms ease-out 0s;
    animation-fill-mode: forwards;
  }
`

const CustomInputRoot = styled.div`
  position: relative;
  flex: 1;
  .icon {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }

  input {
    padding-left: 30px;
  }
`

const ClearButton = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  width: 20px;
  height: 20px;
  z-index: 1;
  display: block;
  margin-top: -0.5em;
  font-size: 1.5rem;
  line-height: 1;
  cursor: pointer;
  border: 2px solid #b0bac5;
  border-radius: 50%;
  background: #b0bac5;

  ::before,
  ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3px;
    height: 12px;
    background: white;
    z-index: 3;
  }

  ::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  :after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`
