import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { SWRConfig } from 'swr'
import { BlockerContainer } from './modules/components/lib/blocker'
import { ModalContainer } from './modules/components/lib/modal'
import { ToastContainer } from './modules/components/lib/toast'
import { FavoritePharmacyProvider } from './modules/favorite/provider'
import { GaProvider } from './modules/ga/ga-provider'
import { Router } from './modules/router'
import { store } from './modules/store'
import { AppThemeProvider } from './theme-provider'
const App: React.FC = () => {
  const swrOptions = {
    revalidateOnFocus: false,
  }

  return (
    <SWRConfig value={swrOptions}>
      <Provider store={store}>
        <GaProvider>
          <AppThemeProvider>
            <HelmetProvider>
              <ModalContainer>
                <ToastContainer>
                  <BlockerContainer>
                    <FavoritePharmacyProvider>
                      <Router />
                    </FavoritePharmacyProvider>
                  </BlockerContainer>
                </ToastContainer>
              </ModalContainer>
            </HelmetProvider>
          </AppThemeProvider>
        </GaProvider>
      </Provider>
    </SWRConfig>
  )
}

export default App
