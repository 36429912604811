import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M84.251 31.648v-14.19c0-.745-.286-1.433-.802-1.978a2.797 2.797 0 00-1.978-.803h-5.103v-2.35c0-.746-.287-1.434-.803-1.978a2.797 2.797 0 00-1.978-.803H31.648l-3.21-7.826c-.201-.516-.574-.946-1.033-1.261A2.96 2.96 0 0025.857 0H4.071c-.746 0-1.434.287-1.978.803A2.848 2.848 0 001.29 2.78v80.438c0 .746.287 1.434.803 1.978A2.797 2.797 0 004.07 86h77.858c.746 0 1.434-.287 1.978-.803a2.797 2.797 0 00.803-1.978V33.196a2.96 2.96 0 00-.459-1.548zm-5.561-11.41v10.177h-2.322V20.24h2.322zM15.193 30.302v-5.762h55.614V30.3H15.193zm55.614-15.194v3.899H15.193v-3.899h55.614zM23.994 5.561l1.634 3.985H12.413c-.746 0-1.434.287-1.978.803a2.797 2.797 0 00-.803 1.978v18.088h-2.78V5.561h17.142zM79.149 80.44H6.85V35.977H79.15v44.462zM55.355 58.222c0 .745-.286 1.433-.802 1.978a2.797 2.797 0 01-1.978.803H45.78v6.794c0 .745-.287 1.433-.803 1.978a2.797 2.797 0 01-1.978.802 2.848 2.848 0 01-1.978-.802 2.797 2.797 0 01-.803-1.978v-6.794h-6.794a2.848 2.848 0 01-1.978-.803 2.797 2.797 0 01-.802-1.978c0-.745.286-1.433.802-1.978a2.797 2.797 0 011.978-.803h6.794v-6.794c0-.745.287-1.433.803-1.978A2.797 2.797 0 0143 45.867c.745 0 1.433.286 1.978.802.516.516.803 1.233.803 1.978v6.794h6.794c.745 0 1.433.287 1.978.803.516.516.802 1.233.802 1.978z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgFolderAdd = _style(SvgIcon)

export default SvgFolderAdd
