import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { Chain } from 'src/modules/entities/chain/entity'
import { Area, Pharmacy, Station } from 'src/modules/entities/pharmacy/entity'
import { routes } from 'src/modules/routes'
import {
  ALL_PREFECTURES,
  AROUND_CURRENT_LOCATION,
  Prefecture,
  PREFECTURE_LIST,
} from '../../../constants/prefecture-list'

export type PageType =
  | 'search'
  | 'search_near'
  | 'search_list'
  | 'search_pref'
  | 'search_area'
  | 'search_station'
  | 'search_chain'
  | 'search_chain_list'
  | 'search_chain_pref'
  | 'search_chain_area'
  | 'search_chain_station'

export const usePageType = (): PageType => {
  const matchRoute = useRouteMatch()

  switch (matchRoute.path) {
    case routes.search.path:
      return 'search'
    case routes.searchList.path:
      return 'search_list'
    case routes.searchNear.path:
      return 'search_near'
    case routes.searchPref.path:
      return 'search_pref'
    case routes.searchPrefArea.path:
      return 'search_area'
    case routes.searchPrefStation.path:
      return 'search_station'
    case routes.searchChain.path:
      return 'search_chain'
    case routes.searchChainList.path:
      return 'search_chain_list'
    case routes.searchChainPref.path:
      return 'search_chain_pref'
    case routes.searchChainPrefArea.path:
      return 'search_chain_area'
    case routes.searchChainPrefStation.path:
      return 'search_chain_station'
    default:
      return 'search'
  }
}
export const selectAreaModalMode = (pageType: PageType) => {
  switch (pageType) {
    case 'search':
    case 'search_near':
    case 'search_list':
      return 'all'
    case 'search_chain_pref':
    case 'search_pref':
      return 'prefecture'
    case 'search_chain_area':
    case 'search_area':
      return 'area'
    case 'search_chain_station':
    case 'search_station':
      return 'station'
    default:
      return 'all'
  }
}

export const useDeprecatedSearchQueryGuard = () => {
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)
  const prefValue = searchParams.get('pref')
  const prefObject = PREFECTURE_LIST.find(p => p.value === prefValue)
  const prefecture = prefObject ? prefObject : null
  const currentLocation =
    searchParams.get('current_location') === 'true' ? true : null
  const isPrefectureAll =
    prefValue === 'all' || location.pathname === '/search/pref/all'
      ? true
      : null

  const redirectPath = () => {
    if (isPrefectureAll) return routes.searchList.build()
    if (currentLocation) return routes.searchNear.build()
    if (prefecture) return routes.searchPref.build(prefecture.value)
    return null
  }

  if (searchParams.get('page') === '1') {
    searchParams.delete('page')
    const newUrl = !!searchParams.toString()
      ? `${location.pathname}?${searchParams.toString()}`
      : `${location.pathname}`
    window.location.replace(newUrl)
  }

  const pathname = redirectPath()

  if (!!pathname)
    history.replace({
      pathname,
    })
}

export const useDeprecatedPageQueryGuard = () => {
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)

  if (searchParams.get('page')) {
    searchParams.delete('page')
    const newUrl = !!searchParams.toString()
      ? `${location.pathname}?${searchParams.toString()}`
      : `${location.pathname}`

    history.replace({
      pathname: newUrl,
    })
  }
}

export const useSearchTitle = ({
  pageType,
  area,
  chain,
  keyword,
  prefecture,
  station,
  page,
}: {
  pageType: PageType
  keyword: string | null
  prefecture: Prefecture | null
  area: Area | null
  station: Station | null
  chain: Chain | null
  page: number | null
}) => {
  const title = () => {
    switch (pageType) {
      case 'search_list':
        if (keyword) {
          return `全国にある${keyword}の検索結果`
        } else {
          return '全国の薬局一覧'
        }
      case 'search_pref':
        if (prefecture && keyword) {
          return `${prefecture.name}にある${keyword}の検索結果`
        } else {
          return prefecture ? `${prefecture.name}の薬局一覧` : '薬局一覧'
        }
      case 'search_area':
        if (area && keyword) {
          return `${area.prefecture}${area.name}にある${keyword}の検索結果`
        } else {
          return area ? `${area.prefecture}${area.name}の薬局一覧` : '薬局一覧'
        }
      case 'search_station':
        if (station && keyword) {
          return `${station.name}駅周辺にある${keyword}の検索結果`
        } else {
          return station ? `${station.name}駅周辺の薬局一覧` : '薬局一覧'
        }
      case 'search_near':
        return `近くの薬局一覧`
      case 'search':
        return '薬局一覧'
      case 'search_chain_list':
        return chain ? `全国の${chain.name}の店舗一覧` : '薬局一覧'
      case 'search_chain_pref':
        return prefecture && chain
          ? `${prefecture.name}の${chain.name}の店舗一覧`
          : '薬局一覧'
      case 'search_chain_area':
        return area && chain
          ? `${area.prefecture}${area.name}の${chain.name}の店舗一覧`
          : '薬局一覧'
      case 'search_chain_station':
        return station && chain
          ? `${station.name}駅周辺の${chain.name}の店舗一覧`
          : '薬局一覧'
      default:
        return '全国の薬局一覧'
    }
  }

  return page ? `${title()}(${page}ページ目)` : title()
}

type SearchMeta = {
  pageType: PageType
  pharmaciesCount?: number
  keyword?: string | null
  prefecture?: Prefecture | null
  area?: Area | null
  station?: Station | null
  chain?: Chain | null
  page?: number | null
}

const addPageInfo = ({
  page,
  title,
}: {
  title: string
  page?: number | null
}) => {
  return !page || page === 1
    ? `${title}｜curon (クロン)お薬サポート`
    : `${title}(${page}ページ目)｜curon (クロン)お薬サポート`
}

export const useSearchMeta = ({
  pageType,
  pharmaciesCount,
  prefecture,
  area,
  station,
  chain,
  page,
}: // conditions: string[],
SearchMeta) => {
  switch (pageType) {
    case 'search_list':
      return {
        title: addPageInfo({
          title: `全国の薬局・ドラッグストアを探す【${pharmaciesCount}件】`,
          page,
        }),
        description: `ネット処方箋受付でお薬受け取りの待ち時間を短縮！全国の薬局・ドラッグストア${pharmaciesCount}件から、地図で探したり駅名や都道府県などお好みの条件で検索することができます！深夜・24時間営業している店舗や日曜・祝日営業している店舗で好きな時間に好きな薬局でおくすりを受け取れます。`,
      }
    case 'search_near':
      return {
        title:
          '近くの薬局・ドラッグストアを地図から探す｜curon (クロン)お薬サポート',
        description: `近くの薬局・ドラッグストアを地図と現在地の距離から簡単に探せます！深夜・24時間営業している店舗や日曜・祝日営業している店舗で好きな時間に好きな薬局でおくすりを受け取れ、ネット処方箋受付でスマホから処方箋を送ってお薬受け取り待ち時間短縮！`,
      }
    case 'search_pref':
      return {
        title: prefecture
          ? addPageInfo({
              title: `${prefecture.name}の薬局・ドラッグストアを探す【${pharmaciesCount}件】`,
              page,
            })
          : '全国の薬局・ドラッグストアを探す｜curon (クロン)お薬サポート',
        description: prefecture
          ? `${prefecture.name}の薬局・ドラッグストア${pharmaciesCount}件から、地図で探したり駅名や都道府県などお好みの条件で検索することができます。深夜・24時間営業している店舗や日曜・祝日営業している店舗で好きな時間に好きな薬局でおくすりを受け取れ、ネット処方箋受付でスマホから処方箋を送ってお薬受け取り待ち時間短縮！`
          : `全国の薬局・ドラッグストア${pharmaciesCount}件から、ネット処方箋受付できる、深夜・24時間営業している店舗や日曜・祝日営業している店舗など様々な条件で薬局・ドラッグストアを探せます。`,
      }
    case 'search_area':
      return {
        title:
          prefecture && area
            ? addPageInfo({
                title: `${prefecture.name}${area.name}の薬局・ドラッグストアを探す【${pharmaciesCount}件】`,
                page,
              })
            : '薬局を探す｜curon (クロン)お薬サポート',
        description:
          prefecture && area
            ? `${prefecture.name}${area.name}の薬局・ドラッグストア${pharmaciesCount}件から、地図で探したり駅名や都道府県などお好みの条件で検索することができます。深夜・24時間営業している店舗や日曜・祝日営業している店舗で好きな時間に好きな薬局でおくすりを受け取れ、ネット処方箋受付でスマホから処方箋を送ってお薬受け取り待ち時間短縮！`
            : ``,
      }
    case 'search_station':
      return {
        title:
          prefecture && station
            ? addPageInfo({
                title: `${station.name}駅周辺の薬局・ドラッグストアを探す【${pharmaciesCount}件】｜curon (クロン)お薬サポート`,
                page,
              })
            : '薬局を探す｜curon (クロン)お薬サポート',
        description:
          prefecture && station
            ? `${station.name}駅（${station.line}）周辺の薬局・ドラッグストア${pharmaciesCount}件から、地図で探したり都道府県などお好みの条件で検索することができます。深夜・24時間営業している店舗や日曜・祝日営業している店舗で好きな時間に好きな薬局でおくすりを受け取れ、ネット処方箋受付でスマホから処方箋を送ってお薬受け取り待ち時間短縮！`
            : ``,
      }
    case 'search_chain_list':
      return {
        title: chain
          ? addPageInfo({
              title: `全国の${chain.name}の店舗検索【${pharmaciesCount}件】`,
              page,
            })
          : '薬局を探す｜curon (クロン)お薬サポート',
        description: chain
          ? `全国の${chain.name}の店舗${pharmaciesCount}件から、地図で探したり駅名や都道府県などお好みの条件で検索することができます。深夜・24時間営業している店舗や日曜・祝日営業している店舗で好きな時間に好きな薬局でおくすりを受け取れ、ネット処方箋受付でスマホから処方箋を送ってお薬受け取り待ち時間短縮！`
          : '',
      }
    case 'search_chain':
      return {
        title: chain
          ? addPageInfo({
              title: `${chain.name}の店舗検索【${pharmaciesCount}件】`,
              page,
            })
          : '薬局を探す｜curon (クロン)お薬サポート',
        description: chain
          ? `${chain.name}の店舗${pharmaciesCount}件から、地図で探したり駅名や都道府県などお好みの条件で検索することができます。深夜・24時間営業している店舗や日曜・祝日営業している店舗で好きな時間に好きな薬局でおくすりを受け取れ、ネット処方箋受付でスマホから処方箋を送ってお薬受け取り待ち時間短縮！`
          : '',
      }
    case 'search_chain_pref':
      return {
        title:
          chain && prefecture
            ? addPageInfo({
                title: `${prefecture.name}の${chain.name}の店舗一覧【${pharmaciesCount}件】`,
                page,
              })
            : '薬局を探す｜curon (クロン)お薬サポート',
        description:
          chain && prefecture
            ? `${prefecture.name}の${chain.name}の店舗${pharmaciesCount}件から、地図で探したり駅名や都道府県などお好みの条件で検索することができます。深夜・24時間営業している店舗や日曜・祝日営業している店舗で好きな時間に好きな薬局でおくすりを受け取れ、ネット処方箋受付でスマホから処方箋を送ってお薬受け取り待ち時間短縮！`
            : '',
      }
    case 'search_chain_area':
      return {
        title:
          chain && prefecture && area
            ? addPageInfo({
                title: `${prefecture.name}${area.name}の${chain.name}の店舗一覧【${pharmaciesCount}件】`,
                page,
              })
            : '薬局を探す｜curon (クロン)お薬サポート',
        description:
          chain && prefecture && area
            ? `${prefecture.name}${area.name}の${chain.name}の店舗${pharmaciesCount}件から、地図で探したり駅名や都道府県などお好みの条件で検索することができます。深夜・24時間営業している店舗や日曜・祝日営業している店舗で好きな時間に好きな薬局でおくすりを受け取れ、ネット処方箋受付でスマホから処方箋を送ってお薬受け取り待ち時間短縮！`
            : '',
      }
    case 'search_chain_station':
      return {
        title:
          chain && prefecture && station
            ? addPageInfo({
                title: `${station.name}駅の${chain.name}の店舗一覧【${pharmaciesCount}件】`,
                page,
              })
            : '薬局を探す｜curon (クロン)お薬サポート',
        description:
          chain && prefecture && station
            ? `${station.name}駅（${station.line}）周辺の${chain.name}の店舗${pharmaciesCount}件から、地図で探したり都道府県などお好みの条件で検索することができます。深夜・24時間営業している店舗や日曜・祝日営業している店舗で好きな時間に好きな薬局でおくすりを受け取れ、ネット処方箋受付でスマホから処方箋を送ってお薬受け取り待ち時間短縮！`
            : '',
      }
    default:
      return {
        title: '全国の薬局・ドラッグストアを探す｜curon (クロン)お薬サポート',
        description: `全国の薬局・ドラッグストア${pharmaciesCount}件から、ネット処方箋受付できる、深夜・24時間営業している店舗や日曜・祝日営業している店舗など様々な条件で薬局・ドラッグストアを探せます。`,
      }
  }
}

export const useCanonicalUrl = ({
  area,
  chain,
  page,
  pageType,
  prefecture,
  station,
}: {
  pageType: PageType
  prefecture: Prefecture | null
  area: Area | null
  station: Station | null
  page: number
  chain: Chain | null
}) =>
  // conditions: string[],
  {
    // 1条件の場合のみ rel="canonical" のURLにクエリも入れる
    // const query = conditions.length === 1 ? `?${conditions.join('')}` : ''
    const query = !!page && page !== 1 ? `?page=${page}` : ''
    switch (pageType) {
      case 'search_list':
        return `https://pha.curon.co${routes.searchList.build()}${query}`
      case 'search_pref':
        return prefecture
          ? `https://pha.curon.co${routes.searchPref.build(
              prefecture.value,
            )}${query}`
          : ''
      case 'search_area':
        return prefecture && area
          ? `https://pha.curon.co${routes.searchPrefArea.build(
              prefecture.value,
              area.slug,
            )}${query}`
          : ''
      case 'search_station':
        return prefecture && station
          ? `https://pha.curon.co${routes.searchPrefStation.build(
              prefecture.value,
              station.code,
            )}${query}`
          : ''
      case 'search':
        return `https://pha.curon.co${routes.search.build()}${query}`
      case 'search_chain_list':
        return chain
          ? `https://pha.curon.co${routes.searchChainList.build(
              chain.code,
            )}${query}`
          : ''
      case 'search_chain_pref':
        return prefecture && chain
          ? `https://pha.curon.co${routes.searchChainPref.build(
              prefecture.value,
              chain.code,
            )}${query}`
          : ''
      case 'search_chain_area':
        return prefecture && area && chain
          ? `https://pha.curon.co${routes.searchChainPrefArea.build(
              prefecture.value,
              area.slug,
              chain.code,
            )}${query}`
          : ''
      case 'search_chain_station':
        return chain && prefecture && station
          ? `https://pha.curon.co${routes.searchChainPrefStation.build(
              prefecture.value,
              station.code,
              chain.code,
            )}${query}`
          : ''
      default:
        return ''
    }
  }

export const useMetaRobots = ({
  pharmacies,
  keyword,
  pageType,
}: {
  pharmacies: Pharmacy[]
  keyword: string | null
  pageType: PageType
}) => {
  // 薬局が存在する場合のみindex，薬局の検索結果がない場合やキーワード条件がある場合は全てnoindex
  return (pharmacies.length < 1 || !!keyword) && pageType !== 'search_near'
    ? 'noindex,nofollow'
    : 'index,follow'
}

export const useSelectedPrefecture = (
  prefecture: Prefecture | null,
  isNear: boolean,
) => {
  if (prefecture) {
    return prefecture
  } else {
    if (isNear) {
      return AROUND_CURRENT_LOCATION
    }
  }

  return ALL_PREFECTURES
}
