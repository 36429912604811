import { connectRouter, RouterState } from 'connected-react-router'
import { History } from 'history'
import { combineReducers } from 'redux'
import { reducer as authReducer, State as AuthState } from './auth/redux'

export type RootState = {
  router: RouterState
  auth: AuthState
}

// NOTE: 独立している reducer の構造はディレクトリの構造に合わせず、できるだけトップレベルにフラットに置く形を取ることとする。
export const rootReducer = (history: History) =>
  combineReducers<RootState>({
    router: connectRouter(history),
    auth: authReducer,
  })
