import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.243 42.054c-9.202 0-16.684-7.51-16.684-16.713 0-9.202 7.482-16.74 16.684-16.74 9.202 0 16.684 7.51 16.684 16.712s-7.482 16.741-16.684 16.741zm0-29.584c-2.522 0-5.016.745-7.109 2.179-2.093 1.433-3.755 3.411-4.73 5.762a12.665 12.665 0 00-.717 7.424 12.776 12.776 0 003.498 6.565 12.934 12.934 0 006.564 3.526 12.79 12.79 0 007.396-.745 12.891 12.891 0 005.762-4.73 12.886 12.886 0 002.15-7.138c0-3.412-1.347-6.68-3.755-9.088a13.022 13.022 0 00-9.059-3.755zM58.566 77.4H1.949a1.93 1.93 0 01-1.376-.573A1.93 1.93 0 010 75.45V55.728c0-3.21 1.29-6.278 3.526-8.543a12.088 12.088 0 018.543-3.554c.2 0 .401.028.573.086.086.028 8.8 2.752 17.2 2.752 8.83 0 17.888-2.724 17.974-2.752.172-.058.373-.086.573-.086 3.211 0 6.278 1.29 8.543 3.554a12.06 12.06 0 013.526 8.543v19.723c0 .516-.2 1.003-.573 1.376-.316.372-.832.573-1.319.573zM3.87 73.53h52.747V55.757a8.167 8.167 0 00-2.322-5.705c-1.491-1.52-3.498-2.437-5.619-2.494-1.72.487-10.234 2.838-18.805 2.838-8.199 0-16.369-2.322-18.089-2.838a8.338 8.338 0 00-5.619 2.494c-1.49 1.52-2.293 3.583-2.322 5.705L3.87 73.53zM49.966 60.66l-4.759 4.758-4.73-4.758a3.362 3.362 0 01-.974-2.38c0-.888.344-1.748.974-2.379a3.477 3.477 0 012.351-1.032 3.309 3.309 0 012.38.917c.659-.602 1.519-.946 2.379-.917.888.029 1.72.373 2.35 1.032.631.63.975 1.49.975 2.38a3.22 3.22 0 01-.946 2.379zm35.46-19.035a1.93 1.93 0 00-1.375-.573h-8.199v-8.228c0-.516-.2-1.003-.573-1.376a1.93 1.93 0 00-1.376-.573c-.516 0-1.004.2-1.376.573a1.93 1.93 0 00-.574 1.376v8.228h-8.198c-.516 0-1.004.2-1.376.573A1.93 1.93 0 0061.805 43c0 .516.201 1.003.574 1.376.372.373.86.573 1.376.573h8.198v8.228c0 .516.201 1.003.574 1.376.372.372.86.573 1.376.573.516 0 1.003-.2 1.376-.573a1.93 1.93 0 00.573-1.376v-8.228h8.199c.516 0 1.003-.2 1.376-.573A1.93 1.93 0 0086 43c0-.516-.2-1.003-.573-1.376z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgPatientAdd = _style(SvgIcon)

export default SvgPatientAdd
