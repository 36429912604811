import css from '@styled-system/css'
import React, { FC } from 'react'
import styled from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

type StyledSystemProps = TypographyProps & SpaceProps & LayoutProps & ColorProps

const styledSystemProps = [typography, space, layout, color]

type FooterProps = StyledSystemProps & React.HTMLAttributes<HTMLDivElement>

const footerBaseCss = css({
  width: '100%',
  fontSize: 'small',
  lineHeight: '18px',
  textAlign: 'center',
  color: 'shuttleGray',
})

const FooterRoot = styled('footer')<StyledSystemProps>(
  footerBaseCss,
  styledSystemProps,
)

export const Footer: FC<FooterProps> = props => {
  return <FooterRoot {...props}>&copy; MICIN, Inc.</FooterRoot>
}
