import { QuestionAnswer } from 'src/modules/entities/question-answer/entity'
import { QuestionType } from 'src/modules/entities/question/entity'

export type QuestionAnswerResponse = {
  uid: string
  description: string
  questionnaire_answer_uid: string
  value: string
  expected_values: string[]
  question_type: QuestionType
  question_uid: string
  required: boolean
  image: string
}

export const parse = (resp: QuestionAnswerResponse): QuestionAnswer => {
  return {
    uid: resp.uid,
    description: resp.description,
    questionnaireAnswerUid: resp.questionnaire_answer_uid,
    value: resp.value,
    expectedValues: resp.expected_values,
    questionType: resp.question_type,
    questionUid: resp.question_uid,
    required: resp.required,
    image: resp.image,
  }
}
