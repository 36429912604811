import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { PatientAccount } from 'src/modules/entities/patient-account/entity'

const create = actionCreatorFactory('auth')

export const actions = {
  reset: create('RESET'),
  authorize: create<{
    account: PatientAccount
  }>('AUTHORIZE'),
  unauthorize: create('UNAUTHORIZE'),
}

export type State = {
  account: PatientAccount | null
  authorized: boolean
}

const initialState: State = {
  account: null,
  authorized: false,
}

export const reducer = reducerWithInitialState(initialState)
  .case(actions.reset, state => ({
    ...state,
    account: null,
    authorized: false,
  }))
  .case(actions.authorize, (state, payload) => ({
    ...state,
    account: payload.account,
    authorized: true,
  }))
  .case(actions.unauthorize, state => ({
    ...state,
    account: null,
    authorized: false,
  }))
  .build()
