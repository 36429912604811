import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M58.05 53.177a3.478 3.478 0 010 4.902 3.428 3.428 0 01-2.437 1.003c-.86 0-1.777-.344-2.436-1.003L43 47.902 32.823 58.079a3.428 3.428 0 01-2.436 1.003 3.451 3.451 0 01-2.437-1.003 3.478 3.478 0 010-4.902L38.127 43 27.95 32.823a3.478 3.478 0 010-4.902 3.478 3.478 0 014.902 0l10.177 10.177 10.176-10.177a3.478 3.478 0 014.902 0 3.478 3.478 0 010 4.902L47.902 43 58.05 53.177zM86 43C86 19.293 66.707 0 43 0S0 19.293 0 43s19.293 43 43 43 43-19.293 43-43zm-6.937 0c0 19.895-16.197 36.063-36.063 36.063C23.105 79.063 6.937 62.866 6.937 43 6.937 23.105 23.105 6.937 43 6.937c19.895 0 36.063 16.168 36.063 36.063z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgCancel = _style(SvgIcon)

export default SvgCancel
