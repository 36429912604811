import React, { FC, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'

import { PublicPageHeader } from 'src/modules/components/header/public-page-header'

import { Button } from 'src/modules/components/lib/button'
import {
  Location as LocationIcon,
  SearchLeft,
} from 'src/modules/components/lib/curon-icon'
import { Input } from 'src/modules/components/lib/input'
import { Loader } from 'src/modules/components/lib/loader'
import { Screen } from 'src/modules/components/lib/screen'
import { Flexbox, View } from 'src/modules/components/lib/view'
import { MutualInduction } from 'src/modules/components/mutualInduction/mutualInduction'
import { MedicinePickupSelectDayRadio } from 'src/modules/components/net-application/medicine-pickup-select-day-radio'
import { AreaModal } from 'src/modules/components/net-application/modal-area/modal-area'
import { KeywordModal } from 'src/modules/components/net-application/modal-keyword'
import { PharmacyList } from 'src/modules/components/net-application/pharmacy-list'
import { SearchNavigation } from 'src/modules/components/net-application/search-navigation'
import { SearchPagination } from 'src/modules/components/net-application/search-pagination'
import { SearchTitleHeader } from 'src/modules/components/net-application/search-title-header'
import {
  ALL_PREFECTURES,
  Prefecture,
} from 'src/modules/constants/prefecture-list'
import { Chain } from 'src/modules/entities/chain/entity'
import { Area, Pharmacy, Station } from 'src/modules/entities/pharmacy/entity'
import { getSearchPagePath } from 'src/modules/helpers/get-serch-page-path'
import {
  PageType,
  selectAreaModalMode,
} from 'src/modules/screens/net-application/search/helper'

import { Footer } from '../../top/footer'

type Props = {
  keyword: string | null
  prefecture: Prefecture
  area: Area | null
  station: Station | null
  chain: Chain | null
  selectedDay: string
  // conditions: string[]
  pharmacies: Pharmacy[] | []
  pageType: PageType
  page: number
  totalPage: number
  totalCount: number
  searchTitle: string
  fetched: boolean
  authorized: boolean
  loading: boolean
  areaModalVisible: boolean
  setAreaModalVisible: (visible: boolean) => void
  keywordModalVisible: boolean
  setKeywordModalVisible: (visible: boolean) => void
}

export const NetApplicationSearchScreen: FC<Props> = props => {
  const {
    keyword,
    prefecture,
    area,
    station,
    chain,
    selectedDay,
    // conditions,
    pharmacies,
    pageType,
    page,
    totalPage,
    totalCount,
    searchTitle,
    fetched,
    authorized,
    loading,
    areaModalVisible,
    setAreaModalVisible,
    keywordModalVisible,
    setKeywordModalVisible,
  } = props

  const [inputKeyword, setInputKeyword] = useState(keyword || '')
  const [inputChain, setInputChain] = useState(chain)
  // const [conditionModalVisible, setConditionModalVisible] = useState(false)
  // const [searchConditions, setSearchConditions] = useState<string[]>(conditions)
  const location = useLocation()

  const defaultMode = selectAreaModalMode(pageType)

  const history = useHistory()

  const keywordAreaRefreshPath = getSearchPagePath({
    chainCode: undefined,
    keyword: undefined,
    prefecture: prefecture?.value,
    areaSlug: area?.slug,
    stationCode: station?.code,
    selectedDay,
  })

  const getDisplayCount = () => {
    if (pharmacies.length === 0) {
      return `(0件 / 全0件)`
    }
    const start = (page - 1) * 25 + 1
    const end = pharmacies.length === 25 ? page * 25 : totalCount

    return `(${start}〜${end}件 / 全${totalCount}件)`
  }

  return (
    <View minHeight="100%">
      <PublicPageHeader
        fetched={fetched}
        authorized={authorized}
        displayMutualInduction
      />
      <MutualInduction />
      <Screen>
        <SearchNavigation
          pageType={pageType}
          keyword={keyword}
          prefecture={prefecture}
          area={area}
          station={station}
          chain={chain}
          displayMutualInduction
        />
        <View
          backgroundColor="appBackground"
          px="16px"
          marginTop="48px"
          height="100%"
        >
          <SearchTitleHeader searchTitle={searchTitle} />

          <Flexbox flexDirection="column" alignItems="stretch" width="100%">
            <View width="100%" height="100%">
              <CustomInputRoot>
                <SearchLeft color="shuttleGray" className="icon" />
                <Input
                  type="search"
                  width="100%"
                  placeholder="店舗・キーワードで探す"
                  value={inputChain?.name || chain?.name || inputKeyword}
                  readOnly
                  onClick={() => setKeywordModalVisible(true)}
                />
                {(!!inputKeyword || chain?.name) && (
                  <ClearButton
                    onClick={() => {
                      if (inputKeyword) {
                        setInputKeyword('')
                      } else {
                        setInputChain(null)
                      }
                      history.push(keywordAreaRefreshPath)
                    }}
                  />
                )}
              </CustomInputRoot>

              <CustomInputRoot>
                <LocationIcon color="shuttleGray" className="icon" />
                <Input
                  type="text"
                  width="100%"
                  placeholder="エリアから探す"
                  value={(() => {
                    switch (defaultMode) {
                      case 'all':
                        return ''
                      case 'prefecture':
                        return prefecture.id === ALL_PREFECTURES.id
                          ? ''
                          : prefecture.name
                      case 'area':
                        return area ? `${area.prefecture}${area.name}` : ''
                      case 'station':
                        return station
                          ? `${station.line} ${station.name}駅`
                          : ''
                      default:
                        return ''
                    }
                  })()}
                  readOnly
                  onClick={() => setAreaModalVisible(true)}
                />
              </CustomInputRoot>
              {(chain || station || area || prefecture) && (
                <MedicinePickupSelectDayRadio />
              )}
              {!loading && (
                <SearchSummaryBox>
                  <SearchSummary>検索結果{getDisplayCount()}</SearchSummary>
                  {/* <FilterConditionButton
                  onClick={() => setConditionModalVisible(true)}
                >
                  <Control />
                  こだわり条件
                {searchConditions.length > 0 ? <ConditionBadge /> : null}
                </FilterConditionButton> */}
                </SearchSummaryBox>
              )}
            </View>
          </Flexbox>
        </View>
        {/* {searchConditions.length > 0 && (
        <View m={4}>
          <Flexbox flexWrap="wrap">
            {searchConditions
              .map(condition =>
                PHARMACY_SERVICE_LIST.find(s => s.value === condition),
              )
              .map(
                condition =>
                  condition && (
                    <ConditionTag
                      key={condition.value}
                      onClick={() => {
                        const newConditions = searchConditions.filter(
                          c => c !== condition.value,
                        )
                        setSearchConditions(newConditions)
                        onSearch(
                          pageType,
                          prefecture.value,
                          area ? area.slug : null,
                          station ? station.code : null,
                          newConditions,
                          inputKeyword,
                        )
                      }}
                    >
                      {condition.label}
                      <Close2 size="11px" color="#000" />
                    </ConditionTag>
                  ),
              )}
          </Flexbox>
        </View>
      )} */}
        {loading ? (
          <Loader />
        ) : (
          <>
            <PharmacyList authorized={authorized} pharmacies={pharmacies} />
            <SearchPagination
              current={page}
              max={totalPage}
              path={location.pathname}
              params={new URLSearchParams(location.search)}
            />
          </>
        )}
        {/* <View mt={4} pt={5} pb={5} pl={4} pr={4} bg="#fff">
        <View pb={2} borderBottom="1px solid #ECF0F5">
          <Text fontWeight="bold" fontSize="16px">
            他の条件で探す
          </Text>
        </View>
        
        <View>
          <View pt={3} pb={3}>
            <Text fontWeight="bold" fontSize="14px" color="#637182">
              市区町村から探す
            </Text>
          </View>
          <Flexbox>
            {areaList.slice(0, 5).map(area => (
              <CategoryLink
                key={area.name}
                to={routes.searchPrefArea.build(area.slug)}
              >
                {area.name}
              </CategoryLink>
            ))}
          </Flexbox>
        </View>
        <View>
          <View pt={3} pb={3}>
            <Text fontWeight="bold" fontSize="14px" color="#637182">
              駅から探す
            </Text>
          </View>
          <Flexbox>
            {stationList.slice(0, 5).map(station => (
              <CategoryLink
                key={station.name}
                to={routes.searchPrefArea.build(station.code)}
              >
                {station.name}
              </CategoryLink>
            ))}
          </Flexbox>
        </View>
       
        <View>
          <View pt={3} pb={3}>
            <Text fontWeight="bold" fontSize="14px" color="#637182">
              こだわり条件で探す
            </Text>
          </View>
          <Flexbox flexWrap="wrap" style={{ rowGap: '12px' }}>
            {PHARMACY_SERVICE_LIST.slice(0, 4).map(service => (
              <CategoryLink
                key={service.label}
                to={`${location.pathname}?conditions=${service.value}`}
              >
                {service.label}
              </CategoryLink>
            ))}
          </Flexbox>
        </View>
      </View>
      <ConditionModal
        visible={conditionModalVisible}
        defaultConditions={searchConditions}
        onCloseClick={() => setConditionModalVisible(false)}
        onSearch={selectedList => {
          setSearchConditions(selectedList)
          onSearch(
            pageType,
            prefecture.value,
            area ? area.slug : '',
            station ? station.code : '',
            selectedList,
            inputKeyword,
          )
        }}
      /> */}

        <AreaModal
          defaultMode={defaultMode}
          prefecture={prefecture}
          visible={areaModalVisible}
          onCloseClick={() => setAreaModalVisible(false)}
        />

        <KeywordModal
          visible={keywordModalVisible}
          keyword={inputKeyword}
          setKeyword={setInputKeyword}
          setChain={setInputChain}
          onCloseClick={() => setKeywordModalVisible(false)}
        />

        <Footer />
      </Screen>
    </View>
  )
}

// const CategoryLink: FC<{ to: string } & ViewProps> = props => {
//   const { to, children } = props
//   return (
//     <Link
//       to={to}
//       mr={4}
//       fontWeight="300"
//       style={{ textDecorationLine: 'underline' }}
//     >
//       {children}
//     </Link>
//   )
// }

const CustomInputRoot = styled.div`
  position: relative;
  margin-bottom: 8px;

  .icon {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }

  input {
    padding-left: 30px;
  }
`
const SearchSummaryBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding: 10px 0;
  height: 44px;
`

const SearchSummary = styled.div`
  font-size: 16px;
  font-weight: 600;
`

const ClearButton = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  width: 20px;
  height: 20px;
  z-index: 1;
  display: block;
  margin-top: -0.5em;
  font-size: 1.5rem;
  line-height: 1;
  cursor: pointer;
  border: 2px solid #b0bac5;
  border-radius: 50%;
  background: #b0bac5;

  ::before,
  ::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 3px;
    height: 12px;
    background: white;
    z-index: 3;
  }

  ::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  :after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  // ::after {
  //   display: block;
  //   color: rgb(176, 186, 197);
  //   border-radius: 50%;
  //   -webkit-font-smoothing: antialiased;
  //   font-style: normal;
  //   font-weight: normal;
  //   font-variant: normal;
  //   text-transform: none;
  // }
`

const LocationButton = styled(Button)`
  width: 144px;
  height: 30px;
  font-weight: 700;
  font-size: 12px;
  padding: 6px;

  @media (max-width: 320px) {
    width: 190px;
    height: 34px;
  }

  @media (min-width: 768px) {
    width: 90px;
    height: 34px;
  }
`

// const FilterConditionButton = styled.div`
//   display: inline-flex;
//   position: relative;
//   align-items: center;
//   padding: 16px 12px;
//   height: 44px;
//   column-gap: 10px;
//   background: #fff;
//   border: 1px solid #fff;
//   box-shadow: 0px 2px 7px rgba(111, 111, 111, 0.15);
//   border-radius: 4px;
//   font-size: 12px;
//   font-weight: 600;
//   color: #2ba168;
//   cursor: pointer;
// `

// const ConditionBadge = styled.div`
//   position: absolute;
//   top: 6px;
//   right: 6px;
//   height: 8px;
//   width: 8px;
//   border-radius: 4px;
//   background: #e33b24;
// `

// const ConditionTag = styled.div`
//   display: flex;
//   column-gap: 8px;
//   align-items: center;
//   margin: 4px 8px 4px 0;
//   height: 32px;
//   padding: 12px;
//   background-color: #fff;
//   border: 1px solid #75879c;
//   border-radius: 16px;
//   font-weight: 600;
//   font-size: 12px;
//   color: #272c33;
// `
