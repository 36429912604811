import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43.894 25.083c9.89 0 17.916 8.027 17.916 17.917 0 2.33-.466 4.515-1.29 6.557l10.464 10.464A42.345 42.345 0 0083.274 43c-6.199-15.73-21.5-26.875-39.416-26.875-5.017 0-9.819.896-14.262 2.508l7.74 7.74a17.392 17.392 0 016.558-1.29zM8.06 15.301l8.17 8.17 1.649 1.648A42.298 42.298 0 004.477 43c6.2 15.73 21.5 26.875 39.417 26.875a42.197 42.197 0 0015.695-3.01l1.505 1.505 10.499 10.463 4.55-4.55L12.612 10.75l-4.55 4.55zm19.816 19.816l5.554 5.554c-.179.752-.286 1.54-.286 2.329 0 5.948 4.801 10.75 10.75 10.75.788 0 1.576-.108 2.329-.287l5.554 5.554c-2.4 1.183-5.053 1.9-7.883 1.9-9.89 0-17.917-8.027-17.917-17.917 0-2.83.717-5.483 1.9-7.883zm15.444-2.795l11.288 11.287.071-.573c0-5.949-4.801-10.75-10.75-10.75l-.609.036z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgVisibilityOff = _style(SvgIcon)

export default SvgVisibilityOff
