import React, { FC, Fragment } from 'react'

import { Link } from 'src/modules/components/lib/link'
import { Text } from 'src/modules/components/lib/text'
import { View, Flexbox } from 'src/modules/components/lib/view'
import { getSearchPagePath } from 'src/modules/helpers/get-serch-page-path'
import { Spacer } from 'src/modules/screens/top/spacer'

export type SearchStation = {
  slug: string
  name: string
  pref: string
}

export const mainSearchStation: SearchStation[] = [
  {
    slug: 'st1130208',
    name: '新宿駅',
    pref: 'tokyo',
  },
  {
    slug: 'st1133228',
    name: '大井町駅',
    pref: 'tokyo',
  },
  {
    slug: 'st1133229',
    name: '大森駅',
    pref: 'tokyo',
  },
  {
    slug: 'st1130511',
    name: '南浦和駅',
    pref: 'saitama',
  },
  {
    slug: 'st2800208',
    name: '大手町駅',
    pref: 'tokyo',
  },
  {
    slug: 'st2800405',
    name: '神楽坂駅',
    pref: 'tokyo',
  },
  {
    slug: 'st2800417',
    name: '葛西駅',
    pref: 'tokyo',
  },
  {
    slug: 'st2200106',
    name: '練馬駅',
    pref: 'tokyo',
  },
  {
    slug: 'st2200114',
    name: '東久留米駅',
    pref: 'tokyo',
  },
  {
    slug: 'st1130620',
    name: '八王子駅',
    pref: 'tokyo',
  },
  {
    slug: 'st9930418',
    name: '一之江駅',
    pref: 'tokyo',
  },
  {
    slug: 'st9930420',
    name: '篠崎駅',
    pref: 'tokyo',
  },
  {
    slug: 'st2600320',
    name: '青葉台駅',
    pref: 'kanagawa',
  },
  {
    slug: 'st1130301',
    name: '川崎駅',
    pref: 'kanagawa',
  },
  {
    slug: 'st2400112',
    name: '千歳烏山駅',
    pref: 'tokyo',
  },
  {
    slug: 'st2500301',
    name: '新百合ヶ丘駅',
    pref: 'kanagawa',
  },
]

type Props = {}

export const Station: FC<Props> = () => {
  const mainSearchStationArrays = [
    mainSearchStation.slice(0, 4),
    mainSearchStation.slice(4, 8),
    mainSearchStation.slice(8, 12),
    mainSearchStation.slice(12, 16),
  ]

  return (
    <View width="100%">
      <Text fontSize="large" fontWeight="bold">
        駅から探す
      </Text>
      <Spacer size={14} />
      {mainSearchStationArrays.map((mainSearchStationArray, index) => {
        return (
          <Flexbox flexWrap="wrap" justifyContent="space-between" key={index}>
            {mainSearchStationArray.map((station, index) => {
              const path = getSearchPagePath({
                stationCode: station.slug,
                prefecture: station.pref,
              })
              return (
                <Fragment key={index}>
                  <Link to={path} paddingBottom="16px">
                    <Text
                      fontWeight="300"
                      style={{
                        paddingRight: '10px',
                        textDecoration: 'underline',
                      }}
                    >
                      {station.name}
                    </Text>
                  </Link>
                  {(index + 1) % 4 !== 0 && (
                    <>
                      <Text
                        color="darkGray"
                        fontSize="14px"
                        paddingRight="16px"
                      >
                        |
                      </Text>
                    </>
                  )}
                </Fragment>
              )
            })}
          </Flexbox>
        )
      })}
    </View>
  )
}
