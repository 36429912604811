import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="69"
      height="71"
      viewBox="0 0 69 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.2183 2.73834C58.9229 5.06017 62.833 8.72532 65.454 13.27C68.9645 19.357 69.9155 26.5884 68.0983 33.3761C66.2811 40.1638 61.8442 45.9527 55.7621 49.4716C51.221 52.0988 46.0026 53.3201 40.7673 52.9807C36.5469 52.7072 32.4683 51.4295 28.8642 49.2724L5.50036 70.5L0.855835 64.9691L22.7097 44.1889C20.3469 41.5449 18.5267 38.4409 17.3748 35.0518C15.6865 30.0845 15.5096 24.728 16.8664 19.6602C18.2232 14.5923 21.0527 10.0408 24.997 6.58146C28.9412 3.12214 33.823 0.910523 39.0245 0.226428C44.2261 -0.457668 49.5137 0.416502 54.2183 2.73834ZM21.6365 25.1597C21.3663 29.2865 22.3258 33.4008 24.3937 36.9824H24.398C25.7708 39.3605 27.5986 41.4449 29.7771 43.1165C31.9555 44.7881 34.4419 46.0142 37.0943 46.7247C39.7467 47.4353 42.513 47.6164 45.2354 47.2577C47.9578 46.899 50.5828 46.0075 52.9606 44.6341C56.5419 42.5658 59.4295 39.4819 61.2582 35.7725C63.0868 32.0631 63.7745 27.8948 63.2343 23.7945C62.694 19.6943 60.95 15.8463 58.2228 12.7372C55.4957 9.62815 51.9078 7.39755 47.913 6.32751C43.9181 5.25747 39.6957 5.39605 35.7796 6.72572C31.8635 8.05538 28.4296 10.5164 25.9122 13.7976C23.3947 17.0788 21.9068 21.0329 21.6365 25.1597Z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgSearchRight = _style(SvgIcon)

export default SvgSearchRight
