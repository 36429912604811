import { DayOfWeek } from 'src/modules/entities/enums/day-of-week'
import { nonspecificWeekNumberRepeatTypes } from 'src/modules/entities/enums/repeat-type'
import { RepeatCycle } from '../../repeat-cycle/entity'
import { BusinessHourInfo } from '../entity'

// weekly や no_repeat　の　RepeatCycles
export function getMonotonousCycle(
  businessHoursInfo: BusinessHourInfo,
  dayOfWeek: DayOfWeek,
): RepeatCycle | null {
  const cycles: RepeatCycle[] =
    businessHoursInfo.repeatCycles.filter(r => r.dayOfWeek === dayOfWeek) || []
  return (
    cycles.find(c => nonspecificWeekNumberRepeatTypes.includes(c.repeatType)) ||
    null
  )
}
