import React, { FC, useCallback } from 'react'
import styled from 'styled-components'
import { displayServiceTagsId } from 'src/modules/constants/display_service_tags'
import { PharmacyService } from 'src/modules/entities/pharmacy/entity'

type Props = {
  service: PharmacyService
}

export const ServiceTag: FC<Props> = props => {
  const { service } = props

  const replaceTag = useCallback((v: string) => {
    const replaceTagMap = [
      {
        input: 'クレジットカード使用可能',
        output: 'カード可',
      },
    ]
    const [replacedTag] = replaceTagMap
      .filter(t => t.input === v)
      .map(t => t.output)
    return replacedTag || v
  }, [])

  if (!displayServiceTagsId.includes(service.uid)) {
    return null
  }

  return <Tag>{replaceTag(service.name)}</Tag>
}

const Tag = styled.div`
  border-radius: 12px;
  padding: 4px 10px;
  font-weight: 600;
  font-size: 12px;
  color: #d16404;
  background-color: #fff5d6;
`
