import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M58.595 6.192A20.977 20.977 0 0043.717 0h-1.434c-5.59 0-10.95 2.236-14.906 6.192-3.928 3.985-6.164 9.345-6.164 14.935v43.717a21.11 21.11 0 006.192 14.935 21.094 21.094 0 0014.907 6.192h1.433c5.59 0 10.951-2.236 14.907-6.192 3.956-3.956 6.163-9.345 6.192-14.935V21.127a21.514 21.514 0 00-6.25-14.935zm-26.66 4.558a14.65 14.65 0 0110.348-4.3h1.434a14.65 14.65 0 0110.348 4.3 14.667 14.667 0 014.3 10.377v21.156h-30.73V21.127c0-3.87 1.548-7.625 4.3-10.377zm22.13 64.5a14.65 14.65 0 01-10.348 4.3h-1.434a14.65 14.65 0 01-10.348-4.3 14.667 14.667 0 01-4.3-10.377v-16.14h30.702v16.14a14.569 14.569 0 01-4.272 10.377z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgMedicine = _style(SvgIcon)

export default SvgMedicine
