import { Moment } from 'moment'
import { getDayOfWeek } from 'src/modules/entities/enums/day-of-week'
import { timeRangeCompetitor } from 'src/modules/entities/time-range/helpers/time-range-competitor'
import { getNthDayOfWeek } from 'src/modules/helpers/get-nth-day-of-week'
import { convertNumberToRepeatType } from '../../enums/repeat-type'
import { TimeRange } from '../../time-range/entity'
import { BusinessHourInfo } from '../entity'
import { getDissimilarCycles } from './get-dissimilar-cycles'
import { getMonotonousCycle } from './get-monotonous-cycle'

// TODO : 祝日かとの判定はまだ実装していません。
// ServerSideに実装するが、Frontに実装するが、その判断が必要です
export function getTimeRangesOfDate(
  info: BusinessHourInfo,
  mnt: Moment,
): TimeRange[] {
  const day = getDayOfWeek(mnt)
  const weekOfMonth: number = getNthDayOfWeek(mnt)

  if (!day) {
    return []
  }

  let rangeCycleOfRepeatType

  const monotonousCycle = getMonotonousCycle(info, day)

  if (monotonousCycle) {
    rangeCycleOfRepeatType = monotonousCycle
  } else {
    const repeatType = convertNumberToRepeatType(weekOfMonth)
    rangeCycleOfRepeatType = Object.values(getDissimilarCycles(info, day)).find(
      rc => rc.key === repeatType,
    )?.repeatCycle
  }

  if (!rangeCycleOfRepeatType) {
    return []
  }

  const sortedTimeRange =
    rangeCycleOfRepeatType.timeRanges.sort(timeRangeCompetitor)
  return sortedTimeRange
}
