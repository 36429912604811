import {
  PatientAccountResponse,
  parse,
} from 'src/modules/apis/patient-account/response'
import { PatientAccount } from 'src/modules/entities/patient-account/entity'
import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestPost } from 'src/modules/server/request'

export type SubmitParams = {
  token_uid: string | null
}

export const submit = async (params: SubmitParams): Promise<PatientAccount> => {
  const resp = await requestPost<PatientAccountResponse>({
    url: `${PHARMACY_API_URL}/patients/patient_accounts/auth_by_token.json`,
    params,
  })

  return parse(resp.data)
}
