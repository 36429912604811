import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M81.184 12.613H59.31V2.465A2.465 2.465 0 0056.846 0H29.154a2.465 2.465 0 00-2.465 2.465v10.148H4.816A2.465 2.465 0 002.35 15.08a2.484 2.484 0 002.465 2.465h5.533l5.045 66.163A2.472 2.472 0 0017.859 86h50.253c1.29 0 2.38-1.003 2.465-2.293l5.046-66.163h5.532a2.465 2.465 0 002.466-2.465c0-1.348-1.061-2.466-2.437-2.466zM31.619 4.96h22.733v7.339H31.619V4.959zm34.228 76.11H20.153l-4.816-63.152h55.326l-4.816 63.152z"
        fill={props.color || '#000'}
      />
      <path
        d="M30.903 69.402h.114c1.376-.057 2.408-1.233 2.351-2.58l-1.72-35.432c-.057-1.376-1.233-2.408-2.58-2.35-1.347.057-2.408 1.232-2.35 2.58l1.72 35.431c.057 1.32 1.146 2.38 2.465 2.351zM43 69.402a2.465 2.465 0 002.465-2.465V31.505A2.465 2.465 0 0043 29.04a2.484 2.484 0 00-2.465 2.465v35.432A2.447 2.447 0 0043 69.402zM54.983 69.402h.114a2.462 2.462 0 002.466-2.35l1.72-35.433c.057-1.376-.975-2.522-2.351-2.58-1.376-.057-2.523.975-2.58 2.351l-1.72 35.432c-.086 1.347.975 2.523 2.35 2.58z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgDelete = _style(SvgIcon)

export default SvgDelete
