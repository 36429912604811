export const FixedZIndex = {
  check: 1,
  radio: 1,
  fancySearchOptions: 1,
  fancySelectOptions: 1,
  header: 2,
  followUpChatInputBar: 2,
  progressNotification: 2,
  modal: 100,
  blocker: 999,
  tab: 999, // TODO remove as tab is not used after my-page implementation

  videoCallRoot: 3,
  videoCall: {
    message: 0,
    video: 10,
    pharmacyName: 20,
    buttons: 20,
  },
} as const

export const MAX_PAGE_WIDTH = '600px' as const

export const HEADER_HEIGHT = 56

export const MUTUAL_INDUCTION_HEIGHT = 55

export const LOGIN_FAQ_LINK =
  'https://micin.force.com/FAQforPatients/s/topic/0TO7F000000t8HkWAI/ログインについて'

export const VIDEO_CALL_FAQ_LINK =
  'https://micin.force.com/FAQforPatients/s/article/0532'

export const TRANSACTION_LAW_LINK = 'https://micin.jp/law'

export const CREDIT_CARD_INVISIBLE_GUIDE: string =
  'https://micin.force.com/FAQforPatients/s/article/1517'

export const CANNOT_UPLOAD_IMAGE_LINK: string =
  'https://micin.force.com/FAQforPatients/s/article/1602'

export const ID_PLATFORM_URL =
  process.env.REACT_APP_ID_PLATFORM_URL || 'http://localhost:11090'

export const KOALA_WEB_URL =
  process.env.REACT_APP_KOALA_WEB_URL || 'http://localhost:11080'

// NEW Pharmacy Patient Web URL
export const PHARMACY_PATIENT_URL =
  process.env.PUBLIC_URL || 'http://localhost:4200'
