import React, { FC } from 'react'
import styled from 'styled-components'
import { ReactComponent as FooterLogo } from 'src/assets/logo_footer.svg'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox, View } from 'src/modules/components/lib/view'

export const Footer: FC = () => {
  return (
    <View
      backgroundColor="green"
      padding="57px 10px 30px 10px"
      fontFamily="'Noto Sans JP', sans-serif"
    >
      <TopContentContainer>
        <FooterWrap>
          <Flexbox flexDirection="row" flexWrap="nowrap">
            <FooterLogo />
            <Text
              color="white"
              textAlign="center"
              fontSize="12px"
              padding="8px 0 0 15px"
            >
              {' '}
              オンライン診療サービス クロン
            </Text>
          </Flexbox>

          <FooterInner>
            <FooterContent>
              <ListTitle>医療機関の方へ</ListTitle>
              <LinkText>
                <FooterLink href="https://curon.co/">
                  医療機関向けトップ
                </FooterLink>
                <FooterLink href="https://news.curon.co/interviews/">
                  導入事例
                </FooterLink>
                <FooterLink href="https://app.curon.co/add-clinic/search">
                  導入医療機関一覧
                </FooterLink>
                <FooterLink href="https://curon.co/faq">
                  よくある質問
                </FooterLink>
                <FooterLink href="https://go.curon.co/curon_accountregistration">
                  導入のお問い合わせ
                </FooterLink>
                <FooterLink href="https://go.curon.co/curon_inquirypage">
                  その他のお問い合わせ
                </FooterLink>
                <FooterLink href="https://news.curon.co/info">
                  お知らせ・最新情報
                </FooterLink>
              </LinkText>
            </FooterContent>

            <FooterContent>
              <ListTitle>オンライン診療</ListTitle>
              <LinkText>
                <FooterLink href="https://news.curon.co/">
                  トピックス
                </FooterLink>
                <FooterLink href="https://news.curon.co/terms">
                  オンライン診療・用語集
                </FooterLink>
              </LinkText>
            </FooterContent>

            <FooterContent>
              <ListTitle>薬局の方へ</ListTitle>
              <LinkText>
                <FooterLink href="https://curon.co/pharmacy">
                  薬局向けトップ
                </FooterLink>
                <FooterLink href="https://curon.co/pharmacy/medguide">
                  オンライン服薬指導
                </FooterLink>
                <FooterLink href="https://curon.co/pharmacy/followup">
                  フォローアップ
                </FooterLink>
                <FooterLink href="https://curon.co/pharmacy/net-reception">
                  処方箋ネット受付
                </FooterLink>
                <FooterLink href="https://news.curon.co/pharmacy-interviews">
                  導入事例
                </FooterLink>
                <FooterLink href="https://go.curon.co/pharmacy/contact">
                  薬局への導入お問い合わせ
                </FooterLink>
                <FooterLink href="https://news.curon.co/pharmacy-info">
                  お知らせ・最新情報
                </FooterLink>
              </LinkText>
            </FooterContent>

            <FooterContent>
              <ListTitle>オンライン服薬指導</ListTitle>
              <LinkText>
                <FooterLink href="https://news.curon.co/pharmacy-topics">
                  トピックス
                </FooterLink>
                <FooterLink href="https://news.curon.co/pharmacy">
                  薬局薬剤師業務 関連用語集
                </FooterLink>
              </LinkText>
            </FooterContent>

            <FooterContent>
              <ListTitle>一般の方へ</ListTitle>
              <LinkText>
                <FooterLink href="https://app.curon.co">
                  一般向けオンライン診療トップ
                </FooterLink>

                <FooterLink href="https://app.curon.co/add-clinic/search">
                  医療機関を探す
                </FooterLink>

                <FooterLink href="https://pha.curon.co/">
                  クロン処方箋ネット受付
                </FooterLink>

                <FooterLink href="https://app.curon.co/guide?t=web">
                  クロンの使い方
                </FooterLink>

                <FooterLink href="https://app.curon.co/cp/covid19">
                  新型コロナウイルス感染症をご心配の方へ
                </FooterLink>

                <FooterLink
                  href="https://products.micin-insurance.jp/list?utm_source=mcn&utm_medium=curon&utm_campaign=footerlink"
                  target="_blank"
                >
                  持病(がん)をお持ちの方向けの保険
                </FooterLink>

                <FooterLink href="https://app.curon.co/contact">
                  お問い合わせ
                </FooterLink>
              </LinkText>
            </FooterContent>
            <FooterContent>
              <ListTitle>全般</ListTitle>
              <LinkText>
                <FooterLink href="https://micin.jp/">運営会社</FooterLink>
                <FooterLink href="https://micin.jp/privacy" target="_blank">
                  プライバシーポリシー
                </FooterLink>
                <FooterLink
                  href="https://curon.co/assets/external_transmission.pdf"
                  target="_blank"
                >
                  外部送信ポリシー
                </FooterLink>
                <FooterLink
                  href="https://curon.co/static/app/privacy.html"
                  target="_blank"
                >
                  オンライン医療サービス個人情報保護方針
                </FooterLink>
                <FooterLink href="https://micin.jp/security" target="_blank">
                  情報セキュリティ基本方針
                </FooterLink>
              </LinkText>
            </FooterContent>
          </FooterInner>
        </FooterWrap>

        <Text color="white" fontSize="normal" textAlign="center">
          © MICIN, Inc.
        </Text>
      </TopContentContainer>
    </View>
  )
}

const ContentContainer = styled(View)`
  width: 100%;
  max-width: 600px;
  padding: 16px;
  margin: 0 auto;
`
const TopContentContainer = styled(ContentContainer)`
  padding: 0;
  @media (min-width: 768px) {
    max-width: 960px;
  }
`

const FooterWrap = styled(Text)`
  padding: 0 13px;
`

const FooterInner = styled(Text)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr repeat(3, 0.5fr);
  grid-row-gap: 40px;
  grid-column-gap: 30px;
  margin: 25px auto 0;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    margin: 30px auto;
  }
`

const FooterContent = styled(Text)`
  &:nth-child(1) {
    grid-row: 1/3;
  }
  &:nth-child(2) {
    grid-row: 3/4;
    grid-column: 1/2;
  }
  &:nth-child(3) {
    grid-row: 2/3;
    grid-column: 2/3;
  }
  &:nth-child(4) {
    grid-row: 3/4;
    grid-column: 2/3;
  }
  &:nth-child(5) {
    grid-row: 1/2;
    grid-column: 2/3;
  }
  &:nth-child(6) {
    grid-row: 4/5;
    grid-column: 2/3;
  }

  @media (min-width: 768px) {
    width: calc(100% / 5 - 25px);
  }
`

const ListTitle = styled(Text)`
  border-bottom: 1px solid #fff;
  padding-bottom: 8px;
  font-size: 14px;
  color: #ffffff;
`

const LinkText = styled(Text)`
  padding: 7px 0;
`

const FooterLink = styled.a`
  color: white;
  font-size: 12px;
  padding: 7px 0;
  display: block;
`
