import React, {
  FC,
  useRef,
  useEffect,
  useState,
  useContext,
  useCallback,
} from 'react'
import ReactDOM from 'react-dom'
import styled, { keyframes } from 'styled-components'
import { FixedZIndex } from 'src/modules/const'
import { Close } from './curon-icon'
import { View, ViewProps } from './view'

export type ModalProps = {
  visible: boolean
  showCloseButton?: boolean
  onVisibleChange?: (visible: boolean) => void
  containerSize?: 'normal' | 'small'
} & ViewProps

export const Modal: FC<ModalProps> = ({
  visible,
  showCloseButton,
  onVisibleChange,
  containerSize,
  ...props
}) => {
  const context = useContext(ModalContext)
  const [initialRender, setInitialRender] = useState(true)
  const containerSizeClass = containerSize || 'normal'

  useEffect(() => {
    if (visible) {
      setInitialRender(false)
    }
  }, [visible])

  const visibleClass = initialRender ? '' : visible ? 'visible' : 'invisible'

  const stackDepth = useModalStackDepth(visible)

  if (!context.root) {
    return null
  }

  return ReactDOM.createPortal(
    <ModalRoot
      className={`${visibleClass}`}
      style={{ zIndex: stackDepth }}
      {...props}
    >
      <div className={`modal-container ${containerSizeClass}`}>
        <div className="header">
          {showCloseButton && (
            <Close
              color="darkGray"
              width="18px"
              height="18px"
              onClick={() => {
                onVisibleChange && onVisibleChange(false)
              }}
            />
          )}
        </div>
        <div className="content">{props.children}</div>
      </div>
    </ModalRoot>,
    context.root,
  )
}
Modal.defaultProps = {
  showCloseButton: true,
}

export type DialogueProps = {
  title?: string
  showCloseButton?: boolean
} & ModalProps
export const Dialogue: FC<DialogueProps> = ({
  title,
  showCloseButton,
  visible,
  onVisibleChange,
  ...props
}) => {
  const context = useContext(ModalContext)
  const [initialRender, setInitialRender] = useState(true)

  useEffect(() => {
    if (visible) {
      setInitialRender(false)
    }
  }, [visible])

  const visibleClass = initialRender ? '' : visible ? 'visible' : 'invisible'

  const stackDepth = useModalStackDepth(visible)

  if (!context.root) {
    return null
  }

  return ReactDOM.createPortal(
    <ModalRoot
      className={`${visibleClass} dialogueRoot`}
      style={{ zIndex: stackDepth }}
      {...props}
    >
      <div className="dialogue-container">
        <div className="header">
          <div className="close-button-container">
            {showCloseButton && (
              <Close
                className="close"
                color="darkGray"
                width="14px"
                height="14px"
                onClick={() => {
                  onVisibleChange && onVisibleChange(false)
                }}
              />
            )}
          </div>
          {title && <div className="title">{title}</div>}
        </div>
        <div className="content">{props.children}</div>
      </div>
    </ModalRoot>,
    context.root,
  )
}
Dialogue.defaultProps = {
  showCloseButton: true,
}

const ToVisibleKeyFrames = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const ToInvisibleKeyFrames = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
`

const ModalRoot = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  visibility: hidden;
  pointer-events: none;

  &.dialogueRoot {
    background-color: rgba(0, 0, 0, 0.45);
    text-align: left;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;

    animation: ${ToVisibleKeyFrames} 100ms ease-out 0s;
  }

  &.invisible {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    animation: ${ToInvisibleKeyFrames} 100ms ease-out 0s;
    animation-fill-mode: forwards;
  }

  .modal-container {
    display: inline-block;
    position: relative;
    width: 90%;
    margin: 10% 0;
    padding-bottom: 18px;
    overflow-y: auto;
    background: white;
    max-width: 720px;
    border-radius: 5px;
    .header {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 18px 18px 0 18px;
      svg {
        cursor: pointer;
      }
    }

    &.small {
      max-width: 375px;
    }
  }

  .dialogue-container {
    background: white;
    width: 90%;
    max-width: 720px;
    max-height: 90%;
    border-radius: 5px;
    padding: 20px 16px 20px 24px;
    .header {
      display: grid;
      text-align: right;
      padding: 0;
      .close-button-container {
        height: 14px;
      }
      .title {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
        padding-right: 24px;
      }
    }
    .content {
      font-size: 16px;
    }
  }
`

export type ModalContainerProps = ViewProps

export const ModalContainer: FC<ModalContainerProps> = ({ ...props }) => {
  const containerElement = useRef<HTMLDivElement>(null)
  const [rootElement, setRootElement] = useState<HTMLElement | null>(null)

  const stackDepthRef = useRef<number>(0)
  const incrementStackDepth = useCallback(() => {
    stackDepthRef.current += 1
    return stackDepthRef.current
  }, [])
  const decrementStackDepth = useCallback(() => {
    stackDepthRef.current -= 1
    return stackDepthRef.current
  }, [])

  useEffect(() => {
    const root = document.createElement('div')
    setRootElement(root)
    if (containerElement.current) {
      containerElement.current.appendChild(root)
    }
    // needs cleanup?
  }, [])

  return (
    <ModalContext.Provider
      value={{
        root: rootElement,
        incrementStackDepth,
        decrementStackDepth,
      }}
    >
      <View width="100%" height="100%" {...props}>
        {props.children}
        <div
          style={{
            position: 'fixed',
            bottom: 0,
            width: '100%',
            zIndex: FixedZIndex.modal,
          }}
          ref={containerElement}
        ></div>
      </View>
    </ModalContext.Provider>
  )
}

type ModalContextValue = {
  root: HTMLElement | null
  incrementStackDepth: () => number
  decrementStackDepth: () => number
}

export const ModalContext = React.createContext<ModalContextValue>({
  root: null,
  incrementStackDepth: () => 0,
  decrementStackDepth: () => 0,
})

export const useModalStackDepth = (visible: boolean) => {
  const { incrementStackDepth, decrementStackDepth } = useContext(ModalContext)
  const [stackDepth, setStackDepth] = useState<number>(0)

  useEffect(() => {
    if (visible) {
      setStackDepth(incrementStackDepth())
      return () => {
        decrementStackDepth()
      }
    }
  }, [decrementStackDepth, incrementStackDepth, visible])

  return stackDepth
}
