import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M85.697 37.263l-11.5-23.094a2.9 2.9 0 00-2.595-1.607H14.398a2.9 2.9 0 00-2.596 1.607L.303 37.263c-.2.401-.303.844-.303 1.292v31.984a2.899 2.899 0 002.899 2.899H83.1A2.899 2.899 0 0086 70.539V38.555c0-.448-.103-.891-.303-1.292zM16.192 18.359h53.615l8.66 17.394h-18.75c-1.37 0-2.556.97-2.84 2.311-.103.49-2.665 11.99-14.36 11.99s-14.258-11.5-14.36-11.978a2.898 2.898 0 00-2.84-2.323H7.532l8.66-17.394zm64.01 49.281H5.798V41.55h17.319c2.037 5.936 7.834 14.302 19.4 14.302 11.566 0 17.364-8.366 19.4-14.302h18.285v26.09z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgInbox = _style(SvgIcon)

export default SvgInbox
