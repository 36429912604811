import React, { FC, useCallback, useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useHistory, useLocation } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import { useFetch as useFetchChain } from 'src/modules/apis/chain/search/use-fetch'
import { Input } from 'src/modules/components/lib/input'
import { Link } from 'src/modules/components/lib/link'
import { Text } from 'src/modules/components/lib/text'
import { View } from 'src/modules/components/lib/view'
import { Chain } from 'src/modules/entities/chain/entity'
import { getSearchPagePath } from 'src/modules/helpers/get-serch-page-path'
import { useSearchQuery } from 'src/modules/hooks/use-search-query'
import { useViewportHeight } from 'src/modules/hooks/useViewportHeight'

import { Clickable } from '../lib/clickable'
import { Close2, SearchLeft } from '../lib/curon-icon'
import { ModalContext, useModalStackDepth } from '../lib/modal'
const ToVisibleKeyFrames = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`

const ToInvisibleKeyFrames = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
`
type KeywordContainerRootProps = {
  containerRootHeight: any
}

const KeywordContainerRoot = styled.div<KeywordContainerRootProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.containerRootHeight};
  background: white;
  visibility: hidden;
  &.visible {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
    animation: ${ToVisibleKeyFrames} 200ms ease-out 0s;
  }
  &.invisible {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    animation: ${ToInvisibleKeyFrames} 200ms ease-out 0s;
    animation-fill-mode: forwards;
  }
`

const CustomInputRoot = styled.div`
  position: relative;
  .icon {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }
  input {
    padding-left: 30px;
  }
`

const OverlayControl = styled(View)`
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 2;
  padding: 8px 12px 24px;
`

const FilterButton = styled.a<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  background: ${props => (props.disabled ? '#D3DDE8' : '#2BA168')};
  border-radius: 22px;
  font-size: 14px;
  font-weight: 600;
  color: ${props => (props.disabled ? '#637182' : '#fff')};
  text-align: center;
`

const TitleArea = styled.div`
  padding: 0 16px;
  align-items: center;
  background-color: #f5f5f5;
`

const ItemButton = styled.div`
  padding: 16px;
  border-bottom: solid 1px #ecf0f5;
  width: 100%;
`

const OptionLinkRow = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #313541;
`

const KeywordContainer: FC<
  React.HTMLAttributes<HTMLDivElement> & {
    visible: boolean
  }
> = ({ visible, ...props }) => {
  const context = useContext(ModalContext)
  const stackDepth = useModalStackDepth(visible)
  const vh = useViewportHeight()

  if (!context.root) {
    return null
  }

  return ReactDOM.createPortal(
    <KeywordContainerRoot
      style={{ zIndex: stackDepth, overflow: 'scroll' }}
      className={visible ? 'visible' : 'invisible'}
      containerRootHeight={`calc(${vh * 100}px)`}
      {...props}
    >
      {props.children}
    </KeywordContainerRoot>,
    context.root,
  )
}

type Props = {
  visible: boolean
  keyword: string
  setKeyword: (keyword: string) => void
  setChain?: (chain: Chain | null) => void
  // conditions: string[]
  onCloseClick: () => void
  onSearch?: ({ chain, keyword }: { keyword?: string; chain?: Chain }) => void
}

export const KeywordModal: FC<Props> = props => {
  const { visible, onCloseClick, keyword, setKeyword, onSearch, setChain } =
    props
  const inputCheck = /^\s*$/
  const history = useHistory()

  const { data } = useFetchChain({ keyword: keyword })

  const {
    prefecture,
    area: areaSlug,
    station: stationCode,
    selectedDay,
  } = useSearchQuery()

  const onSearchKeyword = useCallback(
    (keyword: string) => {
      if (!!onSearch && !!setChain) {
        onSearch({ keyword })
        setChain(null)
        return
      }
      const path = getSearchPagePath({
        keyword: keyword,
        prefecture: prefecture?.value,
        areaSlug,
        stationCode,
        selectedDay,
      })
      history.push(path)
      onCloseClick()
    },
    [
      areaSlug,
      history,
      onCloseClick,
      onSearch,
      prefecture?.value,
      setChain,
      stationCode,
    ],
  )

  const searchChainPath = useCallback(
    (chain: string) => {
      const path = getSearchPagePath({
        chainCode: chain,
        prefecture: prefecture?.value,
        areaSlug,
        stationCode,
      })
      return path
    },
    [areaSlug, prefecture?.value, stationCode],
  )

  const onSearchChain = useCallback(
    (chain: Chain) => {
      if (!!onSearch && !!setChain) {
        onSearch({ chain })
        setChain(chain)
        setKeyword('')
        return
      }
    },
    [onSearch, setChain, setKeyword],
  )

  return (
    <KeywordContainer visible={visible}>
      <View position="relative" m={4} pr="30px">
        <CustomInputRoot>
          <SearchLeft color="shuttleGray" className="icon" />
          <Input
            type="search"
            width="100%"
            placeholder="店舗・キーワードで探す"
            value={keyword}
            onChange={e => setKeyword(e.target.value)}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                const value = keyword.replace(/\s+/g, '')
                e.preventDefault()
                onSearchKeyword(value)
              }
            }}
          />
        </CustomInputRoot>
        <Clickable
          onClick={onCloseClick}
          position="absolute"
          right="0"
          top="12px"
        >
          <Close2 size="16px" color="shuttleGray" />
        </Clickable>
      </View>

      {!!keyword && !inputCheck.test(keyword) && (
        <View>
          <TitleArea>
            <Text color="darkGray" fontSize="14px">
              キーワード
            </Text>
          </TitleArea>
          <Clickable
            onClick={() => {
              onSearchKeyword(keyword)
            }}
          >
            <ItemButton>
              <Text>“{keyword}”を含む薬局名で探す</Text>
            </ItemButton>
          </Clickable>
        </View>
      )}

      {!!data && data.length > 0 && (
        <View style={{ marginBottom: '60px' }}>
          <TitleArea>
            <Text color="darkGray" fontSize="14px">
              薬局チェーン
            </Text>
          </TitleArea>
          {data?.map((chain: Chain) => (
            <>
              {!!onSearch ? (
                <Clickable
                  onClick={() => {
                    onSearchChain(chain)
                  }}
                >
                  <ItemButton>
                    <Text>{chain.name}</Text>
                  </ItemButton>
                </Clickable>
              ) : (
                <OptionLinkRow to={searchChainPath(chain.code)}>
                  <ItemButton>
                    <Text>{chain.name}</Text>
                  </ItemButton>
                </OptionLinkRow>
              )}
            </>
          ))}
        </View>
      )}

      <OverlayControl>
        <FilterButton
          onClick={() => {
            onSearchKeyword(keyword)
          }}
        >
          検索
        </FilterButton>
      </OverlayControl>
    </KeywordContainer>
  )
}
