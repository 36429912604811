import { PharmacyResponse, parse } from 'src/modules/apis/pharmacy/response'
import { Pharmacy } from 'src/modules/entities/pharmacy/entity'
import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'

export type Params = {
  uid: string
  lat: number
  lng: number
}

export type FetchResponse = PharmacyResponse[]

export type PharmaciesSearchResponse = {
  pharmacies: Pharmacy[]
}

export const fetch: (
  params: Params,
) => Promise<PharmaciesSearchResponse> = async ({ uid, lat, lng }) => {
  const resp = await requestGet<FetchResponse>({
    url: `${PHARMACY_API_URL}/patients/net_receptions/external_pharmacies/${uid}/pharmacies/nearby.json`,
    params: {
      lat,
      lng,
    },
  })

  return {
    pharmacies: resp.data.map(parse),
  }
}
