// Generated by the command below:
// yarn svgr \
//   --typescript \
//   --icon \
//   --template curon-icon.template.js \
//   --replace-attr-values="#000={props.color || '#000'}" \
//   --replace-attr-values="#040000={props.color || '#000'}" \
//   --replace-attr-values="#231815={props.color || '#000'}" \
//   --replace-attr-values="#008E44={props.color || '#000'}" \
//   -d src/modules/components/favorite src/modules/components/favorite

import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.9536 23.5395C6.91068 19.0368 3.05392 14.3254 1.66166 10.9436C0.249151 7.51262 0.783926 4.67236 2.43584 2.78201C4.03954 0.946818 6.57106 0.161887 8.85876 0.537206C10.4577 0.799534 11.9567 1.63378 12.9536 3.06786C13.9505 1.63378 15.4495 0.799534 17.0485 0.537206C19.3362 0.161887 21.8677 0.946818 23.4714 2.78201C25.1233 4.67236 25.6581 7.51262 24.2456 10.9436C22.8533 14.3254 18.9965 19.0368 12.9536 23.5395Z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgHeart = _style(SvgIcon)

export default SvgHeart
