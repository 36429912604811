import moment, { locale } from 'moment'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import { Text } from '../lib/text'
import { View } from '../lib/view'

const StyledLabel = styled.label`
  display: inline-block;
  width: 100%;
`

const HiddenInput = styled.input`
  display: none;
`

type CustomBoxProps = {
  checked: boolean
  isFirst: boolean
  isLast: boolean
}

const CustomBox = styled(View)<CustomBoxProps>`
  height: 48px;
  width: calc(100% -1px);
  cursor: pointer;
  position: relative;
  background-color: ${({ checked, theme }) =>
    checked ? theme.colors.pistachioGreen : 'white'};
  border: 1px solid
    ${({ checked, theme }) => (checked ? theme.colors.seaGreen : '#DEE5ED')};
  border-radius: ${props =>
    props.isFirst ? '4px 0 0 4px' : props.isLast ? '0 4px 4px 0' : '0'};
  margin-left: ${props => (props.isFirst ? '0' : '-1px')};
  z-index: ${props => (props.checked ? 1 : 0)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StyledStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`

const StyledHStack = styled.div`
  display: flex;
  width: 100%;
`

export const MedicinePickupSelectDayRadio: FC = () => {
  const labels = [
    {
      value: '0',
      label: '本日',
      date: moment().format('M/D'),
      day: moment().format('ddd'),
    },
    {
      value: '1',
      label: '明日',
      date: moment().add(1, 'days').format('M/D'),
      day: moment().add(1, 'days').format('ddd'),
    },
    {
      value: '2',
      label: '2日後',
      date: moment().add(2, 'days').format('M/D'),
      day: moment().add(2, 'days').format('ddd'),
    },
    {
      value: '3',
      label: '3日後',
      date: moment().add(3, 'days').format('M/D'),
      day: moment().add(3, 'days').format('ddd'),
    },
  ]

  const location = useLocation()
  const history = useHistory()
  const isInitialMount = useRef(true)
  const [selectedValue, setSelectedValue] = useState('0')

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const selectedDay = searchParams.get('selected_day')
    if (selectedDay) {
      setSelectedValue(selectedDay)
    }
  }, [location.search])

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
    } else {
      addQueryParameter('selected_day', selectedValue)
    }
  }, [selectedValue])

  const handleChange = (value: string) => {
    setSelectedValue(value)
    addQueryParameter('selected_day', value)
  }

  const addQueryParameter = (key: string, value: string) => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.set(key, value)
    history.replace({
      pathname: location.pathname,
      search: searchParams.toString(),
    })
  }

  return (
    <StyledStack>
      <Text fontSize={'small'} fontWeight={'bold'}>
        お薬の受取希望日
      </Text>
      <StyledHStack>
        {labels.map(({ value, label, date, day }, idx) => (
          <CustomRadio
            key={value}
            value={value}
            label={label}
            date={date}
            day={day}
            selectedValue={selectedValue}
            onChange={handleChange}
            isFirst={idx === 0}
            isLast={idx === labels.length - 1}
          />
        ))}
      </StyledHStack>
    </StyledStack>
  )
}

type CustomRadioProps = {
  value: string
  label: string
  date: string
  day: string
  selectedValue: string
  isFirst: boolean
  isLast: boolean
  onChange: (value: string) => void
}

export const CustomRadio: FC<CustomRadioProps> = ({
  value,
  label,
  onChange,
  date,
  day,
  selectedValue,
  isFirst,
  isLast,
}: CustomRadioProps) => {
  const checked = value === selectedValue

  return (
    <StyledLabel>
      <HiddenInput
        type="radio"
        value={value}
        checked={checked}
        onChange={() => onChange(value)}
      />
      <CustomBox checked={checked} isFirst={isFirst} isLast={isLast}>
        <Text fontSize="small" fontWeight={'bold'}>
          {label}
        </Text>
        <Text fontSize={'10px'} fontWeight={'bold'} color={'dsGray.600'}>
          {date}
          {day}
        </Text>
      </CustomBox>
    </StyledLabel>
  )
}
