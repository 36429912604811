import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { AreaResponse } from '../response'

export type Params = {
  slug?: string
}

export const fetch = async (params: Params) => {
  if (!params.slug) {
    return null
  }

  const resp = await requestGet<AreaResponse>({
    url: `${PHARMACY_API_URL}/patients/pharmacies/areas/${params.slug}.json`,
    params: {},
  })

  return resp.data
}