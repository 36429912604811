import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M78.948 52.804c-2.15-3.813-4.042-7.912-5.848-11.868-.487-1.032-.975-2.121-1.462-3.153-1.52-3.24-1.605-5.705-1.605-9.403 0-6.307-2.437-12.069-6.852-16.168-4.3-4.013-10.234-6.22-16.626-6.22h-.918V2.722c0-.716-.286-1.404-.802-1.92C44.347.287 43.63 0 42.915 0c-.718 0-1.406.287-1.922.803a2.713 2.713 0 00-.802 1.92v3.24h-.316c-6.45 0-12.412 2.092-16.798 5.905-4.616 4.013-7.167 9.69-7.167 15.967 0 2.351 0 6.307-.545 8.629-.286 1.261-2.666 6.307-8.37 16.34-1.49 2.609-3.297 8.285-.717 12.929 2.322 4.185 7.023 5.246 10.578 5.36h52.231c3.526-.143 8.227-1.175 10.578-5.36 2.608-4.644.774-10.32-.717-12.929zm-4.042 10.263c-.889 1.576-2.895 2.436-5.963 2.58H17.057c-3.068-.115-5.074-1.004-5.963-2.58-1.233-2.208-.344-5.762.688-7.568 5.39-9.46 8.37-15.452 8.915-17.802.688-2.924.688-7.11.688-9.862 0-4.758 1.835-8.858 5.275-11.868 3.383-2.952 8.084-4.558 13.215-4.558h6.68c5.045 0 9.632 1.692 12.928 4.759 3.354 3.125 5.132 7.31 5.132 12.183 0 4.157.143 7.454 2.121 11.696.487 1.032.975 2.093 1.433 3.125 1.864 4.07 3.784 8.285 6.078 12.298 1.003 1.835 1.892 5.39.66 7.597zM43 86c-3.01 0-5.82-1.032-7.883-2.924a9.963 9.963 0 01-2.523-3.526c-.573-1.347-.889-2.78-.86-4.271 0-.717.287-1.405.803-1.921a2.713 2.713 0 011.92-.803c.717 0 1.405.287 1.921.803s.803 1.204.803 1.92c0 3.47 2.924 5.275 5.848 5.275 2.895 0 5.848-1.806 5.848-5.274 0-.717.286-1.405.802-1.921a2.713 2.713 0 011.921-.803c.717 0 1.405.287 1.92.803.517.516.803 1.204.803 1.92.03 1.463-.286 2.925-.86 4.272-.573 1.347-1.462 2.551-2.522 3.526C48.819 84.968 46.01 86 43 86z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgBell = _style(SvgIcon)

export default SvgBell
