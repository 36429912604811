import { useCallback } from 'react'
import { FetchOption, useFetch as _useFetch } from '../../hooks/use-fetch'
import { fetch } from './request'

export const useFetch = (
  pharmacyVisitUid: string,
  options?: FetchOption<ResolvedType<ReturnType<typeof fetch>>>,
) => {
  const request = useCallback(() => {
    return fetch(pharmacyVisitUid)
  }, [pharmacyVisitUid])

  return _useFetch(request, options)
}
