import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#video_check_svg__clip0)">
        <path
          d="M72.44 40.105c-.372 0-.745.029-1.117.057v-15.28c0-.4-.115-.83-.316-1.175-.2-.372-.487-.659-.831-.888-.344-.23-.745-.344-1.175-.373a2.239 2.239 0 00-1.204.258l-14.85 7.281V21.5c0-.66-.258-1.261-.716-1.72a2.406 2.406 0 00-1.72-.716H2.437c-.631 0-1.262.258-1.72.716A2.406 2.406 0 000 21.5v40.535c0 .66.258 1.261.717 1.72.458.458 1.06.717 1.72.717h48.131c.63 0 1.261-.258 1.72-.717.459-.459.717-1.06.717-1.72V53.55l6.306 3.096c1.434 5.905 6.794 10.29 13.158 10.29 7.482 0 13.56-6.02 13.56-13.415C86 46.125 79.923 40.105 72.44 40.105zM48.132 59.598H4.845V23.937h43.258v35.661h.028zm10.98-8.456l-6.135-3.01V35.404l13.473-6.623V41.51c-3.784 1.834-6.564 5.389-7.338 9.632zm13.33 10.921c-4.788 0-8.686-3.84-8.686-8.542s3.898-8.543 8.686-8.543c4.787 0 8.686 3.841 8.686 8.543.028 4.701-3.899 8.542-8.686 8.542zm-.316-2.494c-.573 0-1.118-.2-1.576-.573l-3.068-2.58a3.12 3.12 0 01-.602-.745 2.586 2.586 0 01-.258-.917c-.028-.316 0-.631.115-.947.086-.315.258-.573.459-.83.2-.23.458-.43.745-.603.287-.143.602-.23.917-.258.316-.029.631 0 .946.115.316.086.574.258.832.459l1.261 1.089 3.497-3.813c.201-.23.488-.43.774-.573a2.07 2.07 0 01.918-.23c.315-.028.63.03.946.144.315.114.573.286.831.487.23.23.43.488.573.774.144.287.201.602.23.918 0 .315-.029.63-.144.946a2.148 2.148 0 01-.516.802l-5.074 5.533a2.19 2.19 0 01-.802.573c-.316.172-.66.23-1.004.23z"
          fill={props.color || '#000'}
        />
      </g>
      <defs>
        <clipPath id="video_check_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgVideoCheck = _style(SvgIcon)

export default SvgVideoCheck
