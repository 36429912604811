import React, { FC } from 'react'
import { Pharmacy } from '../../entities/pharmacy/entity'
import { NotFound } from '../lib/curon-icon'
import { Text, InlineText } from '../lib/text'
import { Flexbox, View } from '../lib/view'
import { PharmacyCard } from './pharmacy-card'

type Props = {
  authorized: boolean
  pharmacies: Pharmacy[] | []
  externalPharmacyUid?: string
}

export const PharmacyList: FC<Props> = ({
  authorized,
  pharmacies,
  externalPharmacyUid,
}) => {
  return (
    <View width="100%" height="100%">
      {pharmacies?.length ? (
        pharmacies.map(pharmacy => (
          <PharmacyCard
            key={pharmacy.uid}
            authorized={authorized}
            pharmacy={pharmacy}
            externalPharmacyUid={externalPharmacyUid}
          />
        ))
      ) : (
        <Flexbox
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <View mt="6" />
          <NotFound size="60px" color="#C2CCC5" />
          <View mt="8" />
          <Text
            textAlign="center"
            fontSize="16px"
            fontWeight="bold"
            color="#313541"
            mb="16px"
            px="16px"
            style={{
              wordBreak: 'break-word',
            }}
          >
            <InlineText>ご指定の条件にマッチした薬局は</InlineText>
            <InlineText>見つかりませんでした。</InlineText>
          </Text>
          <Text textAlign="center" fontSize="14px" color="#313541" px="16px">
            検索条件や地域などを変更して改めて検索を行ってください。
          </Text>
        </Flexbox>
      )}
    </View>
  )
}
