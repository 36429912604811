import { DeliveryRecord } from 'src/modules/entities/delivery-record/entity'
import { YamatoDeliveryType } from 'src/modules/entities/enums/yamato-delivery-type'
import {
  DeliveryRequestResponse,
  parse as parseDeliveryRequestResponse,
} from '../delivery-request/response'

export type DeliveryRecordResponse = {
  uid: string
  delivery_type: string
  delivery_request?: DeliveryRequestResponse
  completed_at: string | null
}

export const parse = (resp: DeliveryRecordResponse): DeliveryRecord => {
  const res = {
    uid: resp.uid,
    deliveryType: resp.delivery_type as YamatoDeliveryType,
    deliveryRequest:
      resp.delivery_request &&
      parseDeliveryRequestResponse(resp.delivery_request),
    completedAt: resp.completed_at,
  }

  return res
}
