import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#print_svg__clip0)" fill={props.color || '#000'}>
        <path d="M82.445 14.82H65.073V2.552A2.557 2.557 0 0062.522 0H23.449a2.557 2.557 0 00-2.551 2.551v12.27H3.555a2.557 2.557 0 00-2.552 2.551v41.51a2.557 2.557 0 002.552 2.55H20.21v22.045a2.557 2.557 0 002.551 2.552h40.535a2.557 2.557 0 002.551-2.552V61.433h16.656a2.557 2.557 0 002.551-2.552V17.343a2.62 2.62 0 00-2.609-2.522zM26.001 5.075h34.027v9.747H26.001V5.074zM60.745 56.33v24.596h-35.49V45.322h35.461V56.33h.029zm19.178 0H65.819V42.77a2.557 2.557 0 00-2.552-2.55H22.733a2.557 2.557 0 00-2.552 2.55v13.56H6.077V19.895h73.846V56.33z" />
        <path d="M50.797 67.539H35.231a2.557 2.557 0 00-2.551 2.55 2.557 2.557 0 002.551 2.552h15.566a2.557 2.557 0 002.552-2.551c-.03-1.405-1.176-2.551-2.552-2.551zM52.861 56.33a2.557 2.557 0 00-2.092-1.09H35.203a2.557 2.557 0 00-2.552 2.552 2.557 2.557 0 002.552 2.551h15.566a2.557 2.557 0 002.551-2.55c0-.517-.143-1.033-.459-1.463zM71.61 30.846a2.523 2.523 0 100-5.046 2.523 2.523 0 000 5.046z" />
      </g>
      <defs>
        <clipPath id="print_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgPrint = _style(SvgIcon)

export default SvgPrint
