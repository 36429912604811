import { useEffect, useState, useCallback, useRef } from 'react'
import * as logging from 'src/modules/helpers/logging'

export type FetchOption<T> = {
  immediate?: boolean
  onComplete?: (data: T) => void
  onFailure?: (error: string) => void
}

export type SWRFetchOption<T> = Pick<FetchOption<T>, 'onFailure' | 'onComplete'>

export function useFetch<T>(
  request: () => Promise<T>,
  options?: FetchOption<T>,
) {
  const [fetched, setFetched] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<T | null>(null)
  const onComplete = options?.onComplete
  const onFailure = options?.onFailure
  const unmounted = useRef<boolean>()

  const immediate = !options || options.immediate !== false // default true

  const doFetch = useCallback(async () => {
    setFetching(true)
    setError(null)
    setData(null)

    try {
      const data = await request()
      if (unmounted.current) {
        return
      }
      setData(data)
      setFetched(true)
      setFetching(false)
      if (onComplete) {
        onComplete(data)
      }
    } catch (error: any) {
      if (unmounted.current) {
        return
      }
      setError(error)
      setFetching(false)
      if (onFailure) {
        onFailure(error)
      }
      logging.error(error)
    }
  }, [onComplete, onFailure, request])

  useEffect(() => {
    if (immediate) {
      doFetch()
    }
  }, [doFetch, immediate])

  useEffect(() => {
    return () => {
      // TODO: should cancel the ongoing request
      unmounted.current = true
    }
  }, [])

  return {
    fetched,
    fetching,
    error,
    data,
    doFetch,
  }
}
