import React, { FC, memo } from 'react'
import styled from 'styled-components'
import { ArrowRight } from './curon-icon'
import { Link, Anchor } from './link'
import { Flexbox, ViewProps } from './view'
export type BreadcrumbsProps = {
  breadcrumbs: {
    label: string
    href?: string
  }[]
} & ViewProps

const Component: FC<BreadcrumbsProps> = ({ breadcrumbs, ...props }) => (
  <BreadCrumbsNavigation aria-label="パンくずリスト">
    <Flexbox
      height="32px"
      px="14px"
      alignItems="center"
      fontSize="12px"
      {...props}
    >
      <Link to="/search">薬局を探す</Link>
      <Flexbox alignItems="center">
        {breadcrumbs.map(({ label, href }, index) => (
          <React.Fragment key={index}>
            <ArrowRight
              aria-hidden={true}
              height="10px"
              width="10px"
              minHeight="10px"
              minWidth="10px"
              mx={1}
              color="shuttleGray"
            />
            {href ? (
              <Link
                to={href}
                aria-current={
                  breadcrumbs.length === index + 1 ? 'page' : undefined
                }
              >
                {label}
              </Link>
            ) : (
              <Anchor
                href={undefined}
                color="darkGray"
                style={{ cursor: 'default', whiteSpace: 'nowrap' }}
                aria-current={
                  breadcrumbs.length === index + 1 ? 'page' : undefined
                }
              >
                {label}
              </Anchor>
            )}
          </React.Fragment>
        ))}
      </Flexbox>
    </Flexbox>
  </BreadCrumbsNavigation>
)

const BreadCrumbsNavigation = styled('nav')`
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  white-space: nowrap;
  overflow-x: auto;
`

export const BreadCrumbs = memo(Component)
