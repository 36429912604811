import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.48018 2.40115C6.9086 2.43 7.27077 2.72913 7.38005 3.14438L8.37956 6.94231C8.49039 7.36345 8.31595 7.80789 7.94824 8.0412L5.3606 9.68301C5.88399 10.9221 6.49884 11.918 7.26518 12.7349C8.08214 13.5013 9.07798 14.1161 10.3171 14.6395L11.9589 12.0519C12.1922 11.6841 12.6366 11.5097 13.0578 11.6205L16.8557 12.62C17.271 12.7293 17.5701 13.0915 17.5989 13.5199L17.8169 16.7561C17.8607 17.4064 17.2798 17.9246 16.6399 17.8008C12.1606 16.9345 8.57129 15.8828 6.34619 13.9192L6.33614 13.9301C6.29259 13.8851 6.24947 13.8395 6.20677 13.7933C6.16056 13.7506 6.11498 13.7075 6.07002 13.664L6.08089 13.6539C4.11731 11.4288 3.06558 7.8395 2.19929 3.36021C2.07553 2.72031 2.59366 2.13938 3.24395 2.18318L6.48018 2.40115ZM6.8359 6.9705L4.82736 8.24489C4.41779 6.99226 4.06235 5.53311 3.72006 3.8235L6.02989 3.90782L6.8359 6.9705ZM13.0296 13.1642L11.7552 15.1727C13.0078 15.5823 14.467 15.9377 16.1766 16.28L16.0923 13.9702L13.0296 13.1642Z"
        fill={props.color || '#2BA168'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  object,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgTel2 = _style(SvgIcon)

export default SvgTel2
