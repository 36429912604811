import React, { FC } from 'react'

import styled from 'styled-components'
import { ReactComponent as NearMeIcon } from 'src/assets/search/nearMe.svg'
import { Clickable } from 'src/modules/components/lib/clickable'
import { ArrowRight } from 'src/modules/components/lib/curon-icon'
import { Link } from 'src/modules/components/lib/link'
import { Loader } from 'src/modules/components/lib/loader'
import { Text } from 'src/modules/components/lib/text'
import { View, Flexbox } from 'src/modules/components/lib/view'
import { Pharmacy } from 'src/modules/entities/pharmacy/entity'
import { getSearchPagePath } from 'src/modules/helpers/get-serch-page-path'
import { Spacer } from 'src/modules/screens/top/spacer'
import { NearPharmacyCard } from './near-pharmacy-card'

type Props = {
  nearPharmacies: Pharmacy[] | null
  nearPharmaciesLoading: boolean
  isGeoApproved: boolean
  currentPosition: GeolocationPosition | null
}

export const Near: FC<Props> = ({
  nearPharmacies,
  nearPharmaciesLoading,
  currentPosition,
  isGeoApproved,
}) => {
  // nearPharmaciesを10件にする
  const pharmacies = nearPharmacies?.slice(0, 10)
  const nearPagePath = getSearchPagePath({
    near: true,
  })

  return (
    <View width="100%">
      {isGeoApproved && (
        <>
          <Flexbox
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text fontWeight="bold" fontSize="16px">
              現在地近くの薬局
            </Text>

            <Flexbox alignItems="center">
              <Link to={nearPagePath}>
                <Text fontWeight="bold" color="dsGray.600">
                  地図で見る
                </Text>
              </Link>
              <Spacer horizontal size={4} />
              <ArrowRight size="13px" color="darkGray" />
            </Flexbox>
          </Flexbox>
          <Spacer size={12} />
        </>
      )}

      <ScrollView>
        {nearPharmaciesLoading && (
          <Flexbox justifyContent="center" alignItems="center" width="100%">
            <Loader py="5" />
          </Flexbox>
        )}
        {!nearPharmaciesLoading &&
          pharmacies &&
          pharmacies.map(pharmacy => (
            <>
              <NearPharmacyCard
                key={pharmacy.uid}
                pharmacy={pharmacy}
                currentPosition={currentPosition}
              />
              <Spacer horizontal size={14} />
            </>
          ))}

        {!currentPosition && !nearPharmaciesLoading && (
          <View justifyContent="center" alignItems="center" width="100%">
            <IconTextButton to={nearPagePath}>
              <Flexbox
                justifyContent="center"
                alignItems="center"
                width="100%"
                paddingX="8px"
              >
                <NearMeIcon />
                <Spacer horizontal size={8} />
                <Text
                  textAlign="center"
                  color="green"
                  style={{
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '130%',
                  }}
                >
                  現在地から探す
                </Text>
              </Flexbox>
            </IconTextButton>
            <Spacer size={15} />
            <Text
              color="dsGray.400"
              textAlign="center"
              style={{
                fontWeight: 600,
                fontSize: '12px',
                lineHeight: '130%',
              }}
            >
              ※この端末の位置情報の利用をONにしてください。
            </Text>
          </View>
        )}
      </ScrollView>
    </View>
  )
}

const ScrollView = styled(Flexbox)`
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-bottom: 5px;
  &::-webkit-scrollbar {
    display: none;
  }
`

const IconTextButton = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 8px 0;
  border-radius: 4px;
  box-shadow: 0px 2px 7px rgba(111, 111, 111, 0.15);
`
