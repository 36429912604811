import css from '@styled-system/css'
import React, { FC } from 'react'
import styled from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'
import { ReactComponent as LogoHorizontalSVG } from 'src/assets/logo_horizontal.svg'
import { ReactComponent as LogoVerticalSVG } from 'src/assets/logo_vertical.svg'
import { ReactComponent as LogowithoutServiceNameSVG } from 'src/assets/logo_without_name.svg'

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const styledSystemProps = [typography, space, layout, color, position]

export type LogoProps = {
  horizontal?: boolean
  withoutServiceName?: boolean
} & StyledSystemProps &
  React.HTMLAttributes<HTMLSpanElement>

export const Logo: FC<LogoProps> = ({
  horizontal,
  withoutServiceName,
  ...props
}) => {
  if (withoutServiceName) {
    return <LogowithoutServiceName {...props} />
  }

  return horizontal ? (
    <LogoHorizontal {...props} />
  ) : (
    <LogoVertical {...props} />
  )
}

Logo.defaultProps = {
  horizontal: true,
  withoutServiceName: false,
}

const LogowithoutServiceName: FC<LogoProps> = props => (
  <Wrapper {...props}>
    <LogowithoutServiceNameSVG />
  </Wrapper>
)

const LogoVertical: FC<LogoProps> = props => (
  // will be remove after the refactoring
  <Wrapper {...props}>
    <LogoVerticalSVG />
  </Wrapper>
)

const LogoHorizontal: FC<LogoProps> = props => (
  // will be remove after the refactoring
  <Wrapper {...props}>
    <LogoHorizontalSVG />
  </Wrapper>
)

const Wrapper = styled('span')<StyledSystemProps>(
  css({
    display: 'inline-block',
    verticalAlign: 'middle',
    svg: {
      verticalAlign: 'middle',
      display: 'block',
    },
  }),
  props =>
    props.color &&
    css({
      svg: {
        '*': {
          fill: props.color,
          // stroke: props.color,
        },
      },
    }),
  props =>
    props.width &&
    css({
      svg: {
        width: props.width,
      },
    }),
  props =>
    props.height &&
    css({
      svg: {
        height: props.height,
      },
    }),
  styledSystemProps,
)
