import React, { FC } from 'react'
import styled from 'styled-components'
import { Image } from 'src/modules/components/lib/image'
import { Text } from 'src/modules/components/lib/text'
import { View } from 'src/modules/components/lib/view'

export const AboutCampaign: FC = () => {
  return (
    <View fontFamily="'Noto Sans JP', sans-serif">
      <AboutContainer>
        <AboutBlock>
          <TitleWrap>
            <Image
              src="/cp/campaign202210/about_title.png"
              alt="キャンペーン概要"
              width="100%"
            />
          </TitleWrap>

          <Text marginBottom="27px" lineHeight="1.57em">
            本キャンペーンへの応募を希望する方は、以下の応募要項をよくお読みいただき、ご同意の上でご応募ください。
          </Text>

          <AboutBox>
            <BoxTitle>
              <span>キャンペーン概要</span>
            </BoxTitle>
            <Text>
              本キャンペーン期間内に『クロン処方箋ネット受付』をご利用の上でお薬を受け取り、アンケート（1分程度）にご回答いただいた応募者の方全員にAmazonギフト券500円分をプレゼントいたします。
            </Text>
          </AboutBox>

          <AboutBox>
            <BoxTitle>
              <span>エントリー受付期間</span>
            </BoxTitle>
            <Text textAlign="center" fontWeight="500">
              2022年10月5日(水)00:00から
              <br />
              10月31日(月)23:59まで
            </Text>
            <Text fontSize="12px">
              ※上記期間内にエントリーが完了している方が本キャンペーンのプレゼント対象者となります
            </Text>
          </AboutBox>

          <AboutBox>
            <BoxTitle>
              クロン処方箋ネット受付利用
              <br />
              <Border>及びアンケート回答期限</Border>
            </BoxTitle>
            <Text textAlign="center" fontWeight="500">
              2022年10月5日(水)00:00から
              <br />
              11月30日(水)23:59まで
            </Text>
            <Text fontSize="12px">
              ※上記期間内にクロン処方箋ネット受付とアンケート回答まで完了している方がプレゼント対象者となります
              <br />
              ※エントリー受付期間とクロン処方箋ネット受付のご利用期限が異なりますので、十分にご注意ください
            </Text>
          </AboutBox>

          <AboutBox>
            <BoxTitle>
              <span>プレゼント条件</span>
            </BoxTitle>
            <Text>
              <ListText>・キャンペーンにエントリーしていること</ListText>
              <ListText>・クロンにアカウント登録していること</ListText>
              <ListText>
                ・クロン処方箋ネット受付を利用すること（2022年11月30日まで）
              </ListText>
              <ListText>
                ・アンケートに回答すること（2022年11月30日まで）
              </ListText>
            </Text>
          </AboutBox>

          <AboutBox>
            <BoxTitle>
              <span>応募方法</span>
            </BoxTitle>
            <Text marginBottom="6px">
              本キャンペーン期間内にエントリーを完了いただき『クロン処方箋ネット受付』をご利用の上、アンケート（1分程度）にご回答ください。
            </Text>
            <View>
              <Image
                src="/cp/campaign202210/about_img.png"
                alt="01エントリー、02登録またはログイン、03受付完了、メール受信、04ネット受付の利用（お薬を受け取る）、05アンケート回答、06ギフトコード連絡"
                width="100%"
                verticalAlign="bottom"
              />
            </View>
          </AboutBox>

          <AboutBox>
            <BoxTitle>
              <span>プレゼント内容</span>
            </BoxTitle>
            <ImageBox maxWidth="195px" margin="0 auto 5px">
              <Image
                src="/cp/campaign202210/about_card.png"
                alt="Amazonギフト券500円分"
                width="100%"
                verticalAlign="bottom"
              />
            </ImageBox>
            <Text textAlign="center">Amazonギフト券500円分</Text>
          </AboutBox>

          <AboutBox className="last">
            <BoxTitle>
              <span>プレゼントの受け取り方法</span>
            </BoxTitle>
            <ListText>
              ・クロン処方箋ネット受付のご利用後、アンケート回答完了後にご登録のメールアドレスへAmazonギフト券コードを送付します
            </ListText>
            <ListText>
              ・Amazonギフト券コードの送付には最大1ヵ月程度お待ちいただく場合がございます
            </ListText>
          </AboutBox>
        </AboutBlock>
      </AboutContainer>
    </View>
  )
}

const AboutContainer = styled(View)`
  max-width: 430px;
  margin: 0 auto;
  padding: 94px 20px 30px;
  background: #fffcd6;
  position: relative;
  ::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 9%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background: url(/cp/campaign202210/main_bg_under.png) no-repeat 0 0 /
      contain;
  }
`

const AboutBlock = styled(View)`
  padding: 40px 20px;
  line-height: 1.57em;
  background: #fff;
  border: 2px solid #fd7c08;
  border-radius: 4px;
  position: relative;
`

const TitleWrap = styled(View)`
  max-width: 222px;
  width: 100%;
  line-height: 1.42em;
  position: absolute;
  top -30px;
  left: 50%;
  transform: translateX(-50%);
`

const AboutBox = styled(View)`
  margin: 0 0 42px;
  &.last {
    margin-bottom: 0;
  }
`

const BoxTitle = styled(Text)`
  margin: 0 0 10px;
  text-align: center;
  font-weight: 700;
  span {
    border-bottom: 2px solid #ffd801;
  }
`

const Border = styled(View)`
  display: inline-block;
  padding: 0 1em;
  border-bottom: 2px solid #ffd801;
`

const ListText = styled(Text)`
  padding: 0 0 0 1em;
  text-indent: -1em;
`

const ImageBox = styled(View)`
  img {
    image-rendering: -webkit-optimize-contrast;
  }
`
