import { useCallback } from 'react'
import { useToast } from '../lib/toast'

type errorHandler = (err: any) => void

export const useErrorToastOnFailure = (
  originalHandler?: errorHandler,
): errorHandler => {
  const { showToast } = useToast()

  const onFailure = useCallback(
    (error: any) => {
      originalHandler && originalHandler(error)

      const message =
        error?.message || `問題が発生しました。時間を置いて再度お試しください。`
      showToast(message, true)
    },
    [originalHandler, showToast],
  )

  return onFailure
}
