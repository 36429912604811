import { PharmacySchedule } from 'src/modules/entities/enums/pharmacy-schedule'
import { PharmacyOpenedStatus } from 'src/modules/entities/enums/pharmacy-status'
import { TargetDateOpeningHour } from 'src/modules/entities/target-date-opening-hour/entity'
import {
  TimeRangeResponse,
  parse as parseTimeRangesResponse,
} from '../time-range/response'

export type TargetDateOpeningHourResponse = {
  date: string
  schedule_type: PharmacySchedule
  status: PharmacyOpenedStatus
  time_ranges?: TimeRangeResponse[]
}

export const parse = (
  resp: TargetDateOpeningHourResponse,
): TargetDateOpeningHour => {
  const res: TargetDateOpeningHour = {
    date: resp.date,
    scheduleType: resp.schedule_type,
    status: resp.status,
    timeRanges: resp.time_ranges
      ? resp.time_ranges.map(parseTimeRangesResponse)
      : [],
  }

  return res
}
