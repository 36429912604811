import { DayOfWeek } from 'src/modules/entities/enums/day-of-week'
import { RepeatType } from 'src/modules/entities/enums/repeat-type'
import { RepeatCycle } from 'src/modules/entities/repeat-cycle/entity'
import {
  TimeRangeResponse,
  parse as parseTimeRangeResponse,
} from '../time-range/response'

export type RepeatCycleResponse = {
  uid: string
  day_of_week: DayOfWeek
  repeat_type: RepeatType
  time_ranges: TimeRangeResponse[]
}

export const parse = (resp: RepeatCycleResponse): RepeatCycle => {
  const res: RepeatCycle = {
    uid: resp.uid,
    dayOfWeek: resp.day_of_week,
    repeatType: resp.repeat_type,
    timeRanges: resp.time_ranges
      ? resp.time_ranges.map(parseTimeRangeResponse)
      : [],
  }

  return res
}
