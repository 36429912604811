import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#profile_svg__clip0)">
        <path
          d="M43 86a43.09 43.09 0 01-27.292-9.747A43.128 43.128 0 01.802 51.4c-1.95-9.746-.43-19.895 4.242-28.666A43.06 43.06 0 0126.516 3.268 43.062 43.062 0 0155.44 1.834a43.155 43.155 0 0123.278 17.258 42.94 42.94 0 017.052 28.093 43.154 43.154 0 01-12.384 26.201 42.41 42.41 0 01-13.932 9.346A42.137 42.137 0 0142.999 86zm0-80.324c-8.63 0-17 2.981-23.68 8.457A37.406 37.406 0 006.392 35.718C4.7 44.175 6.019 52.977 10.09 60.602a37.271 37.271 0 0018.633 16.885c7.97 3.296 16.856 3.755 25.112 1.233 8.256-2.494 15.394-7.798 20.21-14.993a37.274 37.274 0 006.106-24.395 37.376 37.376 0 00-10.75-22.733 37.572 37.572 0 00-12.097-8.113 37.743 37.743 0 00-14.305-2.81zm0 33.597a12.32 12.32 0 01-6.91-2.093 12.661 12.661 0 01-4.586-5.59c-.946-2.264-1.176-4.787-.717-7.195.487-2.408 1.663-4.644 3.411-6.364a12.479 12.479 0 016.364-3.411 12.37 12.37 0 017.196.716 12.661 12.661 0 015.59 4.587 12.32 12.32 0 012.092 6.909c0 3.296-1.318 6.478-3.64 8.8a12.479 12.479 0 01-8.8 3.641zm0-20.01c-1.492 0-2.954.43-4.215 1.262a7.45 7.45 0 00-2.78 3.383 7.63 7.63 0 00-.43 4.386 7.52 7.52 0 002.063 3.87 7.518 7.518 0 003.87 2.064 7.676 7.676 0 004.386-.43 7.634 7.634 0 003.383-2.78c.831-1.233 1.261-2.695 1.261-4.215a7.668 7.668 0 00-2.207-5.36c-1.405-1.405-3.325-2.18-5.332-2.18zM62.205 64.3H23.792c-.659 0-1.26-.258-1.72-.717a2.456 2.456 0 01-.716-1.72v-13.33a9.33 9.33 0 012.723-6.593 9.33 9.33 0 016.593-2.723c.258 0 .488.029.746.114.057.03 5.82 1.806 11.352 1.806 5.848 0 11.81-1.806 11.868-1.806.23-.057.458-.114.716-.114a9.33 9.33 0 016.594 2.723 9.33 9.33 0 012.723 6.593v13.33c0 .316-.057.631-.172.947a2.955 2.955 0 01-.516.802c-.23.23-.487.402-.803.516-.344.115-.659.172-.974.172zm-35.977-4.873h33.54V48.532c0-1.117-.43-2.207-1.204-3.01a4.428 4.428 0 00-2.924-1.404c-1.548.43-7.195 1.92-12.9 1.92-5.447 0-10.865-1.461-12.384-1.92a4.428 4.428 0 00-2.924 1.404c-.774.832-1.175 1.892-1.204 3.01v10.894z"
          fill={props.color || '#000'}
        />
      </g>
      <defs>
        <clipPath id="profile_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgProfile = _style(SvgIcon)

export default SvgProfile
