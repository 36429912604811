import moment from 'moment'
import { TimeRange } from '../entity'

export function formatTimeRange(timeRange: TimeRange): string {
  const formatter = 'HH:mm'
  const open = moment(timeRange.openedAt).format(formatter)
  const close = moment(timeRange.closedAt).format(formatter)
  return `${open} - ${close}`
}

export const formatTimeRanges = (timeRanges: TimeRange[]): string => {
  const rangesStrings = timeRanges.reduce((result: string, _, index, array) => {
    if (index % 2 === 0) {
      let temp: string = formatTimeRange(array[index])
      if (index + 1 < array.length) {
        temp += ' / ' + formatTimeRange(array[index + 1]) + '\n'
      }
      result += temp
    }
    return result
  }, '')
  return rangesStrings
}
