export type ElectronicPrescriptionResponse = {
  uid: string
  electronic_prescription_number?: string
}

export const parse = (resp?: ElectronicPrescriptionResponse) => {
  if (!resp) return null
  const res = {
    uid: resp.uid,
    electronicPrescriptionNumber: resp.electronic_prescription_number,
  }
  return res
}
