import React, { FC, PropsWithChildren, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSubmit } from 'src/modules/apis/auth/by_token/use-submit'
import { Loader } from 'src/modules/components/lib/loader'
import { PatientAccount } from 'src/modules/entities/patient-account/entity'
import { getQueryParams } from 'src/modules/helpers/get-query-params'
import { useAccount } from '../hooks/use-account'
import { actions as authActions } from './redux'

export const TokenLoginGuard: PropsWithChildren<FC> = ({ children }) => {
  const { token_uid } = getQueryParams(['token_uid'])
  const { authorized } = useAccount()
  const dispatch = useDispatch()
  const normalFlow = !token_uid

  // Don't show error since token might already be expired after user logged in
  const { doSubmit, submitting, submitted } = useSubmit({
    onComplete: useCallback(
      (account: PatientAccount) => {
        dispatch(authActions.authorize({ account }))
      },
      [dispatch],
    ),
  })

  useEffect(() => {
    if (!token_uid || authorized) {
      return
    }

    doSubmit({ token_uid })
  }, [doSubmit, token_uid, authorized])

  if (authorized || normalFlow) {
    // Display as is
    return <>{children}</>
  }

  if (submitting || !submitted) {
    return <Loader />
  }

  return <>{children}</>
}
