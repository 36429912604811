import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M76.124 64.885L63.58 52.34a6.688 6.688 0 00-9.46 0l-3.653 3.654A61.576 61.576 0 0130.01 35.536l4.006-4.006a6.697 6.697 0 000-9.46L21.47 9.528a6.684 6.684 0 00-9.467.008l-7.882 7.944C.349 20.645-.978 25.845.742 30.773 9.568 56.065 29.938 76.434 55.23 85.261c4.758 1.66 9.645.418 12.752-2.783.045-.04.088-.08.131-.123l8.01-8.011a6.696 6.696 0 000-9.46zm-3.996 5.463l-7.83 7.83a2.79 2.79 0 00-.27.257c-1.648 1.791-4.3 2.41-6.936 1.49C33.412 71.66 14.342 52.59 6.078 28.91c-.946-2.711-.263-5.52 1.74-7.157.076-.062.149-.128.218-.198l7.971-8.033c.265-.265.573-.304.734-.304.16 0 .47.04.733.304l12.544 12.544a1.038 1.038 0 010 1.467l-5.55 5.55a2.825 2.825 0 00-.463 3.386 67.228 67.228 0 0025.528 25.528 2.826 2.826 0 003.386-.463l5.198-5.198a1.038 1.038 0 011.467 0L72.128 68.88a1.039 1.039 0 010 1.467zM45.167 22.06a2.826 2.826 0 100 5.651c7.237 0 13.125 5.888 13.125 13.125a2.826 2.826 0 005.652 0c0-10.353-8.423-18.776-18.777-18.776z"
        fill={props.color || '#000'}
      />
      <path
        d="M45.167 10.969a2.826 2.826 0 100 5.651c13.353 0 24.216 10.863 24.216 24.216a2.826 2.826 0 105.651 0c0-16.469-13.398-29.867-29.867-29.867z"
        fill={props.color || '#000'}
      />
      <path
        d="M74.04 11.963C66.328 4.25 56.074.002 45.167.002a2.826 2.826 0 000 5.651c19.4 0 35.181 15.783 35.181 35.182a2.826 2.826 0 005.652 0c0-10.907-4.247-21.161-11.96-28.873z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgPhoneCall = _style(SvgIcon)

export default SvgPhoneCall
