import css from '@styled-system/css'
import styled from 'styled-components'
import {
  borderRadius,
  BorderRadiusProps,
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

type StyledSystemProps = { invalid?: boolean } & TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  BorderRadiusProps &
  PositionProps

const styledSystemProps = [
  typography,
  space,
  layout,
  color,
  borderRadius,
  position,
]

export const Input = styled('input')<StyledSystemProps>(
  props =>
    css({
      minHeight: '40px',
      px: '2',
      py: '1',
      fontFamily: 'inherit',
      fontSize: 'large',
      color: 'appText',
      border: '1px solid',
      borderColor: props.invalid ? 'red' : 'shuttleGray',
      borderRadius: '5px',
      bg: props.invalid ? 'errorInputBackgroundColor' : 'white',
      appearance: 'none',

      '&::placeholder': {
        color: 'shuttleGray',
      },
      '&:focus': {
        color: 'appText',
        bg: props.invalid ? 'errorInputBackgroundColor' : 'pistachioGreen',
        borderColor: props.invalid ? 'red' : 'seaFormGreen',
        outline: 'none',
      },
      '&::-webkit-search-cancel-button': {
        appearance: 'none',
        height: '24px',
        width: '24px',
        marginLeft: '0.4em',
        borderRadius: '50%',
        backgroundImage:
          'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEUSURBVHgBtdPLDcIwDABQO5U4dwRGYATYhCs/AQMAGYGPgCOjICaAERihA0BCHFFES+N8JHxok9Z9TeIE4E+BZWO83K0QMc8w26zl4A4JMZT7Tqb1FJS6WHiy2PY14un9vlBC9A5ydIswLSqUOptmTn1BF4N2v3JySqBECIw6+oHNCNdmUYoUvAkF1AVWErRJ0F8JnmVxoQqfPfxJDMQ59CDnN2wchQf3obYLrik6cGr4UCfM4e87i7Iwg4MP9cIszqAUAkJCNz2jMbWcn/iXol6oajj3uYhCzfRDT6iIQWlNFZotF4BjDFoWKuQQYSxayWVwTEFDcAvP5LH9UI9rDOrDbfEMOktBKWjajQUt/5CCcvgLvecyqJ1EvLwAAAAASUVORK5CYII=)',
        backgroundColor: '#eef2f6',
        backgroundPosition: 'center',
        backgroundSize: '50%',
        backgroundRepeat: 'no-repeat',
        cursor: 'pointer',
      },
    }),
  styledSystemProps,
)
