import { PharmacyResponse, parse } from 'src/modules/apis/pharmacy/response'
import { Pharmacy } from 'src/modules/entities/pharmacy/entity'
import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'

export type Params = {
  uid: string
  page?: number
  keyword?: string
}

export type FetchResponse = {
  count_per_page: number
  current_page: number
  pharmacies: PharmacyResponse[]
  total_count: number
  total_page: number
}

export type PharmaciesSearchResponse = {
  count_per_page: number
  current_page: number
  pharmacies: Pharmacy[]
  total_count: number
  total_page: number
}

export const fetch: (
  params: Params,
) => Promise<PharmaciesSearchResponse> = async ({ uid, page, keyword }) => {
  const resp = await requestGet<FetchResponse>({
    url: `${PHARMACY_API_URL}/patients/net_receptions/external_pharmacies/${uid}/pharmacies.json`,
    params: {
      page,
      keyword,
    },
  })

  return {
    count_per_page: resp.data.count_per_page,
    current_page: resp.data.current_page,
    pharmacies: resp.data.pharmacies.map(parse),
    total_count: resp.data.total_count,
    total_page: resp.data.total_page,
  }
}
