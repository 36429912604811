import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M86 2.953V32.02c0 .745-.287 1.462-.831 1.978a2.824 2.824 0 01-1.978.831 2.708 2.708 0 01-1.978-.831 2.824 2.824 0 01-.832-1.978V9.575L22.79 67.109a2.833 2.833 0 01-.917.602c-.344.143-.717.2-1.09.2a2.96 2.96 0 01-1.548-.458c-.458-.316-.831-.746-1.032-1.262a3.178 3.178 0 01-.172-1.634 2.822 2.822 0 01.774-1.433L76.397 5.619H53.922a2.707 2.707 0 01-1.978-.832 2.824 2.824 0 01-.831-1.978c0-.745.286-1.462.831-1.978A2.707 2.707 0 0153.922 0h29.097c.745 0 1.462.287 1.978.831l.028.029.029.029.029.028s.028.029.057.029c.573.545.86 1.261.86 2.007zM73.817 85.169a2.824 2.824 0 00.831-1.978V44.032c0-.745-.287-1.462-.831-1.978a2.824 2.824 0 00-1.978-.831c-.746 0-1.462.286-1.978.831a2.824 2.824 0 00-.832 1.978v36.35H5.62V16.912h38.413c.745 0 1.462-.286 1.978-.831a2.824 2.824 0 00.831-1.978c0-.745-.286-1.462-.831-1.978a2.824 2.824 0 00-1.978-.831H2.809c-.745 0-1.462.286-1.978.831A2.707 2.707 0 000 14.104v69.087c0 .745.287 1.462.831 1.978A2.824 2.824 0 002.81 86h69.03c.745 0 1.462-.287 1.978-.831z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgExternal = _style(SvgIcon)

export default SvgExternal
