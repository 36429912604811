import { useCallback } from 'react'
import { SubmitOption, useSubmit as _useSubmit } from '../../hooks/use-submit'
import { submit, SubmitParams } from './request'

export const useSubmit = (
  options?: SubmitOption<ResolvedType<ReturnType<typeof submit>>>,
) => {
  const request = useCallback((params: SubmitParams) => {
    return submit(params)
  }, [])

  return _useSubmit(request, options)
}
