import { PharmacyVisit } from 'src/modules/entities/pharmacy-visit/entity'
import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { PharmacyVisitResponse, parse } from '../response'

export const fetch = async (
  pharmacyVisitUid: string,
): Promise<PharmacyVisit> => {
  const resp = await requestGet<PharmacyVisitResponse>({
    url: `${PHARMACY_API_URL}/patients/pharmacy_visits/${pharmacyVisitUid}.json`,
    params: {},
  })

  return parse(resp.data)
}
