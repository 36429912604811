import { SubmitParams } from 'src/modules/components/patient-registration/form'
import { ClientAccountType } from 'src/modules/entities/enums/client-account-type'
import { Gender } from 'src/modules/entities/enums/gender'
import { PatientAccount } from 'src/modules/entities/patient-account/entity'

import {
  parse as parsePatientResponse,
  PatientResponse,
} from '../patient/response'

export type PatientAccountResponse = {
  uid: string
  email: string
  main_user_uid: string
  client_account_type: ClientAccountType | null
  patients: PatientResponse[]
  line_login_associated: boolean
  apple_login_associated: boolean
  email_login_associated: boolean
}

export const parse = (resp: PatientAccountResponse): PatientAccount => {
  const res = {
    uid: resp.uid,
    email: resp.email,
    mainUserUid: resp.main_user_uid,
    clientAccountType: resp.client_account_type,
    patients: resp.patients.map(parsePatientResponse),
    lineLoginAssociated: resp.line_login_associated,
    appleLoginAssociated: resp.apple_login_associated,
    emailLoginAssociated: resp.email_login_associated,
  }

  return res
}

export type PatientAccountLineProfileResponse = {
  birthday: string
  gender: 'male' | 'female' | string
  firstname: string
  lastname: string
  firstname_j: string
  lastname_j: string
  phone: string
  postal: string
  prefecture: string
  city: string
  district: string
  building: string
  avatar: string
  street_address: string
}

export const parseLineProfile = (
  resp: PatientAccountLineProfileResponse,
): SubmitParams => {
  return {
    lastname: resp.lastname,
    firstname: resp.firstname,
    lastnameJ: resp.lastname_j,
    firstnameJ: resp.firstname_j,
    phone: resp.phone,
    gender:
      resp.gender === 'male'
        ? Gender.Male
        : resp.gender === 'female'
        ? Gender.Female
        : Gender.None,
    birthday: resp.birthday,
    postal: resp.postal,
    prefecture: resp.prefecture,
    city: resp.city,
    district: resp.district,
    building: resp.building,
  }
}
