import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { AreaResponse } from '../response'

export type Params = {
  prefecture: string | null
  keywordForSearchPharmacy: string | null
  chainCode: string | null
}

export const fetch = async (params: Params) => {
  const { prefecture, keywordForSearchPharmacy, chainCode } = params
  if (!prefecture) {
    return []
  }

  const resp = await requestGet<AreaResponse[]>({
    url: `${PHARMACY_API_URL}/patients/pharmacies/areas/search_by_prefecture.json`,
    params: {
      prefecture,
      keyword_for_search_pharmacy: keywordForSearchPharmacy,
      chain_code: chainCode,
    },
  })

  return resp.data
}
