import { useCallback } from 'react'
import { FetchOption, useFetch as _useFetch } from '../../hooks/use-fetch'
import { fetch, Params } from './request'

export const useFetch = (
  params: Params,
  options?: FetchOption<ResolvedType<ReturnType<typeof fetch>>>,
) => {
  const { keyword } = params

  const request = useCallback(() => {
    return fetch({
      keyword,
    })
  }, [keyword])

  return _useFetch(request, options)
}
