import React, { FC } from 'react'
import { ReactComponent as Error } from 'src/assets/top/error.svg'
import { useAuthorizationRefresh } from 'src/modules/auth/use-authorization-refresh'
import { Border } from 'src/modules/components/lib/border'
import { Link } from 'src/modules/components/lib/link'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox, View } from 'src/modules/components/lib/view'
import { routes } from 'src/modules/routes'
import { Footer } from 'src/modules/screens/top/footer'
import { Header } from 'src/modules/screens/top/header'

export const FailedEntryCampaign: FC = () => {
  const { authorized, fetched } = useAuthorizationRefresh()

  return (
    <>
      <Header fetched={fetched} authorized={authorized} />
      <View maxWidth="430px" mx="auto" p="20px" fontFamily="Noto Sans JP">
        <View mt="50px" mx="auto" textAlign="center">
          <Error />
        </View>
        <Text p="10px" textAlign="center" fontSize="16px" fontWeight="500">
          ご指定のページを表示できません
        </Text>
        <View paddingBottom="31px">
          <Text>
            このページは下記の理由により表示できません。
            <br />
            <br />
            ・キャンペーン期間外の場合
            <br />
            ・サーバーでエラーが発生している場合
          </Text>
        </View>
        <Border borderColor="lightGray" />

        <Flexbox mt="28px" mb="74px" justifyContent="center">
          <Link to={routes.index.build()}>
            <Text borderBottom="1px solid green">トップページに戻る</Text>
          </Link>
        </Flexbox>
      </View>
      <Footer />
    </>
  )
}
