import { useCallback, useContext } from 'react'
import {
  FetchOption,
  useFetch as _useFetch,
} from 'src/modules/apis/hooks/use-fetch'
import { FetchAppState } from './request'

export const useFetch = (
  options?: FetchOption<ResolvedType<ReturnType<typeof fetch>>>,
) => {
  const { fetch } = useContext(FetchAppState)
  const request = useCallback(() => {
    return fetch()
  }, [fetch])

  return _useFetch(request, options)
}
