import { TimeRange } from 'src/modules/entities/time-range/entity'

export type TimeRangeResponse = {
  uid: string
  closed_at: string
  opened_at: string
}

export const parse = (resp: TimeRangeResponse): TimeRange => {
  const res: TimeRange = {
    uid: resp.uid,
    openedAt: resp.opened_at,
    closedAt: resp.closed_at,
  }
  return res
}
