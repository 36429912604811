import moment from 'moment'
import React, { FC, useMemo, Fragment } from 'react'
import { ReactComponent as ClockImage } from 'src/assets/search/clock.svg'
import { ReactComponent as NearMeIcon } from 'src/assets/search/near-me.svg'
import { ArrowRight } from 'src/modules/components/lib/curon-icon'
import { Link } from 'src/modules/components/lib/link'
import { Text } from 'src/modules/components/lib/text'
import { View, Flexbox } from 'src/modules/components/lib/view'
import { getIrregularHourToday } from 'src/modules/entities/business-hour-info/helpers/get-irregular-hour-info'
import { getTimeRangesOfDate } from 'src/modules/entities/business-hour-info/helpers/get-time-ranges-of-date'
import { Pharmacy } from 'src/modules/entities/pharmacy/entity'
import { formatTimeRange } from 'src/modules/entities/time-range/helpers/format-time-ranges'
import { timeRangeCompetitor } from 'src/modules/entities/time-range/helpers/time-range-competitor'
import { getDistance, getDistanceText } from 'src/modules/helpers/get-distance'
import { routes } from 'src/modules/routes'
import { Spacer } from 'src/modules/screens/top/spacer'

type Props = {
  pharmacy: Pharmacy
  currentPosition: GeolocationPosition | null
  externalPharmacyUid?: string
}

export const NearPharmacyCard: FC<Props> = ({
  pharmacy,
  currentPosition,
  externalPharmacyUid,
}) => {
  const timeRangeOfDate = useMemo(() => {
    if (!pharmacy.businessHourInfo) return null

    return getTimeRangesOfDate(pharmacy.businessHourInfo, moment())
  }, [pharmacy.businessHourInfo])

  const irregularHourToday = useMemo(() => {
    if (!pharmacy.businessHourInfo) return null
    return getIrregularHourToday(pharmacy.businessHourInfo)
  }, [pharmacy.businessHourInfo])

  const distance = useMemo(() => {
    if (!pharmacy.lat || !currentPosition) return null
    const pharmacyLocation = {
      lat: pharmacy.lat,
      lng: pharmacy.lng,
    }

    const currentLocation = {
      lat: currentPosition.coords.latitude,
      lng: currentPosition.coords.longitude,
    }

    return getDistance({
      from: pharmacyLocation,
      to: currentLocation,
    })
  }, [pharmacy, currentPosition])

  return (
    <Link
      to={{
        pathname: routes.pharmaciesDetail.build(pharmacy.uid),
        search: externalPharmacyUid
          ? `external_pharmacy_uid=${externalPharmacyUid}`
          : undefined,
      }}
    >
      <Flexbox
        minWidth="280px"
        maxHeight="90px"
        minHeight="90px"
        borderRadius="8px"
        background="white"
        boxShadow="0px 2px 7px 0px rgba(111, 111, 111, 0.15)"
        justifyContent="space-between"
      >
        <View padding="9px 0 9px 11px ">
          <Text
            fontSize="large"
            fontWeight="bold"
            color="dsGray.900"
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              width: '250px',
            }}
          >
            {pharmacy.name}
          </Text>

          <Flexbox alignItems="flex-start">
            <Flexbox alignItems="flex-start" color="#637182">
              <ClockImage />
              <Spacer size={4} horizontal />
              <Text color="dsGray.400">本日</Text>
              <Spacer size={8} horizontal />

              {!irregularHourToday && (
                <>
                  {timeRangeOfDate && timeRangeOfDate?.length === 0 ? (
                    <Text>休業</Text>
                  ) : (
                    <>
                      <View>
                        {timeRangeOfDate?.map((time, index) => (
                          <Fragment key={index}>
                            <Text fontWeight="bold" color="dsGray.400">
                              {formatTimeRange(time)}
                            </Text>
                          </Fragment>
                        ))}
                      </View>
                    </>
                  )}
                </>
              )}

              {irregularHourToday && (
                <>
                  <Spacer size={8} horizontal />
                  {irregularHourToday.opened ? (
                    <>
                      <Text color="dsRed.600">特別営業</Text>
                      {irregularHourToday.timeRanges
                        .sort((a, b) => timeRangeCompetitor(a, b))
                        .map((time, index) => (
                          <Text key={index}>{formatTimeRange(time)}</Text>
                        ))}
                    </>
                  ) : (
                    <Text color="dsRed.600">特別休業</Text>
                  )}
                </>
              )}
            </Flexbox>

            <Spacer horizontal size={8} />
            {distance && (
              <Flexbox alignItems="center">
                <NearMeIcon />
                <Spacer horizontal size={4} />
                <Text fontSize="14px" fontWeight="bold" color="dsGray.400">
                  {getDistanceText(distance)}
                </Text>
              </Flexbox>
            )}
          </Flexbox>
        </View>

        <Flexbox
          width="26px"
          backgroundColor="dsGreen.400"
          alignItems="center"
          justifyContent="center"
          borderRadius="0 8px 8px 0"
        >
          <ArrowRight color="white" size="16px" />
        </Flexbox>
      </Flexbox>
    </Link>
  )
}
