import { PharmacyResponse, parse } from 'src/modules/apis/pharmacy/response'
import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'

export type Params = {
  chainCode?: string
}

export type FetchResponse = {
  pharmacies: PharmacyResponse[]
  count: number
  prefecture_count: { [key: string]: number }
}

export const swrKey = 'patients/pharmacies/latest'

export const fetch = async (params: Params) => {
  const resp = await requestGet<FetchResponse>({
    url: `${PHARMACY_API_URL}/patients/pharmacies/latest.json`,
    params: {
      chain_code: params.chainCode,
    },
  })

  return {
    pharmacies: resp.data.pharmacies.map(parse),
    count: resp.data.count,
    prefectureCount: resp.data.prefecture_count,
  }
}
