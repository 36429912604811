import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.55" cx="8" cy="8" r="8" fill="#B1DEC7" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.7803 4.71967C13.0732 5.01256 13.0732 5.48744 12.7803 5.78033L7.28033 11.2803C6.98744 11.5732 6.51256 11.5732 6.21967 11.2803L3.21967 8.28033C2.92678 7.98744 2.92678 7.51256 3.21967 7.21967C3.51256 6.92678 3.98744 6.92678 4.28033 7.21967L6.75 9.68934L11.7197 4.71967C12.0126 4.42678 12.4874 4.42678 12.7803 4.71967Z"
        fill={props.color || '#2BA168'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  object,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgCheck2 = _style(SvgIcon)

export default SvgCheck2
