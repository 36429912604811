import { IdDocument } from 'src/modules/entities/id-document/entitiy'

export type IdDocumentResponse = {
  uid: string
  url: string
  registered_at: string
  document_type: string
  confirmed_at: string
}

export const parse = (resp: IdDocumentResponse): IdDocument => {
  const idDocumentType =
    resp.document_type === 'claimant_certification'
      ? 'ClaimantCertification'
      : 'InsuranceCard'
  return {
    uid: resp.uid,
    url: resp.url,
    registeredAt: resp.registered_at,
    documentType: idDocumentType,
    confirmedAt: resp.confirmed_at,
  }
}
