import { DeliveryFeePaidBy } from 'src/modules/entities/enums/delivery-fee-paid-by'
import { SettlementRecordStatus } from 'src/modules/entities/enums/settlement-record-status'
import type { CurrentSettlementRecord } from 'src/modules/entities/current-settlement-record/entity'

export type CurrentSettlementRecordResponse = {
  cancelled_at: string | null
  delivery_fee: number
  delivery_fee_paid_by: DeliveryFeePaidBy
  failed_at: string | null
  insurance_covered_amount: number
  non_insurance_covered_amount: number
  paid_at: string | null
  patient_payment_amount: number
  status: SettlementRecordStatus
  uid: string
}

export const parse = (
  resp: CurrentSettlementRecordResponse,
): CurrentSettlementRecord => ({
  cancelledAt: resp.cancelled_at,
  deliveryFee: Number(resp.delivery_fee),
  deliveryFeePaidBy: resp.delivery_fee_paid_by,
  failedAt: resp.failed_at,
  insuranceCoveredAmount: Number(resp.insurance_covered_amount),
  nonInsuranceCoveredAmount: Number(resp.non_insurance_covered_amount),
  paidAt: resp.paid_at,
  patientPaymentAmount: Number(resp.patient_payment_amount),
  status: resp.status,
  uid: resp.uid,
})
