import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'

export const fetch = async (): Promise<{ unreadCount: number }> => {
  const resp = await requestGet<{ unread_count: number }>({
    url: `${PHARMACY_API_URL}/patients/announcements/unread_count.json`,
    params: {},
  })

  return {
    unreadCount: resp.data.unread_count,
  }
}
