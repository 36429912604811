import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#creditcard_svg__clip0)">
        <path
          d="M85.226 11.352a2.579 2.579 0 00-1.835-.746H2.61c-.688 0-1.348.287-1.835.746A2.607 2.607 0 000 13.186v59.627c0 .688.287 1.347.774 1.835a2.58 2.58 0 001.835.745H83.42c.688 0 1.347-.287 1.835-.745a2.607 2.607 0 00.774-1.835V13.186a2.764 2.764 0 00-.803-1.834zM5.189 26.43h75.594v4.76H5.189v-4.76zM80.81 15.795v5.475H5.19v-5.475H80.81zM5.19 70.205V36.377h75.594v33.826H5.189zm31.246-20.297H15.222a2.706 2.706 0 01-1.835-.745 2.607 2.607 0 01-.774-1.834c0-.688.287-1.348.774-1.835a2.58 2.58 0 011.835-.745h21.213c.688 0 1.348.286 1.835.745.487.487.774 1.147.774 1.834 0 .688-.287 1.348-.774 1.835a2.579 2.579 0 01-1.835.745zm0 11.668H15.222a2.706 2.706 0 01-1.835-.745 2.607 2.607 0 01-.774-1.835c0-.688.287-1.348.774-1.835a2.58 2.58 0 011.835-.745h21.213c.688 0 1.348.287 1.835.745.487.487.774 1.147.774 1.835s-.287 1.347-.774 1.834a2.706 2.706 0 01-1.835.746zm34.343 2.15H55.183a2.706 2.706 0 01-1.834-.745 2.607 2.607 0 01-.774-1.835V45.178c0-.688.286-1.347.774-1.834a2.579 2.579 0 011.834-.745h15.595c.688 0 1.347.286 1.835.745.487.487.774 1.146.774 1.834v15.968c0 .688-.287 1.347-.774 1.834a2.58 2.58 0 01-1.835.746zm-12.986-5.189h10.406V47.73H57.792v10.807z"
          fill={props.color || '#000'}
        />
      </g>
      <defs>
        <clipPath id="creditcard_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgCreditcard = _style(SvgIcon)

export default SvgCreditcard
