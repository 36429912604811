type Area = {
  name: string
  slug: string
}

export const disableArea: Area[] = [
  {
    name: '札幌市',
    slug: 'ac011002',
  },
  {
    name: '仙台市',
    slug: 'ac041009',
  },
  {
    name: 'さいたま市',
    slug: 'ac111007',
  },
  {
    name: '千葉市',
    slug: 'ac121002',
  },
  {
    name: '横浜市',
    slug: 'ac141003',
  },
  {
    name: '川崎市',
    slug: 'ac141305',
  },
  {
    name: '相模原市',
    slug: 'ac141500',
  },
  {
    name: '新潟市',
    slug: 'ac151009',
  },
  {
    name: '静岡市',
    slug: 'ac221007',
  },
  {
    name: '浜松市',
    slug: 'ac221309',
  },
  {
    name: '名古屋市',
    slug: 'ac231002',
  },
  {
    name: '京都市',
    slug: 'ac261009',
  },
  {
    name: '京都市',
    slug: 'aomori',
  },
  {
    name: '大阪市',
    slug: 'ac271004',
  },
  {
    name: '堺市',
    slug: 'ac271403',
  },
  {
    name: '神戸市',
    slug: 'ac281000',
  },
  {
    name: '岡山市',
    slug: 'ac331007',
  },
  {
    name: '広島市',
    slug: 'ac331007',
  },
  {
    name: '北九州市',
    slug: 'ac401005',
  },
  {
    name: '福岡市',
    slug: 'ac401307',
  },
  {
    name: '熊本市',
    slug: 'ac431001',
  },
]
