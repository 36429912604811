import css from '@styled-system/css'
import React, { FC } from 'react'
import styled from 'styled-components'
import {
  background,
  BackgroundProps,
  borders,
  BordersProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  boxShadow,
  BoxShadowProps,
} from 'styled-system'

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps &
  FlexboxProps &
  GridProps &
  BackgroundProps &
  BordersProps &
  BoxShadowProps

const styledSystemProps = [
  typography,
  space,
  layout,
  color,
  position,
  flexbox,
  grid,
  background,
  borders,
  boxShadow,
]

export type ViewProps = StyledSystemProps & React.HTMLAttributes<HTMLDivElement>

export const View: FC<ViewProps> = props => {
  return <ViewRoot {...props}>{props.children}</ViewRoot>
}

const ViewRoot = styled('div')<StyledSystemProps>({}, styledSystemProps)

export type EditableViewProps = { placeholder: string } & ViewProps

export const EditableView = React.forwardRef(
  (props: EditableViewProps, ref: React.Ref<HTMLDivElement>) => {
    return (
      <EditableViewRoot
        ref={ref}
        contentEditable={true}
        data-placeholder={props.placeholder}
        {...props}
      >
        {props.children}
      </EditableViewRoot>
    )
  },
)

const EditableViewRoot = styled('div')<StyledSystemProps>(
  css({
    outline: 'none',
    fontSize: '16px',
    ':empty:before': {
      content: 'attr(data-placeholder)',
      color: '#CBD3DC',
    },
  }),
  styledSystemProps,
)

export const Flexbox: FC<ViewProps> = props => {
  return (
    <View display="flex" {...props}>
      {props.children}
    </View>
  )
}

export const Grid: FC<ViewProps> = props => {
  return (
    <View display="grid" {...props}>
      {props.children}
    </View>
  )
}
