import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43 0C24.003 0 8.547 15.456 8.547 34.453v.952c0 9.608 5.509 20.804 16.373 33.277 7.876 9.042 15.641 15.347 15.968 15.611L43 86l2.113-1.707c.326-.264 8.092-6.569 15.968-15.61 10.864-12.474 16.372-23.67 16.372-33.278v-.952C77.453 15.456 61.998 0 43 0zm27.73 35.406c0 16.236-20.92 35.891-27.73 41.858-6.811-5.969-27.73-25.623-27.73-41.858v-.953c0-15.29 12.44-27.73 27.73-27.73 15.29 0 27.73 12.44 27.73 27.73v.953z"
        fill={props.color || '#000'}
      />
      <path
        d="M43 18.487c-8.803 0-15.966 7.162-15.966 15.966 0 8.803 7.163 15.966 15.966 15.966 8.804 0 15.966-7.163 15.966-15.966 0-8.804-7.162-15.966-15.966-15.966zm0 25.21c-5.097 0-9.243-4.147-9.243-9.244 0-5.097 4.146-9.244 9.243-9.244s9.244 4.147 9.244 9.244c0 5.097-4.147 9.243-9.244 9.243z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgPin = _style(SvgIcon)

export default SvgPin
