import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M81.246 20.075H64.193l-3.696-9.627a2.872 2.872 0 00-2.67-1.848H28.19c-1.193 0-2.252.74-2.672 1.848l-3.696 9.627H4.771A4.779 4.779 0 000 24.845v47.78a4.779 4.779 0 004.771 4.772H81.23a4.779 4.779 0 004.77-4.772v-47.78c.018-2.637-2.133-4.77-4.753-4.77zm-.958 51.61H5.728V25.803H23.79c1.193 0 2.251-.739 2.671-1.848l3.696-9.626h25.688l3.696 9.626a2.872 2.872 0 002.67 1.848h18.061l.017 45.882z"
        fill={props.color || '#000'}
      />
      <path
        d="M43.008 26.274c-11.121 0-20.16 9.055-20.16 20.16 0 11.122 9.039 20.16 20.16 20.16 11.122 0 20.16-9.055 20.16-20.16s-9.038-20.16-20.16-20.16zm0 34.608c-7.963 0-14.43-6.468-14.43-14.431 0-7.963 6.467-14.431 14.43-14.431 7.964 0 14.432 6.468 14.432 14.43 0 7.947-6.468 14.432-14.432 14.432z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgCamera = _style(SvgIcon)

export default SvgCamera
