import React, { FC } from 'react'
import styled from 'styled-components'
import { ArrowLeft2, ArrowRight2 } from 'src/modules/components/lib/curon-icon'
import { Link } from 'src/modules/components/lib/link'

const ADJACENT_PAGES = 2 // 前後のページ数

const withQuery = (query: string) => (query ? `?${query}` : '')

type PrevProps = {
  current: number
  path: string
  params: URLSearchParams
}

const Prev: FC<PrevProps> = props => {
  const { current, path, params } = props
  const prevPage = current - 1
  if (prevPage >= 1) {
    params.set('page', String(prevPage))
    const query = withQuery(params.toString())
    return (
      <Number>
        <Link to={`${path}${query}`}>
          <ArrowLeft2 size="14px" color="#2BA168" />
        </Link>
      </Number>
    )
  }
  return (
    <Number>
      <a>
        <ArrowLeft2 size="14px" color="#2BA168" />
      </a>
    </Number>
  )
}

type NextProps = {
  current: number
  max: number
  path: string
  params: URLSearchParams
}

const Next: FC<NextProps> = props => {
  const { current, max, path, params } = props
  const nextPage = current + 1
  if (nextPage <= max) {
    params.set('page', String(nextPage))
    const query = withQuery(params.toString())
    return (
      <Number>
        <Link to={`${path}${query}`}>
          <ArrowRight2 size="14px" color="#2BA168" />
        </Link>
      </Number>
    )
  }
  return (
    <Number>
      <a>
        <ArrowRight2 size="14px" color="#2BA168" />
      </a>
    </Number>
  )
}

type FirstProps = {
  current: number
  path: string
  params: URLSearchParams
}

const First: FC<FirstProps> = props => {
  const { current, path, params } = props
  params.delete('page')
  const query = withQuery(params.toString())
  return current - ADJACENT_PAGES > 1 ? (
    <Number>
      <Link to={`${path}${query}`}>1</Link>
    </Number>
  ) : null
}

type LastProps = {
  current: number
  max: number
  path: string
  params: URLSearchParams
}

const Last: FC<LastProps> = props => {
  const { current, max, path, params } = props

  params.set('page', String(max))
  const query = withQuery(params.toString())
  return max - current > ADJACENT_PAGES ? (
    <Number>
      <Link to={`${path}${query}`}>{max}</Link>
    </Number>
  ) : null
}

type PrevPagesType = {
  current: number
  path: string
  params: URLSearchParams
}

const prevRanges = [...Array(ADJACENT_PAGES)]
const PrevPages: FC<PrevPagesType> = props => {
  const { current, path, params } = props
  return (
    <>
      {current - ADJACENT_PAGES - 1 > 1 ? <Number>...</Number> : ''}
      {prevRanges
        .map((_, i) => current - ADJACENT_PAGES + i)
        .filter(i => i > 0)
        .map(i => {
          if (i > 1) params.set('page', String(i))
          const query = withQuery(params.toString())
          return (
            <Number key={i}>
              <Link to={`${path}${query}`}>{i}</Link>
            </Number>
          )
        })}
    </>
  )
}

type NextPagesType = {
  current: number
  max: number
  path: string
  params: URLSearchParams
}

const nextRanges = [...Array(ADJACENT_PAGES)]
const NextPages: FC<NextPagesType> = props => {
  const { current, max, path, params } = props
  return (
    <>
      {nextRanges
        .map((_, i) => current + (i + 1))
        .filter(i => i <= max)
        .map(i => {
          params.set('page', String(i))
          const query = withQuery(params.toString())
          return (
            <Number key={i}>
              <Link to={`${path}${query}`}>{i}</Link>
            </Number>
          )
        })}
      {current + ADJACENT_PAGES + 1 < max ? <Number>...</Number> : ''}
    </>
  )
}

type CurrentPageType = {
  current: number
}

const CurrentPage: FC<CurrentPageType> = props => {
  const { current } = props
  return (
    <CurrentNumber>
      <a>{current}</a>
    </CurrentNumber>
  )
}

type PaginationProps = {
  current: number
  max: number
  path: string
  params: URLSearchParams
}

export const SearchPagination: FC<PaginationProps> = props => {
  const { current, max, path, params } = props
  return (
    <>
      <PaginationBox>
        <Prev current={current} path={path} params={params} />
        <First current={current} path={path} params={params} />
        <PrevPages current={current} path={path} params={params} />
        <CurrentPage current={current} />
        <NextPages current={current} max={max} path={path} params={params} />
        <Last current={current} max={max} path={path} params={params} />
        <Next current={current} max={max} path={path} params={params} />
      </PaginationBox>
    </>
  )
}

const PaginationBox = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 14px;
  margin: 24px 0;
`

const Number = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #2ba168;
  a {
    width: 28px;
    height: 28px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    color: #2ba168;
  }
`

const CurrentNumber = styled(Number)`
  background: #b1dec7;
  border-radius: 14px;
  color: #272c33;
`
