import { ConnectedRouter } from 'connected-react-router'
import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import { FallbackBackground } from 'src/modules/components/fallback/fallback-background'
import { HamburgerHeaderProvider } from 'src/modules/components/header/hamburger-header-provider'
import { NetApplicationChainTopSearchScreenContainer } from 'src/modules/screens/net-application/search/chain-top/screen-container'
import { NetApplicationNearSearchScreenContainer } from 'src/modules/screens/net-application/search/near/screen-container'
import { NetApplicationSearchScreenContainer } from 'src/modules/screens/net-application/search/screen-container'
import { NetApplicationSearchTopScreenContainer } from 'src/modules/screens/net-application/search/top/screen-container'
import { NotFound } from 'src/modules/screens/NotFound'
import { PrerenderScreen } from 'src/modules/screens/prerender/screen'
import { LoggedInGuard } from './auth/logged-in-guard'
import { MainProfileGuard } from './auth/main-profile-guard'
import { MicinAccountGuard } from './auth/micin-account-guard'
import { TokenLoginGuard } from './auth/token-login-guard'
import { ScrollRestoration } from './helpers/scroll-restoration'
import { routes } from './routes'
import { AppTop } from './screens/cp/app/screen'
import { CompletedEntryCampaign } from './screens/cp/campaign202210/completed'
import { FailedEntryCampaign } from './screens/cp/campaign202210/failed'
import { Campaign } from './screens/cp/campaign202210/screen'
import { CampaignScreen } from './screens/entry-campaign/screen'
import { LoginScreenContainer } from './screens/login/screen-container'
import { MaintenanceScreenContainer } from './screens/maintenance/screen-container'
import { NetApplicationExternalPharmacyTopSearchScreenContainer } from './screens/net-application/search/external-pharmacy-top/screen-container'
import { RedirectScreen } from './screens/redirect/screen'
import { ApplicationCompletedGuard } from './screens/telepharmacy/application-completed-guard'
import { TopPage } from './screens/top/screen'
import { history } from './store'

const AccountRegisterRouter = lazy(
  () => import('./screens/register-account/router'),
)
const UpdatePasswordScreenContainer = lazy(
  () => import('./screens/update-password/screen-container'),
)
const NotFoundScreen = lazy(() => import('./screens/not-found/screen'))
const HomeScreenContainer = lazy(
  () => import('./screens/home/screen-container'),
)
const NetApplicationDetailScreenContainer = lazy(
  () => import('./screens/net-application/detail/screen-container'),
)

const NetApplicationRouter = lazy(
  () => import('./screens/net-application/router'),
)
const GuestRouter = lazy(() => import('./screens/guest/router'))
const FollowUpRouter = lazy(
  () => import('src/modules/screens/follow-up/router'),
)
const TelepharmacyRouter = lazy(
  () => import('src/modules/screens/telepharmacy/router'),
)
const SettingsRouter = lazy(() => import('./screens/settings/router'))
const AnnouncementRouter = lazy(() => import('./screens/announcement/router'))
const ReservationVideoCallScreenContainer = lazy(
  () =>
    import(
      'src/modules/screens/telepharmacy/reservation/video-call/screen-container'
    ),
)

export const Router = () => {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [])

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path={routes.index.path}>
          <ScrollRestoration />
          <TopPage />
        </Route>
        <Route exact path={routes.app.path}>
          <AppTop />
        </Route>
        <Route exact path={routes.campaign202210.path}>
          <Campaign />
        </Route>
        <Route exact path={routes.completedEntryCampaign.path}>
          <CompletedEntryCampaign />
        </Route>
        <Route exact path={routes.FailedEntryCampaign.path}>
          <FailedEntryCampaign />
        </Route>
        {/* プリレンダリング対象のルートはSuspense使用不可のため、この行より上に移動する必要がある。
            またステージング環境デプロイ後に、クルクル(Suspense)と、ページのコンポーネントが両方表示されてしまう場合も、この行より上に移動する必要がある。
        */}
        <Route exact path={routes.login.path}>
          <LoginScreenContainer />
        </Route>
        <Route exact path={routes.search.path}>
          <NetApplicationSearchTopScreenContainer />
        </Route>
        <Route exact path={routes.searchNear.path}>
          <NetApplicationNearSearchScreenContainer />
        </Route>
        <Route exact path={routes.searchList.path}>
          <NetApplicationSearchScreenContainer />
        </Route>
        <Route exact path={routes.searchPref.path}>
          <NetApplicationSearchScreenContainer />
        </Route>
        <Route exact path={routes.searchPrefArea.path}>
          <NetApplicationSearchScreenContainer />
        </Route>
        <Route exact path={routes.searchPrefStation.path}>
          <NetApplicationSearchScreenContainer />
        </Route>
        <Route exact path={routes.searchChainList.path}>
          <NetApplicationSearchScreenContainer />
        </Route>
        <Route exact path={routes.searchChain.path}>
          <NetApplicationChainTopSearchScreenContainer />
        </Route>
        <Route exact path={routes.searchChainPref.path}>
          <NetApplicationSearchScreenContainer />
        </Route>
        <Route exact path={routes.searchChainPrefArea.path}>
          <NetApplicationSearchScreenContainer />
        </Route>
        <Route exact path={routes.searchChainPrefStation.path}>
          <NetApplicationSearchScreenContainer />
        </Route>
        <Route exact path={routes.searchExternalPharmacy.path}>
          <NetApplicationExternalPharmacyTopSearchScreenContainer />
        </Route>
        <Route exact path={routes.pharmaciesDetail.path}>
          <Suspense fallback={<FallbackBackground />}>
            <NetApplicationDetailScreenContainer />
          </Suspense>
        </Route>
        <Route path={routes.accountRegister.path}>
          <Suspense fallback={<FallbackBackground />}>
            <AccountRegisterRouter />
          </Suspense>
        </Route>
        <Route exact path={routes.notFound.path}>
          <Suspense fallback={<FallbackBackground />}>
            <NotFoundScreen />
          </Suspense>
        </Route>
        <Route path={routes.announcement.path}>
          <Suspense fallback={<FallbackBackground />}>
            <LoggedInGuard>
              <AnnouncementRouter />
            </LoggedInGuard>
          </Suspense>
        </Route>
        <Route path={routes.settings.path}>
          <Suspense fallback={<FallbackBackground />}>
            <MainProfileGuard
              requiredFields={['name', 'gender', 'birthday', 'phone']}
            >
              <MicinAccountGuard>
                <SettingsRouter />
              </MicinAccountGuard>
            </MainProfileGuard>
          </Suspense>
        </Route>
        <Route path={routes.updatePassword.path}>
          <Suspense fallback={<FallbackBackground />}>
            <LoggedInGuard>
              <MicinAccountGuard>
                <UpdatePasswordScreenContainer />
              </MicinAccountGuard>
            </LoggedInGuard>
          </Suspense>
        </Route>
        <Route path={routes.followUp.path}>
          <Suspense fallback={<FallbackBackground />}>
            <MainProfileGuard
              requiredFields={['name', 'gender', 'birthday', 'phone']}
            >
              <MicinAccountGuard>
                <HamburgerHeaderProvider>
                  <FollowUpRouter />
                </HamburgerHeaderProvider>
              </MicinAccountGuard>
            </MainProfileGuard>
          </Suspense>
        </Route>
        <Route path={routes.netApplication.path}>
          <Suspense fallback={<FallbackBackground />}>
            <MainProfileGuard
              requiredFields={['name', 'gender', 'birthday', 'phone']}
            >
              <HamburgerHeaderProvider>
                <NetApplicationRouter />
              </HamburgerHeaderProvider>
            </MainProfileGuard>
          </Suspense>
        </Route>
        <Route path={routes.guest.path}>
          <Suspense fallback={<FallbackBackground />}>
            <GuestRouter />
          </Suspense>
        </Route>
        {/* Moved from <TelepharmacyRouter /> in order to allow token login */}
        <Route path={routes.telepharmacyVideoCall.path}>
          <Suspense fallback={<FallbackBackground />}>
            <TokenLoginGuard>
              <MainProfileGuard
                requiredFields={[
                  'name',
                  'name_j',
                  'gender',
                  'birthday',
                  'phone',
                  'address',
                ]}
              >
                <ApplicationCompletedGuard>
                  <ReservationVideoCallScreenContainer />
                </ApplicationCompletedGuard>
              </MainProfileGuard>
            </TokenLoginGuard>
          </Suspense>
        </Route>
        <Route path={routes.telepharmacy.path}>
          <Suspense fallback={<FallbackBackground />}>
            <MainProfileGuard
              requiredFields={['name', 'gender', 'birthday', 'phone']}
            >
              <HamburgerHeaderProvider>
                <TelepharmacyRouter />
              </HamburgerHeaderProvider>
            </MainProfileGuard>
          </Suspense>
        </Route>
        <Route path={routes.home.path}>
          <Suspense fallback={<FallbackBackground />}>
            <MainProfileGuard
              requiredFields={['name', 'gender', 'birthday', 'phone']}
            >
              <MicinAccountGuard>
                <HamburgerHeaderProvider>
                  <HomeScreenContainer />
                </HamburgerHeaderProvider>
              </MicinAccountGuard>
            </MainProfileGuard>
          </Suspense>
        </Route>
        <Route path={routes.campaign.path}>
          <Suspense fallback={<FallbackBackground />}>
            <CampaignScreen />
          </Suspense>
        </Route>
        <Route path={routes.redirect.path}>
          <Suspense fallback={<FallbackBackground />}>
            <RedirectScreen />
          </Suspense>
        </Route>
        <Route path={routes.maintenance.path}>
          <MaintenanceScreenContainer />
        </Route>
        <Route path="/prerender/1B3D8F2H5J7L">
          <PrerenderScreen />
        </Route>
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </ConnectedRouter>
  )
}
