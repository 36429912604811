import React, { FC, useCallback, useState } from 'react'

import { TextButton } from 'src/modules/components/lib/button'
import { ArrowRight, ArrowDown } from 'src/modules/components/lib/curon-icon'
import { Link } from 'src/modules/components/lib/link'
import { Text } from 'src/modules/components/lib/text'
import { View, Flexbox } from 'src/modules/components/lib/view'
import { PREFECTURE_LIST } from 'src/modules/constants/prefecture-list'
import { getSearchPagePath } from 'src/modules/helpers/get-serch-page-path'
import { Spacer } from 'src/modules/screens/top/spacer'

type Props = {}

type SearchPrefecture = {
  value: string
  name: string
}

export const mainSearchPrefecture: SearchPrefecture[] = [
  {
    value: 'tokyo',
    name: '東京都',
  },
  {
    value: 'kanagawa',
    name: '神奈川県',
  },
  {
    value: 'shizuoka',
    name: '静岡県',
  },
  {
    value: 'aichi',
    name: '愛知県',
  },
  {
    value: 'kyoto',
    name: '京都府',
  },
  {
    value: 'osaka',
    name: '大阪府',
  },
]

export const LocationSearch: FC<Props> = () => {
  const [showAccordion, setShowAccordion] = useState(false)

  const prefectureList = PREFECTURE_LIST.filter(
    pref =>
      !mainSearchPrefecture.some(mainPref => mainPref.value === pref.value),
  )

  const opneAccordion = useCallback(() => {
    setShowAccordion(true)
  }, [])

  const closeAccordion = useCallback(() => {
    setShowAccordion(false)
  }, [])

  return (
    <View width="100%">
      <Text fontSize="large" fontWeight="bold">
        エリアから探す
      </Text>
      <Spacer size={14} />
      <Flexbox
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        style={{ columnGap: '20px', rowGap: '14px' }}
      >
        {mainSearchPrefecture.map(pref => (
          <MainCity pref={pref} />
        ))}
      </Flexbox>
      <Spacer size={14} />
      <Flexbox justifyContent="flex-end">
        <TextButton onClick={showAccordion ? closeAccordion : opneAccordion}>
          <Flexbox alignItems="center">
            <Text fontWeight="bold" color="darkGray">
              全国から探す
            </Text>
            <Spacer horizontal size={4} />
            {showAccordion ? (
              <ArrowDown size="13px" color="darkGray" />
            ) : (
              <ArrowRight size="13px" color="darkGray" />
            )}
          </Flexbox>
        </TextButton>
      </Flexbox>
      {showAccordion && (
        <View>
          <Spacer size={14} />
          <Flexbox
            display="grid"
            gridTemplateColumns="repeat(4, 1fr)"
            style={{ columnGap: '20px', rowGap: '14px' }}
          >
            {prefectureList.map(pref => {
              const path = getSearchPagePath({
                near: false,
                chainCode: undefined,
                keyword: undefined,
                prefecture: pref.value,
              })

              return (
                <Link to={path}>
                  <Text
                    textAlign="center"
                    style={{
                      textDecoration: 'underline',
                    }}
                  >
                    {pref.name}
                  </Text>
                </Link>
              )
            })}
          </Flexbox>
        </View>
      )}
    </View>
  )
}

export const MainCity: FC<{ pref: SearchPrefecture }> = ({ pref }) => {
  const path = getSearchPagePath({
    near: false,
    chainCode: undefined,
    keyword: undefined,
    prefecture: pref.value,
  })

  return (
    <Link
      display="flex"
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
      paddingY="20px"
      style={{
        borderRadius: '4px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
        border: ' 1px solid #B2BFCE',
      }}
      to={path}
    >
      <Text color="black" textAlign="center" fontSize="large" fontWeight="bold">
        {pref.name}
      </Text>
    </Link>
  )
}
