import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.125 3.62085C0.125 3.29409 0.252577 2.98067 0.512471 2.7826C1.32794 2.16114 3.44501 0.875 7 0.875C10.555 0.875 12.6721 2.16114 13.4875 2.78261C13.7474 2.98067 13.875 3.29409 13.875 3.62085V14C13.875 14.6904 13.3154 15.25 12.625 15.25L10.7499 15.25L11.558 16.0581C11.9517 16.4518 11.6729 17.125 11.1161 17.125H11.0088C10.8431 17.125 10.6841 17.0592 10.5669 16.9419L8.87494 15.25H5.12494L3.433 16.9419C3.31579 17.0592 3.15681 17.125 2.99105 17.125H2.88382C2.32701 17.125 2.04815 16.4518 2.44188 16.0581L3.24994 15.25L1.375 15.25C0.684644 15.25 0.125 14.6904 0.125 14V3.62085ZM12.625 4.625H7.625V7.75H12.625V4.625ZM1.375 7.75H6.375V4.625H1.375V7.75ZM4.08344 12.3333C4.77379 12.3333 5.33344 11.7736 5.33344 11.0833C5.33344 10.3929 4.77379 9.83328 4.08344 9.83328C3.39308 9.83328 2.83344 10.3929 2.83344 11.0833C2.83344 11.7736 3.39308 12.3333 4.08344 12.3333ZM11.1666 11.0833C11.1666 11.7736 10.6069 12.3333 9.91656 12.3333C9.22621 12.3333 8.66656 11.7736 8.66656 11.0833C8.66656 10.3929 9.22621 9.83328 9.91656 9.83328C10.6069 9.83328 11.1666 10.3929 11.1666 11.0833Z"
        fill={props.color || '#4E5966'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  any,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgTrain = _style(SvgIcon)

export default SvgTrain
