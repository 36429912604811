import React, { FC } from 'react'

import { Loader } from 'src/modules/components/lib/loader'
import { Text } from 'src/modules/components/lib/text'
import { View } from 'src/modules/components/lib/view'
import { PharmacyCard } from 'src/modules/components/net-application/pharmacy-card'
import { Pharmacy } from 'src/modules/entities/pharmacy/entity'

type Props = {
  authorized: boolean
  pharmacies: Pharmacy[] | null
  fetching: boolean
}

export const NewPharmacy: FC<Props> = ({
  authorized,
  fetching,
  pharmacies,
}) => {
  return (
    <View width="100%">
      <Text fontSize="large" fontWeight="bold">
        新着店舗
      </Text>
      {fetching && <Loader py="5" />}
      {pharmacies?.map((pharmacy, i) => (
        <View key={i}>
          <PharmacyCard
            key={pharmacy.uid}
            authorized={authorized}
            pharmacy={pharmacy}
          />
        </View>
      ))}
    </View>
  )
}
