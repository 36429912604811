import { replace } from 'connected-react-router'
import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { fetch as fetchPing } from 'src/modules/apis/auth/ping/request'
import { useSubmit } from 'src/modules/apis/campaign/use-fetch'
import { actions as authActions } from 'src/modules/auth/redux'
import { Loader } from 'src/modules/components/lib/loader'
import { useAccount } from 'src/modules/hooks/use-account'
import {
  AllowedProfileField,
  useIdPlatform,
} from 'src/modules/hooks/useIdPlatform'
import { routes } from 'src/modules/routes'

export const CampaignScreen = () => {
  const { campaign_code: campaignCode } = useParams<{ campaign_code: string }>()
  const dispatch = useDispatch()

  const { doSubmit } = useSubmit({
    onComplete: useCallback((data?: { redirectUrl: string }) => {
      if (!!data?.redirectUrl) {
        window.location.replace(data.redirectUrl)
        return
      }
      dispatch(replace(routes.FailedEntryCampaign.path))
    }, []),
    onFailure: useCallback(() => {
      dispatch(replace(routes.FailedEntryCampaign.path))
    }, []),
  })

  const { authorized, authorizedAccount, hasEntireProfilesById } = useAccount()
  const { signUpUri, loginUri, settingsUserByPatientUid } = useIdPlatform()

  useEffect(() => {
    if (!authorized || !authorizedAccount) {
      // LoggedInGuard とは遷移先が異なるため、この場でそのまま定義
      const doPing = async () => {
        try {
          const account = await fetchPing()
          if (account) {
            dispatch(authActions.authorize({ account }))
          } else {
            dispatch(authActions.unauthorize())
            window.location.replace(signUpUri())
          }
        } catch (error) {
          dispatch(authActions.unauthorize())
          window.location.replace(loginUri())
        }
      }

      doPing()
      return
    }

    const { uid: patientUid } = authorizedAccount.patients[0]
    const requiredFields: AllowedProfileField[] = [
      'name',
      'gender',
      'birthday',
      'phone',
    ]
    if (!hasEntireProfilesById(patientUid, requiredFields)) {
      return window.location.replace(
        settingsUserByPatientUid(patientUid, requiredFields),
      )
    }

    if (campaignCode) {
      doSubmit({ campaignCode: campaignCode })
    }
  }, [
    authorized,
    authorizedAccount,
    campaignCode,
    dispatch,
    doSubmit,
    hasEntireProfilesById,
    loginUri,
    settingsUserByPatientUid,
    signUpUri,
  ])

  return <Loader />
}
