import React, { FC, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useAuthorizationRefresh } from 'src/modules/auth/use-authorization-refresh'
import { Flexbox } from 'src/modules/components/lib/view'
import { MutualInduction } from 'src/modules/components/mutualInduction/mutualInduction'
import { routes } from 'src/modules/routes'
import { About } from './about'
import { Example } from './example'
import { Footer } from './footer'
import { Header } from './header'
import { Main } from './main'
import { Pharmacy } from './pharmacy'
import { Recommended } from './recommended'
import { RequiredItems } from './required-items'
import { Usage } from './usage'

export const TopPage: FC = () => {
  const pageTopRef = useRef<HTMLDivElement>(null)
  const { authorized, fetched } = useAuthorizationRefresh()

  const pageMeta = [
    {
      property: 'description',
      content:
        'curonお薬サポートは、ネット処方箋受付可能な，24時間営業・日曜・祝日・深夜に営業している薬局・ドラッグストアを約5000件の薬局から検索可能。お薬の受け取り待ち時間短縮してくれます。',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:site_name',
      content: '処方箋ネット受付curon(クロン)',
    },
    {
      property: 'og:title',
      content:
        '【curonお薬サポート】処方箋ネット受付＆薬局・ドラッグストア検索サイト',
    },
    {
      property: 'og:description',
      content:
        'お薬は待たずに受け取れる！スマホで撮影した処方箋画像を、事前に全国の薬局に送信・予約できるサービスです。好きな時間に好きな場所で処方薬を受け取ることができます。',
    },
    {
      property: 'og:url',
      content: `${window.location.origin}${routes.index.path}`,
    },
  ]

  // アンカーリンクがある場合は指定のidの箇所までスクロール
  const hash = window.location.hash
  if (hash) {
    const target = document.getElementById(hash.replace('#', ''))
    if (target) {
      window.scrollTo(0, target.getBoundingClientRect().top - 50)
    }
  }

  return (
    <>
      <Helmet
        meta={pageMeta}
        title={
          '【curonお薬サポート】処方箋ネット受付＆薬局・ドラッグストア検索サイト'
        }
      >
        <meta
          name="description"
          content="curonお薬サポートは、ネット処方箋受付可能な，24時間営業・日曜・祝日・深夜に営業している薬局・ドラッグストアを約5000件の薬局から検索可能。お薬の受け取り待ち時間短縮してくれます。"
        />
      </Helmet>
      <Flexbox flexDirection="column" minHeight="100vh">
        <div ref={pageTopRef}></div>
        <Header fetched={fetched} authorized={authorized} />
        <MutualInduction largeHeader />
        <Main />
        <About />
        <Pharmacy />
        <Recommended />
        <section id="usage">
          <Usage />
        </section>
        <Example />
        <RequiredItems />
        <Footer />
      </Flexbox>
    </>
  )
}
