import React, { FC, useContext, useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import { FixedZIndex } from 'src/modules/const'
import { PatientAccount } from 'src/modules/entities/patient-account/entity'
import { useIdPlatform } from 'src/modules/hooks/useIdPlatform'
import { useViewportHeight } from 'src/modules/hooks/useViewportHeight'
import { routes } from 'src/modules/routes'

import { Clickable } from '../lib/clickable'
import { Account, Close, Menu, Setting, Badge } from '../lib/curon-icon'
import { Anchor, Link } from '../lib/link'
import { Logo } from '../lib/logo'
import { Text } from '../lib/text'
import { Flexbox, Grid, View } from '../lib/view'

export enum NavPage {
  Top = 'top',
  Other = 'other',
}

const headerHeight = 60

export type Context = {
  account: PatientAccount | null
  currentPage: NavPage
  unreadAnnouncementCount: number
}

export const HamburgerHeaderContext = React.createContext<Context>({
  account: null,
  currentPage: NavPage.Top,
  unreadAnnouncementCount: 0,
})

export const HamburgerHeader: FC = () => {
  const [pulldownOpen, setPulldownOpen] = useState(false)
  const { account, unreadAnnouncementCount } = useContext(
    HamburgerHeaderContext,
  )
  const hasUnreadAnnouncements = unreadAnnouncementCount > 0

  return (
    <>
      <View width="100%" minHeight={headerHeight}>
        {/* This block can avoid setting `headerHeight` to padding-top when using header */}
      </View>
      <View
        position="fixed"
        top="0"
        right="0"
        left="0"
        zIndex={FixedZIndex.header}
      >
        <Grid
          px={3}
          height={headerHeight}
          bg="white"
          gridTemplateColumns="1fr 32px"
          gridGap={8}
          alignItems="center"
        >
          <Logo width="87px" horizontal={false} />
          <Clickable
            onClick={() => {
              setPulldownOpen(!pulldownOpen)
            }}
            position="relative"
          >
            <Menu width="32px" height="32px" color="green" />
            <Badge
              position="absolute"
              top="1px"
              right="-10px"
              size="20px"
              color="red"
              opacity={hasUnreadAnnouncements ? 1 : 0}
              style={{ transition: 'opacity 0.2s ease-in-out' }}
            />
          </Clickable>
        </Grid>
        <PullDown
          account={account}
          visible={pulldownOpen}
          hasUnreadAnnouncements={hasUnreadAnnouncements}
          onVisibleChange={setPulldownOpen}
        />
      </View>
    </>
  )
}

const PullDown: FC<{
  account: PatientAccount | null
  visible: boolean
  hasUnreadAnnouncements: boolean
  onVisibleChange?: (visible: boolean) => unknown
}> = ({ account, visible, hasUnreadAnnouncements, onVisibleChange }) => {
  const [initialRender, setInitialRender] = useState(true)
  const vh = useViewportHeight()

  useEffect(() => {
    if (visible) {
      setInitialRender(false)
    }
  }, [visible])

  const visibleClass = initialRender ? '' : visible ? 'visible' : 'invisible'

  const { accountMenuUri } = useIdPlatform()

  return (
    <PullDownRoot
      className={`${visibleClass}`}
      pullDownRootHeight={`calc(${vh * 100}px)`}
    >
      <Grid className="content">
        <PullDownListTitle bg="#F0FAF5">
          <PullDownText>
            <Account color="#637183" />
            <Text color="#637182">{account?.email}</Text>
          </PullDownText>
          <Clickable
            className="close-button-container"
            onClick={() => onVisibleChange && onVisibleChange(false)}
          >
            <Close width="24px" height="24px" color="darkGray" />
          </Clickable>
        </PullDownListTitle>
        <PullDownListContent>
          <PullDownAnchor href={accountMenuUri()}>
            <Setting color="#637182" />
            <Text>設定</Text>
          </PullDownAnchor>
          <PullDownHr />
          <PullDownLink to={routes.announcementHistory.build()}>
            <Text>お知らせ</Text>
            {hasUnreadAnnouncements && <Badge color="red" size="20px" />}
          </PullDownLink>
          <PullDownAnchor
            href="https://micin.force.com/FAQforPatients/s/topic/0TO7F0000009q42WAA/クロンお薬サポートに関するfaq"
            target="_blank"
          >
            <Text>よくあるお問い合わせ</Text>
          </PullDownAnchor>
          <PullDownAnchor
            href={
              process.env.REACT_APP_ENV === 'prod'
                ? 'https://id.curon.co/terms.html'
                : 'https://stg8.id.stg.curon.co/terms.html'
            }
            target="_blank"
          >
            <Text>利用規約</Text>
          </PullDownAnchor>
          <PullDownAnchor href="https://micin.jp/privacy" target="_blank">
            <Text>プライバシーポリシー</Text>
          </PullDownAnchor>
          <PullDownAnchor
            href="https://curon.co/assets/external_transmission.pdf"
            target="_blank"
          >
            <Text>外部送信ポリシー</Text>
          </PullDownAnchor>
          <PullDownAnchor
            href="https://curon.co/static/app/privacy.html"
            target="_blank"
          >
            <Text>オンライン医療サービス個人情報保護方針</Text>
          </PullDownAnchor>
        </PullDownListContent>
      </Grid>
    </PullDownRoot>
  )
}

const PullDownListTitle = styled(Flexbox)`
  width: 100%;
  padding: 21px;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
`

const PullDownListContent = styled(Grid)`
  padding: 0px 21px;
`

const PullDownLink = styled(Link)`
  display: flex;
  width: 100%;
  height: 48px;
  gap: 12px;
  font-weight: 300;
  font-size: 16px;
  text-decoration: none;
  color: #272c33;
  align-items: center;
`

const PullDownAnchor = styled(Anchor)`
  display: flex;
  width: 100%;
  height: 48px;
  gap: 12px;
  font-weight: 300;
  font-size: 16px;
  text-decoration: none;
  color: #272c33;
  align-items: center;
`

const PullDownText = styled('a')`
  display: flex;
  width: 100%;
  gap: 12px;
  font-weight: 300;
  font-size: 16px;
  cursor: default;
  text-decoration: none;
  color: #272c33;
  align-items: center;
`

const PullDownHr = styled('hr')`
  width: 100%;
  border: 1px solid #d3dde8;
  color: ${props => props.theme.colors.shuttleGray};
`

const ToVisibleKeyFrames = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const ToInvisibleKeyFrames = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
`
type PullDownRootProps = {
  pullDownRootHeight: string
}

const PullDownRoot = styled.div<PullDownRootProps>`
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.pullDownRootHeight};
  background: white;
  overflow-y: scroll;
  z-index: ${FixedZIndex.header};
  visibility: hidden;
  pointer-events: none;
  align-items: flex-start;

  &.visible {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;

    animation: ${ToVisibleKeyFrames} 100ms ease-out 0s;
  }

  &.invisible {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    animation: ${ToInvisibleKeyFrames} 100ms ease-out 0s;
    animation-fill-mode: forwards;
  }

  .close-button-container {
    align-self: center;
  }
`
