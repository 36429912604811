import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { StationResponse } from '../response'

export type Params = {
  code?: string
}

export const fetch = async (params: Params) => {
  if (!params.code) {
    return null
  }

  const resp = await requestGet<StationResponse>({
    url: `${PHARMACY_API_URL}/patients/pharmacies/stations/${params.code}.json`,
    params: {},
  })

  return resp.data
}