import { createContext } from 'react'
import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { parse, AppStateResponse } from './response'

export const fetch = async () => {
  const resp = await requestGet<AppStateResponse[]>({
    url: `${PHARMACY_API_URL}/patients/app_states.json`,
  })

  return resp.data ? parse(resp.data) : undefined
}

export const FetchAppState = createContext({
  fetch,
})
