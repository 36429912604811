import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import { useSWRFetch as useChainFetch } from 'src/modules/apis/chain/show/use-fetch'
import { useSWRFetch as usePharmacySearchFetch } from 'src/modules/apis/pharmacy/search/use-fetch'

import { useAuthorizationRefresh } from 'src/modules/auth/use-authorization-refresh'
import { Loader } from 'src/modules/components/lib/loader'
import { useToast } from 'src/modules/components/lib/toast'
import { TOKYO_STATION_POSITION } from 'src/modules/constants/tokyo_station_position'
import { useGeoPosition } from 'src/modules/geo-location'
import { useSearchQuery } from 'src/modules/hooks/use-search-query'
import { useSWRChainCodeState } from 'src/modules/hooks/useSWRChainCodeState'
import {
  useDeprecatedSearchQueryGuard,
  useSearchMeta,
  useDeprecatedPageQueryGuard,
} from '../helper'
import { NetApplicationNearSearchScreen } from './screen'

export const NetApplicationNearSearchScreenContainer = () => {
  useDeprecatedSearchQueryGuard()
  useDeprecatedPageQueryGuard()

  const { authorized, fetched } = useAuthorizationRefresh()
  const { data: chainCode, setChain } = useSWRChainCodeState()
  const { data: chain } = useChainFetch({
    code: chainCode,
  })
  const { showToast } = useToast()

  const { title: metaTitle, description: metaDescription } = useSearchMeta({
    pageType: 'search_near',
  })

  const { keyword, prefecture, currentPage: page, selectedDay } = useSearchQuery()

  const [areaModalVisible, setAreaModalVisible] = useState(false)
  const [keywordModalVisible, setKeywordModalVisible] = useState(false)
  const [searchMapModalVisible, setSearchMapModalVisible] = useState(false)
  // isGeoApprovedは初期でfalseが入ってしまうためデフォルトはtrueにしておく
  const [geoApproved, setGeoApproved] = useState(true)
  const {
    data: currentPosition,
    isApproved: isGeoApproved,
    isCompleted,
  } = useGeoPosition({
    onCompleted: () => {
      if (!isGeoApproved) {
        showToast('位置情報の取得に失敗しました。', true)
        setGeoApproved(false)
        return
      }
    },
  })
  const lat = currentPosition
    ? currentPosition.coords.latitude
    : TOKYO_STATION_POSITION.lat
  const lng = currentPosition
    ? currentPosition.coords.longitude
    : TOKYO_STATION_POSITION.lng

  const isFetchingCurrentPosition: boolean = useMemo(
    () => geoApproved && (!isCompleted || !currentPosition),
    [isCompleted, geoApproved, currentPosition],
  )

  const {
    data,
    loading: pharmacyIndexFetching,
    trigger,
  } = usePharmacySearchFetch({
    params: {
      keyword: null,
      prefecture: prefecture ? prefecture.name : null,
      area: null,
      station: null,
      currentLocation: true,
      lat: lat,
      lng: lng,
      chain: chainCode,
      page,
      selectedDay
    },
    skip: isFetchingCurrentPosition,
    options: {
      onComplete: data => {
        if (data.pharmacies.length === 0 && searchMapModalVisible) {
          showToast('検索結果がありませんでした。', true)
        }
      },
    },
  })

  useEffect(() => {
    return () => {
      // 一度検索されたら、チェーンをグローバルの状態から削除する
      setChain(null)
    }
  }, [setChain])

  useEffect(() => {
    window.scrollTo({ top: 0 }) // the new page will start from the middle without this
    setAreaModalVisible(false)
    setKeywordModalVisible(false)
  }, [data])

  if (pharmacyIndexFetching && page === 1 && !searchMapModalVisible) {
    return <Loader />
  }

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href="https://pha.curon.co/search/near" />
      </Helmet>
      <NetApplicationNearSearchScreen
        keyword={chain?.name || keyword}
        pharmacies={data?.pharmacies || []}
        searchTitle="近くの薬局一覧"
        isGeoApproved={!!isGeoApproved}
        authorized={authorized}
        fetched={fetched}
        loading={pharmacyIndexFetching}
        areaModalVisible={areaModalVisible}
        keywordModalVisible={keywordModalVisible}
        setAreaModalVisible={setAreaModalVisible}
        setKeywordModalVisible={setKeywordModalVisible}
        setSearchMapModalVisible={setSearchMapModalVisible}
        searchMapModalVisible={searchMapModalVisible}
        pharmacySearchLoading={pharmacyIndexFetching}
        pharmacySearchTrigger={trigger}
        currentPosition={currentPosition}
      />
    </>
  )
}
