import { AppState } from 'src/modules/entities/app-state/entity'

export type AppStateResponse = {
  uid: string
  state: string
  message: string
  entity: string
  start_datetime: string
  end_datetime: string
}

export const parse = (resp: AppStateResponse[]): AppState | undefined => {
  const targetResp = resp?.[0]
  const res = targetResp
    ? {
        uid: targetResp.uid,
        state: targetResp.state,
        message: targetResp.message,
        entity: targetResp.entity,
        startDatetime: targetResp.start_datetime,
        endDatetime: targetResp.end_datetime,
      }
    : undefined

  return res
}
