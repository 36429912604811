import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'src/modules/components/lib/link'
import { routes } from 'src/modules/routes'

type ButtonLocation = 'main' | 'banner' | 'usage' | 'required'
type gtmId =
  | 'gtm-phanet-link-btn01'
  | 'gtm-phanet-link-btn02'
  | 'gtm-phanet-link-btn03'
type SearchPharmacyLinkProps = {
  buttonLocation?: ButtonLocation
  gtmId?: gtmId
}

export const SearchPharmacyLink: FC<SearchPharmacyLinkProps> = ({
  buttonLocation,
  gtmId,
}) => (
  <StyledAnchor
    to={routes.search.path}
    buttonLocation={buttonLocation}
    id={gtmId}
  >
    薬局を探す
  </StyledAnchor>
)

SearchPharmacyLink.defaultProps = {
  buttonLocation: 'main',
}

const StyledAnchor = styled(Link)<{ buttonLocation?: string }>`
  max-width: 270px;
  height: 50px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 9px;
  flex: 0 0 200px;
  color: white;
  font-style: normal;
  font-family: 'Hiragino Sans';
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  background-color: #eb7f0c;
  border-radius: 60px;
  transition: 0.3s;
  ::hover {
    box-shadow: 0 2px 16px rgba(0, 117, 57, 0.55);
  }
  ::before {
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    margin: 0 6px 0 5px;
    background: url(/top/search.svg) no-repeat 0 0 / contain;
  }

  @media (max-width: 560px) {
    font-size: 14px;
    ${({ buttonLocation }) =>
      buttonLocation === 'banner' &&
      css`
        font-size: 16px;
      `}
    height: 46px;
  }

  :hover {
    box-shadow: 0px 2px 16px rgba(63, 63, 63, 0.45);
  }
  ${({ buttonLocation }) =>
    buttonLocation === 'banner' &&
    css`
      font-size: 17px;
      width: 190px;
    `}
`
