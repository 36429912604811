import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { StationResponse } from '../response'

export type Params = {
  prefecture: string | null
  keywordForSearchPharmacy: string | null
  chainCode: string | null
}

export const fetch = async (params: Params) => {
  const { prefecture, keywordForSearchPharmacy, chainCode } = params

  const resp = await requestGet<StationResponse[]>({
    url: `${PHARMACY_API_URL}/patients/pharmacies/stations/search_by_prefecture.json`,
    params: {
      prefecture,
      keyword_for_search_pharmacy: keywordForSearchPharmacy,
      chain_code: chainCode,
    },
  })

  return resp.data
}
