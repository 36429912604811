import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 17.125C13.4873 17.125 17.125 13.4873 17.125 9C17.125 4.51269 13.4873 0.875 9 0.875C4.51269 0.875 0.875 4.51269 0.875 9C0.875 13.4873 4.51269 17.125 9 17.125ZM8.84283 4.07034C8.49766 4.07034 8.21783 4.35016 8.21783 4.69534V9.08102C8.21783 9.15566 8.23092 9.22724 8.25491 9.2936C8.29869 9.44196 8.39729 9.57443 8.54184 9.65788L11.3387 11.2726C11.6376 11.4452 12.0199 11.3428 12.1924 11.0439C12.365 10.7449 12.2626 10.3627 11.9637 10.1901L9.46783 8.74913V4.69534C9.46783 4.35016 9.18801 4.07034 8.84283 4.07034Z"
        fill={props.color || '#4E5966'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  object,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgClock = _style(SvgIcon)

export default SvgClock
