import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43 0C19.29 0 0 19.29 0 43s19.29 43 43 43 43-19.29 43-43S66.71 0 43 0zm0 78.182C23.6 78.182 7.818 62.4 7.818 43 7.818 23.601 23.601 7.818 43 7.818c19.4 0 35.182 15.783 35.182 35.182 0 19.4-15.783 35.182-35.182 35.182z"
        fill={props.color || '#000'}
      />
      <path
        d="M43 18.242a5.219 5.219 0 00-5.212 5.215A5.216 5.216 0 0043 28.667a5.216 5.216 0 005.21-5.21A5.219 5.219 0 0043 18.242zM43 36.485a3.91 3.91 0 00-3.91 3.909v23.455a3.91 3.91 0 007.82 0V40.394a3.91 3.91 0 00-3.91-3.91z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgInformation = _style(SvgIcon)

export default SvgInformation
