import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.97705 11.7697C3.95559 9.51836 2.02721 7.16265 1.33108 5.47175C0.62482 3.75625 0.892207 2.33612 1.71816 1.39094C2.52001 0.473348 3.78577 0.0808825 4.92962 0.268542C5.72912 0.399706 6.47861 0.81683 6.97705 1.53387C7.47549 0.81683 8.22499 0.399706 9.02448 0.268542C10.1683 0.0808825 11.4341 0.473348 12.2359 1.39094C13.0619 2.33612 13.3293 3.75625 12.623 5.47175C11.9269 7.16265 9.99851 9.51836 6.97705 11.7697Z"
        fill={props.color || '#046CC2'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  any,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgHeart = _style(SvgIcon)

export default SvgHeart
