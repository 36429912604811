import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { FetchOption, useFetch as _useFetch } from '../../hooks/use-fetch'
import { ChainResponse, parse } from '../response'

export type Params = {
  code?: string
}

export const fetch = async (params: Params) => {
  if (!params.code) return null
  const resp = await requestGet<ChainResponse>({
    url: `${PHARMACY_API_URL}/patients/pharmacies/chains/${params.code}.json`,
    params: {},
  })

  return parse(resp.data)
}
