import React, { FC, useEffect, PropsWithChildren } from 'react'
import { useAccount } from '../hooks/use-account'
import { AllowedProfileField, useIdPlatform } from '../hooks/useIdPlatform'
import { LoggedInGuard } from './logged-in-guard'

type Props = {
  requiredFields: AllowedProfileField[]
}

export const MainProfileGuard: PropsWithChildren<FC<Props>> = ({
  requiredFields,
  children,
}) => {
  const { redirectSettingsUserByPatientUid } = useIdPlatform()
  const { authorizedAccount, hasEntireProfilesById } = useAccount()

  useEffect(() => {
    if (!authorizedAccount) return

    const { uid: patientUid } = authorizedAccount.patients[0]
    if (!hasEntireProfilesById(patientUid, requiredFields)) {
      return redirectSettingsUserByPatientUid(patientUid, requiredFields)
    }
  }, [
    authorizedAccount,
    hasEntireProfilesById,
    redirectSettingsUserByPatientUid,
    requiredFields,
  ])

  return <LoggedInGuard>{children}</LoggedInGuard>
}
