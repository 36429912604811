import { replace } from 'connected-react-router'
import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { routes } from 'src/modules/routes'
import { ClientAccountType } from '../entities/enums/client-account-type'
import { useAccount } from '../hooks/use-account'

export const MicinAccountGuard: PropsWithChildren<FC> = ({ children }) => {
  const { authorizedAccount } = useAccount()
  const { clientAccountType } = authorizedAccount || {}

  const dispatch = useDispatch()
  const { pathname } = useLocation()

  useEffect(() => {
    // For KDDI users, do not allow some routes
    if (clientAccountType === ClientAccountType.KddiAccount) {
      dispatch(replace(routes.notFound.build()))
    }
  }, [clientAccountType, dispatch, pathname])

  return <>{children}</>
}
