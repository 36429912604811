import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M85.197 10.263a2.78 2.78 0 00-1.949-.803H2.752a2.73 2.73 0 00-1.95.803A2.78 2.78 0 000 12.212v61.576c0 .745.287 1.433.803 1.95a2.78 2.78 0 001.949.802h80.467a2.73 2.73 0 001.95-.803 2.73 2.73 0 00.802-1.949V12.212a2.636 2.636 0 00-.774-1.95zm-4.73 4.701v7.11l-37.782 31.36L5.533 22.132v-7.167h74.934zM5.533 71.036v-41.71l35.374 29.785c.488.43 1.118.659 1.778.659.63 0 1.261-.23 1.777-.63l36.005-29.9v41.796H5.533z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgMail = _style(SvgIcon)

export default SvgMail
