export enum Gender {
  None = 0,
  Male = 1,
  Female = 2,
}

export const genderStrings: { [key: number]: string } = {
  [Gender.None]: '',
  [Gender.Male]: '男性',
  [Gender.Female]: '女性',
}

export const genderToString = (gender: Gender): string => {
  return genderStrings[gender]
}
