import { PaidBy } from 'src/modules/entities/enums/paid-by'
import { SettlementType } from 'src/modules/entities/enums/settlement-type'
import { SettlementRecord } from 'src/modules/entities/settlement-record/entity'

export type SettlementRecordResponse = {
  uid: string
  pharmacy_visit_uid: string | null
  amount: string
  insurance_covered_amount: string
  non_insurance_covered_amount: string
  paid_at: string | null
  paid_by: PaidBy
  refunded_at: string | null
  failed_at: string | null
  created_at: string
  updated_at: string
  settlement_type: SettlementType
}

export const parse = (resp: SettlementRecordResponse): SettlementRecord => {
  const res: SettlementRecord = {
    uid: resp.uid,
    pharmacyVisitUid: resp.pharmacy_visit_uid,
    amount: Number(resp.amount),
    insuranceCoveredAmount: Number(resp.insurance_covered_amount),
    nonInsuranceCoveredAmount: Number(resp.non_insurance_covered_amount),
    paidAt: resp.paid_at,
    paidBy: resp.paid_by,
    refundedAt: resp.refunded_at,
    failedAt: resp.failed_at,
    createdAt: resp.created_at,
    updatedAt: resp.updated_at,
    settlementType: resp.settlement_type,
  }

  return res
}
