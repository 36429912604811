import GoogleMapReact from 'google-map-react'
import React, { FC, useEffect, useState, useMemo } from 'react'
import { ReactComponent as CurrentPositionPin } from 'src/assets/search/now_place.svg'
import { Button } from 'src/modules/components/lib/button'
import { Expansion } from 'src/modules/components/lib/curon-icon'
import { View } from 'src/modules/components/lib/view'
import { TOKYO_STATION_POSITION } from 'src/modules/constants/tokyo_station_position'
import { Pharmacy } from 'src/modules/entities/pharmacy/entity'
import { Spacer } from 'src/modules/screens/top/spacer'
import { GOOGLE_MAPS_API_KEY } from 'src/modules/server/const'
import { PharmacyPin } from './pharmacy-pin'

type Props = {
  onOpen: () => void
  pharmacySearchData: Pharmacy[] | undefined
  pharmacySearchLoading: boolean
  currentPosition?: GeolocationPosition | null
  isGeoApproved: boolean
}

export const SmallSearchMap: FC<Props> = ({
  onOpen,
  pharmacySearchData,
  currentPosition,
  isGeoApproved,
}) => {
  const pharmacyList = useMemo(
    () => pharmacySearchData || [],
    [pharmacySearchData],
  )

  const [map, setMap] = useState<any | null>(null)
  const [selectPharmacy, setSelectPharmacy] = useState<Pharmacy | null>(null)

  useEffect(() => {
    // 初回中心座標を現在地にする
    if (!map || !currentPosition || !isGeoApproved) return
    map.panTo({
      lat: currentPosition.coords.latitude,
      lng: currentPosition.coords.longitude,
    })
  }, [currentPosition, isGeoApproved, map])

  return (
    <>
      <View width="100%" height="100%" style={{ position: 'relative' }}>
        <GoogleMapReact
          defaultZoom={14}
          options={maps => ({
            gestureHandling: 'greedy',
            fullscreenControl: false,
            draggableCursor: 'pointer',
          })}
          onGoogleApiLoaded={({ map }) => {
            setMap(map)
          }}
          defaultCenter={{
            lat: TOKYO_STATION_POSITION.lat,
            lng: TOKYO_STATION_POSITION.lng,
          }}
          onZoomAnimationStart={() => {
            setSelectPharmacy(null)
          }}
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          yesIWantToUseGoogleMapApiInternals
          draggable={false}
        >
          {pharmacyList.map((pharmacy, index) => (
            <View
              key={pharmacy.uid}
              {...{ lat: pharmacy.lat, lng: pharmacy.lng }}
            >
              <PharmacyPin
                pharmacy={pharmacy}
                onClick={() => {}}
                index={index}
                selected={pharmacy.uid === selectPharmacy?.uid}
              />
            </View>
          ))}
          {currentPosition && (
            <View
              {...{
                lat: currentPosition.coords.latitude,
                lng: currentPosition.coords.longitude,
              }}
            >
              <CurrentPositionPin width="35px" height="35px" />
            </View>
          )}
        </GoogleMapReact>
        <Button
          width="133px"
          shape="square"
          onClick={onOpen}
          fontSize="12px"
          padding="10px"
          style={{ position: 'absolute', bottom: '15px', right: '15px' }}
        >
          <Expansion />
          <Spacer horizontal size={5} />
          大きな地図で見る
        </Button>
      </View>
    </>
  )
}
