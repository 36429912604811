import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.99988 16.5C13.5897 16.5 16.4999 13.5899 16.4999 10C16.4999 6.41015 13.5897 3.5 9.99988 3.5C6.41003 3.5 3.49988 6.41015 3.49988 10C3.49988 13.5899 6.41003 16.5 9.99988 16.5ZM9.99988 18C14.4182 18 17.9999 14.4183 17.9999 10C17.9999 5.58172 14.4182 2 9.99988 2C5.5816 2 1.99988 5.58172 1.99988 10C1.99988 14.4183 5.5816 18 9.99988 18Z"
        fill={props.color || '#2BA168'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0489 10.75H2.27536V9.25H17.0489V10.75Z"
        fill={props.color || '#2BA168'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1396 10.1284C12.0802 8.04481 11.1853 5.86365 9.49599 2.82506L10.807 2.0962C12.5082 5.15615 13.5684 7.61211 13.639 10.0857C13.7103 12.589 12.7682 14.9849 10.7725 17.9341L9.53021 17.0935C11.445 14.2638 12.1981 12.1823 12.1396 10.1284Z"
        fill={props.color || '#2BA168'}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.01174 10.1284C8.07114 8.04481 8.96603 5.86365 10.6553 2.82506L9.34432 2.0962C7.64314 5.15616 6.58287 7.61211 6.51235 10.0857C6.44098 12.589 7.38312 14.9849 9.3788 17.9341L10.6211 17.0935C8.70634 14.2638 7.95318 12.1823 8.01174 10.1284Z"
        fill={props.color || '#2BA168'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  object,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgWeb = _style(SvgIcon)

export default SvgWeb
