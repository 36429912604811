import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const DURATION = 1.0 // sec

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M15.9998 0C15.1092 0 14.3872 0.721975 14.3872 1.61261V7.22977C14.3872 8.1204 15.1092 8.84238 15.9998 8.84238C16.8905 8.84238 17.6124 8.12047 17.6124 7.22977V1.61261C17.6124 0.722042 16.8904 0 15.9998 0Z"
          fill="#B0BAC5"
        >
          <Animate />
        </path>
        <path
          d="M10.9387 8.65809L6.96681 4.68618C6.33705 4.05643 5.31601 4.05643 4.68618 4.68618C4.05643 5.31594 4.05643 6.33698 4.68618 6.96674L8.65816 10.9387C9.28792 11.5685 10.309 11.5685 10.9387 10.9387C11.5685 10.3089 11.5685 9.28785 10.9387 8.65809Z"
          fill="#B0BAC5"
        >
          <Animate index={1} />
        </path>
        <path
          d="M7.22983 14.3875H1.61261C0.721974 14.3875 0 15.1094 0 16.0001C0 16.8907 0.721974 17.6127 1.61261 17.6127H7.22983C8.12047 17.6127 8.84244 16.8907 8.84244 16.0001C8.84238 15.1094 8.1204 14.3875 7.22983 14.3875Z"
          fill="#B0BAC5"
        >
          <Animate index={2} />
        </path>
        <path
          d="M10.9387 21.0612C10.309 20.4314 9.28792 20.4314 8.65816 21.0612L4.68618 25.0332C4.05643 25.663 4.05643 26.684 4.68618 27.3138C5.31601 27.9435 6.33705 27.9435 6.96681 27.3138L10.9387 23.3419C11.5685 22.7121 11.5685 21.6911 10.9387 21.0612Z"
          fill="#B0BAC5"
        >
          <Animate index={3} />
        </path>
        <path
          d="M15.9998 23.1577C15.1092 23.1577 14.3872 23.8797 14.3872 24.7703V30.3875C14.3872 31.2781 15.1092 32.0001 15.9998 32.0001C16.8905 32.0001 17.6124 31.2781 17.6124 30.3875V24.7703C17.6124 23.8798 16.8904 23.1577 15.9998 23.1577Z"
          fill="#B0BAC5"
        >
          <Animate index={4} />
        </path>
        <path
          d="M27.3137 25.0332L23.3418 21.0612C22.712 20.4314 21.691 20.4314 21.0612 21.0612C20.4314 21.6911 20.4314 22.7121 21.0612 23.3419L25.0332 27.3138C25.6629 27.9435 26.684 27.9435 27.3137 27.3138C27.9435 26.684 27.9435 25.6629 27.3137 25.0332Z"
          fill="#B0BAC5"
        >
          <Animate index={5} />
        </path>
        <path
          d="M30.3876 14.3875H24.7704C23.8798 14.3875 23.1577 15.1094 23.1577 16.0001C23.1577 16.8907 23.8797 17.6127 24.7704 17.6127H30.3876C31.2783 17.6127 32.0002 16.8907 32.0002 16.0001C32.0002 15.1094 31.2782 14.3875 30.3876 14.3875Z"
          fill="#B0BAC5"
        >
          <Animate index={6} />
        </path>
        <path
          d="M27.3137 4.68618C26.684 4.05643 25.6629 4.05643 25.0332 4.68618L21.0612 8.65809C20.4314 9.28785 20.4314 10.3089 21.0612 10.9387C21.691 11.5685 22.712 11.5685 23.3418 10.9387L27.3137 6.96674C27.9435 6.33698 27.9435 5.31594 27.3137 4.68618Z"
          fill="#B0BAC5"
        >
          <Animate index={7} />
        </path>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

const Animate = ({ index = 0 }: { index?: number }) => {
  return (
    <animate
      attributeName="fill-opacity"
      values="1;0;1;1;1;1"
      dur={DURATION}
      repeatCount="indefinite"
      begin={DURATION - (DURATION / 8) * index}
    />
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgReload = _style(SvgIcon)

export default SvgReload
