import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#video_svg__clip0)">
        <path
          d="M85.627 21.213a2.968 2.968 0 00-1.003-1.06 3.09 3.09 0 00-1.405-.43 3.06 3.06 0 00-1.433.287l-17.888 8.8V18.548a2.93 2.93 0 00-.86-2.064 2.931 2.931 0 00-2.064-.86H2.924c-.774 0-1.52.315-2.064.86A2.93 2.93 0 000 18.546v48.877c0 .774.315 1.52.86 2.064a2.93 2.93 0 002.064.86h58.021a2.93 2.93 0 002.064-.86 2.93 2.93 0 00.86-2.064V57.19l17.888 8.8c.402.202.832.288 1.29.288a2.93 2.93 0 002.064-.86 2.93 2.93 0 00.86-2.065V22.618a2.441 2.441 0 00-.344-1.404zM58.021 64.5H5.848v-43h52.173v43zm22.131-5.82l-16.254-7.997V35.318l16.254-7.998v31.36z"
          fill={props.color || '#000'}
        />
      </g>
      <defs>
        <clipPath id="video_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgVideo = _style(SvgIcon)

export default SvgVideo
