import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Profile } from '../entities/profile/entity'
import { RootState } from '../reducer'
import { AllowedProfileField } from './useIdPlatform'

const profileFieldsMap: Record<AllowedProfileField, (keyof Profile)[]> = {
  name: ['lastname', 'firstname'],
  name_j: ['lastnameJ', 'firstnameJ'],
  gender: ['gender'],
  birthday: ['birthday'],
  phone: ['phone'],
  address: ['postal', 'prefecture', 'city', 'district'],
}

export const useAccount = () => {
  const authorizedAccount = useSelector(
    (state: RootState) => state.auth.account,
  )
  const authorized = useSelector((state: RootState) => state.auth.authorized)

  const patients = authorizedAccount?.patients
  const mainAccountProfile = patients?.[0]?.profile

  // NOTE: 必須プロフィール項目が登録されていない場合がある
  const getProfileFieldsArray = useCallback(
    (fields: AllowedProfileField[]) =>
      fields.flatMap(field => profileFieldsMap[field]),
    [],
  )

  const hasEntireProfilesById = useCallback(
    (patientUid: string, requiredFields: AllowedProfileField[]) => {
      const currentPatient = patients?.find(
        patient => patient.uid === patientUid,
      )

      return (
        !!currentPatient?.profile &&
        getProfileFieldsArray(requiredFields).every(
          field => currentPatient.profile?.[field],
        )
      )
    },
    [getProfileFieldsArray, patients],
  )

  return {
    authorized,
    authorizedAccount,
    mainAccountProfile,
    hasEntireProfilesById,
  }
}
