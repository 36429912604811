import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.638 86c-.573 0-1.147-.23-1.548-.63a2.215 2.215 0 01-.63-1.549V66.306H4.442c-.573 0-1.146-.23-1.548-.63a2.215 2.215 0 01-.63-1.549V2.18c0-.574.23-1.147.63-1.548C3.297.229 3.841 0 4.443 0h77.142c.574 0 1.147.23 1.548.63.402.402.631.975.631 1.549v61.92c0 .573-.23 1.146-.63 1.548-.402.401-.975.63-1.549.63H49.25L30.186 85.341c-.2.2-.459.372-.717.458-.258.144-.544.201-.83.201zM6.622 61.92h22.016c.573 0 1.147.23 1.548.63.401.402.63.975.63 1.549v14.448L46.785 62.55c.2-.201.459-.373.717-.488.258-.114.544-.172.831-.172h31.046V4.357H6.622V61.92zm61.72-35.03H18.776c-.574 0-1.147-.23-1.548-.631a2.215 2.215 0 010-3.096c.401-.402.974-.631 1.548-.631h49.536c.573 0 1.146.23 1.548.63a2.215 2.215 0 010 3.097c-.402.401-.946.63-1.52.63zm-14.65 18.575h-.028l-35.46-.573c-.574 0-1.119-.23-1.549-.66a2.215 2.215 0 01-.63-1.547c0-.574.23-1.118.66-1.548.4-.402.974-.631 1.547-.631h.029l35.46.573c.574 0 1.118.23 1.548.66.402.401.631.974.631 1.548 0 .573-.23 1.118-.66 1.548-.4.401-.974.63-1.547.63z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgChat = _style(SvgIcon)

export default SvgChat
