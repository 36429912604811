export const routes = {
  index: {
    path: '/',
    build: () => '/',
  },
  home: {
    path: '/home',
    build: () => '/home',
  },
  login: {
    path: '/login',
    build: () => '/login',
  },
  search: {
    path: '/search',
    build: () => '/search',
  },
  searchNear: {
    path: '/search/near',
    build: () => '/search/near',
  },
  searchList: {
    path: '/search/list',
    build: () => `/search/list`,
  },
  searchPref: {
    path: '/search/pref/:prefecture',
    build: (prefecture: string) => `/search/pref/${prefecture}`,
  },
  searchPrefArea: {
    path: '/search/pref/:prefecture/area/:areaSlug',
    build: (prefecture: string, slug: string) =>
      `/search/pref/${prefecture}/area/${slug}`,
  },
  searchPrefStation: {
    path: '/search/pref/:prefecture/station/:stationCode',
    build: (prefecture: string, code: string) =>
      `/search/pref/${prefecture}/station/${code}`,
  },
  searchChainList: {
    path: '/search/chain/:chain/list',
    build: (chain: string) => `/search/chain/${chain}/list`,
  },
  searchChain: {
    path: '/search/chain/:chain',
    build: (chain: string) => `/search/chain/${chain}`,
  },
  searchChainPref: {
    path: '/search/pref/:prefecture/chain/:chain',
    build: (prefecture: string, chain: string) =>
      `/search/pref/${prefecture}/chain/${chain}`,
  },
  searchChainPrefArea: {
    path: '/search/pref/:prefecture/area/:areaSlug/chain/:chain',
    build: (prefecture: string, slug: string, chain: string) =>
      `/search/pref/${prefecture}/area/${slug}/chain/${chain}`,
  },
  searchChainPrefStation: {
    path: '/search/pref/:prefecture/station/:stationCode/chain/:chain',
    build: (prefecture: string, code: string, chain: string) =>
      `/search/pref/${prefecture}/station/${code}/chain/${chain}`,
  },
  // curon連携（外部薬局向け）
  searchExternalPharmacy: {
    path: '/search/external_pharmacy/:external_pharmacy_uid',
    build: (external_pharmacy_uid: string) =>
      `/search/external_pharmacy/${external_pharmacy_uid}`,
  },
  pharmaciesDetail: {
    path: '/pharmacies/:pharmacy_uid',
    build: (pharmacy_uid: string) => `/pharmacies/${pharmacy_uid}`,
  },
  app: {
    path: '/cp/app',
    build: () => '/cp/app',
  },
  campaign202210: {
    path: '/cp/campaign202210',
    build: () => '/cp/campaign202210',
  },
  completedEntryCampaign: {
    path: '/cp/completed_entry_campaign202210',
    build: () => '/cp/completed_entry_campaign202210',
  },
  FailedEntryCampaign: {
    path: '/cp/failed_entry_campaign',
    build: () => '/cp/failed_entry_campaign',
  },
  // register flow for login use case
  accountRegister: {
    path: '/account_register',
    build: () => `/account_register`,
  },
  accountRegisterProfile: {
    path: '/account_register/profile',
    build: () => `/account_register/profile`,
  },
  afterAccountRegister: {
    path: '/account_register/after-register',
    build: () => `/account_register/after-register`,
  },
  // register flow for non-login use case
  guest: {
    path: '/guest',
    build: () => `/guest`,
  },
  guestRegister: {
    path: '/guest/register',
    build: () => `/guest/register`,
  },
  // TODO: redirect to mypage
  guestRegisterTop: {
    path: '/guest/register/:token_uid',
    build: (token_uid: string) => `/guest/register/${token_uid}`,
  },
  // TODO: redirect to mypage
  guestVideoCallRoom: {
    path: '/guest/video_call_room/:token_uid',
    build: (token_uid: string) => `/guest/video_call_room/${token_uid}`,
  },
  // TODO: redirect to mypage
  guestAttachmentsView: {
    path: '/guest/attachments/:token_uid',
    build: (token_uid: string) => `/guest/attachments/${token_uid}`,
  },
  notFound: {
    path: '/404',
    build: () => '/404',
  },
  netApplication: {
    path: '/net-application',
    build: () => './net-application',
  },
  netApplicationPatient: {
    path: '/net-application/patient/:pharmacy_uid',
    build: (pharmacy_uid: string) => `/net-application/patient/${pharmacy_uid}`,
  },
  netApplicationPrescription: {
    path: '/net-application/prescription/:pharmacy_visit_uid',
    build: (pharmacy_visit_uid: string) =>
      `/net-application/prescription/${pharmacy_visit_uid}`,
  },
  netApplicationQuestionnaireInput: {
    path: '/net-application/questionnaire_input/:pharmacy_visit_uid',
    build: (pharmacy_visit_uid: string) =>
      `/net-application/questionnaire_input/${pharmacy_visit_uid}`,
  },
  netApplicationDone: {
    path: '/net-application/done/:pharmacy_visit_uid',
    build: (pharmacy_visit_uid: string) =>
      `/net-application/done/${pharmacy_visit_uid}`,
  },
  telepharmacy: {
    path: '/telepharmacy',
    build: () => '/telepharmacy',
  },
  telePharmacySearchPharmacy: {
    path: '/telepharmacy/search-pharmacy',
    build: () => '/telepharmacy/search-pharmacy',
  },
  telepharmacyHistory: {
    path: '/telepharmacy/history',
    build: () => '/telepharmacy/history',
  },
  telepharmacyPharmacyDetail: {
    path: '/telepharmacy/pharmacy-detail/:pharmacy_uid',
    build: (pharmacy_uid: string) =>
      `/telepharmacy/pharmacy-detail/${pharmacy_uid}`,
  },
  telepharmacyVideoCall: {
    path: '/telepharmacy/:pharmacy_visit_uid/video-call',
    build: (pharmacy_visit_uid: string) =>
      `/telepharmacy/${pharmacy_visit_uid}/video-call`,
  },
  telepharmacyDetails: {
    path: '/telepharmacy/:pharmacy_visit_uid',
    build: (pharmacy_visit_uid: string) =>
      `/telepharmacy/${pharmacy_visit_uid}`,
  },
  telepharmacyDocuments: {
    path: '/telepharmacy/:pharmacy_visit_uid/documents',
    build: (pharmacy_visit_uid: string) =>
      `/telepharmacy/${pharmacy_visit_uid}/documents`,
  },
  telepharmacyQuestionnaireAnswer: {
    path: '/telepharmacy/:pharmacy_visit_uid/questionnaire_answer',
    build: (pharmacy_visit_uid: string) =>
      `/telepharmacy/${pharmacy_visit_uid}/questionnaire_answer`,
  },
  telepharmacyPayment: {
    path: '/telepharmacy/:pharmacy_visit_uid/payment',
    build: (pharmacy_visit_uid: string) =>
      `/telepharmacy/${pharmacy_visit_uid}/payment`,
  },
  followUp: {
    path: '/follow_ups',
    build: () => '/follow_ups',
  },
  followUpApplicationPatient: {
    path: '/follow_ups/application/patient/:pharmacy_code',
    build: (pharmacy_code: string) =>
      `/follow_ups/application/patient/${pharmacy_code}`,
  },
  followUpChat: {
    path: '/follow_ups/episodes/:episode_uid/chat',
    build: (episode_uid: string) => `/follow_ups/episodes/${episode_uid}/chat`,
  },
  followUpHistory: {
    path: '/follow_ups/episodes/:episode_uid/history',
    build: (episode_uid: string) =>
      `/follow_ups/episodes/${episode_uid}/history`,
  },
  followUpQuestionnaire: {
    path: '/follow_ups/:follow_up_uid/questionnaire',
    build: (follow_up_uid: string) =>
      `/follow_ups/${follow_up_uid}/questionnaire`,
  },
  followUpQuestionnaireAnswer: {
    path: '/follow_ups/:follow_up_uid/questionnaire_answer',
    build: (follow_up_uid: string) =>
      `/follow_ups/${follow_up_uid}/questionnaire_answer`,
  },
  followUpReservation: {
    path: '/follow_ups/:follow_up_uid/reservation',
    build: (follow_up_uid: string) =>
      `/follow_ups/${follow_up_uid}/reservation`,
  },
  updatePassword: {
    path: '/update_password',
    build: () => '/update_password',
  },
  announcement: {
    path: '/announcements',
    build: () => '/announcements',
  },
  announcementHistory: {
    path: '/announcements/history',
    build: () => '/announcements/history',
  },
  announcementDetails: {
    path: '/announcements/:announcement_uid/details',
    build: (announcement_uid: string) =>
      `/announcements/${announcement_uid}/details`,
  },
  settings: {
    path: '/settings',
    build: '/settings',
  },
  settingsEditProfile: {
    path: '/settings/edit_profile/:patient_uid',
    build: (patient_uid: string) => `/settings/edit_profile/${patient_uid}`,
  },
  settingsAddProfile: {
    path: '/settings/add_profile',
    build: () => `/settings/add_profile`,
  },
  creditCards: {
    path: '/settings/credit_cards',
    build: () => '/settings/credit_cards',
  },
  creditCardsNew: {
    path: '/settings/credit_cards/new',
    build: () => '/settings/credit_cards/new',
  },
  // Redirect destination from koala-web
  redirect: {
    path: '/redirect/:token',
    build: (token: string) => `/redirect/${token}`,
  },
  maintenance: {
    path: '/maintenance',
    build: () => '/maintenance',
  },

  campaign: {
    path: '/entry-campaign/:campaign_code',
    build: (campaign_code: string) => `/entry-campaign/${campaign_code}/`,
  },
} as const
