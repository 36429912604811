import React, { useCallback, useState, FC } from 'react'
import { useFetch as useAppStateFetch } from 'src/modules/apis/app-state/use-fetch'
import { Loader } from 'src/modules/components/lib/loader'
import { AppState } from 'src/modules/entities/app-state/entity'
import { routes } from 'src/modules/routes'
import { MaintenanceScreen } from './screen'

export const MaintenanceScreenContainer: FC = () => {
  const [appState, setAppState] = useState<AppState | undefined>({
    state: 'active',
  })

  const { fetching } = useAppStateFetch({
    onComplete: useCallback((fetchedAppState: AppState | undefined) => {
      setAppState(fetchedAppState)
    }, []),
  })

  if (fetching) {
    return <Loader />
  }

  if (!appState) {
    window.location.replace(routes.home.build())
  }

  return <MaintenanceScreen appState={appState} />
}
