import React, { FC } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { PharmaciesSearchResponse } from 'src/modules/apis/net-reception/external-pharmacy/list/request'
import { PublicPageHeader } from 'src/modules/components/header/public-page-header'

import { Button } from 'src/modules/components/lib/button'
import { SearchLeft } from 'src/modules/components/lib/curon-icon'
import { Input } from 'src/modules/components/lib/input'
import { Loader } from 'src/modules/components/lib/loader'
import { Screen } from 'src/modules/components/lib/screen'

import { Text } from 'src/modules/components/lib/text'
import { Flexbox, View } from 'src/modules/components/lib/view'
import { Near } from 'src/modules/components/net-application/chainTop/near'
import { PharmacyList } from 'src/modules/components/net-application/pharmacy-list'
import { SearchPagination } from 'src/modules/components/net-application/search-pagination'
import {
  ExternalPharmacyBrandLogo,
  Pharmacy,
} from 'src/modules/entities/pharmacy/entity'
import { Footer } from 'src/modules/screens/top/footer'
import { Spacer } from 'src/modules/screens/top/spacer'

type PaginationMeta = Omit<PharmaciesSearchResponse, 'pharmacies'>
type Props = {
  pharmacies: Pharmacy[] | []
  paginationMeta?: PaginationMeta
  nearPharmacies: Pharmacy[] | []
  fetched: boolean
  authorized: boolean
  loading: boolean
  nearPharmaciesLoading: boolean
  currentPosition: GeolocationPosition | null
  isGeoApproved: boolean | null
  externalPharmacyUid: string
  brand?: ExternalPharmacyBrandLogo
}

export const NetApplicationExternalPharmacySearchTopScreen: FC<Props> = ({
  pharmacies,
  paginationMeta,
  nearPharmacies,
  fetched,
  authorized,
  loading,
  nearPharmaciesLoading,
  currentPosition,
  isGeoApproved,
  externalPharmacyUid,
  brand,
}) => {
  const location = useLocation()
  const history = useHistory()
  const keyword = new URLSearchParams(location.search).get('keyword')

  const { register, handleSubmit, getValues } = useForm({
    defaultValues: {
      search: keyword ?? '',
    },
  })

  return (
    <View minHeight="100%">
      <PublicPageHeader fetched={fetched} authorized={authorized} />
      <Screen>
        <View backgroundColor="appBackground" px="16px" height="100%">
          <Spacer size={24} />
          {brand && (
            <>
              <View
                bg={'white'}
                textAlign={'center'}
                paddingY={'6px'}
                style={{ boxSizing: 'border-box' }}
              >
                <img
                  width={'100%'}
                  height={'78px'}
                  style={{ objectFit: 'contain' }}
                  src={brand.imageUrl}
                  alt={''}
                />
              </View>
              <Spacer size={24} />
            </>
          )}
          {isGeoApproved === true ? (
            <Near
              nearPharmacies={nearPharmacies}
              nearPharmaciesLoading={nearPharmaciesLoading}
              currentPosition={currentPosition}
              externalPharmacyUid={externalPharmacyUid || undefined}
              isGeoApproved
            />
          ) : (
            <View width="100%">
              <Text fontWeight="bold" fontSize="16px">
                現在地近くの薬局
              </Text>
              {isGeoApproved === false && (
                <>
                  <Spacer size={16} />
                  <Flexbox
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    backgroundColor="dsGray.100"
                    paddingY="12px"
                    borderRadius="8px"
                  >
                    <Text fontWeight="bold" color="dsGray.400" px={3}>
                      ※この端末の位置情報の利用をONにしてください。
                    </Text>
                  </Flexbox>
                </>
              )}
            </View>
          )}
          <Spacer size={24} />
          <View>
            <Text fontSize="large" fontWeight="bold">
              店舗・キーワードから探す
            </Text>
            <Spacer size={16} />
            <form
              onSubmit={handleSubmit(() => {
                const query = getValues('search')
                history.push({
                  search: query ? `keyword=${getValues('search')}` : undefined,
                })
              })}
            >
              <Flexbox alignItems={'center'}>
                <CustomInputRoot>
                  <SearchLeft color="shuttleGray" className="icon" />
                  <Input
                    type="search"
                    width="100%"
                    minHeight={48}
                    placeholder="店舗・キーワード"
                    {...register('search')}
                  />
                </CustomInputRoot>
                <Spacer size={16} horizontal />
                <Button
                  type={'submit'}
                  shape={'square'}
                  size={'small'}
                  width={60}
                  fontSize={'normal'}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  検索
                </Button>
              </Flexbox>
            </form>
          </View>
          <Spacer size={24} />
          <View width="100%" mb={6}>
            <Text fontSize="large" fontWeight="bold">
              薬局一覧
            </Text>
            {pharmacies.length > 0 && paginationMeta && (
              <PaginationCountDisplay paginationMeta={paginationMeta} />
            )}
            {loading ? (
              <Loader py="5" />
            ) : (
              <PharmacyList
                authorized={authorized}
                pharmacies={pharmacies}
                externalPharmacyUid={externalPharmacyUid || undefined}
              />
            )}
          </View>
        </View>

        {pharmacies.length > 0 && paginationMeta && (
          <SearchPagination
            current={paginationMeta.current_page}
            max={paginationMeta.total_page}
            path={location.pathname}
            params={new URLSearchParams(location.search)}
          />
        )}

        <Footer />
      </Screen>
    </View>
  )
}

const CustomInputRoot = styled.div`
  position: relative;
  flex: 1 1 0;

  .icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }

  input {
    padding-left: 40px;
  }
`

const PaginationCountDisplay: FC<{ paginationMeta: PaginationMeta }> = ({
  paginationMeta: { count_per_page, current_page, total_count },
}) => {
  const start = count_per_page * (current_page - 1) + 1
  const end =
    total_count < count_per_page * current_page
      ? total_count
      : count_per_page * current_page

  return (
    <Text fontSize={'small'} fontWeight={600} my={4}>
      ({start}〜{end}件 / 全{total_count}
      件)
    </Text>
  )
}
