import React, { FC, useCallback, useState } from 'react'

import { TextButton } from 'src/modules/components/lib/button'
import { ArrowRight, ArrowDown } from 'src/modules/components/lib/curon-icon'
import { Link } from 'src/modules/components/lib/link'
import { Text } from 'src/modules/components/lib/text'
import { View, Flexbox } from 'src/modules/components/lib/view'
import { PREFECTURE_LIST } from 'src/modules/constants/prefecture-list'
import { getSearchPagePath } from 'src/modules/helpers/get-serch-page-path'
import { sortPrefectureList } from 'src/modules/helpers/sort-prefecture-list'
import { Spacer } from 'src/modules/screens/top/spacer'

type Props = {
  prefectureCount?: { [key: string]: number }
  chainCode: string
}

type SearchPrefecture = {
  value: string
  name: string
}

export const LocationSearch: FC<Props> = ({ prefectureCount, chainCode }) => {
  const [showAccordion, setShowAccordion] = useState(false)

  // PREFECTURE_LISTにprefectureCountの件数を追加
  const prefectureList = PREFECTURE_LIST.map(pref => {
    const prefecture = {
      ...pref,
      count:
        prefectureCount && prefectureCount[pref.name]
          ? prefectureCount[pref.name]
          : 0,
    }
    return prefecture
  })

  const chainListPath = getSearchPagePath({
    chainCode: chainCode,
    prefecture: 'all',
  })

  const list = sortPrefectureList(prefectureList)
  const mainSearchPrefecture = prefectureList
    .sort((a, b) => b.count - a.count)
    .filter(pref => pref.count !== 0)
    .slice(0, 6)

  const opneAccordion = useCallback(() => {
    setShowAccordion(true)
  }, [])

  const closeAccordion = useCallback(() => {
    setShowAccordion(false)
  }, [])

  return (
    <View width="100%">
      <Text fontSize="large" fontWeight="bold">
        店舗数の多いエリアから探す
      </Text>
      <Spacer size={14} />
      <Flexbox
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        style={{ columnGap: '20px', rowGap: '14px' }}
      >
        {mainSearchPrefecture.map(pref => (
          <MainCity pref={pref} chainCode={chainCode} />
        ))}
      </Flexbox>
      <Spacer size={14} />
      <Flexbox justifyContent="flex-end">
        <TextButton onClick={showAccordion ? closeAccordion : opneAccordion}>
          <Flexbox alignItems="center">
            <Text fontWeight="bold" color="darkGray">
              全国から探す
            </Text>
            <Spacer horizontal size={4} />
            {showAccordion ? (
              <ArrowDown size="13px" color="darkGray" />
            ) : (
              <ArrowRight size="13px" color="darkGray" />
            )}
          </Flexbox>
        </TextButton>
      </Flexbox>
      {showAccordion && (
        <View>
          <Link to={chainListPath}>
            <Text
              style={{
                textDecoration: 'underline',
              }}
            >
              全国から探す
            </Text>
          </Link>
          <Spacer size={14} />
          {list.map(prefList => {
            return (
              <>
                <Text color="dsGray.600" fontWeight="bold" lineHeight="150%">
                  {prefList.name}
                </Text>
                <Spacer size={5} />
                <Flexbox
                  display="grid"
                  gridTemplateColumns="repeat(5, 1fr)"
                  style={{ columnGap: '15px', rowGap: '10px' }}
                >
                  {prefList.list.map(pref => {
                    const path = getSearchPagePath({
                      near: false,
                      chainCode: chainCode,
                      keyword: undefined,
                      prefecture: pref.value,
                    })
                    if (pref.count === 0)
                      return <Text color="dsGray.400">{pref.name}</Text>
                    return (
                      <Link to={path}>
                        <Text
                          style={{
                            textDecoration: 'underline',
                          }}
                        >
                          {pref.name}
                        </Text>
                      </Link>
                    )
                  })}
                </Flexbox>
                <Spacer size={12} />
              </>
            )
          })}
        </View>
      )}
    </View>
  )
}

export const MainCity: FC<{ pref: SearchPrefecture; chainCode: string }> = ({
  pref,
  chainCode,
}) => {
  const path = getSearchPagePath({
    near: false,
    chainCode: chainCode,
    keyword: undefined,
    prefecture: pref.value,
  })

  return (
    <Link
      display="flex"
      flexDirection="column"
      width="100%"
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
      paddingY="20px"
      style={{
        borderRadius: '4px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
        border: ' 1px solid #B2BFCE',
      }}
      to={path}
    >
      <Text color="black" textAlign="center" fontSize="large" fontWeight="bold">
        {pref.name}
      </Text>
    </Link>
  )
}
