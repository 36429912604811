import React, {
  useCallback,
  useState,
  useMemo,
  FC,
  PropsWithChildren,
} from 'react'
import { useSubmit as useCreate } from 'src/modules/apis/favorite-pharmacy/create/use-submit'
import { useSubmit as useDestroy } from 'src/modules/apis/favorite-pharmacy/destroy/use-submit'
import { useFetch } from 'src/modules/apis/favorite-pharmacy/list/use-fetch'
import { FavoritePharmacy } from 'src/modules/entities/favorite-pharmacy/entity'
import { useToast } from '../components/lib/toast'
import { Active } from '../entities/enums/active'
import { useAccount } from '../hooks/use-account'
import { FavoritePharmacyContext } from './context'

const MAX_FAVORITE_PHARMACIES = 10

export const FavoritePharmacyProvider: PropsWithChildren<FC> = ({
  children,
}) => {
  const [favorites, setFavorites] = useState<FavoritePharmacy[] | null>(null)
  const toast = useToast() // 注: FavoritePharmacyProvider は ToastContainer に wrap される必要がある
  const { authorized } = useAccount()

  const { doFetch, fetching, fetched } = useFetch({
    immediate: authorized, // 認証が完了したら Fetch する
    onComplete: useCallback(data => {
      setFavorites(data)
    }, []),
  })

  const { doSubmit: doCreate, submitting: creating } = useCreate({
    onComplete: useCallback(
      (data: FavoritePharmacy) => {
        toast.showToast('お気に入り薬局に登録しました')
        setFavorites(favorites => {
          favorites = (favorites || []).filter(f => f.uid !== data.uid)
          return [data, ...favorites]
        })
      },
      [toast],
    ),
  })

  const { doSubmit: doDestroy, submitting: destroying } = useDestroy({
    onComplete: useCallback((data: { uid: string }) => {
      setFavorites(favorites =>
        (favorites || []).filter(f => f.uid !== data.uid),
      )
    }, []),
  })

  // 検索結果に prepend するお気に入りは、アクティブな薬局のみ
  const activeFavorites = useMemo(
    () =>
      favorites
        ? favorites.filter(f => f.pharmacy.active === Active.Active)
        : null,
    [favorites],
  )

  // お気に入りに追加できるのは10件まで
  const canAdd =
    (activeFavorites && activeFavorites.length < MAX_FAVORITE_PHARMACIES) ||
    false

  const canSubmit = authorized && !creating && !destroying

  const add = useCallback(
    (pharmacyUid: string) => {
      if (canSubmit && canAdd) {
        doCreate({ pharmacyUid })
      }
    },
    [canAdd, canSubmit, doCreate],
  )

  const remove = useCallback(
    (pharmacyUid: string) => {
      if (canSubmit) {
        const uid = favorites?.find(f => f.pharmacy.uid === pharmacyUid)?.uid
        if (uid) {
          doDestroy({ uid })
        }
      }
    },
    [canSubmit, doDestroy, favorites],
  )

  const isFavorite = useCallback(
    (pharmacyUid: string) => {
      return !!favorites?.find(f => f.pharmacy.uid === pharmacyUid)
    },
    [favorites],
  )

  return (
    <FavoritePharmacyContext.Provider
      value={{
        doFetch,
        fetched,
        fetching,
        add,
        remove,
        isFavorite,
        canAdd,
        favorites,
        activeFavorites,
      }}
    >
      {children}
    </FavoritePharmacyContext.Provider>
  )
}
