import useSWR from 'swr'
import { SWRFetchOption } from 'src/modules/apis/hooks/use-fetch'
import { fetch, Params } from './request'

export const useSWRFetch = (
  params: Params,
  options?: SWRFetchOption<ResolvedType<ReturnType<typeof fetch>>>,
) => {
  const { keywordForSearchStation, keywordForSearchPharmacy, chainCode } =
    params

  const key = `/patients/pharmacies/station/search_by_keyword/keywordForSearchStation${keywordForSearchStation}_keywordForSearchPharmacy${keywordForSearchPharmacy}_chainCode${chainCode}`

  const { data, error } = useSWR(
    keywordForSearchStation ? key : null,
    () => fetch(params),
    {
      onSuccess: data => {
        options?.onComplete && options.onComplete(data)
      },
      onError: error => {
        options?.onFailure && options.onFailure(error)
      },
    },
  )

  return {
    data,
    error,
    loading: !data && !error,
  }
}
