import { replace } from 'connected-react-router'
import React, { useCallback, FC, useEffect, PropsWithChildren } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { useFetch } from 'src/modules/apis/pharmacy-visit/single/use-fetch'
import { Loader } from 'src/modules/components/lib/loader'
import {
  CompletedBy,
  PharmacyVisit,
} from 'src/modules/entities/pharmacy-visit/entity'
import { routes } from 'src/modules/routes'

export const ApplicationCompletedGuard: PropsWithChildren<FC> = ({
  children,
}) => {
  const dispatch = useDispatch()
  const { pharmacy_visit_uid } = useParams<{ pharmacy_visit_uid: string }>()
  const { pathname } = useLocation()

  const { doFetch, fetching } = useFetch(pharmacy_visit_uid, {
    immediate: false,
    onComplete: useCallback(
      (pharmacyVisit: PharmacyVisit) => {
        const destination = getApplicationCompletedRedirectDestination({
          pharmacyVisit,
        })

        if (destination) {
          dispatch(replace(destination))
        }
      },
      [dispatch],
    ),
  })

  useEffect(() => {
    doFetch()
  }, [doFetch, pathname])

  if (fetching) {
    return <Loader />
  }

  return <>{children}</>
}

export const getApplicationCompletedRedirectDestination = ({
  pharmacyVisit,
}: {
  pharmacyVisit: PharmacyVisit
}) => {
  if (
    pharmacyVisit.applicationCompletedAt ||
    pharmacyVisit.canceledAt ||
    (pharmacyVisit.completedAt &&
      pharmacyVisit.medicineDispensedAt &&
      pharmacyVisit.completedBy === CompletedBy.Pharmacy) ||
    (pharmacyVisit.completedAt &&
      pharmacyVisit.completedBy === CompletedBy.BatchJob)
  ) {
    return
  }
  return routes.home.build()
}
