import { User } from 'src/modules/entities/user/entity'
import {
  ProfileResponse,
  parse as parseProfileResponse,
} from '../profile/response'

export type UserResponse = {
  uid: string
  email: string
  profile: ProfileResponse | null
}

export const parse = (resp: UserResponse): User => {
  const res = {
    uid: resp.uid,
    email: resp.email,
    profile: resp.profile ? parseProfileResponse(resp.profile) : null,
  }

  return res
}
