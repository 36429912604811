import React, { FC } from 'react'
import styled from 'styled-components'
import { Image } from 'src/modules/components/lib/image'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox, View } from 'src/modules/components/lib/view'
import { SearchPharmacyLink } from './search-pharmacy'
import { Spacer } from './spacer'

export const RequiredItems: FC = () => {
  return (
    <ViewContainer>
      <SectionHeaderText>処方箋ネット受付に必要なもの</SectionHeaderText>
      <View padding="0 22px 0">
        <PrepareWrap>
          <PrepareContentBox>
            <PrepareContent minWidth="250px">
              <ImageWrap>
                <Text
                  width="72px"
                  padding="1px 0"
                  fontSize="12px"
                  lineHeight="18px"
                  backgroundColor="#fff500"
                  textAlign="center"
                  position="absolute"
                  top="-30px"
                >
                  予約時
                </Text>
                <Image
                  src="/top/prepare_icon_01.svg"
                  alt="処方箋"
                  width="30px"
                />
              </ImageWrap>
              <PrepareContentText>処方箋（期限内のもの）</PrepareContentText>
            </PrepareContent>

            <PrepareContent minWidth="250px">
              <ImageWrap>
                <Text
                  width="72px"
                  padding="1px 0"
                  fontSize="12px"
                  lineHeight="18px"
                  backgroundColor="#fff500"
                  textAlign="center"
                  position="absolute"
                  top="-30px"
                >
                  受け取り時
                </Text>
                <Image
                  src="/top/prepare_icon_01.svg"
                  alt="処方箋"
                  width="30px"
                />
              </ImageWrap>
              <PrepareContentText>処方箋の原本（紙）</PrepareContentText>
            </PrepareContent>

            <PrepareContent>
              <ImageWrap>
                <Image
                  src="/top/prepare_icon_02.svg"
                  alt="保険証"
                  width="50px"
                />
              </ImageWrap>
              <PrepareContentText>保険証</PrepareContentText>
            </PrepareContent>
          </PrepareContentBox>

          <Spacer size={20} />

          <Text fontSize="12px" lineHeight="21px">
            ※お薬手帳をお持ちの場合は、ご一緒にお持ちください（任意）
            <br />
            ※お受け取りの際に処方箋原本（紙）と保険証をお持ちでない場合は処方薬を受け取れない場合がございます
            <br />
            ※お薬は必ず処方箋の期限内にお受け取りください
            <br />
          </Text>
          <Spacer size={5} />
        </PrepareWrap>
      </View>

      <UnderWrap>
        <BtnWrap>
          <SearchPharmacyLink gtmId="gtm-phanet-link-btn01" />
        </BtnWrap>
      </UnderWrap>
    </ViewContainer>
  )
}

const ViewContainer = styled(View)`
  padding: 75px 0 0;
  font-family: 'HiraginoSans', sans-serif;

  @media (max-width: 560px) {
    padding-top: 43px;
  }
`

const SectionHeaderText = styled(Text)`
  margin: 0 0 40px;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  text-align: center;

  @media (max-width: 560px) {
    margin-bottom: 33px;
    font-size: 18px;
    line-height: 27px;
  }
`

const PrepareWrap = styled(View)`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 63px 30px;
  border: 2px solid #1e8f58;
  border-radius: 8px;

  @media (max-width: 560px) {
    padding: 0 12px 25px;
  }
`

const PrepareContentBox = styled(Flexbox)`
  justify-content: flex-start;

  @media (max-width: 835px) {
    flex-direction: column;
  }
`

const PrepareContent = styled(Flexbox)`
  justify-content: flex-start;
  align-items: center;
  padding: 30px 0 20px 30px;
  border-left: 1px solid #b0bac5;
  &:first-child {
    padding-left: 0;
    border-left: none;
  }
  @media (max-width: 835px) {
    padding: 60px 0 20px;
    border-left: none;
    border-bottom: 1px solid #b0bac5;
    &:last-child {
      padding-top: 20px;
    }
  }
`

const ImageWrap = styled(Flexbox)`
  min-width: 71px;
  flex-direction: column;
  align-items: center;
  position: relative;
`

const PrepareContentText = styled(Text)`
  font-size: 14px;
`

const UnderWrap = styled(View)`
  margin: 85px 0 0;
  padding: 45px 0;
  background-color: ${({ theme }) => theme.colors.pistachioGreen};

  @media (max-width: 560px) {
    margin-top: 59px;
    padding: 28px 0;
  }
`

const BtnWrap = styled(View)`
  max-width: 288px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.pistachioGreen};
`
