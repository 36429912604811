import { PrescriptionNotebookImage } from 'src/modules/entities/prescription-notebook-image/entity'

export type PrescriptionNotebookImageResponse = {
  body: string
  index: number
}

export const parse = (
  resp: PrescriptionNotebookImageResponse,
): PrescriptionNotebookImage => {
  const res = {
    body: resp.body,
    index: resp.index,
  }

  return res
}
