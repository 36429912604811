import moment from 'moment'
import { BusinessHourInfo } from '../entity'

const today = new Date()

export const getIrregularHourToday = (info: BusinessHourInfo) => {
  return info.irregularDays.find(irregularDay => {
    return moment(irregularDay.date).isSame(today, 'date')
  })
}

export const getIrregularDaysFromToday = (info: BusinessHourInfo) => {
  return info.irregularDays.filter(irregularDay => {
    return moment(irregularDay.date).isSameOrAfter(today, 'date')
  })
}
