import moment from 'moment'
import React, { FC, useMemo, Fragment } from 'react'
import { ReactComponent as ClockImage } from 'src/assets/search/clock.svg'
import { ArrowRight } from 'src/modules/components/lib/curon-icon'
import { Link } from 'src/modules/components/lib/link'
import { Text } from 'src/modules/components/lib/text'
import { View, Flexbox } from 'src/modules/components/lib/view'
import { getIrregularHourToday } from 'src/modules/entities/business-hour-info/helpers/get-irregular-hour-info'
import { getTimeRangesOfDate } from 'src/modules/entities/business-hour-info/helpers/get-time-ranges-of-date'
import { Pharmacy } from 'src/modules/entities/pharmacy/entity'
import { formatTimeRange } from 'src/modules/entities/time-range/helpers/format-time-ranges'
import { timeRangeCompetitor } from 'src/modules/entities/time-range/helpers/time-range-competitor'
import { routes } from 'src/modules/routes'
import { Spacer } from 'src/modules/screens/top/spacer'

type Props = {
  pharmacy: Pharmacy
}

export const FavoritePharmacyCard: FC<Props> = ({ pharmacy }) => {
  const timeRangeOfDate = useMemo(() => {
    if (!pharmacy.businessHourInfo) return null

    return getTimeRangesOfDate(pharmacy.businessHourInfo, moment())
  }, [pharmacy.businessHourInfo])

  const irregularHourToday = useMemo(() => {
    if (!pharmacy.businessHourInfo) return null
    return getIrregularHourToday(pharmacy.businessHourInfo)
  }, [pharmacy.businessHourInfo])

  return (
    <Link to={routes.pharmaciesDetail.build(pharmacy.uid)}>
      <Flexbox
        width="100%"
        maxHeight="90px"
        minHeight="90px"
        borderRadius="8px"
        background="white"
        boxShadow="0px 2px 7px 0px rgba(111, 111, 111, 0.15)"
        justifyContent="space-between"
        padding="8px 12px"
      >
        <View>
          <Text
            fontSize="large"
            fontWeight="bold"
            color="dsGray.900"
            lineHeight="24px"
          >
            {pharmacy.name}
          </Text>
          <Spacer size={4} />
          <Flexbox alignItems="flex-start">
            <Flexbox alignItems="flex-start" color="#637182">
              <ClockImage />
              <Spacer size={8} horizontal />
              <Text color="dsGray.400">本日</Text>
              <Spacer size={8} horizontal />

              {!irregularHourToday && (
                <>
                  {timeRangeOfDate && timeRangeOfDate?.length === 0 ? (
                    <Text fontWeight="bold" color="dsGray.400">
                      休業
                    </Text>
                  ) : (
                    <>
                      <View>
                        {timeRangeOfDate?.map((time, index) => (
                          <Fragment key={index}>
                            <Text fontWeight="bold" color="dsGray.400">
                              {formatTimeRange(time)}
                            </Text>
                          </Fragment>
                        ))}
                      </View>
                    </>
                  )}
                </>
              )}

              {irregularHourToday && (
                <>
                  <Spacer size={8} horizontal />
                  {irregularHourToday.opened ? (
                    <View>
                      <Text fontWeight="bold" color="dsRed.600">
                        特別営業
                      </Text>
                      {irregularHourToday.timeRanges
                        .sort((a, b) => timeRangeCompetitor(a, b))
                        .map((time, index) => (
                          <Text key={index}>{formatTimeRange(time)}</Text>
                        ))}
                    </View>
                  ) : (
                    <Text fontWeight="bold" color="dsRed.600">
                      特別休業
                    </Text>
                  )}
                </>
              )}
            </Flexbox>

            <Spacer horizontal size={8} />
          </Flexbox>
        </View>

        <Flexbox alignItems="center" justifyContent="center">
          <ArrowRight color="#4DB27F" size="16px" />
        </Flexbox>
      </Flexbox>
    </Link>
  )
}
