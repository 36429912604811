import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M62.148 61.477a3.36 3.36 0 100-6.72 3.36 3.36 0 000 6.72z"
        fill={props.color || '#000'}
      />
      <path
        d="M73.906 72.563a6.717 6.717 0 01-6.719 6.718H18.813a6.717 6.717 0 01-6.72-6.719V45.017a6.717 6.717 0 016.72-6.72h48.374a6.717 6.717 0 016.72 6.72v27.547zm6.719-1.848v-25.7c0-7.407-6.03-13.437-13.438-13.437h-4.03V19.736C63.156 8.852 54.102 0 43 0S22.844 8.852 22.844 19.736v11.842h-4.032c-7.407 0-13.437 6.03-13.437 13.438v27.547C5.375 79.97 11.405 86 18.813 86h48.374c7.408 0 13.438-6.03 13.438-13.438v-1.847zM56.437 31.578H29.564V19.736c0-7.172 6.03-13.017 13.437-13.017 7.407 0 13.438 5.845 13.438 13.017v11.842z"
        fill={props.color || '#000'}
      />
      <path
        d="M36.785 61.477a3.36 3.36 0 100-6.72 3.36 3.36 0 000 6.72zM24.188 61.477a3.36 3.36 0 100-6.72 3.36 3.36 0 000 6.72zM49.383 61.477a3.36 3.36 0 100-6.72 3.36 3.36 0 000 6.72z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgPassword = _style(SvgIcon)

export default SvgPassword
