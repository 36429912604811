import React, { FC } from 'react'
import styled from 'styled-components'
import { Text } from 'src/modules/components/lib/text'
import { View } from 'src/modules/components/lib/view'
import { EntryBtn } from './entry-btn'

export const Entry: FC = () => {
  return (
    <View
      fontFamily="'Noto Sans JP', sans-serif"
      position="relative"
      zIndex="3"
    >
      <EntryContainer>
        <Text fontSize="20px" fontWeight="700" textAlign="center">
          まずは下記ボタンを押して
          <br />
          エントリー！
        </Text>
        <Text
          marginBottom="13px"
          fontSize="10px"
          fontWeight="500"
          textAlign="center"
        >
          ENTRY
        </Text>
        <EntryBtn />
      </EntryContainer>
    </View>
  )
}

const EntryContainer = styled(View)`
  max-width: 430px;
  margin: 0 auto;
  padding: 70px 5px 60px;
  background: url(/cp/campaign202210/entry_bg.png) no-repeat 0 0 / cover;
  overflow: hidden;
  position: relative;
  ::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 9%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background: url(/cp/campaign202210/entry_bg_top.png) no-repeat 0 0 / contain;
  }
  ::after {
    content: '';
    display: block;
    width: 100%;
    padding-top: 8.3%;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: url(/cp/campaign202210/entry_bg_under.png) no-repeat 0 0 /
        contain,
      #038d44;
  }
`
