import { FavoritePharmacy } from 'src/modules/entities/favorite-pharmacy/entity'
import { parse as pharmacyParse, PharmacyResponse } from '../pharmacy/response'

export type FavoritePharmacyResponse = {
  uid: string
  patient_uid: string
  created_at: string
  pharmacy: PharmacyResponse
}

export const parse = (resp: FavoritePharmacyResponse): FavoritePharmacy => {
  return {
    uid: resp.uid,
    patientUid: resp.patient_uid,
    createdAt: resp.created_at,
    pharmacy: pharmacyParse(resp.pharmacy),
  }
}
