import React, { FC } from 'react'
import styled from 'styled-components'
import {
  borders,
  BordersProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
} from 'styled-system'

type StyledSystemProps = SpaceProps &
  LayoutProps &
  PositionProps &
  FlexboxProps &
  BordersProps

const styledSystemProps = [space, layout, position, flexbox, borders]

export type BorderProps = StyledSystemProps &
  React.HTMLAttributes<HTMLDivElement>

export const Border: FC<BorderProps> = props => {
  return <BorderRoot {...props}>{props.children}</BorderRoot>
}

const BorderRoot = styled('div')<StyledSystemProps>(
  {
    borderTop: '1px solid black',
  },
  styledSystemProps,
)
