import React, { FC } from 'react'
import { View, ViewProps } from './view'

export type CardProps = {
  elevation?: 'high' | 'low' | 'nil'
  options?: Record<string, unknown>
} & ViewProps

export const Card: FC<CardProps> = ({
  elevation = 'low',
  options,
  ...props
}) => {
  const boxShadow =
    elevation === 'high'
      ? '0px 0px 30px rgba(0, 0, 0, 0.1)'
      : elevation === 'low'
      ? '0px 1px 3px rgba(0, 0, 0, 0.1)'
      : ''
  return (
    <View
      boxShadow={boxShadow}
      borderRadius="4px"
      backgroundColor="white"
      {...options}
      {...props}
    >
      {props.children}
    </View>
  )
}
