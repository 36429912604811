import { useCallback } from 'react'
import { routes } from '../routes/id-platform'

type Params = {
  redirect_uri?: string
  fields?: AllowedProfileField[]
}

export type AllowedProfileField =
  | 'name'
  | 'name_j'
  | 'gender'
  | 'birthday'
  | 'phone'
  | 'address'

export const useIdPlatform = () => {
  const getRedirectURI = () => {
    const { origin, pathname, search } = window.location
    return origin + pathname + search
  }

  const redirectUriParams = useCallback((params?: Params) => {
    const redirect_uri = params?.redirect_uri
      ? window.location.origin + params.redirect_uri
      : getRedirectURI()

    const searchParams: {
      client_id: 'yap'
      redirect_uri: string
      fields?: string
    } = {
      client_id: 'yap', // NOTE: 固定値
      redirect_uri,
    }
    if (params?.fields) searchParams.fields = params.fields.join(',')

    return new URLSearchParams(searchParams).toString()
  }, [])

  const accountMenuUri = useCallback(
    (redirect_uri?: string) =>
      routes.accountMenu.path + '?' + redirectUriParams({ redirect_uri }),
    [redirectUriParams],
  )
  const signUpUri = useCallback(
    (redirect_uri?: string) =>
      routes.signUp.path + '?' + redirectUriParams({ redirect_uri }),
    [redirectUriParams],
  )
  const loginUri = useCallback(
    (redirect_uri?: string) =>
      routes.signIn.path + '?' + redirectUriParams({ redirect_uri }),
    [redirectUriParams],
  )
  // NOTE: 共通基盤のクレカ画面が利用できるようになったら利用する
  // const resetPasswordUri = useCallback(
  //   (redirect_uri?: string) =>
  //     routes.resetPassword.path + '?' + redirectUriParams({ redirect_uri }),
  //   [redirectUriParams],
  // )
  const settingsUserByPatientUid = useCallback(
    (
      patient_uid: string,
      profileFields: AllowedProfileField[],
      redirect_uri?: string,
    ) =>
      `${routes.users.path}/${patient_uid}/edit?` +
      redirectUriParams({ redirect_uri, fields: profileFields }),
    [redirectUriParams],
  )

  const redirectToLogin = useCallback(
    (redirect_uri?: string) => {
      window.location.href = loginUri(redirect_uri)
    },
    [loginUri],
  )

  const redirectToSignUp = useCallback(
    (redirect_uri?: string) => {
      window.location.href = signUpUri(redirect_uri)
    },
    [signUpUri],
  )

  const redirectSettingsUserByPatientUid = useCallback(
    (
      patient_uid: string,
      profileFields: AllowedProfileField[],
      redirect_uri?: string,
    ) => {
      window.location.href = settingsUserByPatientUid(
        patient_uid,
        profileFields,
        redirect_uri,
      )
    },
    [settingsUserByPatientUid],
  )

  return {
    accountMenuUri,
    signUpUri,
    loginUri,
    // resetPasswordUri,
    settingsUserByPatientUid,
    redirectToLogin,
    redirectToSignUp,
    redirectSettingsUserByPatientUid,
  }
}
