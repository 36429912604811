import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { Image } from 'src/modules/components/lib/image'
import { View } from 'src/modules/components/lib/view'
import { Spacer } from 'src/modules/screens/top/spacer'

export const Flow: FC = () => {
  const onClickLeft = () => {
    setCurrentStep(prevStep => (prevStep + 4) % 5)
  }
  const onClickRight = () => {
    setCurrentStep(prevStep => (prevStep + 1) % 5)
  }

  const steps = [...Array(5)].map((_, i) => i)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const images = [
    '/cp/app/phone01.png',
    '/cp/app/phone02.png',
    '/cp/app/phone03.png',
    '/cp/app/phone04.png',
    '/cp/app/phone05.png',
  ]
  const prevImagePath = images[currentStep - 1]
  const nextImagePath = images[currentStep + 1]
  const imgPath = images[currentStep]

  return (
    <View backgroundColor="#f0faf5">
      <FlowContainer>
        <Spacer size={40} />

        <FlowTitle>
          処方箋ネット受付の
          <br />
          ご利用の流れ
        </FlowTitle>
        <Spacer size={40} />

        <View
          maxWidth="375px"
          margin="0 auto"
          position="relative"
          textAlign="center"
        >
          {steps.map(step => {
            return (
              currentStep === step && (
                <View key={step}>
                  {!(currentStep === 0) && (
                    <PrevImage>
                      <Image src={prevImagePath} width="147px" height="300px" />
                    </PrevImage>
                  )}
                  <Image src={imgPath} width="183px" height="375px" />
                  {!(currentStep === 4) && (
                    <NextImage>
                      <Image src={nextImagePath} width="147px" height="300px" />
                    </NextImage>
                  )}
                </View>
              )
            )
          })}
          {steps.map(step => {
            return (
              currentStep === step && (
                <View key={step}>
                  {!(currentStep === 0) && (
                    <ArrowLeft src="/cp/app/left.svg" onClick={onClickLeft} />
                  )}
                  {!(currentStep === 4) && (
                    <ArrowRight
                      src="/cp/app/right.svg"
                      onClick={onClickRight}
                    />
                  )}
                </View>
              )
            )
          })}
        </View>
        <Spacer size={15} />

        <View textAlign="center">
          {steps.map(step => {
            return step === currentStep ? (
              <CurrentPoint key={step} />
            ) : (
              <Point key={step} />
            )
          })}
        </View>

        <Spacer size={10} />

        {currentStep === 0 && (
          <>
            <FlowContentTitle>STEP1 薬局を探す</FlowContentTitle>
            <Spacer size={15} />
            <Desc>
              処方箋ネット受付が利用できる
              <br />
              薬局を検索します
            </Desc>
          </>
        )}
        {currentStep === 1 && (
          <>
            <FlowContentTitle>STEP2 処方箋をアップロード</FlowContentTitle>
            <Spacer size={15} />
            <Desc>
              今回薬を受けとる患者さんを選択の後、
              <br />
              処方箋を撮影し、アップロードします。 <br />
              処方箋の文字が読み取れるように
              <br />
              撮影してください。
            </Desc>
          </>
        )}
        {currentStep === 2 && (
          <>
            <FlowContentTitle>STEP3 質問票を選択</FlowContentTitle>
            <Spacer size={15} />
            <Desc>
              選択した薬局の利用状況について
              <br />
              回答します。
            </Desc>
          </>
        )}
        {currentStep === 3 && (
          <>
            <FlowContentTitle>STEP4 質問票を回答</FlowContentTitle>
            <Spacer size={15} />
            <Desc>
              薬局の利用について選択し、
              <br />
              表示された質問表に回答します
            </Desc>
          </>
        )}
        {currentStep === 4 && (
          <>
            <FlowContentTitle>STEP5 調剤完了後受け取り</FlowContentTitle>
            <Spacer size={15} />
            <Desc>
              薬局にてお薬の準備が完了したら、
              <br />
              メールでお知らせします。
              <br />
              薬局に来訪し、お薬の説明を受けて
              <br />
              料金をお支払いください。
            </Desc>
            <Spacer size={20} />
            <DescSmall>
              ※<span>処方箋、保険証</span>を必ずお持ちになってご来店ください。
              <br />
              処方箋、保険証をお持ちではない場合、
              <br />
              お薬のお渡しができかねます。
            </DescSmall>
          </>
        )}
      </FlowContainer>
      <Spacer size={130} />
    </View>
  )
}

const FlowContainer = styled(View)`
  margin: 0 auto;
  color: #272c33;
  font-family: Hiragino Sans, sans-serif;
  overflow: hidden;
`

const FlowTitle = styled(View)`
  font-weight: 700;
  font-size: 20px;
  line-height: 1.5em;
  text-align: center;
  color: #1e8f58;
`

const PrevImage = styled(View)`
  opacity: 0.6;
  display: inline-block;
  max-width: 145px;
  position: absolute;
  top: 50%;
  left: -70px;
  transform: translateY(-50%);
  @media (max-width: 320px) {
    left: -100px;
  }
`

const NextImage = styled(View)`
  opacity: 0.6;
  display: inline-block;
  max-width: 145px;
  position: absolute;
  top: 50%;
  right: -70px;
  transform: translateY(-50%);
  @media (max-width: 320px) {
    right: -100px;
  }
`

const ArrowLeft = styled(Image)`
  display: inline-block;
  position: absolute;
  top: 43%;
  left: 5%;
`
const ArrowRight = styled(Image)`
  display: inline-block;
  position: absolute;
  top: 43%;
  right: 5%;
`

const Point = styled(View)`
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #b2bfce;
  border-radius: 50%;
  margin: 5px;
`

const CurrentPoint = styled(Point)`
  background: #2ba168;
`

const FlowContentTitle = styled(View)`
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  color: #1e8f58;
`
const Desc = styled(View)`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
`

const DescSmall = styled(View)`
  font-size: 12px;
  text-align: center;
  span {
    font-weight: bold;
  }
`
