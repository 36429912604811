import React, { useMemo } from 'react'

import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router'
import { useLocation } from 'react-router-dom'
import { useSWRFetch as useExternalPharmaciesFetch } from 'src/modules/apis/net-reception/external-pharmacy/list/use-fetch'
import { useSWRFetch as useExternalPharmacySearchFetch } from 'src/modules/apis/net-reception/external-pharmacy/search/use-fetch'
import { useSWRFetch as useExternalPharmacyFetch } from 'src/modules/apis/net-reception/external-pharmacy/show/use-fetch'

import { useAuthorizationRefresh } from 'src/modules/auth/use-authorization-refresh'
import { useToast } from 'src/modules/components/lib/toast'
import { useGeoPosition } from 'src/modules/geo-location'
import { NetApplicationExternalPharmacySearchTopScreen } from './screen'

export const NetApplicationExternalPharmacyTopSearchScreenContainer = () => {
  const { authorized, fetched } = useAuthorizationRefresh()
  const { showToast } = useToast()

  const { external_pharmacy_uid = '' } = useParams<{
    external_pharmacy_uid?: string
  }>()

  const location = useLocation()
  const page = new URLSearchParams(location.search).get('page')
  const keyword = new URLSearchParams(location.search).get('keyword')

  const { data, loading } = useExternalPharmaciesFetch({
    params: {
      uid: external_pharmacy_uid,
      page: Number(page) || 1,
      keyword: keyword ?? undefined,
    },
    skip: !external_pharmacy_uid,
  })

  const { data: brand } = useExternalPharmacyFetch({
    params: {
      uid: external_pharmacy_uid,
    },
  })

  const {
    data: currentPosition,
    isApproved: isGeoApproved,
    isCompleted,
  } = useGeoPosition({
    onCompleted: () => {
      if (!isGeoApproved) {
        showToast('位置情報の取得に失敗しました。', true)
      }
    },
  })

  const isFetchingCurrentPosition = useMemo(
    () => isGeoApproved && (!isCompleted || !currentPosition),
    [isCompleted, isGeoApproved, currentPosition],
  )

  const { data: nearPharmacy, loading: pharmacyIndexFetching } =
    useExternalPharmacySearchFetch({
      params: {
        uid: external_pharmacy_uid,
        lat: currentPosition?.coords.latitude ?? 0,
        lng: currentPosition?.coords.longitude ?? 0,
      },
      skip:
        isFetchingCurrentPosition ||
        !isGeoApproved ||
        !currentPosition ||
        !external_pharmacy_uid,
      options: {
        onComplete: data => {
          if (data.pharmacies.length === 0) {
            showToast('検索結果がありませんでした。', true)
          }
        },
      },
    })

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <NetApplicationExternalPharmacySearchTopScreen
        pharmacies={data?.pharmacies || []}
        paginationMeta={
          data
            ? {
                count_per_page: data.count_per_page,
                current_page: data.current_page,
                total_count: data.total_count,
                total_page: data.total_page,
              }
            : undefined
        }
        nearPharmacies={nearPharmacy?.pharmacies || []}
        authorized={authorized}
        fetched={fetched}
        loading={loading}
        nearPharmaciesLoading={pharmacyIndexFetching}
        currentPosition={currentPosition}
        isGeoApproved={isGeoApproved}
        externalPharmacyUid={external_pharmacy_uid}
        brand={brand}
      />
    </>
  )
}
