import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M82.791 0H3.209A3.218 3.218 0 000 3.209v79.582A3.218 3.218 0 003.209 86H82.791A3.218 3.218 0 0086 82.791V3.209A3.218 3.218 0 0082.791 0zm-3.209 6.418v48.038l-7.67-7.83c-2.246-2.278-5.23-3.53-8.439-3.466-3.209.065-6.161 1.38-8.311 3.723l-5.776 6.257L37.93 35.17c-1.958-3.08-5.167-5.006-8.825-5.327-3.658-.288-7.124 1.091-9.563 3.851L6.386 48.487V6.417h73.196zM6.418 79.582V58.146l17.938-20.184a5.086 5.086 0 014.236-1.7 5.083 5.083 0 013.915 2.374L44.86 58.018a5.076 5.076 0 003.883 2.343c1.605.128 3.145-.45 4.236-1.637l6.9-7.477a5.088 5.088 0 013.69-1.669h.096a5.07 5.07 0 013.658 1.54l12.258 12.516v15.948H6.418z"
        fill={props.color || '#000'}
      />
      <path
        d="M56.638 36.903c6.996 0 12.675-5.744 12.675-12.836 0-7.091-5.68-12.836-12.675-12.836-6.995 0-12.675 5.745-12.675 12.836 0 7.092 5.68 12.836 12.675 12.836zm0-19.254c3.466 0 6.258 2.888 6.258 6.418 0 3.53-2.792 6.418-6.258 6.418s-6.257-2.888-6.257-6.418c0-3.53 2.791-6.418 6.257-6.418z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgImage = _style(SvgIcon)

export default SvgImage
