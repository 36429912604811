import React, { FC } from 'react'
import styled from 'styled-components'
import { Text } from 'src/modules/components/lib/text'
import { View } from 'src/modules/components/lib/view'

export const Terms: FC = () => {
  return (
    <View fontFamily="'Noto Sans JP', sans-serif">
      <TermsContainer>
        <TermsBlock>
          <TermsTitle>
            <span>応募規約</span>
          </TermsTitle>
          <Text marginBottom="20px">
            本規約は、本キャンペーンへのエントリーを完了し、クロン処方箋ネット受付利用及びアンケートにご回答いただいた方（以下、「応募者」といいます。）と当社との間の本キャンペーンに関わる一切の関係に適用されます。
            <br />
            なお、本キャンペーンへエントリーいただいた時点で、応募規約にご同意いただいたものとみなします。
          </Text>
          <TermsBox>
            <BoxTitle>【キャンペーンに関して】</BoxTitle>
            <ListText>
              ・当社が必要と判断した場合には、当社は、予告なくいつでも本キャンペーンの内容を変更、停止、中止及び終了することができるものとします
            </ListText>
            <ListText>
              ・当社は、本キャンペーンにより応募者または第三者に発生した損害に対し、当社に故意または過失がある場合を除いて一切の責任を負いません
            </ListText>
            <ListText>
              ・本キャンペーンへの応募にあたり応募者の氏名やメールアドレス等を正確に提供して頂く必要があり、応募者による誤入力や入力漏れ、虚偽等の事由によりプレゼントの送付日から3ヵ月以内に受領できない場合は受け取る権利を失効するものとします
            </ListText>
            <ListText>
              ・本キャンペーンのエントリーは、必ずクロン処方箋ネット受付をご利用の前にお済ませください（エントリー後にネット受付をご利用の方のみ対象となります）
            </ListText>
            <ListText>
              ・必ず、クロンアプリまたはWEBからクロン処方箋ネット受付をご利用ください
            </ListText>
            <ListText>
              ・事前にクロン処方箋ネット受付が利用可能な薬局をご確認の上、エントリーしてください
            </ListText>
            <ListText>
              ・受付後のキャンセルまたは、処方箋有効期限内に薬局からお薬を受け取られなかった場合は本キャンペーン対象外となります
            </ListText>
            <ListText>
              ・本キャンペーンへの応募は、お一人様1回限りとさせていただきます
            </ListText>
            <ListText>
              ・同一住所にお住まいの方が複数名、本キャンペーンへご応募になられた場合、1名のみ対象とさせていただきます
            </ListText>
            <ListText>
              ・本キャンペーンへのご応募、お問い合わせにかかる通信料は、応募者のご負担となります
            </ListText>
            <ListText>
              ・機種・OS・ブラウザ等の理由により一部のPC、携帯電話、スマートフォン、タブレット等ではご応募いただけない場合もございます
            </ListText>
          </TermsBox>

          <TermsBox>
            <BoxTitle>【アンケート/プレゼントに関して】</BoxTitle>
            <ListText>
              ・アンケートの回答はエントリー完了時に送られてくる「エントリー受付完了メール」内のリンクでご回答ください
            </ListText>
            <ListText>
              ・プレゼントはクロン処方箋ネット受付のご利用かつ、アンケート回答が完了してから最大1ヵ月程度お待ちいただく場合がございます
            </ListText>
          </TermsBox>

          <TermsBox>
            <BoxTitle>【処方箋ネット受付の利用に関して】</BoxTitle>
            <ListText>
              ・処方箋ネット受付は必ず当社が提供しております「クロン処方箋ネット受付」をご利用ください
            </ListText>
            <ListText>
              ・処方箋以外の画像を送信または無断キャンセルなど、薬局様から申告があった場合は、キャンペーン対象外とさせていただきます
            </ListText>
          </TermsBox>

          <TermsBox>
            <BoxTitle>【個人情報の取り扱いについて】</BoxTitle>
            <Text fontSize="12px">
              本キャンペーンにあたってご提供いただいた個人情報は、以下の目的でのみ使用いたします。
            </Text>
            <View marginTop="22px" fontSize="12px">
              <ListText>
                &#9312;エントリー受付完了ご連絡及びアンケート送付
              </ListText>
              <ListText>&#9313;応募者へのプレゼント送付</ListText>
              <ListText>&#9314;上記に関する連絡</ListText>
              <ListText>
                &#9315;商品やサービスの参考とするための個人を特定しない統計情報の形での利用
              </ListText>
              <ListText>
                &#9316;弊社製品・サービス及びキャンペーン情報等のご案内
              </ListText>
            </View>
            <Text marginTop="12px" fontSize="12px">
              本キャンペーンにあたってご提供いただいた個人情報は、応募者ご本人の同意がある場合を除いて、第三者に提供されることはありません。ただし、法令の定めがある場合等、当社「
              <LinkText
                href="https://micin.jp/privacy"
                rel="noopener noreferrer"
                target="_blank"
              >
                プライバシーポリシー
              </LinkText>
              」にて定める事由がある場合を除きます。詳しくは当社「
              <LinkText
                href="https://micin.jp/privacy"
                rel="noopener noreferrer"
                target="_blank"
              >
                プライバシーポリシー
              </LinkText>
              」をご覧ください。
            </Text>
          </TermsBox>

          <TermsBox>
            <BoxTitle>【当社の責任について】</BoxTitle>
            <Text fontSize="12px">
              当社は、本応募規約に基づき当社が行った行為及び本キャンペーンに関連して応募者に損害が生じた場合には、当社の故意または過失がない限り、一切の責任を負わないものとします。応募者は当社に対して一切の要求、請求等をすることができないものとします。また、当社に故意又は重大な過失がある場合を除き、当社の賠償義務は、直接、通常かつ現実に生じた損害に限られます。
            </Text>
          </TermsBox>

          <TermsBox className="last">
            <BoxTitle>【その他】</BoxTitle>
            <ListText>
              ・Amazon.co.jp は、本プロモーションのスポンサーではありません
            </ListText>
          </TermsBox>
        </TermsBlock>
      </TermsContainer>
    </View>
  )
}

const TermsContainer = styled(View)`
  max-width: 430px;
  margin: 0 auto;
  padding: 30px 20px 83px;
  background: #fffcd6;
  position: relative;
  ::after {
    content: '';
    display: block;
    width: 100%;
    padding-top: 8.5%;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: url(/cp/campaign202210/about_service_bg_top.png) no-repeat 0 0 /
        contain,
      #43b079;
  }
`

const TermsBlock = styled(View)`
  padding: 27px 20px 35px;
  line-height: 1.57em;
  background: #fff;
  border: 2px solid #ffd801;
  border-radius: 4px;
  position: relative;
`

const TermsTitle = styled(Text)`
  margin: 0 0 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.45em;
  span {
    border-bottom: 2px solid #fd7c08;
  }
`

const TermsBox = styled(View)`
  margin: 0 0 20px;
  line-height: 1.57em;
  &.last {
    margin-bottom: 0;
  }
`

const BoxTitle = styled(Text)`
  text-align: left;
  color: #fd7c08;
`

const ListText = styled(Text)`
  padding: 0 0 0 1em;
  font-size: 12px;
  text-indent: -1em;
`

const LinkText = styled.a`
  display: inline;
  color: ##1885d9;
`
