import React, { FC } from 'react'

export const ScrollRestoration: FC = () => {
  React.useEffect(() => {
    const scrollRestoration = window.history.scrollRestoration

    // スクロール位置リセット
    window.history.scrollRestoration = 'manual'
    return () => {
      // 'manual' に変更後、他のルートに影響が無いようにリセット
      window.history.scrollRestoration = scrollRestoration
    }
  })

  return null
}
