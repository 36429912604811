import React, { FC } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import styled from 'styled-components'
import { ArrowDown } from 'src/modules/components/lib/curon-icon'
import { Image } from 'src/modules/components/lib/image'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox, View } from 'src/modules/components/lib/view'
import { SearchPharmacyLink } from 'src/modules/screens/top/search-pharmacy'
import { NeareSearchLinkButton } from './near-search-link-button'
import { Spacer } from './spacer'

export const Main: FC = () => {
  return (
    <ViewContainer>
      <ViewInner>
        <LeftSideContainer>
          <View position="relative">
            <CatchyText>
              <CatchyHeadText color="dsGreen.700">
                クロン処方箋ネット受付
              </CatchyHeadText>
              <CatchyMainText color="dsGreen.700">
                待たずに受け取り
              </CatchyMainText>
              <CatchySubText>
                <SubText>事前に処方箋をスマホで送信！</SubText>
              </CatchySubText>
            </CatchyText>
            <BubbleContent>
              <BubbleImage src="/top/speech_bubble.png" alt="吹き出し" />
              <BubbleText>お薬は</BubbleText>
            </BubbleContent>
          </View>

          <SpIllustrationsContainer>
            <MainImage
              src="/top/sp_main_img.svg"
              alt="事前に処方箋をスマホで送信"
            />
          </SpIllustrationsContainer>

          <LinkButtonsContainer>
            <SearchPharmacyLink gtmId="gtm-phanet-link-btn01" />
            <NeareSearchLinkButton gtmId="gtm-phanet-anc-btn01" />
            <LinkText color="dsGray.400" fontSize="12px" width="307px">
              ※この端末の位置情報の利用をONにしてください。
            </LinkText>
            <Spacer size={15} />
            <AnchorLink
              href="#usage"
              offset="90"
              style={{ textAlign: 'center' }}
            >
              <Flexbox alignItems="center" width="100%" justifyContent="center">
                <Text
                  style={{ textDecoration: 'underline' }}
                  color="dsGreen.700"
                >
                  利用方法はこちら
                </Text>
                <Spacer horizontal size={8} />
                <ArrowDown color="#4DB27F" />
              </Flexbox>
            </AnchorLink>
          </LinkButtonsContainer>
        </LeftSideContainer>

        <RightSideContainer>
          <IllustrationsContainer>
            <MainImage
              src="/top/main_img.png"
              alt="事前に処方箋をスマホで送信"
            />
          </IllustrationsContainer>
        </RightSideContainer>
      </ViewInner>
    </ViewContainer>
  )
}

const BubbleContent = styled(View)`
  position: absolute;
  top: -20px;
  left: -55px;
  @media (max-width: 560px) {
    top: -25px;
    left: -50px;
  }
`

const BubbleImage = styled(Image)`
  width: 59px;
`

const BubbleText = styled(Text)`
  position: absolute;
  color: #fff;
  font-weight: 600;
  top: 20px;
  left: 9px;
`

const LinkText = styled(Text)`
  display: none;
  @media (max-width: 560px) {
    display: block;
  }
`

const ViewContainer = styled(View)`
  padding: 15px 0 0;
  background: ${({ theme }) => theme.colors.pistachioGreen};
  font-family: 'HiraginoSans', sans-serif;
  @media (max-width: 560px) {
    padding: 30px 0 220px;
    overflow: hidden;
  }
`

const ViewInner = styled(Flexbox)`
  flex-flow: row wrap;
  justify-content: center;
  align-items: stretch;
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 400px;
  background: url(/top/main_bg.svg) no-repeat right bottom/ 943px auto;

  @media (max-width: 560px) {
    background-position: -170px bottom;
    background-size: 800px auto;
    background: none;
    min-height: auto;
  }
`

const LeftSideContainer = styled(Flexbox)`
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  width: 29%;
  min-width: 340px;
  position: relative;
  @media (max-width: 560px) {
    width: 100%;
  }
  @media (max-width: 340px) {
    min-width: 320px;
  }
`

const LinkButtonsContainer = styled(View)`
  width: 228px;
  margin: 0 auto;
  justify-content: center;

  @media (max-width: 560px) {
    width: 288px;
    position: absolute;
    bottom: -198px;
  }
`

const CatchyMainText = styled(Text)`
  font-size: 28px;
  line-height: 48.5px;
  letter-spacing: 1.4px;
`

const CatchyText = styled(Text)`
  color: ${({ theme }) => theme.colors.green};
  margin-bottom: 10px;
  font-weight: 800;
  font-size: 40px;
  text-align: center;

  @media (max-width: 560px) {
    margin-bottom: 20px;
  }
`

const CatchyHeadText = styled(Text)`
  font-size: 16px;
  letter-spacing: 1.4px;
`

const CatchySubText = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  color: #272c33;
`

const SubText = styled.span`
  font-size: 14px;
  letter-spacing: 1.4px;
`

const RightSideContainer = styled(Flexbox)`
  flex-flow: column nowrap;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1 2 455px;
  max-width: 455px;
  @media (max-width: 560px) {
    display: none;
  }
`

const IllustrationsContainer = styled(Flexbox)`
  align-items: center;
  max-width: 455px;
`

const SpIllustrationsContainer = styled(IllustrationsContainer)`
  display: none;
  @media (max-width: 560px) {
    display: block;
    min-width: 100%;
  }
`
const MainImage = styled(Image)`
  min-width: 100%;
  vertical-align: bottom;
  image-rendering: -webkit-optimize-contrast;
`
