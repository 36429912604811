import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { ChainResponse, parse } from '../response'

export type Params = {
  keyword: string
}

export const fetch = async (params: Params) => {
  const resp = await requestGet<ChainResponse[]>({
    url: `${PHARMACY_API_URL}/patients/pharmacies/chains/search.json`,
    params: {
      keyword: params.keyword,
    },
  })

  return resp.data.map(parse)
}
