import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M83.477 80.955h-2.522V46.297c2.924-1.892 4.844-5.16 4.844-8.887v-6.622a2.826 2.826 0 00-2.838-2.838h-2.006V7.912a2.875 2.875 0 00-2.867-2.867H59.283C57.534 2.035 54.295 0 50.568 0H35.461c-3.727 0-6.966 2.035-8.715 5.045H7.941c-1.606 0-2.867 1.29-2.867 2.896L5.16 27.95H2.981a2.826 2.826 0 00-2.838 2.838v6.823c0 3.784 2.036 7.08 5.046 8.886l.028 34.458H2.523A2.508 2.508 0 000 83.477 2.508 2.508 0 002.523 86h80.954A2.508 2.508 0 0086 83.477a2.508 2.508 0 00-2.523-2.522zM69.803 37.467v-.057a2.872 2.872 0 00-2.866-2.752 2.875 2.875 0 00-2.867 2.867v.286c-.2 2.523-2.293 4.501-4.845 4.501h-.745c-2.494 0-4.53-1.95-4.673-4.386.029-.115.029-.23.029-.373a2.875 2.875 0 00-2.867-2.866 2.882 2.882 0 00-2.866 2.809 4.876 4.876 0 01-4.874 4.816h-.745c-2.523 0-4.587-2.035-4.673-4.53v-.229a2.875 2.875 0 00-2.866-2.866 2.875 2.875 0 00-2.867 2.866v.115c-.115 2.58-2.265 4.644-4.873 4.644h-.746a4.673 4.673 0 01-4.672-4.673v-.401a2.826 2.826 0 00-2.838-2.838 2.826 2.826 0 00-2.838 2.838v.172a4.87 4.87 0 01-4.874 4.873h-.745a4.673 4.673 0 01-4.673-4.672v-3.985h74.304v3.755a4.87 4.87 0 01-4.873 4.874h-.745a4.674 4.674 0 01-4.673-4.673v-.086s-.029 0-.029-.029zM45.523 5.045h5.045a5.053 5.053 0 015.045 5.046 5.053 5.053 0 01-5.045 5.045h-5.045V5.046zm-10.091 0h5.045v10.091h-5.045a5.053 5.053 0 01-5.045-5.045c.028-2.781 2.264-5.046 5.045-5.046zM10.234 25.198V10.091h15.107c0 5.561 4.53 10.09 10.091 10.09h15.107c5.562 0 10.091-4.529 10.091-10.09h15.28V27.95H10.233v-2.752zm30.243 55.757H20.325V60.802h20.152v20.153zm5.046 0V58.623a2.875 2.875 0 00-2.867-2.866h-24.51a2.875 2.875 0 00-2.867 2.866v22.332h-5.045V47.988H11.237c3.01 0 5.734-1.261 7.654-3.297a10.3 10.3 0 007.568 3.297h.746c3.01 0 5.733-1.261 7.654-3.297a10.3 10.3 0 007.568 3.297h.745c3.01 0 5.733-1.261 7.654-3.297a10.3 10.3 0 007.568 3.297h.745c3.01 0 5.734-1.261 7.654-3.297a10.3 10.3 0 007.568 3.297h.746c.229 0 .458 0 .688-.029v32.996H45.523z"
        fill={props.color || '#000'}
      />
      <path
        d="M27.835 75.91a2.508 2.508 0 01-2.522-2.523V68.34a2.508 2.508 0 012.522-2.522 2.508 2.508 0 012.523 2.522v5.046a2.545 2.545 0 01-2.523 2.522zM68.055 76.282H53.32a2.826 2.826 0 01-2.838-2.838V58.709a2.826 2.826 0 012.838-2.838h14.735a2.826 2.826 0 012.838 2.838v14.735c.028 1.548-1.262 2.838-2.838 2.838zm-11.868-5.705h9.03v-9.03h-9.03v9.03z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgPharmacy = _style(SvgIcon)

export default SvgPharmacy
