import { useCallback, useState, useEffect, useRef } from 'react'
import * as logging from 'src/modules/helpers/logging'

export type SubmitOption<T> = {
  immediate?: boolean
  onComplete?: (data: T) => void
  onFailure?: (error: string) => void
  onFinally?: () => void
}

export function useSubmit<T, P>(
  request: (params: P) => Promise<T>,
  options?: SubmitOption<T>,
) {
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<T | null>(null)
  const onComplete = options?.onComplete
  const onFailure = options?.onFailure
  const onFinally = options?.onFinally
  const unmounted = useRef<boolean>()

  const doSubmit = useCallback(
    async (params: P) => {
      setSubmitting(true)
      setError(null)
      setData(null)

      try {
        const data = await request(params)
        if (unmounted.current) {
          return
        }
        setData(data)
        setSubmitted(true)
        setSubmitting(false)
        if (onComplete) {
          onComplete(data)
        }
      } catch (error: any) {
        if (unmounted.current) {
          return
        }
        setError(error)
        setSubmitting(false)
        if (onFailure) {
          onFailure(error)
        }
        logging.error(error)
      }

      onFinally && onFinally()
    },
    [onComplete, onFailure, onFinally, request],
  )

  useEffect(() => {
    return () => {
      unmounted.current = true
    }
  }, [])

  return {
    submitted,
    submitting,
    error,
    data,
    doSubmit,
  }
}
