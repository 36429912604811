import React, { FC, useState } from 'react'
import styled from 'styled-components'

import { Params as PharmacySearchParams } from 'src/modules/apis/pharmacy/search/request'
import { PublicPageHeader } from 'src/modules/components/header/public-page-header'

import {
  Location as LocationIcon,
  SearchLeft,
} from 'src/modules/components/lib/curon-icon'
import { Input } from 'src/modules/components/lib/input'
import { Loader } from 'src/modules/components/lib/loader'
import { Screen } from 'src/modules/components/lib/screen'
import { Flexbox, View } from 'src/modules/components/lib/view'
import { MutualInduction } from 'src/modules/components/mutualInduction/mutualInduction'
import { SearchMap } from 'src/modules/components/net-application/map-search/map-search'
import { SmallSearchMap } from 'src/modules/components/net-application/map-search/small-map-search'
import { MedicinePickupSelectDayRadio } from 'src/modules/components/net-application/medicine-pickup-select-day-radio'
import { AreaModal } from 'src/modules/components/net-application/modal-area/modal-area'
import { KeywordModal } from 'src/modules/components/net-application/modal-keyword'
import { PharmacyList } from 'src/modules/components/net-application/pharmacy-list'
import { SearchNavigation } from 'src/modules/components/net-application/search-navigation'
import { SearchTitleHeader } from 'src/modules/components/net-application/search-title-header'
import { Chain } from 'src/modules/entities/chain/entity'
import { Pharmacy } from 'src/modules/entities/pharmacy/entity'

import { Footer } from '../../../top/footer'

type Props = {
  keyword: string | null
  // conditions: string[]
  pharmacies: Pharmacy[] | []
  searchTitle: string
  isGeoApproved: boolean
  fetched: boolean
  authorized: boolean
  loading: boolean
  areaModalVisible: boolean
  setAreaModalVisible: (visible: boolean) => void
  keywordModalVisible: boolean
  setKeywordModalVisible: (visible: boolean) => void
  searchMapModalVisible: boolean
  setSearchMapModalVisible: (visible: boolean) => void
  pharmacySearchTrigger: (params: PharmacySearchParams) => void
  pharmacySearchLoading: boolean
  currentPosition?: GeolocationPosition | null
}

export const NetApplicationNearSearchScreen: FC<Props> = props => {
  const {
    keyword,
    // conditions,
    pharmacies,
    searchTitle,
    isGeoApproved,
    fetched,
    authorized,
    loading,
    areaModalVisible,
    setAreaModalVisible,
    keywordModalVisible,
    setKeywordModalVisible,
    setSearchMapModalVisible,
    searchMapModalVisible,
    pharmacySearchTrigger,
    pharmacySearchLoading,
    currentPosition,
  } = props

  const [inputKeyword, setInputKeyword] = useState(keyword || '')
  const [inputChain, setInputChain] = useState<Chain | null>(null)

  return (
    <View minHeight="100%">
      <PublicPageHeader
        fetched={fetched}
        authorized={authorized}
        displayMutualInduction
      />
      <MutualInduction />
      <Screen>
        <SearchNavigation
          pageType={'search_near'}
          keyword={keyword}
          prefecture={null}
          area={null}
          station={null}
          chain={null}
          displayMutualInduction
        />
        <View
          backgroundColor="appBackground"
          px="16px"
          marginTop="48px"
          height="100%"
        >
          <SearchTitleHeader searchTitle={searchTitle} />

          <Flexbox flexDirection="column" alignItems="stretch" width="100%">
            <View width="100%" height="100%">
              <CustomInputRoot>
                <SearchLeft color="shuttleGray" className="icon" />
                <Input
                  type="search"
                  width="100%"
                  placeholder="店舗・キーワードで探す"
                  value={inputKeyword}
                  readOnly
                  onClick={() => setKeywordModalVisible(true)}
                />
              </CustomInputRoot>

              <CustomInputRoot>
                <LocationIcon color="shuttleGray" className="icon" />
                <Input
                  type="text"
                  width="100%"
                  placeholder="エリアから探す"
                  value="現在地周辺"
                  readOnly
                  onClick={() => setAreaModalVisible(true)}
                />
              </CustomInputRoot>
              <View width={'100%'} height={'100%'} mb={'16px'}>
                <MedicinePickupSelectDayRadio />
              </View>
              <View
                width="100vw"
                height="185px"
                position="relative"
                left="50%"
                onClick={() => {
                  setSearchMapModalVisible(true)
                }}
                style={{
                  cursor: 'pointer',
                  transform: 'translateX(-50%)',
                  maxWidth: '568px',
                }}
              >
                <SmallSearchMap
                  onOpen={() => setSearchMapModalVisible(true)}
                  pharmacySearchData={pharmacies}
                  pharmacySearchLoading={pharmacySearchLoading}
                  currentPosition={currentPosition}
                  isGeoApproved={isGeoApproved}
                />
              </View>

              {!loading && (
                <SearchSummaryBox>
                  <SearchSummary>検索結果</SearchSummary>
                </SearchSummaryBox>
              )}
            </View>
          </Flexbox>
        </View>
        {loading ? (
          <Loader />
        ) : (
          <PharmacyList authorized={authorized} pharmacies={pharmacies} />
        )}

        <AreaModal
          defaultMode={'all'}
          prefecture={null}
          visible={areaModalVisible}
          onCloseClick={() => setAreaModalVisible(false)}
        />

        <KeywordModal
          visible={keywordModalVisible}
          keyword={inputKeyword}
          setKeyword={setInputKeyword}
          setChain={setInputChain}
          onCloseClick={() => setKeywordModalVisible(false)}
        />

        <View>
          <SearchMap
            visible={searchMapModalVisible}
            onCloseClick={() => setSearchMapModalVisible(false)}
            pharmacySearchData={pharmacies}
            pharmacySearchLoading={pharmacySearchLoading}
            pharmacySearchTrigger={pharmacySearchTrigger}
            chain={inputChain}
            keyword={inputKeyword}
            setKeyword={setInputKeyword}
            setChain={setInputChain}
            currentPosition={currentPosition}
            isGeoApproved={isGeoApproved}
          />
        </View>

        <Footer />
      </Screen>
    </View>
  )
}

const CustomInputRoot = styled.div`
  position: relative;
  margin-bottom: 8px;

  .icon {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }

  input {
    padding-left: 30px;
  }
`
const SearchSummaryBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`

const SearchSummary = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #667587;
`
