import React, { FC } from 'react'

import styled from 'styled-components'
import { SearchLeft } from 'src/modules/components/lib/curon-icon'
import { Input } from 'src/modules/components/lib/input'
import { Text } from 'src/modules/components/lib/text'
import { View } from 'src/modules/components/lib/view'
import { Spacer } from 'src/modules/screens/top/spacer'

type Props = {
  openKeywordModal: () => void
}

const CustomInputRoot = styled.div`
  position: relative;
  margin-bottom: 8px;

  .icon {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }

  input {
    padding-left: 30px;
  }
`

export const KeywordInput: FC<Props> = ({ openKeywordModal }) => {
  return (
    <View>
      <Text fontWeight="bold" fontSize="16px">
        薬局名・店舗名から探す
      </Text>
      <Spacer size={12} />
      <CustomInputRoot>
        <SearchLeft color="shuttleGray" className="icon" />
        <Input
          type="search"
          width="100%"
          placeholder="薬局名・店舗名を入力"
          value={undefined}
          readOnly
          onClick={openKeywordModal}
        />
      </CustomInputRoot>
    </View>
  )
}
