import { createContext } from 'react'
import { FavoritePharmacy } from 'src/modules/entities/favorite-pharmacy/entity'
import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { FavoritePharmacyResponse, parse } from '../response'

export const fetch = async (): Promise<FavoritePharmacy[]> => {
  const resp = await requestGet<FavoritePharmacyResponse[]>({
    url: `${PHARMACY_API_URL}/patients/favorite_pharmacies.json`,
    params: {},
  })

  return resp.data.map(parse)
}

export const FetchContext = createContext({
  fetch,
})
