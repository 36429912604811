import axios from 'axios'
import { routes } from '../routes'

export type RequestOption = {
  url: string
  params?: any
  headers?: {}
}

export type Response<T> = {
  data: T
  status: number
}

const handleError = (error: any) => {
  if (
    error?.response?.status === 503 &&
    window.location.pathname !== routes.index.path
  ) {
    window.location.replace(routes.maintenance.build())
    return
  }

  if (!error.response || !error.response.status) {
    return { message: 'インターネットに接続されていません。', original: error }
  }

  let message = `問題が発生しました。時間を置いて再度お試しください。`
  if (error.response.data.message) {
    if (!error.response.data.message.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~\s]+$/)) {
      message = error.response.data.message
    }
  }

  return {
    message,
    original: error,
  }
}

export const commonHeaders = {
  'X-Requested-With': 'XMLHttpRequest',
}

/* eslint no-throw-literal: 0 */
export const requestGet = async <Data>(
  option: RequestOption,
): Promise<Response<Data>> => {
  const { url, params, headers = {} } = option

  try {
    const res = await axios.get<Data>(url, {
      params,
      url,
      headers: {
        ...commonHeaders,
        ...headers,
      },
      withCredentials: true,
    })

    return {
      data: res.data,
      status: res.status,
    }
  } catch (error) {
    throw handleError(error)
  }
}

export const requestPost = async <Data>(
  option: RequestOption,
): Promise<Response<Data>> => {
  const { url, params } = option

  try {
    const res = await axios.post<Data>(url, params, {
      headers: commonHeaders,
      withCredentials: true,
    })

    return {
      data: res.data,
      status: res.status,
    }
  } catch (error) {
    throw handleError(error)
  }
}

export const requestPatch = async <Data>(
  option: RequestOption,
): Promise<Response<Data>> => {
  const { url, params } = option

  try {
    const res = await axios.patch<Data>(url, params, {
      headers: commonHeaders,
      withCredentials: true,
    })

    return {
      data: res.data,
      status: res.status,
    }
  } catch (error) {
    throw handleError(error)
  }
}

export const requestPut = async <Data>(
  option: RequestOption,
): Promise<Response<Data>> => {
  const { url, params } = option

  try {
    const res = await axios.put<Data>(url, params, {
      headers: commonHeaders,
      withCredentials: true,
    })

    return {
      data: res.data,
      status: res.status,
    }
  } catch (error) {
    throw handleError(error)
  }
}

export const requestDelete = async <Data>(
  option: RequestOption,
): Promise<Response<Data>> => {
  const { url } = option

  try {
    const res = await axios.delete<Data>(url, {
      headers: commonHeaders,
      withCredentials: true,
    })

    return {
      data: res.data,
      status: res.status,
    }
  } catch (error) {
    throw handleError(error)
  }
}
