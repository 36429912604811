import { CallRecord } from 'src/modules/entities/call-record/entity'

export type CallRecordResponse = {
  uid: string
  comment: string | null
}

export const parse = (resp: CallRecordResponse): CallRecord => {
  const res = {
    uid: resp.uid,
    comment: resp.comment,
  }

  return res
}
