import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.84 15.0613L12.0587 11.2853C13.088 10.0907 13.7067 8.53867 13.7067 6.84267C13.7067 3.06667 10.6347 0 6.85333 0C3.072 0 0 3.06667 0 6.84267C0 10.6133 3.072 13.68 6.85333 13.68C8.53867 13.68 10.08 13.072 11.2747 12.0587L15.056 15.8347C15.1627 15.9413 15.3067 15.9947 15.4453 15.9947C15.584 15.9947 15.728 15.9413 15.8347 15.8347C16.0533 15.6213 16.0533 15.2747 15.84 15.0613ZM1.104 6.84267C1.104 3.68 3.68533 1.104 6.85333 1.104C10.0213 1.104 12.6027 3.68 12.6027 6.84267C12.6027 10.0053 10.0213 12.5813 6.85333 12.5813C3.68533 12.5813 1.104 10.0053 1.104 6.84267Z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgSearchLeft = _style(SvgIcon)

export default SvgSearchLeft
