import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import { useSWRFetch as useChainFetch } from 'src/modules/apis/chain/show/use-fetch'
import { useFetch as useAreaShowFetch } from 'src/modules/apis/pharmacy/areas/show/use-fetch'
import { useSWRFetch as usePharmacySearchFetch } from 'src/modules/apis/pharmacy/search/use-fetch'

import { useFetch as useStationShowFetch } from 'src/modules/apis/pharmacy/stations/show/use-fetch'
import { useAuthorizationRefresh } from 'src/modules/auth/use-authorization-refresh'
import { Loader } from 'src/modules/components/lib/loader'
import { Area, Station } from 'src/modules/entities/pharmacy/entity'
import { useSearchQuery } from 'src/modules/hooks/use-search-query'
import {
  useCanonicalUrl,
  useDeprecatedSearchQueryGuard,
  useMetaRobots,
  usePageType,
  useSearchMeta,
  useSearchTitle,
  useSelectedPrefecture,
} from './helper'
import { getJsonLd } from './jsonld'
import { NetApplicationSearchScreen } from './screen'

export const NetApplicationSearchScreenContainer = () => {
  useDeprecatedSearchQueryGuard()

  const { authorized, fetched } = useAuthorizationRefresh()
  const pageType = usePageType()

  const {
    keyword,
    prefecture,
    area: areaSlug,
    station: stationCode,
    currentPage: page,
    chain: chainCode,
    selectedDay,
    // conditions,
  } = useSearchQuery()
  const { data: chain, loading: chainFetching } = useChainFetch({
    code: chainCode,
  })

  const selectedPrefecture = useSelectedPrefecture(prefecture, false)
  const [areaModalVisible, setAreaModalVisible] = useState(false)
  const [keywordModalVisible, setKeywordModalVisible] = useState(false)

  const [area, setArea] = useState<Area | null>(null)
  const [station, setStation] = useState<Station | null>(null)

  const {
    data,
    loading: pharmacyIndexFetching,
    trigger,
  } = usePharmacySearchFetch({
    params: {
      keyword,
      prefecture: prefecture ? prefecture.name : null,
      area: areaSlug,
      station: stationCode,
      // conditions,
      currentLocation: false,
      lat: null,
      lng: null,
      chain: chainCode,
      page,
      selectedDay,
    },
  })

  useEffect(() => {
    window.scrollTo({ top: 0 }) // the new page will start from the middle without this
    setAreaModalVisible(false)
    setKeywordModalVisible(false)
  }, [data])

  const canonicalUrl = useCanonicalUrl({
    pageType,
    prefecture,
    area,
    station,
    page,
    chain,
    // conditions,
  })
  const metaRobots = useMetaRobots({
    pharmacies: data?.pharmacies || [],
    keyword,
    pageType,
  })
  const { title: metaTitle, description: metaDescription } = useSearchMeta({
    pageType,
    pharmaciesCount: data?.count || 0,
    keyword,
    prefecture,
    area,
    station,
    chain,
    page: page === 1 ? null : page,
    // conditions,
  })
  const searchTitle = useSearchTitle({
    pageType,
    keyword,
    prefecture,
    area,
    station,
    chain,
    page: page === 1 ? null : page,
  })

  const jsonLd = getJsonLd({
    pageType,
    prefecture,
    area,
    station,
    chain,
  })

  const { fetching: areaFetching } = useAreaShowFetch(
    {
      slug: areaSlug,
    },
    {
      onComplete: useCallback(setArea, [setArea]),
    },
  )
  const { fetching: stationFetching } = useStationShowFetch(
    {
      code: stationCode,
    },
    {
      onComplete: useCallback(setStation, [setStation]),
    },
  )

  const loading = useMemo(() => {
    switch (pageType) {
      case 'search_list':
      case 'search_pref':
        return pharmacyIndexFetching
      case 'search_area':
        return pharmacyIndexFetching || areaFetching
      case 'search_station':
        return pharmacyIndexFetching || stationFetching
      case 'search_chain':
        return pharmacyIndexFetching || chainFetching
      case 'search_chain_pref':
        return pharmacyIndexFetching || chainFetching
      case 'search_chain_area':
        return pharmacyIndexFetching || areaFetching || chainFetching
      case 'search_chain_station':
        return pharmacyIndexFetching || stationFetching || chainFetching
      default:
        return false
    }
  }, [
    pageType,
    pharmacyIndexFetching,
    areaFetching,
    stationFetching,
    chainFetching,
  ])

  if (pharmacyIndexFetching && page === 1) {
    return <Loader />
  }

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        {!loading && canonicalUrl && (
          <link rel="canonical" href={canonicalUrl} />
        )}
        {!loading && metaRobots && <meta name="robots" content={metaRobots} />}
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      </Helmet>
      <NetApplicationSearchScreen
        keyword={keyword}
        prefecture={selectedPrefecture}
        area={area}
        chain={chain}
        station={station}
        selectedDay={selectedDay}
        // conditions={conditions}
        pharmacies={data?.pharmacies || []}
        pageType={pageType}
        page={page}
        totalPage={data?.totalPage || 1}
        totalCount={data?.count || 0}
        searchTitle={searchTitle}
        authorized={authorized}
        fetched={fetched}
        loading={loading}
        areaModalVisible={areaModalVisible}
        keywordModalVisible={keywordModalVisible}
        setAreaModalVisible={setAreaModalVisible}
        setKeywordModalVisible={setKeywordModalVisible}
      />
    </>
  )
}
