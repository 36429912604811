import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip_svg__clip0)">
        <path
          d="M80.285 14.493a19.416 19.416 0 00-13.82-5.725 19.417 19.417 0 00-13.82 5.725l-30.81 30.81c-4.573 4.571-4.573 12.011 0 16.584 4.572 4.572 12.012 4.572 16.584 0l20.288-20.288a3.909 3.909 0 10-5.528-5.528L32.89 56.358a3.913 3.913 0 01-5.528 0 3.914 3.914 0 010-5.528l30.81-30.809a11.65 11.65 0 018.291-3.435 11.65 11.65 0 018.293 3.435c4.572 4.572 4.572 12.012 0 16.584L41.183 70.178c-7.62 7.62-20.02 7.62-27.64 0a19.416 19.416 0 01-5.725-13.82c0-5.22 2.033-10.129 5.725-13.82L47.116 8.964a3.909 3.909 0 10-5.528-5.528L8.015 37.01C2.846 42.178 0 49.05 0 56.358c0 7.31 2.846 14.18 8.015 19.349 5.335 5.335 12.34 8.002 19.348 8.001 7.006 0 14.014-2.668 19.348-8.001l33.574-33.574c7.62-7.62 7.62-20.02 0-27.64z"
          fill={props.color || '#000'}
        />
      </g>
      <defs>
        <clipPath id="clip_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgClip = _style(SvgIcon)

export default SvgClip
