import { ExternalPharmacyBrandLogo } from 'src/modules/entities/pharmacy/entity'
import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'

export type Params = {
  uid: string
}

export type FetchResponse = {
  image_url: string
}

export type BrandLogoResponse = ExternalPharmacyBrandLogo

export const fetch: (params: Params) => Promise<BrandLogoResponse> = async ({
  uid,
}) => {
  const resp = await requestGet<FetchResponse>({
    url: `${PHARMACY_API_URL}/patients/net_receptions/external_pharmacies/${uid}.json`,
  })

  return {
    imageUrl: resp.data.image_url,
  }
}
