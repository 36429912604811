import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#calendar_svg__clip0)">
        <path
          d="M65.217 40.65a2.607 2.607 0 00-1.835-.775H21.758c-.688 0-1.347.287-1.835.774a2.607 2.607 0 00-.774 1.835v26.488c0 .688.287 1.347.774 1.835a2.607 2.607 0 001.835.774h41.595c.688 0 1.348-.287 1.835-.774a2.607 2.607 0 00.774-1.835V42.484a2.58 2.58 0 00-.745-1.835zm-4.444 12.498h-8.657v-8.027h8.657v8.027zm-22.532 0v-8.027H46.9v8.027H38.24zm8.658 5.189v8.026H38.24v-8.026H46.9zM33.024 45.093v8.026h-8.657v-8.026h8.657zm-8.657 13.244h8.657v8.026h-8.657v-8.026zm27.72 8.055v-8.027h8.686v8.027h-8.686zM79.493 11.18a2.607 2.607 0 00-1.835-.774H65.303V2.609c0-.688-.287-1.348-.774-1.835A2.607 2.607 0 0062.694 0c-.688 0-1.347.287-1.835.774a2.607 2.607 0 00-.774 1.835v7.826H25.886V2.609c0-.688-.287-1.348-.774-1.835A2.607 2.607 0 0023.277 0c-.688 0-1.347.287-1.834.774a2.606 2.606 0 00-.774 1.835v7.826H8.342c-.688 0-1.347.286-1.835.774a2.508 2.508 0 00-.774 1.834V83.42c0 .688.287 1.347.774 1.835a2.607 2.607 0 001.835.774h69.345c.688 0 1.347-.287 1.834-.774a2.607 2.607 0 00.774-1.835V13.043a2.775 2.775 0 00-.802-1.863zm-4.415 69.603H10.922V30.845h64.127v49.938h.029zm0-55.155H10.922V15.623h64.127v10.005h.029z"
          fill={props.color || '#000'}
        />
      </g>
      <defs>
        <clipPath id="calendar_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgCalendar = _style(SvgIcon)

export default SvgCalendar
