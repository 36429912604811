import { Calendar } from '../calendar/entity'
import { CallRecord } from '../call-record/entity'
import { CurrentSettlementRecord } from '../current-settlement-record/entity'
import { DeliveryRecord } from '../delivery-record/entity'
import { ElectronicPrescription } from '../electronic-prescription/entity'
import { IdDocument } from '../id-document/entitiy'
import { Patient } from '../patient/entity'
import { PharmacyVisitAttachment } from '../pharmacy-visit-attachment/entity'
import { Pharmacy } from '../pharmacy/entity'
import { PrescriptionImage } from '../prescription-image/entity'
import { PrescriptionNotebookImage } from '../prescription-notebook-image/entity'
import { QuestionnaireAnswer } from '../questionnaire-answer/entity'
import { ReservationCandidate } from '../reservation-candidate/entity'
import { SettlementRecord } from '../settlement-record/entity'
import { TwilioMessage } from '../twilio-message/entity'

export type PharmacyVisit = {
  uid: string
  doctorVisitUid: string
  patient: Patient | null
  pharmacyUid: string
  applicationType: ApplicationType
  callRecords: CallRecord[]
  settlementRecords: SettlementRecord[]
  currentSettlementRecord: CurrentSettlementRecord | null
  deliveryRecord?: DeliveryRecord
  twilioMessages: TwilioMessage[]
  comment: string | null

  // PharmacyVisitがonline診療経由で作成された場合
  insuranceCardImageUrl: string | null

  // PharmacyVisitがSMS経由で作成された場合(共通基盤にデータを持つ)
  insuranceCard: IdDocument | null
  claimantCertification: IdDocument | null

  visitedAt: string | null
  completedAt: string | null
  createdAt: string
  stripePaymentMethodId: string | null
  reservedCalendar: Calendar | null
  reservedFrameUid: string | null
  reservationStartedAt: string | null
  reservationFinishedAt: string | null
  prescriptionNotebookImages: PrescriptionNotebookImage[]
  prescriptionImages: PrescriptionImage[]
  questionnaireAnswer: QuestionnaireAnswer | null
  electronicPrescription: ElectronicPrescription | null
  reservationCandidates: ReservationCandidate[] // リクエスト予約廃止に伴い空配列となる
  placeToReceiveService: PlaceToReceiveService | null
  medicineDispensedAt: string | null
  medicinePassedAt: string | null
  medicineReceiveAppliedAt: string | null
  fromCuron: boolean
  pharmacy: Pharmacy
  attachments: PharmacyVisitAttachment[]
  message: string | null
  applicationCompletedAt: string | null // 服薬指導予約完了 or 薬局受け取り予約完了
  recentlyVisited: boolean | null
  canceledAt: string | null
  completedBy: CompletedBy | null
  usageNumber: string | null
}

export enum PlaceToReceiveService {
  InHome = 'in_home',
  InStore = 'in_store',
}

export enum ApplicationType {
  NetReception = 'net_reception',
  Pharmacy = 'pharmacy',
  Curon = 'curon',
}

export enum CompletedBy {
  Pharmacy = 'pharmacy',
  BatchJob = 'batch_job',
}
