import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { useAuthorizationRefresh } from 'src/modules/auth/use-authorization-refresh'
import { routes } from 'src/modules/routes'
import { Footer } from 'src/modules/screens/top/footer'
import { Header } from 'src/modules/screens/top/header'
import { AboutCampaign } from './about-campaign'
import { AboutService } from './about-service'
import { Entry } from './entry'
import { FAQ } from './faq'
import { Main } from './main'
import { Terms } from './terms'

export const Campaign: FC = () => {
  const { authorized, fetched } = useAuthorizationRefresh()
  const pageMeta = [
    {
      property: 'description',
      content:
        '③description:【期間限定】クロン処方箋ネット受付を利用し、アンケートに回答いただいた方、全員対象でAmazonギフト券500円分がもらえるプレゼントキャンペーンを実施中！',
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:site_name',
      content: '処方箋ネット受付curon(クロン)',
    },
    {
      property: 'og:title',
      content:
        '処方箋ネット受付利用とアンケート回答で全員にアマギフ500円分プレゼント｜クロンお薬サポート',
    },
    {
      property: 'og:description',
      content:
        '【期間限定】クロン処方箋ネット受付を利用し、アンケートに回答いただいた方、全員対象でAmazonギフト券500円分がもらえるプレゼントキャンペーンを実施中！',
    },
    {
      property: 'og:url',
      content: `${window.location.origin}${routes.campaign202210.path}`,
    },
  ]

  return (
    <>
      <Helmet
        meta={pageMeta}
        title={
          '処方箋ネット受付利用とアンケート回答で全員にアマギフ500円分プレゼント｜クロンお薬サポート'
        }
      />

      <Header fetched={fetched} authorized={authorized} />
      <Main />
      <AboutCampaign />
      <Terms />
      <AboutService />
      <FAQ />
      <Entry />
      <Footer />
    </>
  )
}
