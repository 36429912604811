import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M82.589 22.475H3.41C1.52 22.475 0 20.927 0 19.035a3.401 3.401 0 013.411-3.412H82.59A3.401 3.401 0 0186 19.035c0 1.892-1.52 3.44-3.411 3.44zM82.589 46.411H3.41A3.401 3.401 0 010 43a3.401 3.401 0 013.411-3.412H82.59A3.401 3.401 0 0186 43a3.401 3.401 0 01-3.411 3.411zM82.589 70.376H3.41A3.401 3.401 0 010 66.966a3.401 3.401 0 013.411-3.412H82.59A3.401 3.401 0 0186 66.965a3.401 3.401 0 01-3.411 3.411z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgMenu = _style(SvgIcon)

export default SvgMenu
