import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M43 0C19.264 0 0 19.264 0 43s19.264 43 43 43 43-19.264 43-43S66.736 0 43 0zm4.787 19.321L46.67 52.288h-7.826l-1.118-32.967h10.062zM46.583 66.02c-.946.946-2.236 1.405-3.812 1.405-1.577 0-2.838-.459-3.813-1.405-.946-.946-1.433-2.121-1.433-3.554 0-1.434.487-2.638 1.433-3.555.975-.946 2.265-1.433 3.813-1.433 1.576 0 2.866.458 3.812 1.404.975.946 1.462 2.122 1.462 3.584 0 1.433-.487 2.608-1.462 3.554z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgAttention = _style(SvgIcon)

export default SvgAttention
