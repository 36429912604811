import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M55.494 36.624c-2.503 0-2.739 2.588-2.739 2.588v6.03c0 6.1-4.922 11.072-10.97 11.072-6.048 0-10.97-4.922-10.97-10.97V16.8c0-6.048 4.922-10.97 10.97-10.97 6.048 0 10.97 4.25 10.97 10.298 0 0 .236 3.007 3.142 3.007 2.184 0 2.688-2.335 2.688-2.335 0-9.257-7.526-16.8-16.8-16.8-9.257 0-16.8 7.526-16.8 16.8v28.544c0 9.256 7.526 16.8 16.8 16.8 9.274 0 16.8-7.577 16.8-16.901v-6.166s-.218-2.453-3.091-2.453z"
        fill={props.color || '#000'}
      />
      <path
        d="M66.347 36.675a2.907 2.907 0 00-2.907 2.906v5.763c0 11.944-9.71 21.655-21.655 21.655s-21.639-9.71-21.639-21.655V33.886a2.907 2.907 0 00-5.813 0v11.458c0 14.162 10.77 25.855 24.562 27.317v7.526h-9.122a2.907 2.907 0 00-2.907 2.907A2.918 2.918 0 0029.773 86h24.074a2.907 2.907 0 000-5.813h-9.122v-7.526c13.793-1.462 24.562-13.155 24.562-27.317V39.58a2.936 2.936 0 00-2.94-2.906zM43.784 32.441a4.62 4.62 0 100-9.24 4.62 4.62 0 000 9.24zM55.393 32.441a4.62 4.62 0 100-9.24 4.62 4.62 0 000 9.24zM67.019 32.441a4.62 4.62 0 100-9.24 4.62 4.62 0 000 9.24z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgMicrophoneSettings = _style(SvgIcon)

export default SvgMicrophoneSettings
