import React, { FC } from 'react'
import styled from 'styled-components'
import { Image } from 'src/modules/components/lib/image'
import { Text } from 'src/modules/components/lib/text'
import { View } from 'src/modules/components/lib/view'
import { EntryBtn } from './entry-btn'

export const Main: FC = () => {
  return (
    <View fontFamily="'Noto Sans JP', sans-serif" position="relative">
      <MainContainer>
        <BallonText>
          クロン処方箋ネット受付利用&amp;
          <br />
          アンケート回答で
        </BallonText>
        <Title>
          Amazonギフト券
          <br />
          <span>500</span>円分プレゼント
        </Title>

        <View maxWidth="234px" margin="0 auto 24px">
          <Image
            src="/cp/campaign202210/main_card.png"
            width="100%"
            height="auto"
            alt="amazonギフト券500円分"
          />
        </View>

        <EntryText>
          クロンお薬サポートへの新規登録
          <br />
          またはログインが必要です
        </EntryText>

        <EntryBtn />
      </MainContainer>
    </View>
  )
}

const MainContainer = styled(View)`
  max-width: 430px;
  margin: 0 auto;
  padding: 81px 5px 40px;
  background: url(/cp/campaign202210/main_bg.png) no-repeat 0 0 / cover;
  overflow: hidden;
  position: relative;
`

const BallonText = styled(Text)`
  margin: 0 0 3px;
  font-size: 16px;
  text-align: center;
  font-weight: 700;
  position: relative;
  ::after {
    content: '';
    display: block;
    width: 200px;
    height: 39px;
    position: absolute;
    top: -46px;
    left: 50%;
    transform: translateX(-50%);
    background: url(/cp/campaign202210/main_ballon_text.png) no-repeat 0 0 /
      contain;
  }
`

const Title = styled(Text)`
  margin: 0 0 12px;
  font-size: 30px;
  text-align: center;
  font-weight: 900;
  line-height: 1.3em;
  span {
    font-size: 48px;
  }
`

const EntryText = styled(Text)`
  max-width: 223px;
  margin: 0 auto 9px;
  padding: 0 20px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  line-height: 17px;
  position: relative;
  background: url(/cp/campaign202210/bg_line_left.svg) no-repeat left bottom /
      20px,
    url(/cp/campaign202210/bg_line_right.svg) no-repeat right bottom / 20px;
`
