import React, { FC } from 'react'
import {
  FixedZIndex,
  HEADER_HEIGHT,
  MUTUAL_INDUCTION_HEIGHT,
} from 'src/modules/const'
import { useIdPlatform } from 'src/modules/hooks/useIdPlatform'
import { routes } from '../../routes'
import { Button, LinkButton } from '../lib/button'
import { Link } from '../lib/link'
import { Logo } from '../lib/logo'
import { Flexbox, View } from '../lib/view'

type PublicPageHeaderProps = {
  fetched: boolean
  authorized: boolean
  displayMutualInduction?: boolean
}

export const PublicPageHeader: FC<PublicPageHeaderProps> = ({
  fetched,
  authorized,
  displayMutualInduction,
}) => {
  const space = displayMutualInduction
    ? MUTUAL_INDUCTION_HEIGHT + HEADER_HEIGHT
    : HEADER_HEIGHT
  return (
    <>
      <View width="100%" minHeight={space}>
        {/* This block can avoid setting `headerHeight` to padding-top when using header */}
      </View>
      <View
        height={`${HEADER_HEIGHT}px`}
        position="fixed"
        top="0"
        right="0"
        left="0"
        bg="white"
        zIndex={FixedZIndex.header}
      >
        <Flexbox
          justifyContent="space-between"
          alignItems="center"
          mx="10px"
          height="100%"
        >
          <Link to={routes.index.build()}>
            <Logo width="87px" horizontal={false} />
          </Link>
          {fetched && <LoginButton authorized={authorized} />}
        </Flexbox>
      </View>
    </>
  )
}

type LoginButtonProps = {
  authorized: boolean
}

export const LoginButton: FC<LoginButtonProps> = ({ authorized }) => {
  const { redirectToLogin } = useIdPlatform()

  return authorized ? (
    <LinkButton
      to={routes.home.build()}
      shape="square"
      sizeStyle="small"
      px={6}
    >
      マイページ
    </LinkButton>
  ) : (
    <Button
      shape="square"
      sizeStyle="small"
      px={6}
      onClick={() => redirectToLogin()}
    >
      ログイン
    </Button>
  )
}
