type GetQueryParams = <T extends string>(
  args: T[],
  uri?: string,
) => Record<T, string | null>

export const getQueryParams: GetQueryParams = (names, uri) => {
  const urlParams = new URLSearchParams(uri || window.location.search)
  return names.reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: urlParams.get(curr),
    }
  }, {} as ReturnType<GetQueryParams>)
}
