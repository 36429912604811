import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useAuthorizationRefresh } from 'src/modules/auth/use-authorization-refresh'
import { PublicPageHeader } from 'src/modules/components/header/public-page-header'
import { Footer } from 'src/modules/components/lib/footer'
import { Link } from 'src/modules/components/lib/link'
import { Screen } from 'src/modules/components/lib/screen'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox, View } from 'src/modules/components/lib/view'
import { Spacer } from 'src/modules/screens/top/spacer'

export const NotFound = () => {
  const { authorized, fetched } = useAuthorizationRefresh()

  return (
    <>
      <Helmet>
        <title>お探しのページが見つかりません | curonお薬サポート</title>
        <link rel="canonical" href="https://pha.curon.co/search" />
        <meta
          name="description"
          content="お探しのページが見つかりませんでした、再度検索してください。"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Screen
        minHeight="100vh"
        style={{
          display: 'flex',
          flex: ' 1 1 0%',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <PublicPageHeader fetched={fetched} authorized={authorized} />
        <Flexbox
          textAlign="center"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p="0 16px"
        >
          <View>
            <Text fontWeight="bold" fontSize="18px">
              ご指定のページが見つかりません
            </Text>
            <Spacer size={16} />
            <Text textAlign="left">
              お探しのページはURLが間違っているか、削除された可能性があります。
              <br />
              ご不便をおかけしますがトップページから改めてご利用ください。
            </Text>
            <Spacer size={48} />
          </View>
          <Link to="/">トップページへ戻る</Link>
        </Flexbox>
        <Footer
          style={{
            position: 'absolute',
            bottom: '20px',
          }}
        />
      </Screen>
    </>
  )
}
