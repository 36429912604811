import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.931 0c-9.256 0-16.8 7.526-16.8 16.8v28.544c0 9.256 7.527 16.8 16.8 16.8 9.274 0 16.8-7.577 16.8-16.901V16.8c0-9.257-7.526-16.8-16.8-16.8zm10.97 45.243c0 6.098-4.922 11.071-10.97 11.071-6.048 0-10.97-4.922-10.97-10.97V16.8c0-6.048 4.922-10.97 10.97-10.97 6.049 0 10.97 4.922 10.97 10.97v28.443z"
        fill={props.color || '#000'}
      />
      <path
        d="M67.493 30.98a2.907 2.907 0 00-2.906 2.906v11.458c0 11.944-9.71 21.655-21.656 21.655-11.944 0-21.638-9.71-21.638-21.655V33.886a2.907 2.907 0 00-5.813 0v11.458c0 14.162 10.769 25.855 24.562 27.317v7.526h-9.123a2.907 2.907 0 00-2.906 2.907A2.918 2.918 0 0030.919 86h24.075a2.907 2.907 0 000-5.813h-9.123v-7.526c13.793-1.462 24.562-13.155 24.562-27.317V33.886a2.936 2.936 0 00-2.94-2.907z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgMicrophone = _style(SvgIcon)

export default SvgMicrophone
