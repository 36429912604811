import css from '@styled-system/css'
import React, { FC, useCallback, useState } from 'react'

import styled from 'styled-components'
import { ColorProps } from 'styled-system'
import { PublicPageHeader } from 'src/modules/components/header/public-page-header'

import { Screen } from 'src/modules/components/lib/screen'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox, View } from 'src/modules/components/lib/view'
import { MutualInduction } from 'src/modules/components/mutualInduction/mutualInduction'
import { AreaModal } from 'src/modules/components/net-application/modal-area/modal-area'
import { KeywordModal } from 'src/modules/components/net-application/modal-keyword'
import { StationModal } from 'src/modules/components/net-application/modal-station'
import { Chain } from 'src/modules/components/net-application/top/chain'
import { Favorite } from 'src/modules/components/net-application/top/favorite'
import { Hero } from 'src/modules/components/net-application/top/hero'
import { KeywordInput } from 'src/modules/components/net-application/top/keyword'
import { LocationSearch } from 'src/modules/components/net-application/top/location-search'
import { Near } from 'src/modules/components/net-application/top/near'
import { NewPharmacy } from 'src/modules/components/net-application/top/new-pharmacy'
import { Station } from 'src/modules/components/net-application/top/station'
import { Pharmacy } from 'src/modules/entities/pharmacy/entity'
import { Footer } from 'src/modules/screens/top/footer'
import { Spacer } from 'src/modules/screens/top/spacer'
import { Area } from '../../../../components/net-application/top/area'

type Props = {
  authorized: boolean
  fetched: boolean
  latestPharmacies: Pharmacy[]
  nearPharmacies: Pharmacy[] | null
  favoritePharmacies: Pharmacy[] | null
  nearPharmaciesLoading: boolean
  latestPharmaciesLoading: boolean
  isGeoApproved: boolean
  currentPosition: GeolocationPosition | null
}

export const NetApplicationSearchTopScreen: FC<Props> = ({
  authorized,
  fetched,
  latestPharmacies,
  nearPharmacies,
  nearPharmaciesLoading,
  currentPosition,
  favoritePharmacies,
  latestPharmaciesLoading,
  isGeoApproved,
}) => {
  const [showAreaModal, setShowAreaModal] = useState(false)
  const [showKeywordModal, setShowKeywordModal] = useState(false)
  const [showStationModal, setShowStationModal] = useState(false)
  const [keyword, setKeyword] = useState('')

  const openAreaModal = useCallback(() => {
    setShowAreaModal(true)
  }, [])

  const closeAreaModal = useCallback(() => {
    setShowAreaModal(false)
  }, [])

  const openKeywordModal = useCallback(() => {
    setShowKeywordModal(true)
  }, [])

  const closeKeywordModal = useCallback(() => {
    setShowKeywordModal(false)
  }, [])

  const openStationModal = useCallback(() => {
    setShowStationModal(true)
  }, [])

  const closeStationModal = useCallback(() => {
    setShowStationModal(false)
  }, [])

  return (
    <View minHeight="100%">
      <PublicPageHeader
        fetched={fetched}
        authorized={authorized}
        displayMutualInduction
      />
      <MutualInduction />
      <Screen>
        <Hero />
        <View backgroundColor="dsGray.50" py="14px" px="16px" height="100%">
          <TitleText>薬局を探す</TitleText>
          <Spacer size={14} />
          {favoritePharmacies && favoritePharmacies.length > 0 && (
            <>
              <Favorite favoritePharmacies={favoritePharmacies} />
              <Spacer size={22} />
            </>
          )}
          <Near
            nearPharmacies={nearPharmacies}
            nearPharmaciesLoading={nearPharmaciesLoading}
            currentPosition={currentPosition}
            isGeoApproved={isGeoApproved}
          />
          <Spacer size={22} />
          {/* キーワード検索 */}
          <KeywordInput openKeywordModal={openKeywordModal} />
          <Spacer size={22} />
          {/* エリアや路線・駅から探す */}
          <Area
            openAreaModal={openAreaModal}
            openStationModal={openStationModal}
          />
          <Spacer size={42} />

          <View
            backgroundColor="dsGray.100"
            borderRadius="6px"
            borderColor="dsGray.200"
            paddingX="16px"
            paddingY="40px"
            position="relative"
          >
            <Flexbox
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                margin: 'auto',
                top: '-15px',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span
                style={{
                  width: '3px',
                  height: '24px',
                  transform: 'rotate(-45deg)',
                  background: '#B1DEC7',
                  borderRadius: '4px',
                }}
              />
              <Spacer horizontal size={8} />
              <Text
                textAlign="center"
                fontSize="20px"
                color="dsGreen.800"
                fontWeight="bold"
                style={{
                  textShadow: '0 0 5px #fff, 0 0 5px #fff, 0 0 5px #fff',
                }}
              >
                よく検索されています
              </Text>
              <Spacer horizontal size={8} />
              <span
                style={{
                  width: '3px',
                  height: '24px',
                  transform: 'rotate(45deg)',
                  background: '#B1DEC7',
                  borderRadius: '4px',
                }}
              />
            </Flexbox>
            {/* 薬局チェーンから探す */}
            <Chain />
            <Spacer size={22} />
            {/* よく検索されるエリアから探す */}
            <LocationSearch />
            <Spacer size={22} />
            {/* よく検索される駅から探す */}
            <Station />
          </View>
          <Spacer size={14} />
          {/* 新着店舗 */}
          <NewPharmacy
            authorized={authorized}
            pharmacies={latestPharmacies}
            fetching={latestPharmaciesLoading}
          />
        </View>

        {/* モーダルエリア */}
        {/* 都道府県 */}
        <AreaModal
          defaultMode="all"
          prefecture={null}
          visible={showAreaModal}
          onCloseClick={closeAreaModal}
        />

        {/* キーワード */}
        <KeywordModal
          visible={showKeywordModal}
          onCloseClick={closeKeywordModal}
          setKeyword={setKeyword}
          keyword={keyword}
        />
        {/* 駅 */}
        <StationModal
          visible={showStationModal}
          onCloseClick={closeStationModal}
        />

        <Footer />
      </Screen>
    </View>
  )
}

const TitleText = styled('h1')<ColorProps>(
  css({
    fontSize: '18px',
    color: 'dsGreen.800',
    fontWeight: 600,
    textAlign: 'center',
    margin: 0,
  }),
)
