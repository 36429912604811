import { TwilioMessage } from 'src/modules/entities/twilio-message/entity'

export type TwilioMessageResponse = {
  uid: string
  to: string
  to_with_hyphen: string
  created_at: string
}

export const parse = (resp: TwilioMessageResponse): TwilioMessage => {
  const res = {
    uid: resp.uid,
    to: resp.to,
    toWithHyphen: resp.to_with_hyphen,
    createdAt: resp.created_at,
  }

  return res
}
