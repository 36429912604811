export const sortPrefectureList = (
  prefectureList: {
    count?: number
    id: number
    name: string
    value: string
  }[],
) => {
  const north = prefectureList.filter(pref => {
    return (
      pref.name === '北海道' ||
      pref.name === '青森県' ||
      pref.name === '岩手県' ||
      pref.name === '宮城県' ||
      pref.name === '秋田県' ||
      pref.name === '山形県' ||
      pref.name === '福島県'
    )
  })

  const kanto = prefectureList.filter(pref => {
    return (
      pref.name === '茨城県' ||
      pref.name === '栃木県' ||
      pref.name === '群馬県' ||
      pref.name === '埼玉県' ||
      pref.name === '千葉県' ||
      pref.name === '東京都' ||
      pref.name === '神奈川県'
    )
  })

  const chubu = prefectureList.filter(pref => {
    return (
      pref.name === '新潟県' ||
      pref.name === '富山県' ||
      pref.name === '石川県' ||
      pref.name === '福井県' ||
      pref.name === '山梨県' ||
      pref.name === '長野県' ||
      pref.name === '岐阜県' ||
      pref.name === '静岡県' ||
      pref.name === '愛知県'
    )
  })

  const kinki = prefectureList.filter(pref => {
    return (
      pref.name === '三重県' ||
      pref.name === '滋賀県' ||
      pref.name === '京都府' ||
      pref.name === '大阪府' ||
      pref.name === '兵庫県' ||
      pref.name === '奈良県' ||
      pref.name === '和歌山県'
    )
  })

  const chugoku = prefectureList.filter(pref => {
    return (
      pref.name === '鳥取県' ||
      pref.name === '島根県' ||
      pref.name === '岡山県' ||
      pref.name === '広島県' ||
      pref.name === '山口県'
    )
  })

  const shikoku = prefectureList.filter(pref => {
    return (
      pref.name === '徳島県' ||
      pref.name === '香川県' ||
      pref.name === '愛媛県' ||
      pref.name === '高知県'
    )
  })

  const kyushu = prefectureList.filter(pref => {
    return (
      pref.name === '福岡県' ||
      pref.name === '佐賀県' ||
      pref.name === '長崎県' ||
      pref.name === '熊本県' ||
      pref.name === '大分県' ||
      pref.name === '宮崎県' ||
      pref.name === '鹿児島県' ||
      pref.name === '沖縄県'
    )
  })

  return [
    {
      name: '北海道・東北',
      list: north,
    },
    {
      name: '関東',
      list: kanto,
    },
    {
      name: '中部',
      list: chubu,
    },
    {
      name: '近畿',
      list: kinki,
    },
    {
      name: '中国・四国',
      list: chugoku,
    },
    {
      name: '四国',
      list: shikoku,
    },
    {
      name: '九州・沖縄',
      list: kyushu,
    },
  ]
}
