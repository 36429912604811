import { Prefecture } from 'src/modules/constants/prefecture-list'
import { Chain } from 'src/modules/entities/chain/entity'
import { Area, Station } from 'src/modules/entities/pharmacy/entity'
import { routes } from 'src/modules/routes'
import { PageType } from 'src/modules/screens/net-application/search/helper'

type BreadcrumbParams = {
  pageType: PageType
  prefecture?: Prefecture | null
  area?: Area | null
  station?: Station | null
  chain?: Chain | null
}

const getItemListElement = ({
  pageType,
  area,
  chain,
  prefecture,
  station,
}: BreadcrumbParams) => {
  const baseItem = [
    {
      '@type': 'ListItem',
      position: 1,
      name: '薬局を探す',
      item: `${window.location.origin}/search`,
    },
  ]

  const breadcrumbList = (() => {
    switch (pageType) {
      case 'search_list':
        return [
          {
            '@type': 'ListItem',
            position: 2,
            name: `全国の薬局一覧`,
            item: `${window.location.origin}${routes.searchList.build()}`,
          },
        ]
      case 'search_pref':
        if (!prefecture) return [baseItem, {}]
        return [
          {
            '@type': 'ListItem',
            position: 2,
            name: `${prefecture.name}の薬局を探す`,
            item: `${window.location.origin}${routes.searchPref.build(
              prefecture.value,
            )}`,
          },
        ]
      case 'search_area':
        if (!prefecture || !area) return [baseItem, {}]
        return [
          {
            '@type': 'ListItem',
            position: 2,
            name: prefecture.name,
            item: `${window.location.origin}${routes.searchPref.build(
              prefecture.value,
            )}`,
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: `${area.name}の薬局一覧`,
            item: `${window.location.origin}${routes.searchPrefArea.build(
              prefecture.value,
              area.slug,
            )}`,
          },
        ]
      case 'search_station':
        if (!prefecture || !station) return [baseItem, {}]
        return [
          {
            '@type': 'ListItem',
            position: 2,
            name: prefecture.name,
            item: `${window.location.origin}${routes.searchPref.build(
              prefecture.value,
            )}`,
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: `${station.name}駅周辺の薬局一覧`,
            item: `${window.location.origin}${routes.searchPrefStation.build(
              prefecture.value,
              station.code,
            )}`,
          },
        ]
      case 'search_chain':
        if (!chain) return [baseItem, {}]
        return [
          {
            '@type': 'ListItem',
            position: 2,
            name: chain.name,
            item: `${window.location.origin}${routes.searchChain.build(
              chain.code,
            )}`,
          },
        ]
      case 'search_chain_list':
        if (!chain) return [baseItem, {}]
        return [
          {
            '@type': 'ListItem',
            position: 2,
            name: chain.name,
            item: `${window.location.origin}${routes.searchChain.build(
              chain.code,
            )}`,
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: `全国 ${chain.name}の店舗一覧`,
            item: `${window.location.origin}${routes.searchChainList.build(
              chain.code,
            )}`,
          },
        ]
      case 'search_chain_pref':
        if (!chain || !prefecture) return [baseItem, {}]
        return [
          {
            '@type': 'ListItem',
            position: 2,
            name: chain.name,
            item: `${window.location.origin}${routes.searchChain.build(
              chain.code,
            )}`,
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: `${prefecture.name} ${chain.name}の店舗一覧`,
            item: `${window.location.origin}${routes.searchChainPref.build(
              prefecture.value,
              chain.code,
            )}`,
          },
        ]
      case 'search_chain_area':
        if (!chain || !prefecture || !area) return [baseItem, {}]
        return [
          {
            '@type': 'ListItem',
            position: 2,
            name: chain.name,
            item: `${window.location.origin}${routes.searchChain.build(
              chain.code,
            )}`,
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: prefecture.name,
            item: `${window.location.origin}${routes.searchChainPref.build(
              prefecture.value,
              chain.code,
            )}`,
          },
          {
            '@type': 'ListItem',
            position: 4,
            name: `${area.name} ${chain.name}の店舗一覧`,
            item: `${window.location.origin}${routes.searchChainPrefArea.build(
              prefecture.value,
              area.slug,
              chain.code,
            )}`,
          },
        ]
      case 'search_chain_station':
        if (!chain || !prefecture || !station) return [baseItem, {}]
        return [
          {
            '@type': 'ListItem',
            position: 2,
            name: chain.name,
            item: `${window.location.origin}${routes.searchChain.build(
              chain.code,
            )}`,
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: `東京都`,
            item: `${window.location.origin}${routes.searchChainPref.build(
              prefecture.value,
              chain.code,
            )}`,
          },
          {
            '@type': 'ListItem',
            position: 4,
            name: `${station.name}駅 ${chain.name}の店舗一覧`,
            item: `${
              window.location.origin
            }${routes.searchChainPrefStation.build(
              prefecture.value,
              station.code,
              chain.code,
            )}`,
          },
        ]
      case 'search_near':
        return [
          {
            '@type': 'ListItem',
            position: 2,
            name: `近くの薬局一覧`,
            item: `${window.location.origin}${routes.searchNear.build()}`,
          },
        ]
      default:
        return []
    }
  })()

  return [...baseItem, ...breadcrumbList]
}

export const getJsonLd = (params: BreadcrumbParams) => {
  const itemListElement = getItemListElement(params)

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    name: 'パンくずリスト',
    itemListElement,
  }
}
