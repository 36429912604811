import { Chain } from 'src/modules/entities/chain/entity'

export type ChainResponse = {
  name: string
  code: string
}

export const parse = (resp: ChainResponse): Chain => {
  const res = {
    name: resp.name,
    code: resp.code,
  }

  return res
}
