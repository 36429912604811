import { useEffect, useState } from 'react'

export const getPosition = async (
  options?: PositionOptions,
): Promise<GeolocationPosition | null> => {
  if (!navigator.geolocation) return null

  return new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(resolve, reject, options),
  )
}

const MAX_AGE = 5 * 60 * 1000

export const useGeoPosition = ({
  onCompleted,
}: {
  onCompleted?: () => void
}) => {
  const [data, setData] = useState<GeolocationPosition | null>(null)
  const [isApproved, setIsApproved] = useState<boolean | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      try {
        const res = await getPosition({
          maximumAge: MAX_AGE,
          enableHighAccuracy: false,
        })
        setData(res)
        setIsApproved(true)
      } catch (e) {
        setData(null)
        setIsApproved(false)
      }
      setIsLoading(false)
      setIsCompleted(true)
    })()
  }, [])

  useEffect(() => {
    if (isCompleted) {
      onCompleted && onCompleted()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCompleted])

  return { data, isApproved, isLoading, isCompleted }
}
