import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43 86c23.748 0 43-19.252 43-43S66.748 0 43 0 0 19.252 0 43s19.252 43 43 43zm17.917-25.05a4.14 4.14 0 000-5.835L48.836 43l12.15-12.115a4.14 4.14 0 000-5.836 4.14 4.14 0 00-5.837 0L43.034 37.164 30.92 25.05a4.14 4.14 0 00-5.835 0 4.14 4.14 0 000 5.836L37.199 43 25.084 55.115a4.14 4.14 0 000 5.836 4.109 4.109 0 002.9 1.194 4.08 4.08 0 002.901-1.194L43 48.836 55.115 60.95a4.168 4.168 0 002.901 1.194 4.08 4.08 0 002.9-1.194z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgCancelFull = _style(SvgIcon)

export default SvgCancelFull
