import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestDelete } from 'src/modules/server/request'

export type SubmitParams = {
  uid: string
}

export const submit = async (params: SubmitParams) => {
  await requestDelete({
    url: `${PHARMACY_API_URL}/patients/favorite_pharmacies/${params.uid}.json`,
    params: {},
  })

  return { uid: params.uid }
}
