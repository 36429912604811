import css from '@styled-system/css'
import React, { FC } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'
import styled from 'styled-components'
import {
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps &
  FlexboxProps

const styledSystemProps = [typography, space, layout, color, position, flexbox]

export type AnchorProps = StyledSystemProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>

export const Anchor: FC<AnchorProps> = styled('a')<StyledSystemProps>(
  css({ color: 'green' }),
  styledSystemProps,
)

export type LinkProps = StyledSystemProps & RouterLinkProps

export const Link = styled(RouterLink)<StyledSystemProps>(
  css({ color: 'green' }),
  styledSystemProps,
)
