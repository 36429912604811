import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#arrow_stick_svg__clip0)">
        <path
          d="M80.853 37.808H17.721l28.7-28.931a5.218 5.218 0 000-7.35 5.11 5.11 0 00-7.286 0L1.514 39.477a5.218 5.218 0 000 7.35l37.338 37.666a5.099 5.099 0 003.633 1.527 5.065 5.065 0 003.633-1.527 5.218 5.218 0 000-7.35l-28.68-28.951h63.415C83.7 48.192 86 45.87 86 43c0-2.87-2.3-5.192-5.147-5.192z"
          fill={props.color || '#000'}
        />
      </g>
      <defs>
        <clipPath id="arrow_stick_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgArrowStick = _style(SvgIcon)

export default SvgArrowStick
