export enum RepeatType {
  Weekly = 'weekly',
  FirstWeek = 'first_week',
  SecondWeek = 'second_week',
  ThirdWeek = 'third_week',
  FourthWeek = 'fourth_week',
  FifthWeek = 'fifth_week',
  NoRepeat = 'no_repeat',
}

export const repeatTypeStrings: { [key: string]: string } = {
  [RepeatType.Weekly]: '毎週',
  [RepeatType.FirstWeek]: '毎月第1曜日',
  [RepeatType.SecondWeek]: '毎月第2曜日',
  [RepeatType.ThirdWeek]: '毎月第3曜日',
  [RepeatType.FourthWeek]: '毎月第4曜日',
  [RepeatType.FifthWeek]: '毎月第5曜日',
  [RepeatType.NoRepeat]: '',
}

export const repeatTypeToString = (t: RepeatType): string => {
  return repeatTypeStrings[t]
}

export const specificWeekNumberRepeatTypes = [
  RepeatType.FirstWeek,
  RepeatType.SecondWeek,
  RepeatType.ThirdWeek,
  RepeatType.FourthWeek,
  RepeatType.FifthWeek,
]

export const nonspecificWeekNumberRepeatTypes = [
  RepeatType.Weekly,
  RepeatType.NoRepeat,
]

export const convertNumberToRepeatType = (input: number): RepeatType | null => {
  const repeatTypeNumber: { [key: number]: RepeatType } = {
    1: RepeatType.FirstWeek,
    2: RepeatType.SecondWeek,
    3: RepeatType.ThirdWeek,
    4: RepeatType.FourthWeek,
    5: RepeatType.FifthWeek,
  }

  return repeatTypeNumber?.[input] || null
}

export const convertRepeatTypeToNumber = (input: RepeatType): number => {
  const repeatTypeNumber: { [key: string]: number } = {
    [RepeatType.FirstWeek]: 1,
    [RepeatType.SecondWeek]: 2,
    [RepeatType.ThirdWeek]: 3,
    [RepeatType.FourthWeek]: 4,
    [RepeatType.FifthWeek]: 5,
  }

  // If not in the list above, should be the last one when doing sorting
  return repeatTypeNumber?.[input] || Number.MAX_SAFE_INTEGER
}
