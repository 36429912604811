import React, { FC, useState } from 'react'
import styled from 'styled-components'
import { Image } from 'src/modules/components/lib/image'
import { Text } from 'src/modules/components/lib/text'
import { View } from 'src/modules/components/lib/view'
import { Spacer } from 'src/modules/screens/top/spacer'

export const Main: FC = () => {
  const onClickLeft = () => {
    setCurrentStep(prevStep => (prevStep + 2) % 3)
  }
  const onClickRight = () => {
    setCurrentStep(prevStep => (prevStep + 1) % 3)
  }

  const steps = [...Array(3)].map((_, i) => i)
  const [currentStep, setCurrentStep] = useState<number>(0)
  const images = [
    '/cp/app/main_step1.mp4',
    '/cp/app/main_step2.mp4',
    '/cp/app/main_step3.mp4',
  ]
  const imgPath = images[currentStep]

  return (
    <View backgroundColor="#fff">
      <MainContainer>
        <Spacer size={20} />

        <MainTitle>
          <span>クロン処方箋ネット受付</span>
          <br />
          お薬は待たずに
          <br />
          受け取り
        </MainTitle>

        <Spacer size={10} />

        <Text
          fontSize="14px"
          fontWeight="600"
          textAlign="center"
          color="#3a424c"
        >
          事前に処方箋をスマホで送信！
        </Text>

        <Spacer size={9} />

        <View position="relative">
          <View maxWidth="240px" margin="0 auto" textAlign="center">
            {steps.map(step => {
              return (
                currentStep === step && (
                  <MainVideo
                    key={step}
                    width="235px"
                    height="225px"
                    autoPlay
                    muted
                    loop
                    playsInline
                  >
                    <source src={imgPath} type="video/mp4" />
                  </MainVideo>
                )
              )
            })}
            {steps.map(step => {
              return (
                currentStep === step && (
                  <View key={step}>
                    {!(currentStep === 0) && (
                      <ArrowLeft src="/cp/app/left.svg" onClick={onClickLeft} />
                    )}
                    {!(currentStep === 2) && (
                      <ArrowRight
                        src="/cp/app/right.svg"
                        onClick={onClickRight}
                      />
                    )}
                  </View>
                )
              )
            })}
          </View>
        </View>

        <Spacer size={5} />

        {currentStep === 0 && (
          <>
            <MainContentTitle>STEP1</MainContentTitle>
            <Spacer size={15} />
            <Desc>
              処方箋を受け取ったら
              <br />
              スマホで撮影し、
              <br />
              お好きな薬局に送信
            </Desc>
          </>
        )}
        {currentStep === 1 && (
          <>
            <MainContentTitle>STEP2</MainContentTitle>
            <Spacer size={20} />
            <Desc>
              調剤が完了したら
              <br />
              メールにお知らせします
            </Desc>
          </>
        )}
        {currentStep === 2 && (
          <>
            <MainContentTitle>STEP3</MainContentTitle>
            <Spacer size={20} />
            <Desc>
              都合の良い時間に
              <br />
              お薬を取りに行くだけ
            </Desc>
          </>
        )}

        <Spacer size={25} />
      </MainContainer>
    </View>
  )
}

const MainContainer = styled(View)`
  max-width: 375px;
  margin: 0 auto;
  color: #272c33;
  font-family: Hiragino Sans, sans-serif;
  overflow: hidden;
`

const MainTitle = styled(View)`
  font-weight: 800;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
  color: #157a4b;
  span {
    font-weight: 700;
    font-size: 18px;
    line-height: 1.5em;
  }
`

const ArrowLeft = styled(Image)`
  display: inline-block;
  position: absolute;
  top: 35%;
  left: 3%;
`
const ArrowRight = styled(Image)`
  display: inline-block;
  position: absolute;
  top: 35%;
  right: 3%;
`

const MainContentTitle = styled(View)`
  width: 81px;
  margin: 0 auto;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5em;
  text-align: center;
  color: #fff;
  background: #1e8f58;
  border-radius: 100px;
`
const Desc = styled(View)`
  text-align: center;
  font-weight: 600;
  font-size: 16px;
`

const MainVideo = styled.video`
  outline: none;
  border: none;
`
