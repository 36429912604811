import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#plus_svg__clip0)">
        <path
          d="M84.366 39.073a5.604 5.604 0 00-3.927-1.634H48.56V5.56c0-1.462-.573-2.895-1.634-3.927C45.895.574 44.462 0 43 0c-1.462 0-2.895.573-3.927 1.634a5.604 5.604 0 00-1.634 3.927v31.906H5.56c-1.462 0-2.895.574-3.927 1.634C.574 40.105 0 41.538 0 43c0 1.462.573 2.895 1.634 3.927a5.604 5.604 0 003.927 1.634h31.906v31.906c0 1.462.574 2.896 1.634 3.928a5.605 5.605 0 003.928 1.634c1.462 0 2.895-.574 3.927-1.634a5.605 5.605 0 001.634-3.928V48.561h31.906c1.462 0 2.895-.573 3.927-1.634A5.604 5.604 0 0086.057 43c-.057-1.462-.63-2.895-1.691-3.927z"
          fill={props.color || '#000'}
        />
      </g>
      <defs>
        <clipPath id="plus_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgPlus = _style(SvgIcon)

export default SvgPlus
