import React, { FC } from 'react'
import styled from 'styled-components'
import { Link } from 'src/modules/components/lib/link'
import { routes } from 'src/modules/routes'

export const EntryBtn: FC = () => {
  const ongoingCampaignCode = 'campaign202210'

  return (
    <>
      <StyledAnchor to={routes.campaign.build(ongoingCampaignCode)}>
        今すぐエントリーする
      </StyledAnchor>
    </>
  )
}

const StyledAnchor = styled(Link)`
  max-width: 217px;
  height: 54px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  color: white;
  font-style: normal;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  background-color: #ed5140;
  border-radius: 100px;
  transition: 0.3s;
`
