import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M83.305 12.613H31.103c-1.49 0-2.694 1.319-2.694 2.982v15.308H2.695C1.204 30.903 0 32.22 0 33.883v36.55c0 1.635 1.204 2.982 2.695 2.982h52.202c1.49 0 2.694-1.318 2.694-2.981V55.126h25.714c1.491 0 2.695-1.319 2.695-2.981v-36.55c0-1.663-1.204-2.982-2.695-2.982zM52.202 68.055H5.389v-31.82h23.048v15.91c0 1.634 1.204 2.98 2.695 2.98h21.127v12.93h-.057zm0-18.29H33.769v-13.56h18.433v13.56zm28.409 0H57.563v-15.91c0-1.634-1.204-2.981-2.695-2.981H33.741V17.945h46.841v31.82h.029z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgScreenShare = _style(SvgIcon)

export default SvgScreenShare
