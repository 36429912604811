import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00005 0C4.46565 0 1.59021 2.87547 1.59021 6.40984V6.58703C1.59021 8.37453 2.61502 10.4575 4.63627 12.7781C6.10152 14.4603 7.5463 15.6333 7.60705 15.6824L8.00005 16L8.39305 15.6825C8.45384 15.6333 9.89862 14.4603 11.3638 12.7781C13.3851 10.4575 14.4099 8.37456 14.4099 6.58706V6.40988C14.4099 2.87547 11.5345 0 8.00005 0ZM13.1592 6.58706C13.1592 9.60784 9.26699 13.2646 8.00005 14.3746C6.73277 13.2642 2.8409 9.60763 2.8409 6.58706V6.40988C2.8409 3.56513 5.1553 1.25072 8.00005 1.25072C10.8448 1.25072 13.1592 3.56513 13.1592 6.40988V6.58706Z"
        fill={props.color || '#000'}
      />
      <path
        d="M8.00007 3.43945C6.36219 3.43945 5.02966 4.77198 5.02966 6.40986C5.02966 8.04773 6.36219 9.38026 8.00007 9.38026C9.63794 9.38026 10.9705 8.04773 10.9705 6.40986C10.9705 4.77198 9.63798 3.43945 8.00007 3.43945ZM8.00007 8.12958C7.05182 8.12958 6.28035 7.35811 6.28035 6.40986C6.28035 5.46161 7.05182 4.69014 8.00007 4.69014C8.94832 4.69014 9.71979 5.46161 9.71979 6.40986C9.71979 7.35811 8.94832 8.12958 8.00007 8.12958Z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgLocation = _style(SvgIcon)

export default SvgLocation
