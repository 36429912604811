import css from '@styled-system/css'
import React, { FC } from 'react'
import styled from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  flexbox,
  FlexboxProps,
  grid,
  GridProps,
  borders,
  BordersProps,
  background,
  BackgroundProps,
} from 'styled-system'

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps &
  FlexboxProps &
  GridProps &
  BordersProps &
  BackgroundProps

const styledSystemProps = [
  typography,
  space,
  layout,
  color,
  position,
  flexbox,
  grid,
  borders,
  background,
]

export type ClickableProps = StyledSystemProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>

export const Clickable: FC<ClickableProps> = props => {
  return <ClickableRoot {...props}>{props.children}</ClickableRoot>
}

const ClickableRoot = styled('a')<StyledSystemProps>(
  css({
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
  }),
  styledSystemProps,
)
