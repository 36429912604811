import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { rootReducer } from './reducer'

declare let window: Window
interface Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any
}

export const history = createBrowserHistory()

const configureStore = () => {
  if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_APPLICATION_ENV === 'stg'
  ) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          maxAge: 300,
        })
      : compose

    return createStore(
      rootReducer(history),
      composeEnhancers(
        applyMiddleware(routerMiddleware(history), thunkMiddleware),
      ),
    )
  } else {
    return createStore(
      rootReducer(history),
      compose(applyMiddleware(routerMiddleware(history), thunkMiddleware)),
    )
  }
}

export const store = configureStore()
