import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.8188 4.01562C10.8188 6.22476 9.02799 8.01562 6.81885 8.01562C4.60971 8.01562 2.81885 6.22476 2.81885 4.01562C2.81885 1.80649 4.60971 0.015625 6.81885 0.015625C9.02799 0.015625 10.8188 1.80649 10.8188 4.01562ZM9.31885 4.01562C9.31885 5.39634 8.19956 6.51562 6.81885 6.51562C5.43814 6.51562 4.31885 5.39634 4.31885 4.01562C4.31885 2.63491 5.43814 1.51562 6.81885 1.51562C8.19956 1.51562 9.31885 2.63491 9.31885 4.01562Z"
        fill="#637182"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.0751953 15.9846H13.9243V10.0595C13.9243 9.00432 12.9104 8.28176 11.9324 8.53517C10.6345 8.8715 8.51944 9.12517 6.99982 9.12518V9.12359C5.4516 9.12359 3.36508 8.87151 2.06711 8.53517C1.08915 8.28176 0.0751953 9.00432 0.0751953 10.0595V15.9846ZM1.58115 10.0335C1.57757 10.0403 1.5752 10.0479 1.5752 10.0595V14.4846H12.4243V10.0595C12.4243 10.0479 12.4219 10.0403 12.4184 10.0335C12.4141 10.0254 12.4063 10.015 12.3934 10.0054C12.3667 9.98525 12.3364 9.98002 12.3087 9.98721C11.0175 10.3218 8.81159 10.6877 6.99982 10.6877V10.6899C5.16679 10.6899 2.98205 10.3218 1.69085 9.98721C1.66311 9.98002 1.63282 9.98525 1.60607 10.0054C1.59324 10.015 1.58537 10.0254 1.58115 10.0335Z"
        fill="#637182"
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgAccount = _style(SvgIcon)

export default SvgAccount
