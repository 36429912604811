import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M70.643 0H15.357C6.88.01.01 6.88 0 15.357v55.286C.01 79.12 6.88 85.99 15.357 86h55.286C79.12 85.99 85.99 79.12 86 70.643V15.357C85.99 6.88 79.12.01 70.643 0zm9.214 70.643a9.214 9.214 0 01-9.214 9.214H15.357a9.214 9.214 0 01-9.214-9.214V15.357a9.214 9.214 0 019.214-9.214h55.286a9.214 9.214 0 019.214 9.214v55.286z"
        fill={props.color || '#000'}
      />
      <path
        d="M58.358 39.929H46.072V27.643a3.071 3.071 0 10-6.143 0v12.286H27.643a3.071 3.071 0 100 6.142H39.93v12.286a3.071 3.071 0 106.143 0V46.071h12.286a3.072 3.072 0 000-6.142z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgAddSquare = _style(SvgIcon)

export default SvgAddSquare
