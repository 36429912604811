export const displayServiceTagsId = [
  'late_night_hours',
  'open_sunday',
  'credit_card',
  'parking',
]

export const displayAllServiceTagsId = [
  'late_night_hours',
  'credit_card',
  'herbal_medicine',
  'single_packaged',
  'narcotic_prescription',
  'pharmaceutical_consultation',
  'parking',
  'barrier_free',
  'kids_space',
  'open_sunday',
  'handicap_accessible',
  'multilingual',
]
