import { useCallback, useContext } from 'react'
import { FetchOption, useFetch as _useFetch } from '../../hooks/use-fetch'
import { FetchContext } from './request'

export const useFetch = (
  options?: FetchOption<ResolvedType<ReturnType<typeof fetch>>>,
) => {
  const { fetch } = useContext(FetchContext)
  const request = useCallback(() => {
    return fetch()
  }, [fetch])

  return _useFetch(request, options)
}
