import React, { FC } from 'react'
import styled from 'styled-components'
import { Image } from 'src/modules/components/lib/image'
import { Link } from 'src/modules/components/lib/link'
import { Text } from 'src/modules/components/lib/text'
import { View } from 'src/modules/components/lib/view'
import { routes } from 'src/modules/routes'
import { EntryBtn } from './entry-btn'

export const AboutService: FC = () => {
  return (
    <View fontFamily="'Noto Sans JP', sans-serif">
      <AboutContainer>
        <AboutTitle>クロン処方箋ネット受付について</AboutTitle>
        <Text
          marginBottom="22px"
          fontSize="10px"
          fontWeight="500"
          textAlign="center"
        >
          PRESCRIPTIONS ONLINE
        </Text>
        <View maxWidth="335px" margin="0 auto 15px">
          <Image
            src="/cp/campaign202210/about_service_img.png"
            alt="処方箋ネット受付"
            width="100%"
            verticalAlign="bottom"
          />
        </View>
        <Text marginBottom="5px" lineHeight="1.57em">
          スマホで撮影した処方箋画像を、事前に全国の薬局に送信・予約できるサービスです。
          <br />
          好きな時間に好きな場所で処方薬を受け取ることができます。
        </Text>
        <Text marginBottom="31px" fontSize="12px">
          ※システム利用料は無料です
          <br />
          ※薬局の営業時間内での受け取りとなります
        </Text>

        <View marginBottom="24px">
          <EntryBtn />
        </View>

        <StyledAnchor
          to={routes.index.path}
          rel="noopener noreferrer"
          target="_blank"
        >
          処方箋ネット受付の詳細
        </StyledAnchor>
      </AboutContainer>
    </View>
  )
}

const AboutContainer = styled(View)`
  max-width: 430px;
  margin: 0 auto;
  padding: 30px 20px 46px;
  background: #43b079;
  color: #fff;
  position: relative;
`

const AboutTitle = styled(Text)`
  font-size: 20px;
  font-weight: 700;
  text-align: center;
`

const StyledAnchor = styled(Link)`
  max-width: 217px;
  height: 54px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  color: #1e8f58;
  font-style: normal;
  font-family: 'Noto Sans Jp';
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  background-color: #fff;
  border: 2px solid #1e8f58;
  border-radius: 100px;
  transition: 0.3s;
`
