import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { parse, PatientAccountResponse } from '../../patient-account/response'

export const fetch = async () => {
  const resp = await requestGet<PatientAccountResponse | null>({
    url: `${PHARMACY_API_URL}/patients/patient_accounts/ping.json`,
  })

  return resp.data && resp.data.uid ? parse(resp.data) : null
}
