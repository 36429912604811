import {
  QuestionAnswerResponse,
  parse as questionAnswerParse,
} from 'src/modules/apis/question-answer/response'
import { QuestionnaireAnswer } from 'src/modules/entities/questionnaire-answer/entity'

export type QuestionnaireAnswerResponse = {
  uid: string
  questionnaire_uids: string[]
  created_at: string
  answers: QuestionAnswerResponse[]
}

export const parse = (
  resp: QuestionnaireAnswerResponse,
): QuestionnaireAnswer => {
  return {
    uid: resp.uid,
    questionnaireUids: resp.questionnaire_uids,
    createdAt: resp.created_at,
    questionAnswers: resp.answers.map(questionAnswerParse),
  }
}
