import moment from 'moment'
import React, { FC, useMemo, Fragment } from 'react'
import { ReactComponent as FavoriteImage } from 'src/assets/search/favorite.svg'
import { ReactComponent as ClockImage } from 'src/assets/search/icon-clock.svg'
import { ArrowRight } from 'src/modules/components/lib/curon-icon'
import { Link } from 'src/modules/components/lib/link'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox } from 'src/modules/components/lib/view'
import { getIrregularHourToday } from 'src/modules/entities/business-hour-info/helpers/get-irregular-hour-info'
import { getTimeRangesOfDate } from 'src/modules/entities/business-hour-info/helpers/get-time-ranges-of-date'
import { Pharmacy, toAddress } from 'src/modules/entities/pharmacy/entity'
import { formatTimeRange } from 'src/modules/entities/time-range/helpers/format-time-ranges'
import { timeRangeCompetitor } from 'src/modules/entities/time-range/helpers/time-range-competitor'
import { useFavoritePharmacy } from 'src/modules/favorite/use-favorite-pharmacy'
import { routes } from 'src/modules/routes'
import { Spacer } from 'src/modules/screens/top/spacer'

type Props = {
  pharmacy: Pharmacy
}

export const PharmacyCard: FC<Props> = ({ pharmacy }) => {
  const address = toAddress(pharmacy)
  const { isFavorite } = useFavoritePharmacy()
  const timeRangeOfDate = useMemo(() => {
    if (!pharmacy.businessHourInfo) return null

    return getTimeRangesOfDate(pharmacy.businessHourInfo, moment())
  }, [pharmacy.businessHourInfo])

  const irregularHourToday = useMemo(() => {
    if (!pharmacy.businessHourInfo) return null
    return getIrregularHourToday(pharmacy.businessHourInfo)
  }, [pharmacy.businessHourInfo])

  return (
    <Link
      color="#272C33"
      key={pharmacy.uid}
      to={routes.pharmaciesDetail.build(pharmacy.uid)}
    >
      <Flexbox
        backgroundColor="#fff"
        padding="16px"
        boxShadow="0px 1px 3px rgba(0, 0, 0, 0.1)"
        justifyContent="space-between"
        width="332px"
        height="130px"
        alignItems="center"
      >
        <Flexbox flexDirection="column" width="288px">
          <Flexbox>
            {isFavorite(pharmacy.uid) && (
              <>
                <FavoriteImage />
                <Spacer size={8} horizontal />
              </>
            )}
            <Text fontWeight="bold">{pharmacy.name}</Text>
          </Flexbox>
          <Spacer size={8} />
          <Text
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
            }}
          >
            {address}
          </Text>
          <Spacer size={8} />

          {irregularHourToday && (
            <Flexbox>
              {irregularHourToday.opened ? (
                <>
                  <Text color="red">特別営業</Text>
                  {irregularHourToday.timeRanges
                    .sort((a, b) => timeRangeCompetitor(a, b))
                    .map((time, index) => (
                      <Text key={index}>{formatTimeRange(time)}</Text>
                    ))}
                </>
              ) : (
                <Text color="red">特別休業</Text>
              )}
            </Flexbox>
          )}

          {timeRangeOfDate && (
            <Flexbox alignItems="center" color="#637182">
              <ClockImage />
              <Spacer size={8} horizontal />
              {!irregularHourToday && timeRangeOfDate?.length === 0 ? (
                <Text>休業</Text>
              ) : (
                timeRangeOfDate?.map((time, index, arr) => (
                  <Fragment key={index}>
                    <Text>{formatTimeRange(time)}</Text>
                    {index !== arr.length - 1 && <Text>|</Text>}
                  </Fragment>
                ))
              )}

              {irregularHourToday && (
                <>
                  <Spacer size={8} horizontal />
                  {irregularHourToday.opened ? (
                    <>
                      <Text color="#E33B24">特別営業</Text>
                      {irregularHourToday.timeRanges
                        .sort((a, b) => timeRangeCompetitor(a, b))
                        .map((time, index) => (
                          <Text key={index}>{formatTimeRange(time)}</Text>
                        ))}
                    </>
                  ) : (
                    <Text color="#E33B24">特別休業</Text>
                  )}
                </>
              )}
            </Flexbox>
          )}
        </Flexbox>

        <Flexbox flex={1}>
          <ArrowRight />
        </Flexbox>
      </Flexbox>
    </Link>
  )
}
