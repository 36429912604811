import { IrregularDay } from 'src/modules/entities/irregular-days/entity'
import {
  TimeRangeResponse,
  parse as parseTimeRangesResponse,
} from '../time-range/response'

export type IrregularDayResponse = {
  uid: string
  date: string
  opened: boolean
  time_ranges?: TimeRangeResponse[]
}

export const parse = (resp: IrregularDayResponse): IrregularDay => {
  const res: IrregularDay = {
    uid: resp.uid,
    date: resp.date,
    opened: resp.opened,
    timeRanges: resp.time_ranges
      ? resp.time_ranges.map(parseTimeRangesResponse)
      : [],
  }

  return res
}
