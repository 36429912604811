import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#pharmacy_user_svg__clip0)" fill={props.color || '#000'}>
        <path d="M35.403 38.745a19.25 19.25 0 01-13.698-5.677 19.25 19.25 0 01-5.678-13.7A19.25 19.25 0 0121.705 5.67 19.274 19.274 0 0135.403 0a19.25 19.25 0 0113.7 5.677 19.25 19.25 0 015.677 13.7 19.25 19.25 0 01-5.678 13.698 19.274 19.274 0 01-13.699 5.67zm0-34.526c-8.356 0-15.157 6.801-15.157 15.157s6.801 15.157 15.157 15.157c8.357 0 15.158-6.8 15.158-15.157 0-8.356-6.801-15.157-15.158-15.157zM0 72.988a5.198 5.198 0 005.194 5.194h37.45l-2.132-4.227H5.194a.978.978 0 01-.975-.975V58.805c0-8.073 6.31-14.725 14.368-15.135h.075c.223 0 .454.053.714.134 1.161.462 3.55 1.34 6.43 2.106 3.578.953 6.808 1.436 9.605 1.436 6.266 0 13.208-2.433 15.902-3.482.364-.141.714-.208 1.034-.186 2.13.125 4.136.69 5.934 1.602l3.198-3.042a19.235 19.235 0 00-8.886-2.772 6.707 6.707 0 00-2.813.47c-1.927.75-8.669 3.199-14.376 3.199-5.782 0-12.56-2.485-14.502-3.244a6.035 6.035 0 00-2.53-.432C8.073 39.979 0 48.477 0 58.805v14.183z" />
        <path d="M73.82 41.223a12.095 12.095 0 00-8.611 3.568L48.345 61.654a12.104 12.104 0 00-3.568 8.612c0 6.715 5.463 12.18 12.18 12.18 3.255 0 6.312-1.268 8.61-3.568l16.865-16.863A12.097 12.097 0 0086 53.403c0-6.716-5.464-12.18-12.18-12.18zM57.94 60.008l-5.62 5.62a6.518 6.518 0 00-1.922 4.639.936.936 0 11-1.874 0c0-2.253.877-4.37 2.47-5.963l5.622-5.62a.936.936 0 111.325 1.325zm21.842-.642l-7.77 7.769L60.09 55.21l7.77-7.77a8.372 8.372 0 015.961-2.47c4.65 0 8.432 3.783 8.432 8.433 0 2.252-.876 4.37-2.47 5.963z" />
      </g>
      <defs>
        <clipPath id="pharmacy_user_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgPharmacyUser = _style(SvgIcon)

export default SvgPharmacyUser
