import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M80.267 85.599c-4.071 0-19.178-.602-35.49-8.772A75.35 75.35 0 0117.315 53.55C8.342 41.194 2.523 25.57.029 7.11a2.406 2.406 0 012.006-2.695L26.69.43a2.365 2.365 0 012.522 1.29l9.546 18.72a2.377 2.377 0 01-.602 2.924L26.89 32.88c4.759 8.915 16.684 27.061 27.836 28.724l9.144-10.234a2.38 2.38 0 012.724-.602l17.916 7.568a2.39 2.39 0 011.462 2.465l-2.38 22.676c-.114 1.175-1.088 2.092-2.292 2.15-.086-.03-.46-.03-1.032-.03zM5.13 8.772c2.552 16.598 7.912 30.674 15.996 41.825 6.65 9.23 15.423 16.741 25.6 21.844a80.186 80.186 0 0032.307 8.342l1.978-18.748-14.677-6.192-8.801 9.861a2.416 2.416 0 01-1.92.803c-17.545-.86-33.225-31.849-33.885-33.167-.487-1.004-.258-2.208.602-2.924l11.266-9.518-7.854-15.451L5.13 8.772z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgPhone = _style(SvgIcon)

export default SvgPhone
