import { ApplicationType } from '../entities/pharmacy-visit/entity'

type EventPayload =
  | {
      event: 'sign_up'
    }
  | {
      event: 'login'
    }
  | {
      event: 'verify'
    }
  | {
      event: 'profile_registered'
    }
  | {
      event: 'select_patient'
      pharmacy_visit_uid: string
      application_type: ApplicationType
      pharmacy_uid: string
      pharmacy_name: string
    }
  | {
      event: 'upload_prescription'
      pharmacy_visit_uid: string
      application_type: ApplicationType
      pharmacy_uid: string
      pharmacy_name: string
    }
  | {
      event: 'apply_pharmacy_visit'
      pharmacy_visit_uid: string
      application_type: ApplicationType
      pharmacy_uid: string
      pharmacy_name: string
      place: string
    }
  | {
      event: 'click_induction_banner_to_koala-web'
      patient_uid?: string
    }

export const trackEvent = (payload: EventPayload) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(payload)
  }
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any[]
  }
}
