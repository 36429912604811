import { useCallback } from 'react'
import {
  FetchOption,
  useFetch as _useFetch,
} from 'src/modules/apis/hooks/use-fetch'
import { fetch, Params } from './request'

export const useFetch = (
  params: Params,
  options?: FetchOption<ResolvedType<ReturnType<typeof fetch>>>,
) => {
  const { code } = params

  const request = useCallback(() => {
    return fetch({
      code,
    })
  }, [code])

  return _useFetch(request, options)
}
