import React, { FC } from 'react'

import { Image } from 'src/modules/components/lib/image'
import { Link } from 'src/modules/components/lib/link'
import { Text } from 'src/modules/components/lib/text'
import { View, Flexbox } from 'src/modules/components/lib/view'
import { getSearchPagePath } from 'src/modules/helpers/get-serch-page-path'
import { Spacer } from 'src/modules/screens/top/spacer'

export type SearchChain = {
  slug: string
  name: string
  imagePath?: string
}

export const mainSearchChain: SearchChain[] = [
  {
    slug: 'matsukiyo',
    name: 'マツモトキヨシ',
  },
  {
    slug: 'welcia',
    name: 'ウエルシア',
    imagePath: '/search/welcia.png',
  },
  {
    slug: 'cocokarafine',
    name: 'ココカラ\nファイン',
    imagePath: '/search/cocokara.png',
  },
  {
    slug: 'hanshin',
    name: 'I&H\n（阪神調剤G）',
    imagePath: '/search/i_and_h_logo.svg',
  },
  {
    slug: 'qol',
    name: 'クオール薬局',
    imagePath: '/search/qol.png',
  },
  {
    slug: 'aeon',
    name: 'イオン薬局',
  },
  {
    slug: 'unismile',
    name: 'ユニスマイル',
    imagePath: '/search/unismile.svg',
  },
  {
    slug: 'cosmopharma',
    name: 'コスモファーマ',
    imagePath: '/search/cosmopharma.jpg',
  },
  {
    slug: 'yakuju',
    name: '薬樹',
    imagePath: '/search/yakuju.png',
  },
]

export const Chain: FC = () => {
  return (
    <View width="100%">
      <Text fontSize="large" fontWeight="bold">
        ドラッグストア・薬局チェーンから探す
      </Text>
      <Spacer size={14} />
      <Flexbox
        display="grid"
        gridTemplateColumns="repeat(3, 1fr)"
        style={{ columnGap: '20px', rowGap: '12px' }}
      >
        {mainSearchChain.map(chain => (
          <ImageAndTextButton chain={chain} />
        ))}
      </Flexbox>
    </View>
  )
}

export const ImageAndTextButton: FC<{ chain: SearchChain }> = ({ chain }) => {
  const path = getSearchPagePath({
    chainCode: chain.slug,
  })

  return (
    <Link
      display="flex"
      flexDirection="column"
      width="100%"
      height="90px"
      alignItems="center"
      justifyContent="center"
      backgroundColor="white"
      padding="4px"
      style={{
        borderRadius: '4px',
        boxShadow: '0px 2px 7px rgba(111, 111, 111, 0.15)',
      }}
      to={path}
    >
      {chain.imagePath && (
        <Image
          src={chain.imagePath}
          alt={chain.name}
          width="80px"
          height="50px"
          style={{ objectFit: 'contain' }}
        ></Image>
      )}
      <Text
        textAlign="center"
        color="black"
        style={{
          fontWeight: 600,
          fontSize: '12px',
          whiteSpace: 'pre-line',
        }}
      >
        {chain.name}
      </Text>
    </Link>
  )
}
