import React, { FC, useCallback } from 'react'
import { ReactComponent as PharmPinOff } from 'src/assets/search/pharm_pin_off.svg'
import { ReactComponent as PharmPinOn } from 'src/assets/search/pharm_pin_on.svg'
import { Clickable } from 'src/modules/components/lib/clickable'
import { Pharmacy } from 'src/modules/entities/pharmacy/entity'

type Props = {
  pharmacy: Pharmacy
  onClick: ({ pharmacy, index }: { pharmacy: Pharmacy; index: number }) => void
  selected: boolean
  index: number
}

export const PharmacyPin: FC<Props> = ({
  pharmacy,
  onClick,
  selected,
  index,
}) => {
  const handleMarkerClick = useCallback(() => {
    onClick({ pharmacy, index })
  }, [index, onClick, pharmacy])
  return (
    <Clickable
      onClick={handleMarkerClick}
      height="66px"
      width="50px"
      style={{ transform: 'translate(-50%, -100%)', position: 'absolute' }}
    >
      {selected ? <PharmPinOn /> : <PharmPinOff />}
    </Clickable>
  )
}
