import { Calendar } from 'src/modules/entities/calendar/entity'

export type CalendarResponse = {
  uid: string
  last_name: string
  first_name: string
}

export const parse = (resp: CalendarResponse): Calendar => {
  const res = {
    uid: resp.uid,
    lastName: resp.last_name,
    firstName: resp.first_name,
  }

  return res
}
