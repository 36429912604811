import { Profile } from 'src/modules/entities/profile/entity'

export type ProfileResponse = {
  uid: string
  lastname: string
  firstname: string
  lastname_j: string | null
  firstname_j: string | null
  phone: string
  gender: number
  birthday: string | null
  age: number | null
  postal: string | null
  prefecture: string | null
  city: string | null
  district: string | null
  building: string | null
  avatar_url: string | null
}

export const parse = (resp: ProfileResponse): Profile => {
  const res = {
    uid: resp.uid,
    lastname: resp.lastname,
    firstname: resp.firstname,
    lastnameJ: resp.lastname_j,
    firstnameJ: resp.firstname_j,
    phone: resp.phone,
    gender: resp.gender,
    birthday: resp.birthday,
    age: resp.age,
    postal: resp.postal,
    prefecture: resp.prefecture,
    city: resp.city,
    district: resp.district,
    building: resp.building,
    avatarUrl: resp.avatar_url,
  }

  return res
}
