import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_288_3342)">
        <rect
          width="20"
          height="20"
          transform="translate(0.25 0.571411)"
          fill="white"
        />
        <rect
          x="0.25"
          y="0.571411"
          width="20"
          height="20"
          rx="4"
          fill={props.color || '#2BA168'}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2254 6.471C16.5915 6.83712 16.5915 7.43071 16.2254 7.79682L9.35041 14.6718C8.9843 15.0379 8.3907 15.0379 8.02459 14.6718L4.27459 10.9218C3.90847 10.5557 3.90847 9.96212 4.27459 9.596C4.6407 9.22988 5.2343 9.22988 5.60041 9.596L8.6875 12.6831L14.8996 6.471C15.2657 6.10488 15.8593 6.10488 16.2254 6.471Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_288_3342">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.25 0.571411)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  any,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgCheckbox2 = _style(SvgIcon)

export default SvgCheckbox2
