import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import styled, { keyframes } from 'styled-components'
import { FixedZIndex } from 'src/modules/const'
import { Spinner } from './spinner'
import { Text } from './text'
import { Flexbox, ViewProps } from './view'

export const useBlocker = () => {
  return useContext(BlockerContext)
}

export const BlockerContainer: PropsWithChildren<FC> = ({ children }) => {
  const [visible, setVisible] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')

  const showBlocker = useCallback(
    (message: string) => {
      setVisible(true)
      setMessage(message)
    },
    [setVisible, setMessage],
  )
  const hideBlocker = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  return (
    <BlockerContext.Provider
      value={{ visible, message, showBlocker, hideBlocker }}
    >
      {children}
      <Blocker visible={visible} message={message} />
    </BlockerContext.Provider>
  )
}

type BlockerContextValue = {
  visible: boolean
  message: string
  showBlocker: (message: string) => void
  hideBlocker: () => void
}

const BlockerContext = React.createContext<BlockerContextValue>({
  visible: false,
  message: '',
  showBlocker: () => null,
  hideBlocker: () => null,
})

type BlockerProps = {
  visible: boolean
  message: string
} & ViewProps

const Blocker: FC<BlockerProps> = ({ visible, message, ...props }) => {
  const [initialRender, setInitialRender] = useState(true)

  useEffect(() => {
    if (visible) {
      setInitialRender(false)
    }
  }, [visible])

  const visibleClass = initialRender ? '' : visible ? 'visible' : 'invisible'

  return (
    <BlockerRoot className={`${visibleClass}`} {...props}>
      <Flexbox
        py="20px"
        minWidth="140px"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        backgroundColor="white"
        borderRadius="5px"
      >
        <Spinner spinnerSize="60px" />
        <Text textAlign="center" mt="8px" fontSize="large">
          {message}
        </Text>
      </Flexbox>
    </BlockerRoot>
  )
}

const ToVisibleKeyFrames = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const ToInvisibleKeyFrames = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
`

const BlockerRoot = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  text-align: center;
  z-index: ${FixedZIndex.blocker};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
  visibility: hidden;
  pointer-events: none;

  &.visible {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;

    animation: ${ToVisibleKeyFrames} 100ms ease-out 0s;
  }

  &.invisible {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;

    animation: ${ToInvisibleKeyFrames} 100ms ease-out 0s;
    animation-fill-mode: forwards;
  }
`
