import {
  PharmacyResponse,
  parse as parsePharmacy,
} from 'src/modules/apis/pharmacy/response'
import {
  ApplicationType,
  CompletedBy,
  PharmacyVisit,
  PlaceToReceiveService,
} from 'src/modules/entities/pharmacy-visit/entity'
import {
  parse as parseCalendarResponse,
  CalendarResponse,
} from '../calendar/response'
import {
  CallRecordResponse,
  parse as parseCallRecordResponse,
} from '../call-record/response'
import {
  CurrentSettlementRecordResponse,
  parse as parseCurrentSettlementRecordResponse,
} from '../current-settlement-record/response'
import {
  parse as parseDeliveryRecordResponse,
  DeliveryRecordResponse,
} from '../delivery-record/response'
import {
  parse as parseElectronicPrescriptionResponse,
  ElectronicPrescriptionResponse,
} from '../electronic-prescription/response'
import {
  IdDocumentResponse,
  parse as parseIdDocument,
} from '../id_document/response'
import {
  parse as parsePatientResponse,
  PatientResponse,
} from '../patient/response'
import {
  PharmacyVisitAttachmentResponse,
  parse as parseAttachment,
} from '../pharmacy-visit-attachment/response'
import {
  parse as parsePrescriptionImageResponse,
  PrescriptionImageResponse,
} from '../prescription-image/response'
import {
  parse as parsePrescriptionNotebookImageResponse,
  PrescriptionNotebookImageResponse,
} from '../prescription-notebook-image/response'
import {
  QuestionnaireAnswerResponse,
  parse as questionnaireAnswerParse,
} from '../questionnaire-answer/response'
import {
  ReservationCandidateResponse,
  parse as reservationCandidateParse,
} from '../reservation-candidate/response'
import {
  parse as parseSettlementRecordResponse,
  SettlementRecordResponse,
} from '../settlement-record/response'
import {
  parse as parseTwilioMessageResponse,
  TwilioMessageResponse,
} from '../twilio-message/response'

export type PharmacyVisitResponse = {
  uid: string
  doctor_visit_uid: string
  patient: PatientResponse | null
  pharmacy_uid: string
  application_type: ApplicationType
  call_records: CallRecordResponse[]
  settlement_records: SettlementRecordResponse[]
  current_settlement_record: CurrentSettlementRecordResponse | null
  delivery_record?: DeliveryRecordResponse
  twilio_messages?: TwilioMessageResponse[]
  comment: string | null
  insurance_card_image_url: string | null
  insurance_card: IdDocumentResponse | null
  claimant_certification: IdDocumentResponse | null
  visited_at: string | null
  completed_at: string | null
  created_at: string
  stripe_payment_method_id: string | null
  reserved_calendar: null | CalendarResponse
  reserved_frame_uid: string | null
  reservation_started_at: string | null
  reservation_finished_at: string | null
  prescription_notebook_images?: PrescriptionNotebookImageResponse[]
  prescription_images: PrescriptionImageResponse[]
  questionnaire_answer?: QuestionnaireAnswerResponse
  electronic_prescription?: ElectronicPrescriptionResponse
  reservation_candidates?: ReservationCandidateResponse[] // リクエスト予約廃止に伴い空配列となる
  place_to_receive_service: PlaceToReceiveService | null
  medicine_dispensed_at: string | null
  medicine_passed_at: string | null
  medicine_receive_applied_at: string | null
  from_curon: boolean
  pharmacy: PharmacyResponse
  attachments?: PharmacyVisitAttachmentResponse[]
  message: string | null
  application_completed_at: string | null
  recently_visited: boolean | null
  canceled_at: string | null
  completed_by: CompletedBy | null
  usage_number: string | null
}

export const parse = (resp: PharmacyVisitResponse): PharmacyVisit => {
  return {
    uid: resp.uid,
    doctorVisitUid: resp.doctor_visit_uid,
    patient: resp.patient ? parsePatientResponse(resp.patient) : null,
    pharmacyUid: resp.pharmacy_uid,
    applicationType: resp.application_type,
    callRecords: resp.call_records.map(parseCallRecordResponse),
    settlementRecords: resp.settlement_records.map(
      parseSettlementRecordResponse,
    ),
    currentSettlementRecord: resp.current_settlement_record
      ? parseCurrentSettlementRecordResponse(resp.current_settlement_record)
      : null,
    deliveryRecord:
      resp.delivery_record && parseDeliveryRecordResponse(resp.delivery_record),
    twilioMessages: resp.twilio_messages
      ? resp.twilio_messages.map(parseTwilioMessageResponse)
      : [],
    comment: resp.comment,
    insuranceCardImageUrl: resp.insurance_card_image_url,
    insuranceCard: resp.insurance_card
      ? parseIdDocument(resp.insurance_card)
      : null,
    claimantCertification: resp.claimant_certification
      ? parseIdDocument(resp.claimant_certification)
      : null,
    visitedAt: resp.visited_at,
    completedAt: resp.completed_at,
    createdAt: resp.created_at,
    stripePaymentMethodId: resp.stripe_payment_method_id,
    reservedCalendar: resp.reserved_calendar
      ? parseCalendarResponse(resp.reserved_calendar)
      : null,
    reservedFrameUid: resp.reserved_frame_uid,
    reservationStartedAt: resp.reservation_started_at,
    reservationFinishedAt: resp.reservation_finished_at,
    prescriptionNotebookImages: resp.prescription_notebook_images
      ? resp.prescription_notebook_images.map(
          parsePrescriptionNotebookImageResponse,
        )
      : [],
    prescriptionImages: resp.prescription_images
      ? resp.prescription_images.map(parsePrescriptionImageResponse)
      : [],
    questionnaireAnswer: resp.questionnaire_answer
      ? questionnaireAnswerParse(resp.questionnaire_answer)
      : null,
    electronicPrescription: parseElectronicPrescriptionResponse(
      resp.electronic_prescription,
    ),
    reservationCandidates: resp.reservation_candidates // リクエスト予約廃止に伴い空配列となる
      ? resp.reservation_candidates.map(reservationCandidateParse)
      : [],
    placeToReceiveService: resp.place_to_receive_service,
    medicineDispensedAt: resp.medicine_dispensed_at,
    medicinePassedAt: resp.medicine_passed_at,
    medicineReceiveAppliedAt: resp.medicine_receive_applied_at,
    fromCuron: resp.from_curon,
    pharmacy: parsePharmacy(resp.pharmacy),
    attachments: resp.attachments ? resp.attachments.map(parseAttachment) : [],
    message: resp.message,
    applicationCompletedAt: resp.application_completed_at,
    recentlyVisited: resp.recently_visited,
    canceledAt: resp.canceled_at,
    completedBy: resp.completed_by,
    usageNumber: resp.usage_number,
  }
}
