type param = {
  chainCode?: string
  prefecture?: string
  stationCode?: string
  areaSlug?: string
  keyword?: string
  near?: boolean
  selectedDay?: string
}

export const getSearchPagePath = ({
  areaSlug,
  chainCode,
  prefecture,
  stationCode,
  keyword,
  selectedDay = '0',
  near,
}: param) => {
  let path = '/search'

  if (near) path += '/near'

  if (keyword && !prefecture && !areaSlug && !stationCode && !chainCode) {
    path += `/list?keyword=${keyword}&selected_day=${selectedDay}`
    return path
  }

  if (prefecture === 'all') {
    if (!!chainCode) {
      path += `/chain/${chainCode}`
    }
    path += `/list?selected_day=${selectedDay}`
    return path
  }

  if (prefecture && prefecture !== 'current_location')
    path += `/pref/${prefecture}`

  if (areaSlug && !stationCode) path += `/area/${areaSlug}`
  if (stationCode && !areaSlug) path += `/station/${stationCode}`

  if (chainCode && !keyword) path += `/chain/${chainCode}`

  if (keyword && !chainCode) {
    path += `?keyword=${keyword}&selected_day=${selectedDay}`
  } else {
    path += `?selected_day=${selectedDay}`
  }
  return path
}
