import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestPost } from 'src/modules/server/request'

export const submit = async (
  campaignCode: string,
): Promise<{ redirectUrl: string } | undefined> => {
  const resp = await requestPost<{ redirect_url: string }>({
    url: `${PHARMACY_API_URL}/patients/entry_campaigns.json`,
    params: { campaign_code: campaignCode },
  })

  return resp.data ? { redirectUrl: resp.data.redirect_url } : undefined
}
