import { useLocation, useParams } from 'react-router-dom'
import { PREFECTURE_LIST } from '../constants/prefecture-list'

type SearchPageParams = {
  prefecture?: string
  areaSlug?: string
  stationCode?: string
  chain?: string
}

export const useSearchQuery = () => {
  const location = useLocation()
  const params = useParams() as SearchPageParams
  const searchParams = new URLSearchParams(location.search)
  const encodedKeyword = searchParams.get('keyword')
  const keyword = encodedKeyword ? decodeURI(encodedKeyword) : null
  const prefValue = params.prefecture
  const prefObject = PREFECTURE_LIST.find(p => p.value === prefValue)
  const prefecture = prefObject ? prefObject : null
  const area = params.areaSlug
  const station = params.stationCode
  const chain = params.chain
  const currentPage = Number(searchParams.get('page') || 1)
  const selectedDay = searchParams.get('selected_day') || '0'
  // const conditions = (searchParams.get('conditions') || '')
  //   .split(',')
  //   .filter(s => s)
  return {
    keyword,
    prefecture,
    area,
    station,
    currentPage,
    chain,
    selectedDay,
    // conditions,
  }
}
