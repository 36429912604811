import React, { FC } from 'react'

import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { ReactComponent as HealthImage } from 'src/assets/home-health.svg'
import { ReactComponent as PillImage } from 'src/assets/icon-pill.svg'
import { Button } from 'src/modules/components/lib/button'
import { Link } from 'src/modules/components/lib/link'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox, View, ViewProps } from 'src/modules/components/lib/view'
import { KOALA_WEB_URL, FixedZIndex, HEADER_HEIGHT } from 'src/modules/const'
import { trackEvent } from 'src/modules/ga/track-event'
import { RootState } from 'src/modules/reducer'
import { routes } from 'src/modules/routes'
import { Spacer } from 'src/modules/screens/top/spacer'

type Props = {
  largeHeader?: boolean
}

export const MutualInduction: FC<Props> = ({ largeHeader }) => {
  const koalaWebSearchTopPath = `${KOALA_WEB_URL}/clinic/search?first_visit=true&not_need_clinic_code=true&current_location=true`
  const authorizedUser = useSelector((state: RootState) => state.auth.account)!

  const onAccessKoalaWeb = () => {
    trackEvent({
      event: 'click_induction_banner_to_koala-web',
      patient_uid: authorizedUser.uid,
    })
  }

  return (
    <MutualInductionRoot
      borderTop="solid 1px  #DEE5ED"
      borderBottom="solid 1px #DEE5ED"
      minHeight="55px"
      position={largeHeader ? 'sticky' : 'fixed'}
      background="#fff"
      top={largeHeader ? '60px' : HEADER_HEIGHT}
      width="100%"
      zIndex={FixedZIndex.header}
      largeHeader={!!largeHeader}
    >
      <a href={koalaWebSearchTopPath} style={{ width: '50%' }}>
        <Button
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '0',
            background: 'white',
            boxShadow: 'none',
          }}
        >
          <Flexbox alignItems="center">
            <HealthImage />
            <Spacer horizontal size={8} />
            <Text fontSize="14px" fontWeight="bold" color="ratGray">
              オンライン診療
            </Text>
          </Flexbox>
        </Button>
      </a>
      <View
        style={{
          width: '1px',
          height: '18px',
          marginTop: '17px',
          background: '#DEE5ED',
        }}
      />
      <Link to={routes.search.path} width="50%">
        <Button
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '0',
            borderBottom: 'solid 3px #4DB27F',
            background: 'white',
            boxShadow: 'none',
          }}
          onClick={onAccessKoalaWeb}
        >
          <Flexbox alignItems="center">
            <PillImage />
            <Spacer horizontal size={8} />
            <Text fontSize="14px" fontWeight="bold" color="dsGreen.600">
              処方箋送信
            </Text>
          </Flexbox>
        </Button>
      </Link>
    </MutualInductionRoot>
  )
}

const MutualInductionRoot = styled(Flexbox)<{ largeHeader: boolean }>`
  @media (min-width: 560px) {
    top: ${props => (props.largeHeader ? '70px ' : `${HEADER_HEIGHT}px`)};
  }
`
