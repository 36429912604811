import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M84.593 25.371a2.889 2.889 0 00-2.788-.134l-18.796 9.406v-6.837c0-4.753-3.847-8.6-8.6-8.6H14.28a8.595 8.595 0 00-8.6 8.6v34.4c0 4.754 3.847 8.6 8.6 8.6H54.41c4.753 0 8.6-3.846 8.6-8.6V55.37l18.779 9.407a2.873 2.873 0 003.846-1.277 2.87 2.87 0 00.303-1.294v-34.4a2.822 2.822 0 00-1.344-2.435zM57.28 62.206a2.871 2.871 0 01-2.872 2.873H14.28a2.871 2.871 0 01-2.872-2.872v-34.4a2.87 2.87 0 012.872-2.873H54.41a2.87 2.87 0 012.872 2.872v34.4zm22.928-4.635l-17.2-8.6v-7.928l17.2-8.6V57.57z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgVideoOn = _style(SvgIcon)

export default SvgVideoOn
