import { DeliveryRequest } from 'src/modules/entities/delivery-request/entity'
import { YamatoDeliveryType } from 'src/modules/entities/enums/yamato-delivery-type'

export type DeliveryRequestResponse = {
  uid: string
  delivery_agent: string
  delivery_type: string
  delivery_specified_date: string
  delivery_specified_time: string
}

export const parse = (resp: DeliveryRequestResponse): DeliveryRequest => {
  const res = {
    uid: resp.uid,
    deliveryAgent: resp.delivery_agent,
    deliveryType: resp.delivery_type as YamatoDeliveryType,
    deliverySpecifiedDate: resp.delivery_specified_date,
    deliverySpecifiedTime: resp.delivery_specified_time,
  }

  return res
}
