import { PHARMACY_API_URL } from 'src/modules/server/const'
import { requestGet } from 'src/modules/server/request'
import { StationResponse } from '../response'

export type Params = {
  chainCode?: string
}

export const fetch = async (params: Params) => {
  const resp = await requestGet<StationResponse[]>({
    url: `${PHARMACY_API_URL}/patients/pharmacies/stations/recommend_stations.json`,
    params: {
      chain_code: params.chainCode,
    },
  })

  return resp.data
}
