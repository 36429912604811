import React, { FC } from 'react'
import { Card } from 'src/modules/components/lib/card'
import { Footer } from 'src/modules/components/lib/footer'
import { Logo } from 'src/modules/components/lib/logo'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox, View } from 'src/modules/components/lib/view'
import { AppState } from 'src/modules/entities/app-state/entity'

type Props = { appState?: AppState }

export const MaintenanceScreen: FC<Props> = ({ appState }) => {
  const content = (appState?.message || '')
    .split(/\n/g)
    .map((text: string, index: number) => (
      <Text key={`body-content-${index}`}>{text || <br />}</Text>
    ))

  return (
    <Flexbox
      flexDirection="column"
      backgroundColor="whiteGray"
      minHeight="100vh"
    >
      <Flexbox
        p={3}
        flexGrow={1}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Card maxWidth="640px" padding="65px 15px">
          <View textAlign="center">
            <Logo />
          </View>
          <Text textAlign="center" mt="35px" lineHeight="30px">
            現在メンテナンス中です。
            <br />
            しばらく時間をおいてから、再度アクセスしてください。
          </Text>
          <Text textAlign="center" mt="15px">
            {content}
          </Text>
        </Card>
      </Flexbox>
      <Footer py="16" />
    </Flexbox>
  )
}
