import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'

import { useSWRFetch as useChainFetch } from 'src/modules/apis/chain/show/use-fetch'
import { useSWRFetch as useLatestPharmacies } from 'src/modules/apis/pharmacy/latest/use-fetch'

import { useSWRFetch as usePharmacySearchFetch } from 'src/modules/apis/pharmacy/search/use-fetch'
import { useAuthorizationRefresh } from 'src/modules/auth/use-authorization-refresh'
import { Loader } from 'src/modules/components/lib/loader'
import { useToast } from 'src/modules/components/lib/toast'
import { TOKYO_STATION_POSITION } from 'src/modules/constants/tokyo_station_position'
import { useGeoPosition } from 'src/modules/geo-location'
import { useSearchQuery } from 'src/modules/hooks/use-search-query'
import { useSWRChainCodeState } from 'src/modules/hooks/useSWRChainCodeState'
import { routes } from 'src/modules/routes'
import {
  useDeprecatedSearchQueryGuard,
  useDeprecatedPageQueryGuard,
  useSearchMeta,
} from '../helper'
import { getJsonLd } from '../jsonld'
import { NetApplicationChainSearchTopScreen } from './screen'

export const NetApplicationChainTopSearchScreenContainer = () => {
  useDeprecatedSearchQueryGuard()
  useDeprecatedPageQueryGuard()

  const { authorized, fetched } = useAuthorizationRefresh()
  const { setChain } = useSWRChainCodeState()
  const { showToast } = useToast()
  const [geoApproved, setGeoApproved] = useState(true)
  const {
    chain: chainCode,
    // conditions,
  } = useSearchQuery()

  useEffect(() => {
    // 地図検索の際にulrにchainを持たせられないため、グローバルステートに保持する
    setChain(chainCode)
  }, [chainCode, setChain])

  const { data, loading } = useLatestPharmacies({
    chainCode: chainCode!,
  })

  const { data: chain } = useChainFetch({
    code: chainCode,
  })

  const { title: metaTitle, description: metaDescription } = useSearchMeta({
    pageType: 'search_chain',
    chain: chain,
    pharmaciesCount: data?.count,
  })

  const {
    data: currentPosition,
    isApproved: isGeoApproved,
    isCompleted,
  } = useGeoPosition({
    onCompleted: () => {
      if (!isGeoApproved) {
        showToast('位置情報の取得に失敗しました。', true)
        setGeoApproved(false)
        return
      }
    },
  })
  const lat = currentPosition
    ? currentPosition.coords.latitude
    : TOKYO_STATION_POSITION.lat
  const lng = currentPosition
    ? currentPosition.coords.longitude
    : TOKYO_STATION_POSITION.lng

  const isFetchingCurrentPosition: boolean = useMemo(
    () => geoApproved && (!isCompleted || !currentPosition),
    [isCompleted, geoApproved, currentPosition],
  )

  const {
    data: nearPharmacy,
    loading: pharmacyIndexFetching,
    trigger,
  } = usePharmacySearchFetch({
    params: {
      keyword: null,
      prefecture: null,
      area: null,
      station: null,
      currentLocation: true,
      lat: lat,
      lng: lng,
      chain: chainCode,
      page: null,
    },
    skip: isFetchingCurrentPosition,
    options: {
      onComplete: data => {
        if (data.pharmacies.length === 0) {
          showToast('検索結果がありませんでした。', true)
        }
      },
    },
  })

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />

        <link
          rel="canonical"
          href={`https://pha.curon.co${routes.searchChain.build(chain!.code)}`}
        />
        <meta name="robots" content="index,follow" />
        <script type="application/ld+json">
          {JSON.stringify(
            getJsonLd({ pageType: 'search_chain', chain: chain }),
          )}
        </script>
      </Helmet>

      <NetApplicationChainSearchTopScreen
        chain={chain!}
        latestPharmacies={data?.pharmacies || []}
        nearPharmacies={nearPharmacy?.pharmacies || []}
        prefectureCount={data?.prefectureCount}
        authorized={authorized}
        fetched={fetched}
        loading={loading}
        nearPharmaciesLoading={pharmacyIndexFetching}
        currentPosition={currentPosition}
        isGeoApproved={!!isGeoApproved}
      />
    </>
  )
}
