import Drawer from '@bit/mui-org.material-ui.drawer'
import IconButton from '@bit/mui-org.material-ui.icon-button'
import React, { FC, useState } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import styled from 'styled-components'
import { ReactComponent as CloseIcon } from 'src/assets/top/drawer-close.svg'
import { ReactComponent as HamburgerIcon } from 'src/assets/top/drawer-hamburger.svg'
import { Button, LinkButton } from 'src/modules/components/lib/button'
import { Link, Anchor, AnchorProps } from 'src/modules/components/lib/link'
import { Logo } from 'src/modules/components/lib/logo'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox, View } from 'src/modules/components/lib/view'
import { useIdPlatform } from 'src/modules/hooks/useIdPlatform'
import { routes } from 'src/modules/routes'

type HeaderProps = {
  fetched: boolean
  authorized: boolean
}

export const Header: FC<HeaderProps> = ({ fetched, authorized }) => {
  const [isActive, setActiveState] = useState(false)

  const onClickMenuLink = React.useCallback(() => {
    setActiveState(!isActive)
  }, [setActiveState, isActive])

  return (
    <>
      <HeaderRoot>
        <HeaderBox>
          <Flexbox flexDirection="row" flexWrap="nowrap" alignItems="center">
            <Anchor href={routes.index.path}>
              <ResponsiveLogo withoutServiceName />
            </Anchor>

            <HeaderLinksContainer>
              <StyledLink
                to={routes.search.path}
                rel="noopener noreferrer"
                target="_blank"
              >
                <NoWrapText color="white" mx={2}>
                  薬局を探す
                </NoWrapText>
              </StyledLink>
              <StyledAnchorLink href="#usage" offset="30">
                <NoWrapText color="white" mx={2}>
                  ご利用の流れ
                </NoWrapText>
              </StyledAnchorLink>
              <StyledAnchor
                href="https://micin.force.com/FAQforPatients/s/topic/0TO7F000000t8HtWAI/%E5%87%A6%E6%96%B9[…]3%82%AF%E3%83%AD%E3%83%B3%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6"
                rel="noopener noreferrer"
                target="_blank"
              >
                <NoWrapText color="white" mx={2}>
                  よくある質問
                </NoWrapText>
              </StyledAnchor>
            </HeaderLinksContainer>
          </Flexbox>

          <RightSideButtonsContainer>
            <HiddenOnSmallSizeSP>
              {fetched && <LoginButton authorized={authorized} />}
            </HiddenOnSmallSizeSP>

            <HiddenOnPC>
              <DrawerIconButton
                isActive={isActive}
                onClickDrawerIcon={onClickMenuLink}
              />
            </HiddenOnPC>
          </RightSideButtonsContainer>
        </HeaderBox>
      </HeaderRoot>

      <HiddenOnPC>
        <StyledDrawer anchor="top" open={isActive} onClose={onClickMenuLink}>
          {fetched && (
            <DrawerContent
              authorized={authorized}
              onClickMenuLink={onClickMenuLink}
            />
          )}
        </StyledDrawer>
      </HiddenOnPC>
    </>
  )
}

const DrawerIconButton: FC<{
  onClickDrawerIcon(): void
  isActive: boolean
}> = React.memo(({ onClickDrawerIcon, isActive }) => (
  <IconButton
    style={{
      width: '32px',
      height: '24px',
      padding: 0,
      borderRadius: 0,
    }}
    onClick={() => onClickDrawerIcon()}
    disableRipple
  >
    {isActive ? <CloseIcon /> : <HamburgerIcon />}
  </IconButton>
))

const MenuLinksContainer = styled(Flexbox)`
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
  height: 92px;
`

const MenuContentContainer = styled(Flexbox)`
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 60px;
  height: 130px;
  background: rgba(76, 175, 124, 0.9);
  box-shadow: 0px 4px 10px rgba(49, 53, 65, 0.2);

  @media (max-width: 320px) {
    height: 216px;
  }
`

const DrawerContent: FC<{
  authorized: boolean
  onClickMenuLink(): void
}> = React.memo(({ onClickMenuLink, authorized }) => {
  return (
    <MenuContentContainer>
      <MenuLinksContainer>
        <StyledLink
          to={routes.search.path}
          rel="noopener noreferrer"
          target="_blank"
          onClick={() => onClickMenuLink()}
        >
          <NoWrapText color="white" mx={2}>
            薬局を探す
          </NoWrapText>
        </StyledLink>
        <StyledAnchorLink
          href="#usage"
          onClick={() => onClickMenuLink()}
          offset="30"
        >
          <NoWrapText color="white" mx={2}>
            ご利用の流れ
          </NoWrapText>
        </StyledAnchorLink>
        <StyledAnchor
          href="https://micin.force.com/FAQforPatients/s/topic/0TO7F000000t8HtWAI/%E5%87%A6%E6%96%B9[…]3%82%AF%E3%83%AD%E3%83%B3%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6"
          rel="noopener noreferrer"
          target="_blank"
          onClick={() => onClickMenuLink()}
        >
          <NoWrapText color="white" mx={2}>
            よくある質問
          </NoWrapText>
        </StyledAnchor>
      </MenuLinksContainer>

      <HiddenOnNormalSizeSPOrHigher>
        <LoginButton authorized={authorized} />
      </HiddenOnNormalSizeSPOrHigher>
    </MenuContentContainer>
  )
})

const HeaderRoot = styled(View)`
  position: sticky;
  top: 0;
  z-index: 1500;
  background: ${({ theme }) => theme.colors.seaGreen};
  height: 70px;
  padding: 18px;

  @media (max-width: 560px) {
    height: 60px;
    padding: 21px;
  }
`

const HeaderBox = styled(View)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: 100%;

  @media (min-width: 768px) {
    width: 75vw;
    max-width: 960px;
  }
`

const ResponsiveLogo = styled(Logo)`
  svg {
    width: 120px;
    @media (max-width: 560px) {
      width: 110px;
    }
  }
`

const HeaderLinksContainer = styled(Flexbox)`
  justify-content: space-between;
  align-items: center;
  margin-left: 28px;
  max-width: 200px;

  @media (max-width: 662px) {
    display: none;
  }
`

const RightSideButtonsContainer = styled(Flexbox)`
  flex-flow: row nowrap;
  align-items: center;
  min-width: 168px;
  justify-content: flex-end;

  @media (max-width: 320px) {
    min-width: initial;
  }

  @media (min-width: 321px) and (max-width: 767px) {
    justify-content: space-between;
    min-width: 168px;
  }
`

const NoWrapText = styled(Text)`
  white-space: nowrap;
`

const StyledAnchor = styled(Anchor)<AnchorProps>`
  color: white;
  font-family: 'Hiragino Sans';
  font-weight: 300;
  font-size: 14px;
  @media (max-width: 560px) {
    font-size: 12px;
  }

  &:hover {
    font-weight: 600;
  }
`

const StyledAnchorLink = styled(AnchorLink)`
  color: white;
  font-family: 'Hiragino Sans';
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  @media (max-width: 560px) {
    font-weight: 400;
    font-size: 12px;
  }

  &:hover {
    font-weight: 600;
  }
`

const StyledLink = styled(Link)`
  color: white;
  font-family: 'Hiragino Sans';
  font-weight: 300;
  font-size: 14px;
  text-align: center;
  @media (max-width: 560px) {
    font-weight: 400;
    font-size: 12px;
  }

  &:hover {
    font-weight: 600;
  }
`

const LoginButtonBase = styled(Button)`
  width: 114px;
  height: 30px;
  font-family: 'HiraginoSans-W7', sans-serif;
  font-weight: 700;
  font-size: 12px;
  color: #4caf7c;

  @media (max-width: 320px) {
    width: 190px;
    height: 34px;
  }

  @media (min-width: 768px) {
    width: 90px;
    height: 34px;
  }
`
const MyPageButtonBase = LoginButtonBase.withComponent(LinkButton)

type LoginButtonProps = {
  authorized: boolean
}

const LoginButton: FC<LoginButtonProps> = ({ authorized }) => {
  const { redirectToLogin } = useIdPlatform()

  return authorized ? (
    <MyPageButtonBase
      to={routes.home.build()}
      colorStyle="white"
      shape="square"
      sizeStyle="small"
      px={6}
    >
      <NoWrapText>マイページ</NoWrapText>
    </MyPageButtonBase>
  ) : (
    <LoginButtonBase
      colorStyle="white"
      shape="square"
      sizeStyle="small"
      px={6}
      onClick={() => redirectToLogin(routes.home.build())}
    >
      <NoWrapText>ログイン</NoWrapText>
    </LoginButtonBase>
  )
}

const StyledDrawer = styled(Drawer)`
  background: none;
  z-index: 4;
  .MuiBackdrop-root {
    background: none;
  }
  .MuiPaper-root {
    background: none;
  }
  .MuiDrawer-root {
    z-index: 3;
  }
`

const HiddenOnPC = styled(View)`
  @media (min-width: 663px) {
    display: none;
  }
`

const HiddenOnNormalSizeSPOrHigher = styled(View)`
  @media (min-width: 321px) {
    display: none;
  }
`

const HiddenOnSmallSizeSP = styled(View)`
  @media (max-width: 320px) {
    display: none;
  }
`
