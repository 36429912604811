import css from '@styled-system/css'
import React, { FC } from 'react'
import styled from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const styledSystemProps = [typography, space, layout, color, position]

type HeadingProps = StyledSystemProps & React.HTMLAttributes<HTMLDivElement>

export const Heading: FC<HeadingProps> = props => {
  return <HeadingRoot {...props}>{props.children}</HeadingRoot>
}

const HeadingRoot = styled('div')<StyledSystemProps>(
  css({
    fontSize: 'large',
    fontWeight: 'bold',
  }),
  styledSystemProps,
)
