import { PHARMACY_PATIENT_URL } from '../const'

export const routes = {
  onlineMedicationGuidancePatient: {
    path: `${PHARMACY_PATIENT_URL}/online_medication_guidance/:pharmacy_visit_uid/patient`,
    build: (pharmacyVisitUid: string) =>
      `${PHARMACY_PATIENT_URL}/online_medication_guidance/${pharmacyVisitUid}/patient`,
  },

  onlineMedicationGuidanceDeliveryMethod: {
    path: `${PHARMACY_PATIENT_URL}/online_medication_guidance/:pharmacy_visit_uid/medicine_delivery_method`,
    build: (pharmacyVisitUid: string) =>
      `${PHARMACY_PATIENT_URL}/online_medication_guidance/${pharmacyVisitUid}/medicine_delivery_method`,
  },
}
