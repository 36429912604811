import { PrescriptionImage } from 'src/modules/entities/prescription-image/entity'

export type PrescriptionImageResponse = {
  uid: string
  body: string
  trimmed_body: string
  index: number
}

export const parse = (resp: PrescriptionImageResponse): PrescriptionImage => {
  return {
    uid: resp.uid,
    body: resp.body,
    trimmedBody: resp.trimmed_body,
    index: resp.index,
  }
}
