const r = Math.PI / 180

type LatLng = {
  lat: number
  lng: number
}

export const getDistance = ({
  from: { lat: fromLat, lng: fromLng },
  to: { lat: toLat, lng: toLng },
}: {
  from: LatLng
  to: LatLng
}) => {
  const R = 6371 // km
  const dLat = (toLat - fromLat) * r
  const dLng = (toLng - fromLng) * r
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(fromLat * r) *
      Math.cos(toLat * r) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c

  return d
}

export const getDistanceText = (distance: number) => {
  if (distance >= 1) {
    return `${Math.round(distance * 10) / 10} km`
  }
  return `${Math.round(distance * 1000)} m`
}
