import MuiExpansionPanel from '@bit/mui-org.material-ui.expansion-panel'
import MuiExpansionPanelDetails from '@bit/mui-org.material-ui.expansion-panel-details'
import MuiExpansionPanelSummary from '@bit/mui-org.material-ui.expansion-panel-summary'
import IconButton from '@bit/mui-org.material-ui.icon-button'
import { withStyles } from '@bit/mui-org.material-ui.styles'
import React, { FC, useState, ReactNode } from 'react'
import styled from 'styled-components'
import { ReactComponent as Minus } from 'src/assets/top/minus.svg'
import { ReactComponent as Plus } from 'src/assets/top/plus.svg'
import { Link } from 'src/modules/components/lib/link'
import { Text } from 'src/modules/components/lib/text'
import { Flexbox, View } from 'src/modules/components/lib/view'
import { routes } from 'src/modules/routes'

export const FAQ: FC = () => {
  return (
    <View fontFamily="'Noto Sans JP', sans-serif">
      <FaqContentContainer>
        <FaqTitleText>よくある質問</FaqTitleText>
        <Text
          marginBottom="22px"
          fontSize="10px"
          fontWeight="500"
          textAlign="center"
        >
          FAQ
        </Text>

        <Question>
          <QuestionText>クロン処方箋ネット受付ってなんですか？</QuestionText>
          <Answer>
            スマホで撮影した処方箋画像を、事前に全国の薬局に送信・予約できるサービスです。好きな時間に好きな場所で処方薬を受け取ることができます。詳しくはこちら（
            <Link
              to={routes.index.path}
              rel="noopener noreferrer"
              target="_blank"
              color="#1885d9"
            >
              https://pha.curon.co/
            </Link>
            ）
          </Answer>
        </Question>

        <Question>
          <QuestionText>新規登録じゃなくても応募できますか？</QuestionText>
          <Answer>
            はい、すでにクロンへ登録済みの方でもご応募いただけます。
          </Answer>
        </Question>

        <Question>
          <QuestionText>
            Amazonギフト券はいつ、どうやって届きますか？
          </QuestionText>
          <Answer>
            クロン処方箋ネット受付とアンケートご回答の確認が完了した後、メールでお送りいたします。Amazonギフト券コードの送付には最大1カ月程度お待ちいただく場合がございます。
          </Answer>
        </Question>

        <Question>
          <QuestionText>
            近くにネット受付できる薬局がないのですがどうしたらいいですか？
          </QuestionText>
          <Answer>
            大変申し訳ございませんが、本キャンペーンはクロン処方箋ネット受付ご利用の方を対象とさせていただいております。
          </Answer>
        </Question>

        <Question>
          <QuestionText>何度も応募することはできますか？</QuestionText>
          <Answer>キャンペーン期間中、お一人様1回限りとなります。</Answer>
        </Question>

        <Question>
          <QuestionText>
            アンケートはいつ、どうやって回答すればいいですか？
          </QuestionText>
          <Answer>
            エントリー受付完了後に届くメール内にURLの記載がございますため、そちらからご回答ください。
          </Answer>
        </Question>

        <Question>
          <QuestionText>
            エントリー完了のお知らせメールが届かないのですがどうしたら良いですか？
          </QuestionText>
          <Answer>
            お客様のご利用環境により、エントリー完了メールが届かない場合がございます。エントリー完了メールが届かない場合は以下ご確認ください。
            <br />
            (a)迷惑メールフォルダにメールが受信されている可能性がございますのでご確認ください
            <br />
            (b)ご登録のメールアドレスにお間違いがないかご確認いただき、弊社から届くドメイン「@curon.co」を有効にし、再度エントリーをお願いします
          </Answer>
        </Question>
      </FaqContentContainer>
    </View>
  )
}

type Props = {
  children: [React.ReactElement, React.ReactElement]
}

type Props2 = {
  children: ReactNode
}

const Question: FC<Props> = React.memo((props: Props): JSX.Element => {
  const [isActive, setActiveState] = useState(false)

  const onHeaderClicked = () => {
    setActiveState(!isActive)
  }

  return (
    <ExpansionPanel expanded={isActive} onChange={onHeaderClicked}>
      <ExpansionPanelSummary>
        <Flexbox
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Flexbox flexDirection="row" alignItems="center">
            <QuestionIcon />
            {props.children[0]}
          </Flexbox>

          <Button>{isActive ? <Minus /> : <Plus />}</Button>
        </Flexbox>
      </ExpansionPanelSummary>

      {props.children[1]}
    </ExpansionPanel>
  )
})

Question.displayName = 'Question'

const Answer: FC<Props2> = React.memo(
  (props: Props2): JSX.Element => (
    <ExpansionPanelDetails>
      <Flexbox flexDirection="row">
        <AnswerIcon />
        <View width="80%">
          <AnswerText>{props.children}</AnswerText>
        </View>
      </Flexbox>
    </ExpansionPanelDetails>
  ),
)

Answer.displayName = 'Answer'

const ExpansionPanel = withStyles(theme => ({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    background: '#fffcd6',
  },
  expanded: {},
}))(MuiExpansionPanel)

const ExpansionPanelSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    padding: '0 15px 0 24px',
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary)

const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: `0 ${theme.spacing(3)}px`,
  },
}))(MuiExpansionPanelDetails)

const Button = withStyles(theme => ({
  root: {
    padding: `10px`,
  },
}))(IconButton)

const ContentContainer = styled(View)`
  width: 100%;
  max-width: 600px;
  padding: 16px;
  margin: 0 auto;
`
const FaqContentContainer = styled(ContentContainer)`
  max-width: 430px;
  margin: 0 auto;
  padding: 57px 0 36px;
  background: #fffcd6;
  position: relative;
  ::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 9%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    background: url(/cp/campaign202210/about_service_bg_under.png) no-repeat 0 0 /
      contain;
  }
`

const FaqTitleText = styled(Text)`
  font-weight: 700;
  font-size: 20px;
  text-align: center;
`

const QuestionIconBase = styled(Text)`
  color: white;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  line-height: 26px;
  width: 26px;
  height: 26px;
  margin: 0 10px 0 0;
  min-width: 26px;
  min-height: 26px;
  border-radius: 50%;
  background-color: #e9c400;
`

const QuestionIcon: FC = () => <QuestionIconBase>Q</QuestionIconBase>

const AnswerIconBase = styled(Text)`
  color: white;
  font-family: 'Roboto';
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  line-height: 26px;
  width: 26px;
  height: 26px;
  margin: 0 10px 0 0;
  min-width: 26px;
  min-height: 26px;
  border-radius: 50%;
  background-color: #9f8500;
`

const AnswerIcon: FC = () => <AnswerIconBase>A</AnswerIconBase>

const QuestionText = styled(Text)`
  width: 100%;
  transition: 0.1s;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
`

const AnswerText = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  white-space: pre-line;

  span {
    cursor: pointer;
    color: #04a14d;
    text-decoration: underline;
  }
`
