import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#edit_svg__clip0)">
        <path
          d="M85.17 19.35L66.65.833a2.784 2.784 0 00-1.978-.803 3.17 3.17 0 00-1.06.201c-.344.143-.66.344-.918.602L.832 62.695c-.287.286-.516.63-.63 1.003a2.73 2.73 0 00-.173 1.175l1.204 17.315a2.84 2.84 0 00.803 1.777 2.685 2.685 0 001.777.803l17.315 1.204h.2c.746 0 1.463-.287 1.979-.831l61.862-61.834a2.824 2.824 0 00.832-1.978c0-.746-.287-1.462-.832-1.978zM20.24 80.297L6.622 79.35l-.946-13.617L49.45 21.988l14.563 14.563L20.24 80.296zm47.73-47.701L53.406 18.032 64.644 6.795l14.563 14.562-11.238 11.238z"
          fill={props.color || '#000'}
        />
      </g>
      <defs>
        <clipPath id="edit_svg__clip0">
          <path fill="#fff" d="M0 0h86v86H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgEdit = _style(SvgIcon)

export default SvgEdit
