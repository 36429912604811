import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M42.908 62.132c9.255 0 16.797-7.575 16.797-16.898V34.367l-5.828 5.828v5.04c0 6.096-4.922 11.068-10.969 11.068-1.512 0-2.956-.302-4.266-.873l-4.317 4.317a16.555 16.555 0 008.583 2.385zM31.957 45.805c0-.151-.017-.32-.017-.47V16.797c0-6.047 4.921-10.968 10.968-10.968s10.969 4.921 10.969 10.968v7.088l5.828-5.828v-1.26C59.705 7.542 52.18 0 42.908 0c-9.255 0-16.797 7.525-16.797 16.797v28.538c0 1.898.32 3.712.89 5.408l4.956-4.938z"
        fill={props.color || '#000'}
      />
      <path
        d="M67.465 30.973a2.907 2.907 0 00-2.906 2.906v11.456c0 11.942-9.708 21.65-21.65 21.65-4.486 0-8.651-1.377-12.111-3.712l-4.183 4.183c3.83 2.822 8.399 4.686 13.37 5.207v7.525h-9.12a2.907 2.907 0 00-2.906 2.906A2.918 2.918 0 0030.865 86h24.07a2.907 2.907 0 000-5.812h-9.12v-7.525c13.79-1.461 24.556-13.152 24.556-27.312V33.896a2.9 2.9 0 00-2.906-2.923zM23.29 54.472a21.6 21.6 0 01-2.033-9.138V33.88a2.907 2.907 0 00-2.906-2.906 2.918 2.918 0 00-2.906 2.906v11.456c0 4.887 1.277 9.473 3.528 13.454l4.316-4.317zM76.3 6.702c.74 0 1.495.285 2.066.856a2.9 2.9 0 010 4.116L11.582 78.458a2.898 2.898 0 01-2.066.857c-.74 0-1.495-.286-2.066-.857a2.9 2.9 0 010-4.115L74.234 7.558a2.927 2.927 0 012.066-.856z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgMute = _style(SvgIcon)

export default SvgMute
