import React, { FC, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'

export const PrerenderScreen: FC = () => {
  const defaultUrls = [
    'https://pha.curon.co/',
    'https://pha.curon.co/search',
    'https://pha.curon.co/search/near',
    'https://pha.curon.co/search/list',
    'https://pha.curon.co/search/pref/hokkaido',
    'https://pha.curon.co/search/pref/aomori',
    'https://pha.curon.co/search/pref/iwate',
    'https://pha.curon.co/search/pref/miyagi',
    'https://pha.curon.co/search/pref/akita',
    'https://pha.curon.co/search/pref/yamagata',
    'https://pha.curon.co/search/pref/fukushima',
    'https://pha.curon.co/search/pref/ibaraki',
    'https://pha.curon.co/search/pref/tochigi',
    'https://pha.curon.co/search/pref/gunma',
    'https://pha.curon.co/search/pref/saitama',
    'https://pha.curon.co/search/pref/chiba',
    'https://pha.curon.co/search/pref/tokyo',
    'https://pha.curon.co/search/pref/kanagawa',
    'https://pha.curon.co/search/pref/niigata',
    'https://pha.curon.co/search/pref/toyama',
    'https://pha.curon.co/search/pref/ishikawa',
    'https://pha.curon.co/search/pref/fukui',
    'https://pha.curon.co/search/pref/yamanashi',
    'https://pha.curon.co/search/pref/nagano',
    'https://pha.curon.co/search/pref/shizuoka',
    'https://pha.curon.co/search/pref/aichi',
    'https://pha.curon.co/search/pref/mie',
    'https://pha.curon.co/search/pref/shiga',
    'https://pha.curon.co/search/pref/kyoto',
    'https://pha.curon.co/search/pref/osaka',
    'https://pha.curon.co/search/pref/nara',
    'https://pha.curon.co/search/pref/wakayama',
    'https://pha.curon.co/search/pref/tottori',
    'https://pha.curon.co/search/pref/shimane',
    'https://pha.curon.co/search/pref/okayama',
    'https://pha.curon.co/search/pref/hiroshima',
    'https://pha.curon.co/search/pref/yamagushi',
    'https://pha.curon.co/search/pref/tokushima',
    'https://pha.curon.co/search/pref/kagawa',
    'https://pha.curon.co/search/pref/ehime',
    'https://pha.curon.co/search/pref/kochi',
    'https://pha.curon.co/search/pref/fukuoka',
    'https://pha.curon.co/search/pref/saga',
    'https://pha.curon.co/search/pref/nagasaki',
    'https://pha.curon.co/search/pref/kumamoto',
    'https://pha.curon.co/search/pref/oita',
    'https://pha.curon.co/search/pref/miyazaki',
    'https://pha.curon.co/search/pref/kagoshima',
    'https://pha.curon.co/search/pref/okinawa',
    'https://pha.curon.co/search/chain/welcia/list',
    'https://pha.curon.co/search/chain/cocokarafine/list',
    'https://pha.curon.co/search/chain/hanshin/list',
    'https://pha.curon.co/search/chain/aeon/list',
    'https://pha.curon.co/search/chain/aisei/list',
    'https://pha.curon.co/search/chain/qol/list',
    'https://pha.curon.co/search/chain/pfercos/list',
    'https://pha.curon.co/search/chain/tomods/list',
    'https://pha.curon.co/search/chain/tanpopo/list',
    'https://pha.curon.co/search/chain/hacdrug/list',
    'https://pha.curon.co/search/chain/mheart/list',
    'https://pha.curon.co/search/chain/dacs/list',
    'https://pha.curon.co/search/chain/hanamaru/list',
    'https://pha.curon.co/search/chain/kanemitsu/list',
    'https://pha.curon.co/search/chain/ain/list',
    'https://pha.curon.co/search/chain/welcia',
    'https://pha.curon.co/search/chain/cocokarafine',
    'https://pha.curon.co/search/chain/hanshin',
    'https://pha.curon.co/search/chain/aeon',
    'https://pha.curon.co/search/chain/aisei',
    'https://pha.curon.co/search/chain/qol',
    'https://pha.curon.co/search/chain/pfercos',
    'https://pha.curon.co/search/chain/tomods',
    'https://pha.curon.co/search/chain/tanpopo',
    'https://pha.curon.co/search/chain/hacdrug',
    'https://pha.curon.co/search/chain/mheart',
    'https://pha.curon.co/search/chain/dacs',
    'https://pha.curon.co/search/chain/hanamaru',
    'https://pha.curon.co/search/chain/kanemitsu',
    'https://pha.curon.co/search/chain/ain',
  ]
  const [urls, setUrls] = useState<string[]>(defaultUrls)
  const [adaptiveType, setAdaptiveType] = useState<string>('mobile')

  const prerenderToken = process.env.REACT_APP_PRERENDER_TOKEN

  if (!prerenderToken) {
    return null
  }

  const handleClick = async () => {
    try {
      const requestBody = {
        prerenderToken: prerenderToken,
        urls: urls,
        adaptiveType: adaptiveType,
      }

      const response = await fetch('https://api.prerender.io/recache', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })

      if (response.ok) {
        alert(urls.length + '件　送信しました')
      } else {
        alert('送信に失敗しました')
      }
    } catch (error) {
      alert('送信に失敗しました')
    }
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container>
        <h1>プリレンダーのキャッシュ送信</h1>
        <p>注意：1000件あたり2ドルかかるので連打するなどは注意してください</p>
        <TextArea
          onChange={e => setUrls(e.target.value.split('\n'))}
          defaultValue={defaultUrls.join('\n')}
        />
        <input
          type="radio"
          name="adaptiveType"
          value="mobile"
          defaultChecked
          onClick={() => {
            setAdaptiveType('mobile')
          }}
        />
        モバイル
        <input
          type="radio"
          name="adaptiveType"
          value="desktop"
          onClick={() => {
            setAdaptiveType('desktop')
          }}
        />
        PC
        <br />
        <br />
        <Button onClick={handleClick}>送信</Button>
        <p>送信するURL一覧(全{urls.length}件)</p>
        <UrlList>
          {urls.map((url, index) => {
            return <UrlItem key={index}>{url}</UrlItem>
          })}
        </UrlList>
      </Container>
    </>
  )
}

const Container = styled.div`
  margin: 20px;
`

const TextArea = styled.textarea`
  width: 100%;
  height: 150px;
`

const UrlList = styled.ul`
  list-style-type: none;
  padding: 0;
`

const UrlItem = styled.li`
  margin-bottom: 5px;
`

// buttonのスタイルを定義するコンポーネント
const Button = styled.button`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  color: #333;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  margin: 0;
  padding: 6px 12px;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  white-space: nowrap;
`
