import { useMemo } from 'react'
import {
  AROUND_CURRENT_LOCATION,
  Prefecture,
  PREFECTURE_LIST,
} from 'src/modules/constants/prefecture-list'
import { Chain } from 'src/modules/entities/chain/entity'
import { Area, Station } from 'src/modules/entities/pharmacy/entity'
import { routes } from 'src/modules/routes'
import { PageType } from 'src/modules/screens/net-application/search/helper'

const resolvePrefectureValue = (prefecture: string) => {
  const [first] = PREFECTURE_LIST.filter(p => p.name === prefecture).map(
    p => p.value,
  )
  return first
}

export const useSearchNavBreadCrumbs = ({
  pageType,
  area,
  chain,
  keyword,
  prefecture,
  station,
}: {
  pageType: PageType
  keyword: string | null
  prefecture: Prefecture | null
  area: Area | null
  station: Station | null
  chain: Chain | null
}) =>
  useMemo(() => {
    const defaultBreadcrumb = [
      { label: '薬局一覧', href: routes.search.build() },
    ]
    const breadcrumbList = (() => {
      switch (pageType) {
        case 'search':
          return defaultBreadcrumb
        case 'search_near':
          return [
            {
              label: '近くの薬局一覧',
              href: routes.searchNear.path,
            },
          ]
        case 'search_list':
          return [
            {
              label: '全国の薬局一覧',
              href: routes.searchList.path,
            },
          ]
        case 'search_pref':
          if (prefecture && prefecture.id !== AROUND_CURRENT_LOCATION.id) {
            return [
              {
                label: keyword
                  ? prefecture.name
                  : `${prefecture.name}の薬局一覧`,
                href: routes.searchPref.build(prefecture.value),
              },
            ]
          }
          return defaultBreadcrumb
        case 'search_area':
          if (prefecture && area) {
            return [
              {
                label: area.prefecture,
                href: routes.searchPref.build(
                  resolvePrefectureValue(area.prefecture),
                ),
              },
              {
                label: keyword ? area.name : `${area.name}の薬局一覧`,
                href: routes.searchPrefArea.build(prefecture.value, area.slug),
              },
            ]
          }
          return defaultBreadcrumb
        case 'search_station':
          if (prefecture && station) {
            return [
              {
                label: station.prefecture,
                href: routes.searchPref.build(
                  resolvePrefectureValue(station.prefecture),
                ),
              },
              {
                label: keyword
                  ? `${station.name}駅`
                  : `${station.name}駅周辺の薬局一覧`,
                href: routes.searchPrefStation.build(
                  prefecture.value,
                  station.code,
                ),
              },
            ]
          }
          return defaultBreadcrumb

        case 'search_chain':
          if (chain) {
            return [
              {
                label: `${chain.name}`,
                href: routes.searchChain.build(chain.code),
              },
            ]
          }
          return defaultBreadcrumb

        case 'search_chain_list':
          if (chain) {
            return [
              {
                label: `${chain.name}`,
                href: routes.searchChain.build(chain.code),
              },
              {
                label: `全国 ${chain.name}薬局の店舗一覧`,
                href: routes.searchChainList.build(chain.code),
              },
            ]
          }
          return defaultBreadcrumb

        case 'search_chain_pref':
          if (chain && prefecture) {
            return [
              {
                label: chain.name,
                href: routes.searchChain.build(chain.code),
              },
              {
                label: `${prefecture.name} ${chain.name}の店舗一覧`,
                href: routes.searchChainPref.build(
                  prefecture.value,
                  chain.code,
                ),
              },
            ]
          }
          return defaultBreadcrumb

        case 'search_chain_area':
          if (chain && prefecture && area) {
            return [
              {
                label: chain.name,
                href: routes.searchChain.build(chain.code),
              },
              {
                label: `${prefecture.name} ${chain.name}`,
                href: routes.searchChainPref.build(
                  prefecture.value,
                  chain.code,
                ),
              },
              {
                label: `${area.name} ${chain.name}の店舗一覧`,
                href: routes.searchChainPrefArea.build(
                  prefecture.value,
                  area.slug,
                  chain.code,
                ),
              },
            ]
          }
          return defaultBreadcrumb

        case 'search_chain_station':
          if (chain && prefecture && station) {
            return [
              {
                label: chain.name,
                href: routes.searchChain.build(chain.code),
              },
              {
                label: `${prefecture.name} ${chain.name}`,
                href: routes.searchChainPref.build(
                  prefecture.value,
                  chain.code,
                ),
              },
              {
                label: `${station.name}駅 ${chain.name}の店舗一覧`,
                href: routes.searchChainPrefStation.build(
                  prefecture.value,
                  station.code,
                  chain.code,
                ),
              },
            ]
          }
          return defaultBreadcrumb

        default:
          return defaultBreadcrumb
      }
    })()
    if (keyword) {
      breadcrumbList.push({ label: `${keyword}の検索結果`, href: '' })
    }
    return breadcrumbList
  }, [keyword, pageType, chain, prefecture, area, station])
