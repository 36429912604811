import {
  createMuiTheme,
  MuiThemeProvider,
} from '@bit/mui-org.material-ui.styles'
import React, { FC } from 'react'
import { ThemeProvider, StyleSheetManager } from 'styled-components'
import { Theme } from 'styled-system'

export type ThemeColors = {
  green: string
  greenShadow: string
  seaGreen: string
  seaFormGreen: string
  mintGreen: string
  mintGreenLight: string
  pistachioGreen: string
  darkGreen: string
}

const themeColors: ThemeColors = {
  green: '#008E44',
  greenShadow: 'rgba(0, 117, 57, 0.3)',
  seaGreen: '#4CAF7C',
  seaFormGreen: '#7FC6A1',
  mintGreen: '#B2DDC6',
  mintGreenLight: '#B2DDC699',
  pistachioGreen: '#F2F9F5',
  darkGreen: '#007539',
}

// colorsはexportしないようにしてください。
// テーマの切り替えができなくなるのでstyled-systemまたはMaterialUIのthemeを経由して
// colorsを参照してください。
const colors = {
  appText: '#313541',
  appBackground: '#F7F9FA',
  errorInputBackgroundColor: '#FFE3E9',

  white: '#ffffff',
  shuttleGray: '#B0BAC5',
  slateGray: '#D2DCE8',
  lightGray: '#DFE6EE',
  whiteGray: '#F4F7FA',
  darkGray: '#667587',

  blue: '#0C8AEE',
  lightBlue: '#67B9FF',
  darkBlue: '#005EBB',

  yellow: '#FFCD46',
  lightYellow: '#FFFF78',
  darkYellow: '#C89C02',

  red: '#FE446D',
  lightRed: '#FFBFCF',
  lighterRed: '#FFF5F3',
  darkRed: '#C50043',

  purple: ' #8D6CD1',
  lightPurple: '#BF9BFF',
  darkPurple: '#5C409F',

  brown: '#F99F4D',
  lightBrown: '#FFD07C',
  darkBrown: '#C2701E',
  neutralGray: '#637182',

  alert: '#F33613',

  // パレットにない配色
  ratGray: '#93A3B5',

  // デザインシステムの色 (https://www.figma.com/file/sPqAV0R6EURl23RhYyU25T/2nd-%F0%9F%8E%A8Curon-Design-System?type=design&node-id=1971-7014&mode=dev)
  dsBlue: {
    50: '#F5FBFF',
    100: '#E7F4FD',
    200: '#D0EAFB',
    300: '#A1D5F7',
    400: '#71C0F4',
    500: '#42AAF0',
    600: '#1395EC',
    700: '#0F72BD',
    800: '#0B518E',
    900: '#08365E',
  },
  dsYellow: {
    50: '#FFFCF5',
    100: '#FEF6E6',
    200: '#FEECCD',
    300: '#FCD79C',
    400: '#FBC16A',
    500: '#F2A840',
    600: '#E98E16',
    700: '#C75605',
    800: '#953404',
    900: '#632303',
  },
  dsRed: {
    50: '#FFF6F5',
    100: '#FFECEB',
    200: '#FCD2CF',
    300: '#FAA79E',
    400: '#F77B6E',
    500: '#F25240',
    600: '#E2341D',
    700: '#BD240F',
    800: '#991400',
    900: '#660E00',
  },
  dsGray: {
    50: '#F8FAFB',
    100: '#EEF2F6',
    200: '#DEE5ED',
    300: '#C2CCD6',
    400: '#A5B1C0',
    500: '#8797AB',
    600: '#6C7E93',
    700: '#576375',
    800: '#414B58',
    900: '#2B323B',
  },
  dsGreen: {
    50: '#F7FCFA',
    100: '#ECF9F2',
    200: '#D9F2E5',
    300: '#B3E5CC',
    400: '#65BF92',
    500: '#66CC99',
    600: '#4DB27F',
    700: '#2BA168',
    800: '#26734C',
    900: '#194D33',
  },
}

export const spaces = {
  // picked naming style: https://tailwindcss.com/docs/customizing-spacing
  1: '4px',
  2: '8px',
  3: '12px',
  4: '16px',
  5: '20px',
  6: '24px',
  7: '28px',
  8: '32px',
  9: '36px',
  10: '40px',
  // Use the pixel directly when over 40px
}

const defaultStyledTheme: Theme = {
  colors: { ...colors, ...themeColors },
  fontSizes: {
    small: '12px',
    normal: '14px',
    large: '16px',
    xlarge: '18px',
  },
  fontWeights: {
    // NOTE: overwrite css-native `normal` and `bold`
    normal: 400,
    bold: 600,
  },
  space: spaces,
}

const styledTheme = {
  ...defaultStyledTheme,
  colors: { ...colors, ...themeColors },
}

const muiTheme = createMuiTheme({
  themeColors,
})

export const AppThemeProvider: FC = ({ children }) => {
  return (
    <>
      <StyleSheetManager disableCSSOMInjection>
        <ThemeProvider theme={styledTheme}>
          <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
        </ThemeProvider>
      </StyleSheetManager>
    </>
  )
}
