export const PREFECTURE_LIST = [
  { id: 1, name: '北海道', value: 'hokkaido' },
  { id: 2, name: '青森県', value: 'aomori' },
  { id: 3, name: '岩手県', value: 'iwate' },
  { id: 4, name: '宮城県', value: 'miyagi' },
  { id: 5, name: '秋田県', value: 'akita' },
  { id: 6, name: '山形県', value: 'yamagata' },
  { id: 7, name: '福島県', value: 'fukushima' },
  { id: 8, name: '茨城県', value: 'ibaraki' },
  { id: 9, name: '栃木県', value: 'tochigi' },
  { id: 10, name: '群馬県', value: 'gunma' },
  { id: 11, name: '埼玉県', value: 'saitama' },
  { id: 12, name: '千葉県', value: 'chiba' },
  { id: 13, name: '東京都', value: 'tokyo' },
  { id: 14, name: '神奈川県', value: 'kanagawa' },
  { id: 15, name: '新潟県', value: 'niigata' },
  { id: 16, name: '富山県', value: 'toyama' },
  { id: 17, name: '石川県', value: 'ishikawa' },
  { id: 18, name: '福井県', value: 'fukui' },
  { id: 19, name: '山梨県', value: 'yamanashi' },
  { id: 20, name: '長野県', value: 'nagano' },
  { id: 21, name: '岐阜県', value: 'gifu' },
  { id: 22, name: '静岡県', value: 'shizuoka' },
  { id: 23, name: '愛知県', value: 'aichi' },
  { id: 24, name: '三重県', value: 'mie' },
  { id: 25, name: '滋賀県', value: 'shiga' },
  { id: 26, name: '京都府', value: 'kyoto' },
  { id: 27, name: '大阪府', value: 'osaka' },
  { id: 28, name: '兵庫県', value: 'hyogo' },
  { id: 29, name: '奈良県', value: 'nara' },
  { id: 30, name: '和歌山県', value: 'wakayama' },
  { id: 31, name: '鳥取県', value: 'tottori' },
  { id: 32, name: '島根県', value: 'shimane' },
  { id: 33, name: '岡山県', value: 'okayama' },
  { id: 34, name: '広島県', value: 'hiroshima' },
  { id: 35, name: '山口県', value: 'yamagushi' },
  { id: 36, name: '徳島県', value: 'tokushima' },
  { id: 37, name: '香川県', value: 'kagawa' },
  { id: 38, name: '愛媛県', value: 'ehime' },
  { id: 39, name: '高知県', value: 'kochi' },
  { id: 40, name: '福岡県', value: 'fukuoka' },
  { id: 41, name: '佐賀県', value: 'saga' },
  { id: 42, name: '長崎県', value: 'nagasaki' },
  { id: 43, name: '熊本県', value: 'kumamoto' },
  { id: 44, name: '大分県', value: 'oita' },
  { id: 45, name: '宮崎県', value: 'miyazaki' },
  { id: 46, name: '鹿児島県', value: 'kagoshima' },
  { id: 47, name: '沖縄県', value: 'okinawa' },
]

export const ALL_PREFECTURES = { id: 101, name: '全国', value: 'all' }
export const AROUND_CURRENT_LOCATION = {
  id: 102,
  name: '現在地周辺',
  value: 'current_location',
}

export type Prefecture = {
  id: number
  name: string
  value: string
}
