import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M79.18 59.708c-.183-.587-1.46-1.46-3.75-2.615-.608-.364-1.5-.85-2.656-1.48-1.155-.628-2.189-1.215-3.141-1.722a92.243 92.243 0 01-2.635-1.54c-.142-.102-.548-.386-1.237-.873-.69-.486-1.277-.83-1.743-1.053-.467-.223-.953-.345-1.399-.345-.649 0-1.48.466-2.453 1.399a24.274 24.274 0 00-2.696 3.06 27.269 27.269 0 01-2.594 3.04c-.933.933-1.683 1.4-2.25 1.4-.284 0-.67-.102-1.095-.244a14.69 14.69 0 01-1.013-.405 15.37 15.37 0 01-1.176-.69c-.547-.344-.872-.547-.932-.567-4.5-2.513-8.372-5.371-11.595-8.595-3.223-3.223-6.081-7.094-8.595-11.594-.02-.06-.223-.385-.567-.933-.345-.547-.588-.952-.69-1.175-.121-.244-.243-.568-.405-1.014-.162-.446-.243-.81-.243-1.094 0-.608.466-1.358 1.398-2.25a26.97 26.97 0 013.041-2.595 24.267 24.267 0 003.06-2.696c.933-.973 1.4-1.804 1.4-2.453 0-.446-.122-.932-.345-1.398-.223-.467-.588-1.075-1.054-1.744-.487-.689-.77-1.094-.872-1.236a117.5 117.5 0 01-1.54-2.635c-.527-.933-1.095-1.987-1.723-3.142-.629-1.156-1.115-2.047-1.48-2.656-1.155-2.29-2.027-3.567-2.615-3.75-.223-.1-.588-.141-1.034-.141-.892 0-2.047.141-3.486.486-1.42.324-2.554.649-3.365 1.014-1.642.668-3.365 2.675-5.21 5.98-1.662 3.08-2.493 6.141-2.493 9.141 0 .892.06 1.764.183 2.575.121.85.324 1.783.608 2.837.284 1.055.527 1.825.73 2.352.182.507.526 1.419 1.033 2.736a63.5 63.5 0 01.892 2.433c1.155 3.223 2.534 6.101 4.095 8.635 2.493 4.074 6.04 8.432 10.54 12.932 4.5 4.5 8.859 8.048 13.054 10.663 2.534 1.601 5.413 2.939 8.636 4.094.304.102 1.094.406 2.432.892 1.318.507 2.23.852 2.737 1.034.506.182 1.277.426 2.35.73 1.055.284 2.008.507 2.839.608.851.121 1.703.162 2.574.182 3.02.02 6.081-.81 9.142-2.493 3.324-1.824 5.31-3.568 5.98-5.21.365-.81.689-1.945 1.013-3.364.325-1.42.487-2.595.487-3.487.02-.466-.04-.81-.142-1.034z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgTel = _style(SvgIcon)

export default SvgTel
