import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { useSWRFetch as useStationSearchByRecommendFetch } from 'src/modules/apis/pharmacy/stations/recommend_stations/use-fetch'
import { useSWRFetch as useStationSearchByKeywordFetch } from 'src/modules/apis/pharmacy/stations/search_by_keyword/use-fetch'
import { Input } from 'src/modules/components/lib/input'
import { Link } from 'src/modules/components/lib/link'
import { View } from 'src/modules/components/lib/view'
import { Station } from 'src/modules/entities/pharmacy/entity'
import { getSearchPagePath } from 'src/modules/helpers/get-serch-page-path'
import { useSearchQuery } from 'src/modules/hooks/use-search-query'
import { useViewportHeight } from 'src/modules/hooks/useViewportHeight'
import { PREFECTURE_LIST } from '../../constants/prefecture-list'

import { Border } from '../lib/border'
import { Clickable } from '../lib/clickable'
import { Close2, SearchLeft, Nearby } from '../lib/curon-icon'
import { ModalContext, useModalStackDepth } from '../lib/modal'
import { Text } from '../lib/text'

type AreaContainerRootProps = {
  containerRootHeight: string
}

const AreaContainerRoot = styled.div<AreaContainerRootProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${props => props.containerRootHeight};
  background: white;
  visibility: hidden;
  transition: transform 200ms ease-out 0s;

  &.visible {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    pointer-events: auto;
  }
  &.invisible {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateY(100%);
  }
`

const AreaElementRoot = styled.div`
  &:hover {
    background-color: ${props => props.theme.colors.pistachioGreen};
    outline: none;
  }
`

const CustomInputRoot = styled.div`
  position: relative;
  .icon {
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }
  input {
    padding-left: 30px;
  }
`

const CategoryHeader = styled.div`
  padding: 4px 16px;
  width: 100%;
  background: #f4f7fa;
  border-bottom: 1px solid #ecf0f5;
  font-weight: 600;
  font-size: 14px;
  color: #667587;
`

const OptionLinkRow = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 16px 16px;
  color: #313541;
`

const OptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px 16px 16px;
`

const CurrentLocationRow = styled(Link)`
  display: flex;
  align-items: center;
  padding: 16px;
`

const AreaContainer: FC<
  React.HTMLAttributes<HTMLDivElement> & {
    visible: boolean
  }
> = ({ visible, ...props }) => {
  const context = useContext(ModalContext)
  const stackDepth = useModalStackDepth(visible)
  const vh = useViewportHeight()

  if (!context.root) {
    return null
  }

  return ReactDOM.createPortal(
    <AreaContainerRoot
      style={{ zIndex: stackDepth }}
      className={visible ? 'visible' : 'invisible'}
      containerRootHeight={`calc(${vh * 100}px)`}
      {...props}
    >
      {props.children}
    </AreaContainerRoot>,
    context.root,
  )
}

type StationListProps = {
  stationList: Array<Station>
}

const StationList: FC<StationListProps> = props => {
  const { keyword, chain } = useSearchQuery()

  return (
    <>
      {props.stationList.map(station => {
        const prefecture = PREFECTURE_LIST.find(
          p => p.name === station.prefecture,
        )
        const prefValue = prefecture ? prefecture.value : ''

        const path = getSearchPagePath({
          chainCode: chain,
          keyword: keyword || undefined,
          prefecture: prefValue,
          areaSlug: undefined,
          stationCode: station.code,
        })

        return (
          <AreaElementRoot key={station.code}>
            {station.count > 0 ? (
              <>
                <OptionLinkRow to={path}>
                  <Text>
                    {station.line} {station.name}駅（{station.prefecture}）
                  </Text>
                  <Text>{station.count}件</Text>
                </OptionLinkRow>
                <Border borderColor="#ECF0F5" />
              </>
            ) : (
              <>
                <OptionRow>
                  <Text color="#93A3B5">
                    {station.line} {station.name}駅（{station.prefecture}）
                  </Text>
                  <Text color="#93A3B5">{station.count}件</Text>
                </OptionRow>
                <Border borderColor="#ECF0F5" />
              </>
            )}
          </AreaElementRoot>
        )
      })}
    </>
  )
}

type Props = {
  visible: boolean
  onCloseClick: () => void
}

export const StationModal: FC<Props> = ({ onCloseClick, visible }) => {
  const [inputValue, setInputValue] = useState('')
  const [keyword, setKeyword] = useState('')

  const { chain } = useSearchQuery()

  const { data: stationList } = useStationSearchByRecommendFetch({
    chainCode: chain,
  })

  const { data: stationKeywordData } = useStationSearchByKeywordFetch({
    keywordForSearchStation: keyword ? keyword : null,
    keywordForSearchPharmacy: null,
    chainCode: chain || null,
  })

  const stations = useMemo(
    () => stationKeywordData || stationList,
    [stationKeywordData, stationList],
  )

  const WAIT_SEARCH_PER_SECONDS_FROM_INPUT_VALUE = 700 // 0.7秒
  useEffect(() => {
    const timer = setTimeout(() => {
      setKeyword(inputValue)
    }, WAIT_SEARCH_PER_SECONDS_FROM_INPUT_VALUE)

    return () => clearTimeout(timer)
  }, [keyword, inputValue])

  const nearLocationSearchPath = getSearchPagePath({
    near: true,
  })

  return (
    <AreaContainer visible={visible}>
      <View position="relative" m={4} pr="30px">
        <CustomInputRoot>
          <SearchLeft color="shuttleGray" className="icon" />
          <Input
            type="search"
            width="100%"
            placeholder="駅名で探す"
            value={inputValue}
            onChange={e => {
              setInputValue(e.target.value)
            }}
          />
        </CustomInputRoot>
        <Clickable
          onClick={onCloseClick}
          position="absolute"
          right="0"
          top="12px"
        >
          <Close2 size="16px" color="shuttleGray" />
        </Clickable>
      </View>
      <Border borderColor="#ecf0f5" />

      <View overflowY="scroll" overflowX="hidden" flexGrow={1}>
        <View width="100vw">
          <>
            <AreaElementRoot>
              <CurrentLocationRow to={nearLocationSearchPath}>
                <Nearby color="#27AE57" mr="8px" />
                <Text fontWeight="300" color="black">
                  現在地から探す
                </Text>
              </CurrentLocationRow>
            </AreaElementRoot>
            {!!stations && stations.length > 0 && (
              <>
                <CategoryHeader>
                  {keyword ? '駅名' : 'よく検索される駅'}
                </CategoryHeader>
                <StationList stationList={stations} />
              </>
            )}
          </>
        </View>
      </View>
    </AreaContainer>
  )
}
