import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useSWRFetch as useLatestPharmacySearch } from 'src/modules/apis/pharmacy/latest/use-fetch'
import { useSWRFetch as usePharmacySearchFetch } from 'src/modules/apis/pharmacy/search/use-fetch'
import { useAuthorizationRefresh } from 'src/modules/auth/use-authorization-refresh'
import { useToast } from 'src/modules/components/lib/toast'
import { useFavoritePharmacy } from 'src/modules/favorite/use-favorite-pharmacy'
import { useGeoPosition } from 'src/modules/geo-location'
import { useDeprecatedPageQueryGuard } from '../helper'
import { NetApplicationSearchTopScreen } from './screen'

export const NetApplicationSearchTopScreenContainer = () => {
  useDeprecatedPageQueryGuard()
  const { authorized, fetched, authorizedAccount } = useAuthorizationRefresh()
  const { showToast } = useToast()
  const { favorites } = useFavoritePharmacy()

  const { data: latestPharmacies, loading: latestPharmaciesLoading } =
    useLatestPharmacySearch({})

  const {
    data: currentPosition,
    isApproved: isGeoApproved,
    isCompleted,
    isLoading: isGeoLoading,
  } = useGeoPosition({
    onCompleted: () => {
      if (!isGeoApproved) {
        showToast('位置情報の取得に失敗しました。', true)
        return
      }
    },
  })

  const { data: nearPharmacies, loading: nearPharmaciesLoading } =
    usePharmacySearchFetch({
      params: {
        keyword: null,
        prefecture: null,
        area: null,
        station: null,
        currentLocation: true,
        lat: currentPosition ? currentPosition.coords.latitude : null,
        lng: currentPosition ? currentPosition.coords.longitude : null,
        chain: null,
      },
      skip: !currentPosition,
    })

  const favoritePharmacies = favorites
    ?.filter(
      favoritePharmacy =>
        favoritePharmacy.patientUid === authorizedAccount?.mainUserUid,
    )
    .map(favoritePharmacy => favoritePharmacy.pharmacy)

  const loading = isGeoApproved
    ? nearPharmaciesLoading || isGeoLoading
    : isGeoLoading

  return (
    <>
      <Helmet>
        <title>
          薬局・ドラッグストア検索&ネット処方箋受付 | curon (クロン)お薬サポート
        </title>
        <link rel="canonical" href="https://pha.curon.co/search" />
        <meta
          name="description"
          content="ネット処方箋受付でお薬受け取りの待ち時間を短縮！薬局・ドラッグストアーを地図から探したり、駅名や都道府県などお好みの条件で全国で約5000件の店舗から深夜・24時間営業している店舗や日曜・祝日営業している店舗など、充実した条件でお探しいただけます。"
        />
      </Helmet>
      <NetApplicationSearchTopScreen
        authorized={authorized}
        fetched={fetched}
        latestPharmacies={latestPharmacies?.pharmacies || []}
        nearPharmacies={nearPharmacies?.pharmacies || []}
        favoritePharmacies={favoritePharmacies || []}
        nearPharmaciesLoading={loading}
        currentPosition={currentPosition}
        isGeoApproved={!!isGeoApproved}
        latestPharmaciesLoading={latestPharmaciesLoading}
      />
    </>
  )
}
