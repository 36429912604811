import React, { FC, PropsWithChildren, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetch } from 'src/modules/apis/auth/ping/request'
import { Loader } from 'src/modules/components/lib/loader'
import { useIdPlatform } from 'src/modules/hooks/useIdPlatform'
import { useAccount } from '../hooks/use-account'
import { actions as authActions } from './redux'

export const LoggedInGuard: PropsWithChildren<FC> = ({ children }) => {
  const { authorized, authorizedAccount } = useAccount()
  const { redirectToLogin } = useIdPlatform()

  const dispatch = useDispatch()

  useEffect(() => {
    if (authorized) {
      return
    }

    const doPing = async () => {
      try {
        const account = await fetch()
        if (account) {
          dispatch(authActions.authorize({ account }))
        } else {
          dispatch(authActions.unauthorize())
          redirectToLogin()
        }
      } catch (error) {
        dispatch(authActions.unauthorize())
        redirectToLogin()
      }
    }

    doPing()
  }, [authorized, dispatch, redirectToLogin])

  if (!authorizedAccount || !authorized) {
    return <Loader />
  }

  return <>{children}</>
}
