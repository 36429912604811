import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="14"
      height="17"
      viewBox="0 0 14 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.4221 7.39368C13.4221 10.9404 7.00012 16.9938 7.00012 16.9938C7.00012 16.9938 0.578125 10.9404 0.578125 7.39368C0.578125 3.84691 3.45335 0.97168 7.00012 0.97168C10.5469 0.97168 13.4221 3.84691 13.4221 7.39368ZM9.24629 7.65834C9.24629 8.8989 8.24063 9.90457 7.00007 9.90457C5.75951 9.90457 4.75385 8.8989 4.75385 7.65834C4.75385 6.41779 5.75951 5.41212 7.00007 5.41212C8.24063 5.41212 9.24629 6.41779 9.24629 7.65834Z"
        fill={props.color || '#4E5966'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  object,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgLocation2 = _style(SvgIcon)

export default SvgLocation2
