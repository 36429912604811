import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useIdPlatform } from 'src/modules/hooks/useIdPlatform'
import { routes } from 'src/modules/routes'

export const LoginScreenContainer: FC = () => {
  const { redirectToLogin } = useIdPlatform()

  useEffect(() => {
    // 共通基盤のログイン画面にリダイレクト
    redirectToLogin(routes.home.build())
  }, [redirectToLogin])

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    </>
  )
}
