import React, { FC, useState } from 'react'

import { Button } from 'src/modules/components/lib/button'
import { ArrowUp, ArrowDown } from 'src/modules/components/lib/curon-icon'
import { Text } from 'src/modules/components/lib/text'
import { View, Flexbox } from 'src/modules/components/lib/view'
import { Pharmacy } from 'src/modules/entities/pharmacy/entity'
import { Spacer } from 'src/modules/screens/top/spacer'
import { FavoritePharmacyCard } from './favorite-pharmacy-card'
type Props = {
  favoritePharmacies: Pharmacy[] | null
}

export const Favorite: FC<Props> = ({ favoritePharmacies }) => {
  const [openMore, setOpenMore] = useState(false)

  if (!favoritePharmacies || favoritePharmacies?.length === 0) return null

  const pharmacies = openMore
    ? favoritePharmacies
    : favoritePharmacies.slice(0, 3)
  return (
    <View width="100%">
      <Flexbox width="100%" alignItems="center" justifyContent="space-between">
        <Text fontWeight="bold" fontSize="16px">
          お気に入り薬局
        </Text>
      </Flexbox>
      <Spacer size={12} />

      {pharmacies.map(pharmacy => (
        <>
          <FavoritePharmacyCard key={pharmacy.uid} pharmacy={pharmacy} />
          <Spacer horizontal size={14} />
        </>
      ))}

      {favoritePharmacies.length > 3 && (
        <Flexbox justifyContent="center">
          <Button
            onClick={() => {
              setOpenMore(!openMore)
            }}
            colorStyle="green-outlined"
            sizeStyle="small"
            shape="square"
            style={{
              boxShadow: 'none',
            }}
          >
            <Flexbox alignItems="center">
              {openMore ? '閉じる' : 'もっと見る'}
              <Spacer size={4} horizontal />
              {openMore ? (
                <ArrowUp size="16px" color="#4DB27F" />
              ) : (
                <ArrowDown size="16px" color="#4DB27F" />
              )}
            </Flexbox>
          </Button>
        </Flexbox>
      )}
    </View>
  )
}
