import css from '@styled-system/css'
import * as React from 'react'
import styled, { StyledComponent, ThemeContext } from 'styled-components'
import {
  color,
  ColorProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system'

const SvgIcon = (
  props: React.SVGProps<SVGSVGElement> & {
    size?: number | string
    color?: string
  },
) => {
  const { size } = props

  if (size != null) {
    props = {
      width: size,
      height: size,
      ...props,
    }
  }

  const themeContext = React.useContext(ThemeContext)

  if (props.color != null) {
    props = { ...props, color: themeContext.colors[props.color] || props.color }
  }

  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M83.535 84.137H2.465c-.659 0-1.29-.258-1.748-.717A2.472 2.472 0 010 81.67V26.66c0-.66.258-1.29.717-1.749a2.472 2.472 0 011.748-.716h16.34V4.329c0-.66.258-1.29.717-1.749a2.472 2.472 0 011.749-.717h42.828c.659 0 1.29.258 1.748.717.459.459.717 1.09.717 1.749v19.866h16.913c.316 0 .66.057.946.2.287.115.574.316.803.545.23.23.401.516.545.803.114.286.2.63.2.946V81.67c0 .66-.258 1.29-.716 1.749-.43.459-1.061.717-1.72.717zm-78.576-4.96h76.11V29.154H64.156c-.66 0-1.29-.258-1.749-.717a2.473 2.473 0 01-.716-1.748V6.823H23.765V26.66c0 .66-.258 1.29-.717 1.749a2.472 2.472 0 01-1.749.716H4.96v50.052zm46.555-58.566h-6.049v-6.048h-4.959v6.048h-6.049v4.96h6.049v6.048h4.96v-6.048h6.048v-4.96zM25.657 42.083h-8.228v8.256h8.256v-8.256h-.028zm20.668-.115H38.07v8.256h8.256v-8.256zm22.246 0h-8.256v8.256h8.256v-8.256zM25.657 59.225h-8.228v8.256h8.256v-8.256h-.028zm20.668-.114H38.07v8.256h8.256V59.11zm22.246 0h-8.256v8.256h8.256V59.11z"
        fill={props.color || '#000'}
      />
    </svg>
  )
}

type StyledSystemProps = TypographyProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  PositionProps

const _style = (
  component: React.FC,
): StyledComponent<
  React.FC<
    React.SVGProps<SVGSVGElement> & {
      size?: number | string
      color?: string
    }
  >,
  Record<string, unknown>,
  StyledSystemProps,
  never
> => {
  const styledSystemProps = [typography, space, layout, color, position]
  return styled(component)(css({}), styledSystemProps)
}

const SvgHospital = _style(SvgIcon)

export default SvgHospital
