import { PharmacyVisitAttachment } from 'src/modules/entities/pharmacy-visit-attachment/entity'

export type PharmacyVisitAttachmentResponse = {
  uid: string
  uploaded_at: string
  url: string
}

export const parse = (
  resp: PharmacyVisitAttachmentResponse,
): PharmacyVisitAttachment => {
  return {
    uid: resp.uid,
    uploadedAt: resp.uploaded_at,
    url: resp.url,
  }
}
